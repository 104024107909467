<script lang="ts">
  import { onMount } from "svelte";
  export let text;
  export let bannerID;

  let bannerRef;
  let loadBanner;

  const remove = () => {
    bannerRef.remove();
    localStorage.setItem(bannerID, "viewed");
  };

  // banner will no show up again once it has been closed out
  onMount(() => {
    if (localStorage.getItem(bannerID) === "viewed") {
      loadBanner = false;
    } else {
      loadBanner = true;
    }
  });
</script>

<div
  class="ca-announcement-bar-carta"
  class:load-banner={loadBanner}
  bind:this={bannerRef}
>
  <div class="container-xl ca-announcement-bar-carta__container">
    <div class="ca-announcement-bar-carta__main">
      <slot />
      {text}
    </div>

    <button class="ca-announcement-bar-carta__close" on:click={remove}>
      <svg
        fill="#000000"
        height="200px"
        width="200px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 460.775 460.775"
        xml:space="preserve"
        ><g id="SVGRepo_bgCarrier" stroke-width="0" /><g
          id="SVGRepo_tracerCarrier"
          stroke-linecap="round"
          stroke-linejoin="round"
        /><g id="SVGRepo_iconCarrier">
          <path
            d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
          />
        </g></svg
      >
    </button>
  </div>
</div>

<!-- added 'carta' to the names of all the classes and copied css 
	from anouncement-bar.scss and bootstrap.scss because there was a third party 
	script injecting css making .ca-anouncement-bar display none 
	Refer to TEMP- CES Post Event Stuffs in GTM
-->
<style>
  .ca-announcement-bar-carta.load-banner:has(
      + .ca-announcement-bar-carta.load-banner
    ) {
    margin-bottom: 10px;
  }
  .ca-announcement-bar-carta {
    background-color: #e3eef3;
    padding: 18px 0;
    color: var(--clr-global-body);
    font-size: 0.875rem;
    line-height: 1.45;
    display: none;
  }
  .ca-announcement-bar-carta.load-banner {
    display: block;
  }
  /* .ca-announcement-bar-carta a {
		color: var(--clr-global-body);
	} */
  .ca-announcement-bar-carta__container {
    display: grid;
    grid-template-columns: 1fr 28px;
    gap: 1rem;
    align-items: flex-start;
  }
  .ca-announcement-bar-carta__main {
    width: 100%;
  }
  .ca-announcement-bar-carta__close {
    appearance: none;
    border: 0;
    width: 28px;
    height: 28px;
    background: transparent;
    cursor: pointer;
  }
  .ca-announcement-bar-carta__close svg {
    width: 100%;
  }
  @media (min-width: 48rem) {
    .ca-announcement-bar-carta__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
    }
  }
  @media (min-width: 62rem) {
    .ca-announcement-bar-carta {
      font-size: 1rem;
      padding: 32px 0;
    }
  }
  @media (max-width: 1199.98px) {
    .ca-announcement-bar-carta__container {
      padding-right: var(--bs-gutter-x);
      padding-left: var(--bs-gutter-x);
    }
  }
</style>
