<script lang="ts">
  import "./PricingCard.scss";
  import PricingCardPartner from "./variants/PricingCardPartner.svelte";
  import PricingCardPrimary from "./variants/PricingCardPrimary.svelte";
  import PricingCardTopHorizontal from "./variants/PricingCardTopHorizontal.svelte";
  import PricingCardBottomHorizontal from "./variants/PricingCardBottomHorizontal.svelte";
  import PricingCardFlexible from "./variants/PricingCardFlexible/PricingCardFlexible.svelte";

  export let variant;
  export let heading;
  export let content;
  export let tag;
  export let tagLocation;
  export let features;
  export let price;
  export let priceString;
  export let starting;
  export let buttons;
  export let open = false;
  export let backgroundColor;
  export let priceSuffix;
  export let priceStringSize;
  export let startingOnly = false;

  $: variant = variant || "primary";
  $: heading = heading || "";
  $: content = content || "";
  $: tag = tag || "";
  $: tagLocation = tagLocation === "After heading" ? "order-2" : "";
  $: features = features || [];
  $: price = price || "";
  $: starting = starting || "";
  $: buttons = buttons || [];
  $: open = open || false;
  $: backgroundColor = backgroundColor || "Light Blue";

  const variants = {
    primary: PricingCardPrimary,
    partner: PricingCardPartner,
    "bottom-horizontal": PricingCardBottomHorizontal,
    "Bottom-Horizontal": PricingCardBottomHorizontal,
    "top-horizontal": PricingCardTopHorizontal,
    flexible: PricingCardFlexible,
  };

  const colors = {
    // 'Highlight Green': 'ca-bg--highlight-green',
    // 'Highlight Yellow': 'ca-bg--highlight-yellow',
    // 'Midtone Green': 'ca-bg--midtone-green',
    // 'Midtone Orange': 'ca-bg--midtone-orange',
    // 'Light Green': 'ca-bg--light-green',
    // 'Light Orange': 'ca-bg--light-orange',
    // 'Light Beige': 'ca-bg--light-beige',
    "Light Cream": "ca-bg--light-cream",
    "Light Blue": "ca-bg--light-blue",
    // Black: 'ca-bg--black',
    White: "ca-bg--neutral-white",
  };
</script>

<svelte:component
  this={variants[variant]}
  {heading}
  {content}
  {tag}
  {tagLocation}
  {features}
  {open}
  {price}
  {starting}
  {buttons}
  {priceSuffix}
  {startingOnly}
  {priceString}
  {priceStringSize}
  backgroundColor={colors[backgroundColor]}
/>
