<script lang="ts">
  import MenuSubItem from "../../MenuSubItem.svelte";
  import SubMenuItemTitle from "../../SubMenuItemTitle.svelte";
  import Card from "../../../../Card/Card.svelte";
  export let item;
  export let tabStates;
  export let clickTabStates;
  export let updateClickedTab;
</script>

{#each item.items as menuItemL2, i}
  {@const childItems = menuItemL2.items.slice(1)}
  {@const titleItem = menuItemL2.items[0]}
  {@const featItemExists = menuItemL2?.featuredItem?.title}
  <div
    class="center-and-item-container"
    class:active={tabStates[i]}
    class:clickActive={clickTabStates[i]}
  >
    <div class="closing-bar">
      <svg
        class="arrow-svg"
        on:click={() => updateClickedTab(-1)}
        on:keydown
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="18"
        role="button"
        tabindex="0"
      >
        <path
          d="M0.0999031 8.7795L8.65633 17.3359L9.71647 16.2758L2.96992 9.52924L21.8999 9.52925V8.02999L2.9697 8.02998L9.71647 1.28321L8.65634 0.223074L0.0999031 8.7795Z"
          fill="black"
        />
      </svg>
      <div>{menuItemL2?.title}</div>
      <svg
        class="x-svg"
        on:click={() => updateClickedTab(-1)}
        on:keydown
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        role="button"
        tabindex="0"
      >
        <path
          d="M10.9417 11.7879L0.830078 1.67625L1.95689 0.686157L12.0002 10.7294L22.0435 0.686157L23.1703 1.67625L13.0586 11.7879L23.1701 21.8994L22.0433 22.8895L12.0002 12.8463L1.95706 22.8895L0.830251 21.8994L10.9417 11.7879Z"
          fill="black"
        />
      </svg>
    </div>

    <div class={`center-menu`}>
      <SubMenuItemTitle item={titleItem} variant="tabbed-flyouts" />
      <div class="item-underline" />
      <ul
        class="ca-dropdown-menu__list"
        style="--rowNumber: {Math.ceil(childItems.length / 2)};"
      >
        {#each childItems as menuItemL3}
          <MenuSubItem item={menuItemL3} />
        {/each}
      </ul>
    </div>
    {#if featItemExists}
      <div class="featured-item">
        <Card
          variant="nav-feat"
          {...menuItemL2?.featuredItem}
          backgroundColor={menuItemL2?.featuredItem.colorTheme}
          alignment={Math.ceil(childItems.length / 2) >= 3 && "stretch"}
        />
      </div>
    {/if}
  </div>
{/each}

<style lang="scss">
  .center-and-item-container {
    display: none;
    width: 75%;
    grid-template-columns: 1fr 33%;
    padding: 0;
    .closing-bar {
      display: none;
    }
    @media (min-width: 992px) {
      &.active {
        display: grid;
      }
    }
  }
  .center-menu {
    flex-direction: column;
    margin: 0 25px;
  }
  .ca-dropdown-menu__list {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(var(--rowNumber), min-content);
    column-gap: 24px;
    margin: 24px 0 0 0;
    padding: 0;
    list-style-type: none;
  }

  @media (max-width: 991px) {
    .center-and-item-container {
      display: unset;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: scroll;
      background-color: #fff;
      z-index: 1000;
      transition: 250ms;
      transform: translateX(100%);
      .closing-bar {
        display: grid;
        background-color: var(--colorTheme);
        grid-template-columns: 22px 1fr 36px;
        gap: 13px;
        align-content: center;
        height: 64px;
        padding: 0 1.5rem;
        margin-bottom: 2rem;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;
      }

      .item-underline {
        background-color: #d8d8d8;
        width: 100%;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 28px;
      }
      .center-menu {
        margin: 1rem 1.5rem -1rem 1.5rem;
      }
      .ca-dropdown-menu__list {
        display: unset;
      }
      .featured-item {
        padding: 1.75rem;
      }
      &.clickActive {
        transform: translateX(0);
      }
    }
  }
</style>
