<script lang="ts">
  import Heading from "../../Heading/Heading.svelte";
  import Button from "../../Button/Button.svelte";
  import Carousel from "../../Carousel/Carousel.svelte";
  import CarouselItem from "../../Carousel/CarouselItem.svelte";

  export let id = "";
  export let heading = {
    text: "",
    id: "",
  };

  export let text = "";
  export let media = [];
  export let buttons = [];

  let cardTopLeft = media.items[0];
  let cardBottomLeft = media.items[1];
  let cardTopRight = media.items[2];
  let cardBottomRight = media.items[3];
</script>

<section class="ca-section ca-section-home-hero" {id}>
  <div class="ca-hero">
    <div class="container-xl">
      <div class="row">
        <div class="col-3 d-none d-lg-block">
          <div class="row">
            <div class="col-9">
              <div class="ca-card ca-card--portrait">
                <img
                  width="675"
                  height="1012"
                  src={cardTopLeft.image.src}
                  alt={cardTopLeft.image.alt}
                  class="ca-card__img"
                />
                <div class="ca-card__info">
                  <div class="ca-card__info-header">
                    {cardTopLeft.author.name}
                  </div>
                  <div class="ca-card__info-subheader">
                    {cardTopLeft.author.title}
                  </div>
                  <a
                    href={cardTopLeft.author.url}
                    target="_self"
                    class="ca-card__info-link ca-link"
                    >Read innovator story
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="ca-card ca-card--portrait mt-4">
            <img
              width="1200"
              height="675"
              src={cardBottomLeft.image.src}
              alt={cardBottomLeft.image.alt}
              class="ca-card__img"
            />
            <div class="ca-card__info">
              <div class="ca-card__info-header">
                {cardBottomLeft.author.name}
              </div>
              <div class="ca-card__info-subheader">
                {cardBottomLeft.author.title}
              </div>
              <a
                href={cardBottomLeft.author.url}
                target="_self"
                class="ca-card__info-link ca-link"
                >Read innovator story
              </a>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-6">
          <Heading
            {...heading}
            classList="ca-hero__heading mt-lg-3 mt-xl-5"
            tag="h1"
          />

          <p class="ca-hero__subheading">
            {text}
          </p>

          {#if buttons?.length > 0}
            <div
              class="d-flex flex-column flex-md-row align-items-center justify-content-center gap-3 gap-md-3 mt-5 flex-wrap"
            >
              {#each buttons as button}
                <Button {...button} />
              {/each}
            </div>
          {/if}
        </div>
        <!-- <div class="col-3 d-none d-lg-block">
					<div class="row justify-content-end">
						<div class="col-9">
							<div class="ca-card ca-card--portrait">
								<img
									width="675"
									height="1013"
									src="./img/Website-front-page-2-scaled-e1670871549765.jpg"
									alt="Homepage, Innovator story, Deon Nicholas CEO, Forethought AI"
									class="ca-card__img"
								/>
								<div class="ca-card__info">
									<div class="ca-card__info-header">Deon Nicholas</div>
									<div class="ca-card__info-subheader">CEO, Forethought AI</div>
									<a href="/innovator-stories/deon-nicholas/" class="ca-card__info-link ca-link"
										>Read innovator story
									</a>
								</div>
							</div>
						</div>
					</div>

					<div class="ca-card ca-card--portrait mt-4">
						<img
							width="1200"
							height="675"
							src="./img/Tess-Hatch-website-front-page-scaled-e1670887814318.jpg"
							alt="Homepage, Innovator Story Profile,Tess Hatch, Partner, Bessemer Venture Partners"
							class="ca-card__img"
						/>
						<div class="ca-card__info">
							<div class="ca-card__info-header">Tess Hatch</div>
							<div class="ca-card__info-subheader">Partner, Bessemer Venture Partners</div>
							<a href="/innovator-stories/tess-hatch/" class="ca-card__info-link ca-link"
								>Read innovator story
							</a>
						</div>
					</div>
				</div> -->
        <div class="col-3 d-none d-lg-block">
          <div class="row justify-content-end">
            <div class="col-9">
              <div class="ca-card ca-card--portrait">
                <img
                  width="675"
                  height="1012"
                  src={cardTopRight.image.src}
                  alt={cardTopRight.image.alt}
                  class="ca-card__img"
                />
                <div class="ca-card__info">
                  <div class="ca-card__info-header">
                    {cardTopRight.author.name}
                  </div>
                  <div class="ca-card__info-subheader">
                    {cardTopRight.author.title}
                  </div>
                  <a
                    href={cardTopRight.author.url}
                    target="_self"
                    class="ca-card__info-link ca-link"
                    >Read innovator story
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="ca-card ca-card--portrait mt-4">
            <img
              width="1200"
              height="675"
              src={cardBottomRight.image.src}
              alt={cardBottomRight.image.alt}
              class="ca-card__img"
            />
            <div class="ca-card__info">
              <div class="ca-card__info-header">
                {cardBottomRight.author.name}
              </div>
              <div class="ca-card__info-subheader">
                {cardBottomRight.author.title}
              </div>
              <a
                href={cardBottomRight.author.url}
                target="_self"
                class="ca-card__info-link ca-link"
                >Read innovator story
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Carousel variant="innovator-stories">
    {#each media?.items as item}
      <CarouselItem>
        <div class="swiper-slide ca-card">
          <a href={item.author.url} target="_self">
            <img
              width="675"
              height="1012"
              src={item.image.src}
              alt={item.image.alt}
              class="ca-card__img"
            />
          </a>
          <div class="ca-card__info">
            <div class="ca-card__info-header">{item.author.name}</div>
            <div class="ca-card__info-subheader">{item.author.title}</div>
            <a
              href={item.author.url}
              target="_self"
              class="ca-card__info-link ca-link"
              >Read innovator story
            </a>
          </div>
        </div>
      </CarouselItem>
    {/each}
  </Carousel>
</section>
