<script lang="ts">
  import MenuSubItem from "../../MenuSubItem.svelte";
  import SubMenuItemTitle from "../../SubMenuItemTitle.svelte";
  import Card from "../../../../Card/Card.svelte";
  export let item;
</script>

<div class="grid-container container-xl mx-4">
  {#each item.items as cols}
    <div class="menu-group px-0">
      <SubMenuItemTitle item={{ ...cols, title: cols?.title?.toUpperCase() }} />
      <ul class="sub-item-container">
        {#each cols.items as subitem}
          <MenuSubItem item={subitem} />
        {/each}
      </ul>
    </div>
  {/each}
  {#if item?.featuredItem && item.items.length <= 3}
    <div class="featitem-container">
      <Card
        variant="nav-feat"
        {...item.featuredItem}
        backgroundColor={item.featuredItem.colorTheme}
      />
    </div>
  {/if}
</div>

<style lang="scss">
  .grid-container {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(4, 1fr);
    .menu-group {
      display: flex;
      flex-direction: column;
      .sub-item-container {
        margin-top: 26px;

        @media (min-width: 992px) {
          // reset ul,ol
          padding: 0;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  // mobile
  @media (max-width: 991px) {
    .grid-container {
      display: flex;
      flex-direction: column;
      padding: 0;
      gap: 0;
      .menu-group {
        border-bottom: 1px solid #d8d8d8;
        margin-bottom: 2rem;
        .sub-item-container {
          margin-top: 6px;
          padding: 0;
        }
      }
    }
    .menu-group:last-child {
      margin-bottom: -26px;
    }
    .menu-group:has(+ .featitem-container) {
      border-bottom: none;
      margin-bottom: 1rem;
    }
  }
</style>
