// @ts-strict-ignore
import Heading from "./Heading.svelte";

export const HeadingConfig = {
  name: "Heading",
  component: Heading,
  mapper: (props) => {
    return {
      ...props,
      tag: props?.level,
      size: props?.size?.toLowerCase(),
      classList: props?.classList?.join(" "),
    };
  },
  fragment: `fragment HeadingData on Heading {
        text
        size
        level
        font
        size
        align: textAlignment
        classList
        contain
    }`,
};
