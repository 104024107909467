<script lang="ts">
  import { getFinalImageUrl } from "$lib/images/getContenfulImageUrl";
  import slugify from "slugify";

  export let id = "";
  export let heading = {
    text: "",
    id: "",
  };
  export let text = "";
  export let backgroundColor;
  export let media;

  $: id = id || slugify(heading.text, { lower: true });

  const authors = {
    label: heading.authors.length > 1 ? "Authors" : "Author",
    names: heading.authors.map((author) => author.heading).join(", "),
  };

  $: imageSrc = getFinalImageUrl({
    imageType: "webp",
    src: media.image.url,
  });
  $: imageThumbnail = getFinalImageUrl({
    imageType: "webp",
    src: media.image.url,
    fit: "thumb",
    w: "150",
  });
</script>

<svelte:head>
  <link rel="preload" href={imageSrc} as="image" />
</svelte:head>

<div class={`blog-hero-container ${backgroundColor}`} {id}>
  <div class="container-xl d-flex justify-content-center">
    <div class="grid-container" class:noMedia={!media.image}>
      {#if media.image}
        <div class="media">
          <img
            src={imageThumbnail}
            data-src={imageSrc}
            width={media.image.width}
            height={media.image.height}
            class="lazyload mx-auto mx-lg-0"
            alt={media.image.alt}
          />
        </div>
      {/if}

      <h1 class="heading ca-font-heading ca-text-4xl">
        {@html heading.text}
      </h1>

      <div class="sub-heading">
        <div class="outer">
          <div>
            <span class="label">{authors.label}:&nbsp;</span>{authors.names}
          </div>
          <span class="separator">|</span>
          <div>
            <span class="label">Read time:&nbsp;</span>
            {heading.readTime}
          </div>
        </div>
        <div class="outer">
          <div>
            <span class="label">Published date:&nbsp;</span>
            {heading.publishedDate}
          </div>
        </div>
      </div>

      {#if text}
        <div class="description">
          {@html text}
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .blog-hero-container {
    padding: 77px 0;
    .grid-container {
      display: grid;
      grid-template-areas:
        "media eyebrow"
        "media heading"
        "media sub-heading"
        "media description";
      grid-template-columns: minmax(395px, 31%) minmax(0, 1fr);
      column-gap: 68px;
      max-width: 100%;
      .heading,
      .sub-heading,
      .description {
        text-align: left;
      }
      .sub-heading {
        display: flex;
        flex-direction: column;
        margin-top: 17px;
        font-size: 14px;
        font-weight: 400;
        // line-height: 18px;
        line-height: 1.5rem;
        letter-spacing: 0.03em;
        .outer {
          display: flex;
          gap: 9px;
        }
        .label {
          font-weight: 500;
        }
      }
      .description {
        margin-top: 27px;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
      }
      .heading {
        grid-area: heading;
      }
      .sub-heading {
        grid-area: sub-heading;
      }
      .description {
        grid-area: description;
      }
      .media {
        grid-area: media;
        display: flex;
        img {
          border: solid black 2px;
        }
      }
      &.noMedia {
        grid-template-columns: 1fr;
        max-width: 675px;
        .heading,
        .sub-heading,
        .description {
          text-align: center;
          padding: 0px 14px;
        }
      }
    }
  }
  // mobile
  @media (max-width: 991px) {
    .blog-hero-container {
      padding: 0 0 3.5rem 0;
      // background-color: unset !important;
      .container-xl {
        padding: 0;
      }
      .grid-container,
      .grid-container.noMedia {
        display: flex;
        flex-direction: column;
        .media {
          margin-bottom: 49px;
          img {
            border: unset;
          }
        }
        .heading,
        .sub-heading,
        .description {
          text-align: left;
          padding: 0 24px;
        }
      }
      .grid-container.noMedia {
        grid-template-columns: 1fr;
        max-width: 100%;
      }
    }
  }

  // for subheading to go to 4 rows on extra small screens
  @media (max-width: 515px) {
    .blog-hero-container {
      .grid-container,
      .grid-container.noMedia {
        .sub-heading {
          display: unset;
          .outer {
            display: unset;
          }
          .separator {
            display: none;
          }
        }
      }
    }
  }
</style>
