// @ts-strict-ignore
import type { ArticlePageData } from "$types/locals";

export function getJsonLDBlogData(data) {
  const imageArray = data?.content?.body?.links?.assets?.block?.map((asset) => {
    return {
      "@type": "ImageObject",
      url: asset?.url,
      height: asset?.height,
      width: asset?.width,
      caption: asset?.description || asset?.title,
    };
  });

  const authorsCollection =
    data?.authorsCollection as ArticlePageData["authorsCollection"];

  const authorArray = authorsCollection.items
    .map((author) => {
      if (author?.seo) {
        const authData = {
          "@type": "Person",
          "@id": `/author/${author.seo.uri}/`,
          name: author.name,
          description: author.bio,
          url: `/author/${author.seo.uri}/`,
          sameAs: [
            author.twitterUrl,
            author.linkedInUrl,
            author.alternateUrl,
          ].filter(Boolean),
          worksFor: {
            "@id": "/#organization",
          },
        };
        if (author.avatar?.url) {
          return {
            ...authData,
            image: {
              "@type": "ImageObject",
              "@id": author.avatar?.url,
              url: author.avatar?.url,
              caption: author.name,
              inLanguage: "en-US",
            },
          };
        }

        return authData;
      } else {
        return null;
      }
    })
    .filter(Boolean);

  const jsonLDData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "BlogPosting",
        "@id": data.url + "#richSnippet",
        headline: data.content?.title,
        keywords: data.seo?.keywords,
        datePublished: data?.displayDate || data?.sys?.firstPublishedAt,
        dateModified: data.content?.sys?.publishedAt,
        description: data.seo?.description,
        name: data.content?.title,
        inLanguage: data.locale,
        author: authorArray,
        image: imageArray,
        publisher: {
          "@type": "Organization",
          "@id": "/#organization",
        },
        isPartOf: {
          "@type": "WebPage",
          "@id": data.url + "#webpage",
        },
        mainEntityOfPage: {
          "@id": data.url + "#webpage",
        },
      },
    ],
  };

  if (!jsonLDData["@graph"][0].keywords) {
    delete jsonLDData["@graph"][0].keywords;
  }

  return jsonLDData;
}
