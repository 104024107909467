<script lang="ts">
  import CarouselDefault from "./variants/CarouselDefault.svelte";
  import CarouselCaseStudies from "./variants/CarouselCaseStudies.svelte";
  import CarouselTeam from "./variants/CarouselTeam.svelte";
  import CarouselInnovator from "./variants/CarouselInnovator.svelte";
  import CarouselCustomerQuotes from "./variants/CarouselCustomerQuotes.svelte";

  export let variant = "default";
  export let id;
  export let navigation;
  export let heading;
  export let text;

  const variants = {
    default: CarouselDefault,
    "case-studies": CarouselCaseStudies,
    "meet-the-team": CarouselTeam,
    "innovator-stories": CarouselInnovator,
    "customer-quotes": CarouselCustomerQuotes,
  };
</script>

<svelte:component this={variants[variant]} {id} {navigation} {heading} {text}>
  <slot />
</svelte:component>
