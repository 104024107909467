// @ts-strict-ignore
import { localesMap } from "$lib/constants";

function createPath(slug, parent) {
  if (!slug) {
    return "";
  }

  let result = "/" + slug;

  if (parent?.slug) {
    result = parent.slug + result;
    return createPath(result, parent.parent);
  }

  // break case -- the parent is null, or doesnt have a slug
  return result;
}

export type RelatedPostMapperReturnType = {
  title: string;
  url: string;
  cta: {
    url: string;
  };
  image: {
    src: string;
  };
  category: string;
  date: string;
  seo: any;
  content: string;
  authors?: string[];
};

export function relatedPostsMapper(
  post,
  locale?: string,
): RelatedPostMapperReturnType {
  const displayDate = new Date(
    post?.displayDate || post?.sys?.publishedAt,
  )?.toLocaleDateString(locale, {
    year: "numeric",
    month: "long",
  });

  const title = post?.content?.blogTitle || post?.title || "";

  let localePrefix: string | undefined = undefined;

  if (locale && localesMap[locale]) {
    localePrefix = `/${localesMap[locale]}`;
  }

  const result = {
    title,
    url: [localePrefix, createPath(post.slug, post.parent)]
      .filter(Boolean)
      .join(""),
    cta: {
      url: post?.cta?.url || createPath(post.slug, post.parent),
    },
    image: { src: post?.seo?.image?.url || "/Carta-Logo-.svg" },
    category: post?.categoriesCollection?.items[0]?.name || "article",
    date: displayDate,
    seo: post?.seo,
    content: post?.seo?.description,
    authors: post?.authorsCollection?.items?.[0]
      ? post?.authorsCollection?.items
      : null,
  };

  return result;
}
