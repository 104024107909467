<script lang="ts">
  // TODO: doesn't seem like this variant is used?
  import Heading from "$components/Heading/Heading.svelte";

  export let title;
  export let content;
  export let image;
  export let url;
  export let backgroundColor;
</script>

<a
  href={url}
  target="_self"
  class="card-classroom-video-mini"
  class:has-background-color={backgroundColor !== ""}
  class:ca-bg--highlight-green={backgroundColor === "highlight-green"}
  class:ca-bg--highlight-yellow={backgroundColor === "highlight-yellow"}
  class:ca-bg--midtone-green={backgroundColor === "midtone-green"}
  class:ca-bg--midtone-orange={backgroundColor === "midtone-orange"}
  class:ca-bg--light-green={backgroundColor === "light-green"}
  class:ca-bg--light-orange={backgroundColor === "light-orange"}
  class:ca-bg--light-beige={backgroundColor === "light-beige"}
  class:ca-bg--light-cream={backgroundColor === "light-cream"}
  class:ca-bg--light-blue={backgroundColor === "light-blue"}
  class:ca-bg--black={backgroundColor === "black" ||
    backgroundColor === "neutral-black"}
  class:ca-bg--neutral-white={backgroundColor === "neutral-white"}
>
  {#if image}
    <div class="card-classroom-video-mini-media">
      <img
        width="1200"
        height="600"
        src={image.src || image.uri}
        alt={image.alt}
        class="ca-card__img"
      />

      <div
        class="card-classroom-video-mini-media-icon"
        class:has-background-color={backgroundColor !== ""}
        class:ca-bg--highlight-green={backgroundColor === "highlight-green"}
        class:ca-bg--highlight-yellow={backgroundColor === "highlight-yellow"}
        class:ca-bg--midtone-green={backgroundColor === "midtone-green"}
        class:ca-bg--midtone-orange={backgroundColor === "midtone-orange"}
        class:ca-bg--light-green={backgroundColor === "light-green"}
        class:ca-bg--light-orange={backgroundColor === "light-orange"}
        class:ca-bg--light-beige={backgroundColor === "light-beige"}
        class:ca-bg--light-cream={backgroundColor === "light-cream"}
        class:ca-bg--light-blue={backgroundColor === "light-blue"}
        class:ca-bg--black={backgroundColor === "black" ||
          backgroundColor === "neutral-black"}
        class:ca-bg--neutral-white={backgroundColor === "neutral-white"}
      >
        <!-- TODO: extract into reusable component -->
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_b_341_2577)">
            <circle cx="30" cy="30" r="29.5" stroke="black" />
          </g>
          <path
            class="svgFill"
            fill="transparent"
            d="M41.2646 30.0114L23.7646 18.7614V41.2614L41.2646 30.0114Z"
            stroke="black"
            stroke-width="1.5"
          />
          <defs>
            <filter
              id="filter0_b_341_2577"
              x="-4"
              y="-4"
              width="68"
              height="68"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_341_2577"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_341_2577"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  {/if}

  <div class="card-classroom-video-mini-body">
    <div class="card-classroom-video-mini-body-title-wrapper">
      <Heading
        {...{
          text: title,
          level: 2,
          font: "text",
          size: "2xl",
          classList: "align-left",
        }}
      />
    </div>

    <div class="card-classroom-video-mini-body-content">
      {@html content}
    </div>
  </div>
</a>

<style lang="scss">
  .card-classroom-video-mini-body-content {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 1rem;
  }
  .card-classroom-video-mini-media {
    object-fit: cover;
    object-position: center;
    overflow: hidden;
    display: flex;
    position: relative;

    .svgFill {
      transition: 500ms;
    }
    // TODO: refactor icons into component with dynamic props
    &:hover {
      .svgFill {
        fill: black;
      }
    }

    &-icon {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      border-radius: 100px;
    }
  }

  .has-background-color .card-classroom-video-mini-body {
    padding: 2rem 2rem 2rem 1rem;
  }
  .card-classroom-video-mini-media img {
    aspect-ratio: 16 / 9;
    height: 100%;
  }
  .card-classroom-video-mini {
    max-width: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
</style>
