// @ts-strict-ignore
import Embed from "./Embed.svelte";

export const EmbedConfig = {
  name: "Embed",
  component: Embed,
  mapper: (props) => {
    return {
      ...props,
    };
  },
  // remove tempDevOnlyHs, temporary for UK partner form
  fragment: `fragment EmbedData on Embed {
        snippet
        width
        tempDevOnlyHs
    }`,
};
