<script lang="ts">
  import PricingCard from "../../PricingCard/PricingCard.svelte";

  export let items;
  export let text;

  $: items = items || [];
  $: text = text || "";
</script>

<div class="ca-section mb-4_5">
  <div class="container-xl">
    <div class="row">
      <div class="col-12 col-lg-10 offset-lg-1">
        <div class="container-xl">
          <div class="grid-pricing align-items-start">
            {#each items as item, i (item.content + i)}
              <PricingCard {...item} />
            {/each}
          </div>
          {#if text}
            <div class="ca-text-body-1 text-center mt-4_5">
              <p>{@html text}</p>
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>
