<script lang="ts">
  import ContentfulRichTextParserSimple from "$components/ContentfulRichTextParser/ContentfulRichTextParserSimple.svelte";
  import "$components/Accordion/Accordion.scss";

  export let items;
  let open = false;
</script>

<div class="ca-accordion-list">
  {#each items as { title, tag, content }}
    <details class="ca-accordion" class:open>
      <summary class="ca-accordion__title" on:click={() => !open}>
        <div class="d-flex align-items-center flex-wrap gap-2">
          <span>{@html title}</span>
          {#if tag}
            <span class="ca-dropdown-acc__feat ca-bg--light-blue">{tag}</span>
          {/if}
        </div>
      </summary>
      <div class="ca-accordion__content">
        {#if typeof content === "object"}
          <ContentfulRichTextParserSimple node={content} />
        {:else}
          {@html content}
        {/if}
      </div>
    </details>
  {/each}
</div>

<style>
  :global(.ca-accordion__content > *:not(:first-child)) {
    margin-top: 1rem;
  }
</style>
