<script lang="ts">
  export let title;
  export let content;
  export let cta = {
    url: "#",
    text: "",
  };
  export let backgroundColor;
</script>

<div class="ca-cardlet ca-bg--{backgroundColor}">
  <div class="ca-cardlet__heading">{title}</div>
  <div class="ca-cardlet__body">
    {content}
  </div>
  <div>
    {#if cta}
      <a data-sveltekit-reload href={cta.url} target="_self" class="ca-btn mt-2"
        >{cta.text}</a
      >
    {/if}
  </div>
</div>
