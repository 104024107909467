import type { ContentfulTag } from "$types/graphql-schema";
import type { FilterMenuDataFragment } from "$types/contentful-queries";
import FilterMenu from "./FilterMenu.svelte";
import { ButtonConfig } from "$components/Button/Button.config";

type FilterMenuLinks = {
  key: string;
  value: string;
};

type FilterMenuAccordionData = {
  [key: string]: {
    open: boolean;
    selectedCount: number;
    items: Array<ContentfulTag | null>;
  };
};

export const FilterMenuConfig = {
  name: "FilterMenu",
  component: FilterMenu,
  variants: {
    primary: "primary",
    secondary: "secondary",
    inverted: "inverted",
  },
  mapper: (props: FilterMenuDataFragment) => {
    const items: Array<{ text: string; url: string }> = props.links?.map(
      ({ key, value }: FilterMenuLinks) => ({
        text: key,
        url: value,
      }),
    );

    const buttons = props.buttonsCollection?.items.map((button) =>
      ButtonConfig.mapper(button),
    );

    const accordionData = props.filtersCollection?.items?.reduce(
      (acc, curr) => {
        if (curr?.heading?.text) {
          acc[curr.heading.text] = {
            open: false,
            selectedCount: 0,
            items: curr.contentfulMetadata.tags,
          };
        }
        return acc;
      },
      {} as FilterMenuAccordionData,
    );

    return { ...props, items, buttons, accordionData };
  },
  fragment: `fragment FilterMenuData on FilterMenu {
        __typename
		internalTitle
        links
        buttonsCollection(limit: 5) {
            items {
                text
                url
                variant
                icon
            }
        }
        filtersCollection(limit: 5) {
            items {
                heading {
                    text
                }
                contentfulMetadata {
                    tags {
                        id
                        name
                    }
                }
            }
        }
	}`,
};
