<script lang="ts">
  import MarkdownIt from "markdown-it";
  import containerPlugin from "markdown-it-container";
  export let url;
  export let image;
  export let title;
  export let eyebrow;
  export let content;
  export let cta;

  $: url = url || "";
  $: image = image || {};
  $: title = title || "";
  $: eyebrow = eyebrow || "";
  $: content = content || "";

  $: ctaText = cta?.text || "View more";
  $: ctaUrl = url || cta?.url;

  const md = new MarkdownIt("commonmark").use(containerPlugin, "ul_checkmark");
</script>

<div class="col-12 h-100">
  <a
    data-sveltekit-reload
    href={ctaUrl}
    target="_self"
    class="ca-card ca-card--fixed-info ca-card--blog-category"
  >
    <div class="row h-100 g-0 flex-column">
      <div class="col-12 image">
        <img src={image.src} alt={image.alt} />
      </div>
      <div class="col-12 card-body">
        <div class="ca-card__info h-100">
          <div class="ca-text-eyebrow mb-3">{@html eyebrow.toUpperCase()}</div>
          <div class="ca-card__info-header">
            {@html title}
          </div>
          <div
            class="ca-text-body-2 mt-2 content-container"
            class:mb-4={ctaText}
          >
            {@html md.render(content)}
          </div>
          {#if ctaUrl}
            <div class="ca-card__actions">{ctaText}</div>
          {/if}
        </div>
      </div>
    </div>
  </a>
</div>

<style lang="scss">
  .ca-card__info {
    display: flex;
    flex-direction: column;
  }
  .ca-card__actions {
    margin-top: auto;
  }
  .card-body {
    flex-grow: 1;
  }
  .content-container {
    :global(.ul_checkmark ul) {
      list-style-position: outside;
      :global(li) {
        &::marker {
          content: "✓  ";
        }
      }
    }
  }

  .image {
    height: min-content;
  }
</style>
