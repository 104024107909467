<script lang="ts">
  import DropdownToggle from "$components/DropdownToggle/DropdownToggle.svelte";
  import FooterItem from "./FooterItem.svelte";
  export let data;

  export let footerTextLeft;
  export let footerTextRight;

  let menuItems = data.footerMenus;

  const firstThreeCols = menuItems?.slice(0, 3) || [];
  const remainingCols = menuItems?.slice(3) || [];

  const { locale } = data;

  const currentYear = new Date().getFullYear();
</script>

<footer class="ca-footer">
  <div class="container-fluid">
    <div class="row">
      {#each firstThreeCols as menuColumn, i}
        <div
          class="col-12 col-md-6 col-lg-3"
          class:mt-5={i > 0}
          class:mt-md-0={i > 0}
        >
          <FooterItem {menuColumn} {locale} />
        </div>
      {/each}
      <div class="col-12 col-md-6 col-lg-3 mt-5 mt-md-0">
        {#each remainingCols as menuColumn}
          <FooterItem {menuColumn} />
        {/each}
      </div>

      <!-- keep this as is -->
      <hr class="my-5 d-lg-none" />
      <div class="row mt-4 mt-lg-7 align-items-lg-center">
        <div class="col-12 col-lg-4">
          <div class="d-flex align-items-center justify-content-start gap-4">
            <a
              href="https://www.sipc.org/"
              target="_blank"
              aria-label="Go to SIPC home page"
              rel="noopener noreferrer"
              ><img
                loading="lazy"
                src="/img/sipc-logo.png"
                alt="sipc logo"
                width="68"
                height="38"
              /></a
            ><img
              loading="lazy"
              src="/img/AICPA-SOC-logo@2x.png"
              alt="aicpa logo"
              width="124"
              height="124"
            />
          </div>
        </div>
        <div class="col-12 col-lg-4 mt-4 mt-lg-0">
          <div
            class="d-flex gap-3 gap-lg-4 flex-wrap align-items-center justify-content-between justify-content-lg-end flex-wrap"
          >
            <div class="d-flex gap-2 align-items-center">
              <a
                href="https://itunes.apple.com/us/app/carta-manage-your-equity/id1137735263"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/img/App-Store-badge.svg"
                  alt="App Store badge"
                  width="127"
                  height="43"
                  loading="lazy"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.esharesinc.android&amp;hl=en_US"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/img/Google-Play-badge.svg"
                  alt="Google Play badge"
                  width="142"
                  height="43"
                />
              </a>
            </div>
            <div class="d-flex gap-3 align-items-center justify-content-center">
              <a
                href="https://www.linkedin.com/company/carta--/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/img/linkedin.svg"
                  alt="linkedin"
                  width="38"
                  height="37"
                />
              </a>
              <a
                href="https://www.instagram.com/cartainc/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/img/instagram-icon.svg"
                  alt="instagram icon"
                  width="36"
                  height="36"
                />
              </a>
              <a
                href="https://twitter.com/cartainc"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  loading="lazy"
                  src="/img/twitter.svg"
                  alt="twitter"
                  width="38"
                  height="38"
                />
              </a>
            </div>
          </div>
        </div>

        <div
          class="col-12 col-lg-4 d-lg-flex mt-4 mt-lg-0 justify-content-lg-end"
        >
          <div>
            <DropdownToggle variant="default" {locale} />
          </div>
        </div>
      </div>

      <hr class="my-5" />
      <div class="row gy-4">
        <div class="col-12 col-lg-6 ca-footer__disclaimer">
          {#if footerTextLeft}
            {@html footerTextLeft}
          {:else}
            <p>
              eShares, Inc. DBA Carta, Inc. (“Carta”) is registered with the
              U.S. Securities and Exchange Commission as a transfer agent. The
              services and information described in this communication are
              provided to you “as is” and “as available” without warranties of
              any kind, expressed, implied or otherwise, including but not
              limited to all warranties of merchantability, fitness for a
              particular purpose, or non-infringement.
            </p>
          {/if}
        </div>
        <div class="col-12 col-lg-6 ca-footer__disclaimer">
          {#if footerTextRight}
            {@html footerTextRight}
          {:else}
            <p>
              Neither Carta nor any of its affiliates will be liable for any
              damages, including without limitation direct, indirect, special,
              punitive or consequential damages, caused in any way or arising
              from the use of the services or reliance upon the information
              provided in this communication or in connection with any failure
              of performance, error, omission, interruption, defect, delay in
              operation or transmission, computer virus or line or system
              failure.
            </p>
          {/if}

          <p>&nbsp;</p>
          <p>© {currentYear} eShares, Inc. DBA Carta, Inc.</p>
        </div>
      </div>
    </div>
  </div>
</footer>
