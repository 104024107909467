<script lang="ts">
  import Heading from "../../Heading/Heading.svelte";

  export let heading = {
    text: "",
    id: "",
  };

  export let media = [];
</script>

<section class="ca-section ca-section--editorial pt-4 pb-4" id="">
  <div class="container-xl">
    <div class="row">
      <div class="ca-text-body-1 ca-flow col-12 col-md-10 offset-md-1">
        <Heading
          {...{
            ...{
              tag: "h2",
              font: "heading",
              align: "center",
              size: "3xl",
              classList: "mb-4",
            },
            ...heading,
          }}
        />
        {#if media[0]}
          <p>
            <a href={media[0]?.href} rel="noopener">
              <img
                loading="lazy"
                class="alignnone size-full"
                style="max-width: 91%; margin: 1em auto;"
                src={media[0]?.image?.url}
                alt={media[0]?.image?.alt || ""}
                width={media[0]?.image?.width}
                height={media[0]?.image?.height}
                sizes="(max-width: 1440px) 100vw, 1440px"
                title={media[0]?.image?.title}
              />
            </a>
          </p>
        {/if}
      </div>
    </div>
  </div>
</section>
