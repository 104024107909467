export const marketoFormsToBookItRouters = {
  7226: "Global Demo Router",
  6671: "Global Demo Router",
  6733: "Rippling CTC",
  6663: "IPO Advisory",
  6986: "APAC Demo Router",
};

export const marketoFormsIds = Object.keys(marketoFormsToBookItRouters).map(
  Number,
);

// list of organization types that should trigger a chilipiper router
export const chiliOrgTypes = [
  "PE-Backed Company",
  "Corporation (Not Publicly Listed)",
  "Fund, Investment Vehicle, or Syndicate Lead",
  "Non US entity (such as LTD, Limited, GmbH, S.A.)",
  "Limited Liability Company (LLC) or Limited Partnership (Not PE-Backed)",
];

// EMEA countries that should trigger the EMEA chili router
export const europeanCountryCodes = {
  Akrotiri: "AX",
  Albania: "AL",
  Andorra: "AD",
  Antarctica: "AQ",
  Austria: "AT",
  "Bassas da India": "BS",
  Belgium: "BE",
  "Bosnia and Herzegovina": "BA",
  Bulgaria: "BG",
  "Clipperton Island": "CP",
  Croatia: "HR",
  Cyprus: "CY",
  "Czech Republic": "CZ",
  Denmark: "DK",
  Dhekelia: "DK",
  Estonia: "EE",
  "Europa Island": "EU",
  "Faroe Islands": "FO",
  Finland: "FI",
  France: "FR",
  "France (Territorial)": "FT",
  "French Southern and Antarctic Lands": "TF",
  Germany: "DE",
  Gibraltar: "GI",
  "Glorioso Islands": "GI",
  Greece: "GR",
  Greenland: "GL",
  Guernsey: "GG",
  Hungary: "HU",
  Iceland: "IS",
  Ireland: "IE",
  "Isle of Man": "IM",
  Italy: "IT",
  Jersey: "JE",
  "Juan de Nova Island": "JU",
  Kosovo: "XK",
  Latvia: "LV",
  Liechtenstein: "LI",
  Lithuania: "LT",
  Luxembourg: "LU",
  Malta: "MT",
  Moldova: "MD",
  Monaco: "MC",
  Montenegro: "ME",
  Netherlands: "NL",
  "Netherlands Antilles": "AN",
  "North Macedonia": "MK",
  Norway: "NO",
  "Pitcairn Islands": "PN",
  Poland: "PL",
  Portugal: "PT",
  Romania: "RO",
  "San Marino": "SM",
  Serbia: "RS",
  Slovakia: "SK",
  Slovenia: "SI",
  Spain: "ES",
  "Svalbard and Jan Mayen": "SJ",
  Sweden: "SE",
  Switzerland: "CH",
  "Tromelin Island": "TM",
  Turkey: "TR",
  "United Kingdom": "GB",
  "Vatican City": "VA",
};
