<script lang="ts">
  export let isUsingSequel = false;
</script>

<svelte:head>
  <script lang="ts">
    window.addEventListener("message", receiveMessage, false);
    window["Bizible"] = window["Bizible"] || {
      _queue: [],
      Push: function (o, p) {
        this._queue.push({ type: o, data: p });
      },
    };
    function receiveMessage(event) {
      if (event.data.event === "user-registered") {
        Bizible.Push("User", {
          eMail: event.data.data.email,
        });
      }
    }
  </script>

  {#if isUsingSequel}
    <script
      type="text/javascript"
      src="//cdn.bizible.com/scripts/bizible.js"
      id="bizible-settings"
      data-form
      provider="embed.sequel.io"
      defer
    ></script>
  {:else}
    <script
      type="text/javascript"
      src="//cdn.bizible.com/scripts/bizible.js"
      defer
    ></script>
  {/if}
</svelte:head>
