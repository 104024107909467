<script lang="ts">
  export let title;
  export let image;
  export let url;
</script>

<a
  data-sveltekit-reload
  href={url}
  target="_self"
  class="ca-card ca-card--fixed-info ca-card--blog-featured-mini"
>
  <div class="row g-0 h-100">
    <div class="col-12 col-md-6">
      <img
        width="1200"
        height="675"
        src={image.src}
        alt={image.alt || ""}
        class="ca-card__img h-100"
      />
    </div>
    <div class="col-12 col-md-6">
      <div class="ca-card__info">
        <div class="ca-card__info-header">
          {title}
        </div>
      </div>
    </div>
  </div>
</a>
