<script lang="ts">
  import Media from "./parts/Media.svelte";

  export let id = "";
  export let eyebrow;
  export let heading;
  export let text = "";
  export let backgroundColor = "ca-bg--light-cream";
  export let media;

  // Social links
  export let twitterUrl;
  export let linkedInUrl;
  export let email;

  const socialLinks = [
    email && {
      link: `mailto:${email}`,
      icon: "ca-social-icon--email",
      alt: "Email",
    },
    twitterUrl && {
      link: twitterUrl,
      icon: "ca-social-icon--tw",
      alt: "Twitter Profile",
    },
    linkedInUrl && {
      link: linkedInUrl,
      icon: "ca-social-icon--ln",
      alt: "LinkedIn Profile",
    },
  ].filter(Boolean);
</script>

<div class="main-container {backgroundColor} {id}">
  <div class="content-container">
    <div class="media-container">
      {#if media.image?.url}
        <Media {media} />
      {/if}
    </div>
    {#if eyebrow}
      <div class="ca-text-eyebrow mb-3">{eyebrow}</div>
    {/if}
    <div class="heading-container">
      {heading}
    </div>

    <div class="text-container">
      {text}
    </div>
  </div>
  <div class="social-media-container">
    <div class="ca-social-widget">
      {#each socialLinks as item}
        <a
          href={item.link}
          class="ca-social-icon {item.icon}-blue"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span class="visually-hidden">{item.alt}</span>
        </a>
      {/each}
    </div>
  </div>
</div>

<style>
  .main-container {
    display: flex;
    flex-direction: column;
    padding: 36px 24px;
    max-width: 500px;
    margin: 0 auto;
  }

  .content-container {
    margin-bottom: 20px;
  }
  .content-container .media-container {
    margin-bottom: 48px;
    height: 200px;
    width: 200px;
    border-radius: 50%;
    overflow: hidden;
  }
  .content-container .heading-container {
    font-family: SangBleu Versailles;
    font-size: 36px;
    font-weight: 400;
    line-height: 44px;
    margin-bottom: 1rem;
  }
  .content-container .text-container {
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }
  .social-media-container {
    margin-top: auto;
  }

  /* mobile */
  /* @media (max-width: 991px) {
  } */
</style>
