<script lang="ts">
  import MarkdownIt from "markdown-it";
  import Heading from "../../Heading/Heading.svelte";
  import Button from "../../Button/Button.svelte";
  import Eyebrow from "./parts/Eyebrow.svelte";
  import { paddingSizes } from "../../../lib/constants";

  export let id = "";
  export let eyebrow = "";
  export let eyebrowTag;
  export let backgroundColor;
  export let backgroundColorFit = "";
  $: isCover = backgroundColorFit === "Cover";

  export let heading = {
    text: "",
    id: "",
    font: "heading",
    size: "4xl",
    tag: "h2",
  };

  export let text = "";
  export let containerWidth;
  export let settings;
  export let buttons = [];

  const md = new MarkdownIt({
    breaks: true,
    html: true,
  });

  const pY = settings?.verticalPadding || "M";

  $: cw = containerWidth || "SM";
  $: containerWidthClassnames = {
    XS: "col-12 col-md-6 offset-md-3",
    SM: "col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3",
    MD: "col-12 col-md-8 offset-md-2",
    LG: "col-12 col-md-10 offset-md-1",
    XL: "col-12",
  }[cw];

  $: outerBgClass = isCover ? backgroundColor : "";
</script>

<div
  class={`${outerBgClass} ca-section ca-section-product-hero  ${paddingSizes[pY]}`}
  {id}
>
  <div class="container-xl {backgroundColor}">
    <div class="row">
      <div class={containerWidthClassnames}>
        {#if eyebrow}
          <Eyebrow tag={eyebrowTag}>{eyebrow}</Eyebrow>
        {/if}

        <Heading
          {...{
            ...heading,
            classList: "text-center",
          }}
        />

        {#if text}
          <div class="ca-section__intro ca-text-body-1 ca-flow">
            {@html md.render(text || "")}
          </div>
        {/if}

        {#if buttons?.length > 0}
          <div
            class="d-flex justify-content-center mt-4_5 mt-lg-6 g-4 gap-3 flex-wrap"
          >
            {#each buttons as button}
              <Button {...button} />
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
