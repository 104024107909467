<script lang="ts">
  import { getContinueUrl, setUserSelectedRegion } from "$lib/helpers";
  import type { LocaleKeys, Regions } from "$types/utils";
  import { localizedHomePages, localeNames } from "$lib/constants";

  export let theme = "dark";
  export let locale: LocaleKeys = "en-US";
  export let labels: Record<LocaleKeys, string> = localeNames;
  const iterableLables: LocaleKeys[] = Object.keys(labels) as LocaleKeys[];

  let windowInnerHeight: number;
  let listOffsetHeight: number;
  let localeToggleEl: HTMLDivElement;
  let toggleListEl: HTMLUListElement;
  let showList = false;
  let renderOnBottom = false;

  const toggle = () => {
    showList = !showList;
  };

  const setRender = () => {
    const navbarHeight = 88;
    const toggleElementTop = localeToggleEl.getBoundingClientRect().top;
    const toggleElementBottom = localeToggleEl.getBoundingClientRect().bottom;

    if (
      toggleElementTop - navbarHeight < listOffsetHeight &&
      toggleElementBottom + listOffsetHeight < windowInnerHeight
    ) {
      renderOnBottom = true;
    } else {
      renderOnBottom = false;
    }

    if (
      toggleElementTop - listOffsetHeight > windowInnerHeight ||
      toggleElementBottom < -40
    ) {
      showList = false;
    }
  };

  const clickOffLocaleToggle = (e: Event) => {
    if ((e.target as Node).contains(localeToggleEl)) {
      showList = false;
    }
  };

  const onClickLocale = (locale: LocaleKeys) => {
    const country = locale.slice(-2);
    setUserSelectedRegion(country as Regions);
    document.cookie = `nf_country=${country}; Path=/;`;
    const alternateTag = document.querySelector(
      `link[rel='alternate'][hreflang='${locale}']`,
    ) as HTMLLinkElement;
    const continueUrl = getContinueUrl(
      alternateTag?.href || localizedHomePages[locale],
    );
    // $app.navigation.goto does a pushState.we don't actually get a page refresh with it so we need to set it this way
    window.location.href = continueUrl;
  };
</script>

<svelte:window
  bind:innerHeight={windowInnerHeight}
  on:scroll={setRender}
  on:click={clickOffLocaleToggle}
/>

<div
  class="locale-toggle"
  bind:this={localeToggleEl}
  class:theme-dark={theme === "dark"}
  class:theme-light={theme === "light"}
>
  <button
    class="locale-toggle-button"
    on:click={toggle}
    data-test="locale-toggle-button"
  >
    <svg
      class="globe-logo"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="12.8125" stroke="white" stroke-width="1.875" />
      <path
        d="M3.125 10.625H26.875M3.125 19.375H15H26.875M15 2.5C8.75 3.75 8.75 26.25 15 27.5C21.25 26.25 21.25 3.75 15 2.5Z"
        stroke="white"
        stroke-width="1.875"
      />
    </svg>

    <span class="locale-toggle-button-text">{labels[locale]}</span>

    <svg
      class="locale-toggle-button-icon"
      class:rotate={showList}
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ><path
        d="M14 1 7.5 7 1 1"
        stroke="var(--fill-color)"
        stroke-linecap="square"
        stroke-linejoin="round"
        class="s-ULdW1gAf0k5X"
      /></svg
    >
  </button>

  <ul
    class="locale-toggle-list"
    bind:this={toggleListEl}
    bind:offsetHeight={listOffsetHeight}
    class:render-on-bottom={renderOnBottom}
    class:visible={showList}
  >
    {#each iterableLables as localeChoice}
      {#if labels[locale]}
        <li>
          <button on:click={() => onClickLocale(localeChoice)}>
            {labels[localeChoice]}</button
          >
        </li>
      {/if}
    {/each}
  </ul>
</div>

<style lang="scss">
  .theme-light {
    --color: #000;
    --bg-color: #fff;
    --border-color: #000;
    --fill-color: #000;
    --logo-invert: 1;
  }

  .theme-dark {
    --color: #fff;
    --bg-color: #000;
    --border-color: #fff;
    --fill-color: #fff;
    --logo-invert: 0;
  }

  .locale-toggle {
    display: inline-block;
    padding: 0.4rem 1rem;
    position: relative;
    background-color: var(--bg-color);
  }

  .locale-toggle-button {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.4rem;
    background-color: transparent;
    border: none;
    height: 40px;
    padding: 0;
    cursor: pointer;
  }

  .locale-toggle-button-text {
    color: var(--color);
    text-decoration: underline solid var(--color);
    -webkit-text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 2.5px;
    margin-top: -7px;
  }

  .locale-toggle-button-icon {
    transform: rotate(180deg);
    transition: 0.5s;
  }

  .locale-toggle-button-icon.rotate {
    transform: rotate(0deg);
  }

  .locale-toggle-list {
    visibility: hidden;
    list-style: none;
    padding: 0.5rem 1rem;
    background-color: inherit;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    &.visible {
      visibility: visible;
    }

    li {
      button {
        width: 100%;
        padding: 0.5rem 0;
        background-color: inherit;
        color: inherit;
        text-align: left;
        border: none;
        &:hover {
          text-decoration: underline;
          -webkit-text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }

  .globe-logo {
    filter: invert(var(--logo-invert));
  }

  .locale-toggle-list {
    position: absolute;
    bottom: 100%;
    left: -1px;
    width: calc(100% - 14px);
  }

  .render-on-bottom {
    top: 100%;
    bottom: auto;
  }
</style>
