// @ts-strict-ignore
import slugify from "slugify";
import Panel from "./Panel.svelte";
import { HeadingConfig } from "../Heading/Heading.config";
import type { PanelDataFragment } from "$types/contentful-queries";

export const PanelConfig = {
  name: "Panel",
  component: Panel,
  variants: {
    "call-to-action": "cta",
    "media-text": "media-text",
    "product-hero": "product-hero",
    "innovator-stories": "innovator-stories",
    "centered-with-image-hero": "centered-with-image-hero",
  },
  mapper: (props: PanelDataFragment) => {
    const buttons = props?.buttonsCollection?.items.map((n) => ({
      ...n,
      variant: n?.variant?.toLowerCase(),
    }));

    const media = props?.mediaCollection?.items.map((mediaItem) => {
      let alt: string | null | undefined = undefined;
      let image = {};
      let mediaItemToReturn = mediaItem;
      if (mediaItem?.image) {
        alt = mediaItem.image.description || mediaItem.image.title;
        image = {
          ...mediaItem.image,
          alt,
        };
        mediaItemToReturn = {
          ...mediaItem,
          ...image,
        };
      }
      return mediaItemToReturn;
    });

    const result = {
      ...props,
      buttons,
      variant: props?.variant ? slugify(props.variant).toLowerCase() : "",
      media,
      heading: HeadingConfig.mapper(props?.heading),
    };

    delete result.mediaCollection;
    delete result.buttonsCollection;

    return result;
  },
  fragment: `fragment PanelData on Panel {
        id
        eyebrow
        eyebrowTag
        variant
        containerWidth
        backgroundColor
        backgroundColorFit
        accentColor
        titleHighlights
        heading {
          text
          size
          level
          font
          size
          align: textAlignment
          classList
        }
        text
        buttonsCollection(limit: 4) {
          items {
            text
            url
            variant
            icon
          }
        }
        mediaCollection(limit: 6) {
          items {
            name
            type
            embed
            icon
            image {
              url
              title
              description
            }
            size
            align
            heading
            headingLevel
            text
            href
          }
        }
        animation {
          loop
          autoplay
          file { url }
        }
        settings {
          verticalPadding
          classnames
        }
      }`,
};
