<script lang="ts">
  import FeaturedCards from "$components/FeaturedCards/FeaturedCards.svelte";
  import { CardConfig } from "$components/Card/Card.config";

  export let columns;
  export let backgroundColor;

  // export let gridWidth;
  // export let gridHero;
  // export let gridWidthOptions;
  // export let padding = 'py-4_5 py-md-5 py-lg-6';

  const bgColors = {
    "Midtone Orange": "midtone-orange",
    "Light Orange": "light-orange",
    "Light Beige": "light-beige",
    "Light Cream": "light-cream",
    "Light Blue": "light-blue",
  };

  backgroundColor = bgColors[backgroundColor];
  columns = columns.map((col) => CardConfig.mapper(col));
</script>

<FeaturedCards
  post={{ ...columns[0], variant: "classroom-video", backgroundColor }}
  items={columns.slice(1).map((card) => ({
    ...card,
    variant: "classroom-video-mini",
    backgroundColor,
  }))}
  useMobileCarousel={true}
/>
