<script lang="ts">
  import Heading from "../Heading/Heading.svelte";
  import Card from "../Card/Card.svelte";
  import CardsPagination from "../RelatedCardsGrid/parts/CardsPagination.svelte";

  export let title = "";
  export let post = {};
  export let items = [];
  export let useMobileCarousel = false;

  const carouselItems = [post].concat(items);
  let currentCard = 0;
  const incrementCard = (direction) => {
    if (direction === "left" && currentCard > 0) {
      currentCard--;
    } else if (
      direction === "right" &&
      currentCard < carouselItems.length - 1
    ) {
      currentCard++;
    }
  };
</script>

<div class="featured-cards">
  <Heading
    text={title}
    id=""
    tag="h2"
    font="heading"
    size="2xl"
    align=""
    classList="ca-text-2xl text-center text-md-start mb-4_5"
    contain={false}
  />
  {#if useMobileCarousel}
    <div class="mobile-carousel">
      {#each carouselItems as item, i}
        <div class:hide={currentCard !== i}>
          <Card
            variant={post.variant}
            title={item.title}
            content={item?.content || item?.seo?.description}
            eyebrow={item.eyebrow}
            image={item.image}
            url={item.url}
            cta={item.cta}
            quote={item.quote}
            backgroundColor={item.backgroundColor}
            tag={item.tag}
            categories={item.categories}
            date={item.date}
            alignment={item.alignment}
            icon={item.icon}
            author={item.author}
            authors={item.authors}
            lazyloadImages={item.lazyloadImages}
          />
        </div>
      {/each}
    </div>
    <div class="pagination-container">
      <CardsPagination items={carouselItems} {currentCard} {incrementCard} />
    </div>
  {/if}
  <div class="featured-cards-grid" class:useMobileCarousel>
    <Card
      variant={post.variant}
      title={post.title}
      content={post?.content || post?.seo?.description}
      eyebrow={post.eyebrow}
      image={post.image}
      url={post.url}
      cta={post.cta}
      quote={post.quote}
      backgroundColor={post.backgroundColor}
      tag={post.tag}
      categories={post.categories}
      date={post.date}
      alignment={post.alignment}
      icon={post.icon}
      author={post.author}
      authors={post.authors}
      lazyloadImages={post.lazyloadImages}
    />
    <div class="featured-minis">
      {#each items as item}
        <Card
          {...{ ...item, content: item?.content || item?.seo?.description }}
        />
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  .featured-cards-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;

    @media (min-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .featured-minis {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 0px 6px;
  }
  .mobile-carousel,
  .pagination-container {
    display: none;
  }

  // mobile
  @media (max-width: 991px) {
    .featured-cards-grid,
    .featured-minis {
      grid-template-columns: 1fr;
      justify-items: center;
      padding: 0px;
    }
    .mobile-carousel {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      .hide {
        display: none;
      }
    }
    .pagination-container {
      display: unset;
    }
    .featured-cards-grid.useMobileCarousel {
      display: none;
    }
  }

  // medium sized screens
  @media (min-width: 992px) and (max-width: 1399px) {
    :global(
        .featured-cards .featured-cards-grid .classroom-heading-only-arrow
      ) {
      height: 45px;
      width: 45px;
    }
    :global(.featured-cards .card-classroom-featured-body-text) {
      font-size: clamp(1rem, 0.92rem + 0.4vw, 1.25rem);
      line-height: 1.5;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }

    .featured-cards-grid {
      grid-template-columns: 44% 1fr;
    }
  }
</style>
