export function getFilters(
  filters: Record<string, boolean | undefined | string>,
) {
  const params = Object.keys(filters)
    .filter((key) => {
      return key !== "undefined" && filters[key];
    })
    .filter(Boolean) // Filter out keys with undefined values
    .join(","); // Join the remaining keys with a comma
  return params;
}
