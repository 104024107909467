// @ts-strict-ignore
/**
 *
 * @param {Object} params
 * @param {import("./types").UIBreadcrumbItem[]} params.breadcrumbs List of breadcrumbs, url must be relative
 * @param {string} params.baseURL List of breadcrumbs, url must be relative
 * @returns {import("./types").BreadcrumbList}
 */

export function getJsonLDCrumbData(params) {
  const { breadcrumbs = [], baseURL } = params;
  const baseSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    name: "Breadcrumbs",
  };

  try {
    const elements = breadcrumbs.map((crumb, index) => {
      /** @type {import("./types").BreadcrumbListItem} */
      const item = {
        "@type": "ListItem",
        position: index + 1,
        name: crumb.name,
        item: baseURL + crumb.url,
      };

      return item;
    });

    /** @type {import("./types").BreadcrumbList} */
    const schemaData = { ...baseSchema, itemListElement: elements };

    return schemaData;
  } catch (error) {
    console.error("failed to generate JSONLDCrumb", error);
    return { ...baseSchema, itemListElement: [] };
  }
}

export function getAuthorBreadcrumbs(params: { name?: string; url: URL }) {
  const currentPageTitle = params.name || "";
  const currentPageUrl = params.url.pathname;

  const crumbs = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: currentPageTitle,
      url: currentPageUrl,
    },
  ];
  return crumbs;
}

/**
 *
 * @type {import("./types").CustomBreadcrumbsGenerator}
 */
export function getBlogArticleBreadcrumbs(params) {
  /** @type {string} */
  const currentPageTitle = params.page?.data?.content?.title || "";
  const currentPageUrl = params.page?.url?.pathname || "";

  const crumbs = [
    {
      name: "Home",
      url: "/",
    },
    {
      name: "Blog",
      url: "/blog/",
    },
    {
      name: currentPageTitle,
      url: currentPageUrl,
    },
  ];
  return crumbs;
}
