<script lang="ts">
  import { onMount } from "svelte";
  import Item from "./parts/Item.svelte";
  import ToggleItem from "./parts/ToggleItem.svelte";

  import "./SubNav.scss";

  export let bgColor = "";
  export let items;

  onMount(() => {
    const secondaryNavRef = document.querySelector(".ca-secondary-nav");

    if (!secondaryNavRef) {
      return;
    }

    const secondaryNavLinkRefs =
      secondaryNavRef.querySelectorAll("[href^='#']");

    Array.from(secondaryNavLinkRefs).forEach((element) => {
      element.addEventListener("click", (event) => {
        event.preventDefault();

        const target = document.querySelector(element.hash);

        if (target) {
          window.scrollTo({
            top: target.offsetTop - secondaryNavRef.offsetHeight,
            left: 0,
            behavior: "smooth",
          });
        }
      });
    });

    document.querySelectorAll(".ca-custom-dropdown").forEach((item) => {
      const toggleBtn = item.querySelector("button");
      toggleBtn.addEventListener("click", () => {
        // Close other active dropdowns first
        document
          .querySelectorAll(".ca-custom-dropdown")
          .forEach((customDropdown) => {
            if (customDropdown !== item) {
              customDropdown.classList.remove("active");
            }
          });
        toggleBtn.parentElement.classList.toggle("active");
      });
    });
  });
</script>

{#if items?.length > 0}
  <nav
    class="ca-secondary-nav"
    class:ca-bg--light-blue={bgColor === "light-blue"}
    class:ca-bg--light-cream={bgColor === "light-cream"}
    class:ca-bg--neutral-400={bgColor === "neutral-400"}
    class:ca-bg--midtone-blue={bgColor === "midtone-blue"}
    class:ca-bg--highlight-blue={bgColor === "highlight-blue"}
    class:ca-bg--black={bgColor === "black"}
    class:ca-bg--neutral-black={bgColor === "neutral-black"}
    class:ca-bg--light-beige={bgColor === "light-beige"}
    class:ca-bg--light-orange={bgColor === "light-orange"}
    class:ca-bg--midtone-orange={bgColor === "midtone-orange"}
    class:ca-bg--light-green={bgColor === "light-green"}
    class:ca-bg--midtone-green={bgColor === "midtone-green"}
    class:ca-bg--highlight-green={bgColor === "highlight-green"}
    class:ca-bg--highlight-yellow={bgColor === "highlight-yellow"}
    class:ca-bg--neutral-white={bgColor === "neutral-white" || bgColor === null}
    id=""
  >
    <div class="ca-secondary-nav__container">
      <div class="ca-secondary-nav__scroller gap-3 px-4 pt-1 pb-3">
        {#each items as item}
          <svelte:component
            this={item?.items?.length > 0 ? ToggleItem : Item}
            {item}
          />
        {/each}
      </div>
    </div>
  </nav>
{/if}
