<script lang="ts">
  export let title;
  export let content;
</script>

<div class="ca-card-with-title">
  <div class="ca-card-with-title__title">{title}</div>
  <div class="ca-card-with-title__body ca-bg--neutral-white">
    {content}
  </div>
</div>
