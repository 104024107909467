<script lang="ts">
  import MarkdownIt from "markdown-it";
  import Heading from "../../Heading/Heading.svelte";
  import Button from "../../Button/Button.svelte";
  import Eyebrow from "./parts/Eyebrow.svelte";

  export let eyebrow = "";
  export let eyebrowTag;

  export let id = "";
  export let heading = {
    text: "",
    id: "",
  };

  export let text = "";
  export let buttons = [];

  const md = new MarkdownIt({
    breaks: true,
    html: true,
  });
</script>

<section class="ca-section py-4" {id}>
  <div class="container-xl">
    <div class="ca-bg--black py-5 py-lg-7">
      <div class="container-fluid">
        {#if eyebrow}
          <Eyebrow tag={eyebrowTag}>{eyebrow}</Eyebrow>
        {/if}
        <Heading
          {...{
            ...{
              tag: "h1",
              font: "heading",
              align: "center",
              size: "4xl",
            },
            ...heading,
          }}
          classList="ca-cta__title"
        />

        {#if text && text !== ""}
          <div class="ca-cta__body mt-4_5 ca-flow cmw-container-md mx-auto">
            {@html md.render(text || "")}
          </div>
        {/if}

        {#if buttons?.length > 0}
          <div class="d-flex flex-wrap justify-content-center gap-3 mt-4_5">
            {#each buttons as button}
              <Button {...button} />
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </div>
</section>
