<script lang="ts">
  import MarkdownIt from "markdown-it";
  import Heading from "../../Heading/Heading.svelte";
  import Button from "../../Button/Button.svelte";
  import Eyebrow from "./parts/Eyebrow.svelte";
  import Media from "./parts/Media.svelte";

  export let eyebrow = "";
  export let eyebrowTag;

  export let id = "";
  export let heading = {
    text: "",
    id: "",
  };

  export let text = "";
  export let media = [];
  export let buttons = [];

  const md = new MarkdownIt({
    breaks: true,
    html: true,
  });
</script>

<section class="ca-section ca-section-centered-image py-4 py-md-5 py-lg-7" {id}>
  <div class="container-xl">
    <div class="row">
      <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3">
        {#if eyebrow}
          <Eyebrow tag={eyebrowTag}>{eyebrow}</Eyebrow>
        {/if}
        <Heading
          {...{
            tag: "h2",
            font: "heading",
            size: "4xl",

            ...heading,
            classList: "text-left text-md-center",
          }}
        />

        {#if text}
          <div class="ca-section__intro ca-text-body-1 ca-flow">
            <p style="text-align: center;">
              {@html md.render(text || "")}
            </p>
          </div>
        {/if}

        {#if buttons?.length > 0}
          <div class="d-flex justify-content-center mt-4_5 mt-lg-6">
            {#each buttons as button}
              <Button {...button} />
            {/each}
          </div>
        {/if}
      </div>
    </div>

    {#if media?.[0]}
      <div class="row">
        <div
          class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mt-4_5 mt-lg-6"
        >
          <Media media={media?.[0]} />
        </div>
      </div>
    {/if}
  </div>
</section>
