<script lang="ts">
  export let item;
  export let variant;
</script>

<li class="ca-dropdown-menu__item">
  <a href={item.url} target="_self">
    <div class="title">
      <div class="title-sub-container" class:has-label={item.label}>
        {item.title}
        <div
          class={variant === "static-rows" && item.ctaLabel
            ? ""
            : "thick-underline-transition"}
        />
      </div>
      {#if item.label}
        <div class="highlight-container">{item.label}</div>
      {/if}
    </div>
    {#if item.description}
      <div class="description">{item.description}</div>
    {/if}
    {#if item.ctaLabel && variant === "static-rows"}
      <div class="cta-link">
        {item.ctaLabel}
        <div class="arrow-container">
          <svg
            width="16"
            height="14"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.9001 9.00663L13.3437 0.450195L12.2835 1.51033L19.0301 8.25689L0.100098 8.25689V9.75614L19.0303 9.75615L12.2835 16.5029L13.3437 17.5631L21.9001 9.00663Z"
              fill="black"
            /></svg
          >
        </div>
      </div>
    {/if}
  </a>
</li>

<style lang="scss">
  .ca-dropdown-menu__item {
    display: flex;
    flex-direction: column;
    padding-bottom: 1.5rem;
    .title {
      margin-bottom: 0.5rem;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      display: flex;
      gap: 10px;
      .title-sub-container.has-label {
        @media (min-width: 992px) {
          width: max-content;
        }
      }
    }
    .description {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
    }
    .cta-link {
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      margin-top: 16px;
      flex-grow: 1;
      display: flex;
      align-items: flex-end;
      .arrow-container {
        display: flex;
        opacity: 0;
        transition: opacity 0.25s;
        background-color: var(--colorTheme);
        justify-content: center;
        // margin-top: -2px;
        margin-left: 1rem;
        border-radius: 16px;
        height: 31px;
        width: 31px;
      }
      &:hover {
        .arrow-container {
          opacity: 1;
        }
      }
    }
    @media (min-width: 992px) {
      &:hover {
        .thick-underline-transition {
          width: 100%;
          background-color: black;
        }
      }
    }
  }
  .thick-underline-transition {
    background-color: transparent;
    height: 3px;
    width: 0%;
    margin-top: 5px;
    margin-bottom: -5px;
    transition: all 0.25s linear;
    // cubic-bezier(0.85, -0.01, 0.19, 1.01)
  }
  .highlight-container {
    background-color: var(--colorTheme);
    padding: 4px 10px 4px 10px;
    border-radius: 17px;
    width: fit-content;
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
  // mobile
  @media (max-width: 991px) {
    .ca-dropdown-menu__item {
      padding-bottom: 1rem;
      .description,
      .cta-link {
        display: none;
      }
    }
    .ca-dropdown-menu__item:active {
      text-decoration: underline;
    }
  }
</style>
