<script lang="ts">
  import Button from "../Button/Button.svelte";
  import Heading from "../Heading/Heading.svelte";
  import { bgColors } from "../../lib/constants";
  import { paddingSizes } from "../../lib/constants";

  export let id;
  export let items;
  export let heading;
  export let text;
  export let mediaAlignment = "right";
  export let bgColor;
  export let blockSettings;

  const pY = blockSettings?.verticalPadding || "L";

  let iframeWidth;

  let activeSlug = "";

  if (items) {
    activeSlug = items[0].slug;
  }

  const clickHandler = ({ slug }) => {
    activeSlug = slug;
  };

  $: bgColor = bgColor || "Light Blue";
</script>

<section class={`ca-section ${paddingSizes[pY]}`} {id}>
  <div class="container-xl">
    <div
      class={`${heading.text || text ? "py-4_5 py-md-5 py-lg-7" : "pt-1 pb-4_5 pb-md-5 pb-lg-7"} ${bgColors[bgColor]}`}
    >
      <div class="container-fluid">
        {#if heading.text || text}
          <div class="row">
            {#if heading}
              <div class="col-12 col-lg-8 offset-lg-2 text-center">
                <Heading {...heading} />
              </div>
            {/if}

            {#if text}
              <div class="col-12 col-md-6 offset-md-3">
                <div class="ca-section__intro">{text}</div>
              </div>
            {/if}
          </div>
        {/if}

        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="ca-tabcordion mt-5">
              <div class="ca-tabcordion__filter">
                {#each items as { slug, label }}
                  <button
                    data-toggle={slug}
                    class="ca-btn ca-btn--secondary ca-tabcordion__filter-item"
                    class:active={slug === activeSlug}
                    on:click={(event) =>
                      clickHandler({
                        event,
                        slug,
                      })}
                  >
                    {label}
                  </button>
                {/each}
              </div>

              {#each items as item}
                <div
                  class="ca-tabcordion__item"
                  class:active={item.slug === activeSlug}
                  id={item.slug}
                >
                  <button
                    class="ca-tabcordion__item-toggle"
                    data-toggle={item.slug}
                    on:click={(event) =>
                      clickHandler({
                        event,
                        slug: item.slug,
                      })}
                  >
                    {item.heading?.text}
                  </button>

                  <div class="ca-tabcordion__item-body">
                    <div class="row align-items-lg-center">
                      {#if item.media.url}
                        <div
                          class="col-12 col-lg-6"
                          class:order-lg-1={mediaAlignment === "right"}
                          class:offset-lg-1={mediaAlignment === "right"}
                        >
                          <img
                            width="1200"
                            height="900"
                            src={item.media.url}
                            alt={item.media.alt}
                          />
                        </div>
                      {:else if item.embed.snippet}
                        <!-- to make video bigger, we can remove offeset-lg-1 below and change col-lg-6 to col-lg-7 -->
                        <div
                          class="col-12 col-lg-6 d-flex justify-content-center"
                          class:order-lg-1={mediaAlignment === "right"}
                          class:offset-lg-1={mediaAlignment === "right"}
                          bind:clientWidth={iframeWidth}
                          style="--frame-height: {`${iframeWidth ? iframeWidth * 0.55 : 155}px`}"
                        >
                          <style>
                            iframe {
                              width: 100%;
                              height: var(--frame-height);
                            }
                          </style>
                          {@html item.embed.snippet}
                        </div>
                      {/if}
                      <div
                        class="col-12 mt-3 col-lg-5"
                        class:offset-lg-1={mediaAlignment === "left"}
                      >
                        <Heading
                          {...item.heading}
                          classList="mb-3 d-none d-lg-block"
                        />

                        <div class="ca-text-body-1">
                          {item.text}
                        </div>

                        <div class="mt-3">
                          {#each item.buttons as button}
                            <Button {...button} />
                          {/each}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              {/each}
            </div>
            <!-- END ca-tabcordion -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- somehow css in SubNav.scss was affecting the filter items. This fixed it for time being -->
<style lang="css">
  .ca-bg--black .ca-tabcordion__filter-item {
    color: black;
  }
  .ca-bg--black .ca-tabcordion__filter-item:hover {
    color: white;
  }
  .ca-bg--black .active.ca-tabcordion__filter-item {
    color: white;
  }
  .ca-bg--black .ca-tabcordion__item-toggle {
    color: white;
    border-top: 1px solid white;
  }
  .ca-bg--black .ca-tabcordion__item-toggle::after {
    filter: invert(1);
  }
</style>
