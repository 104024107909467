<script lang="ts">
  export let date;
  export let title;
  export let content;
  export let alignment;
  export let last;
</script>

<div
  class="ca-timeline-item"
  class:ca-timeline-item--odd={alignment === "right"}
  class:ca-timeline-item--even={alignment === "left"}
  class:ca-timeline-item--last={last === true}
>
  <div class="flex flex-wrap">
    <div class="ca-timeline-item__date-wrap">
      <time class="ca-timeline-item__date block" datetime={date}>{date}</time>
    </div>

    <div class="ca-bg--neutral-white ca-clr--global-body">
      <div class="ca-timeline-item__body p-4">
        <div class="flex flex-wrap h-full">
          <div
            class="w-full lg:w-2/3 pr-4 pl-4 lg:flex flex-col justify-between"
          >
            <div class="ca-timeline-item__title ca-text-xl">
              {title}
            </div>
            <div class="ca-timeline-item__tease mt-3">
              {@html content}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
