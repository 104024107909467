<script lang="ts">
  import Card from "../../Card/Card.svelte";

  export let heading;
  export let items;

  $: heading = heading || {};
  $: items = items || [];
</script>

<div class="col-12 col-lg-8">
  {#if Object.keys(heading)?.length > 0}
    <h2 class="ca-text-2xl text-center text-md-start mb-4_5">{heading.text}</h2>
  {/if}
  <div class="row g-4 ca-blog-category-cards">
    {#each items as item}
      <Card variant="card-blog" {...item} />
    {/each}
  </div>
  <div class="mt-4_5 d-flex justify-content-center justify-content-md-end">
    <a href="/blog/all/" target="_self" class="ca-btn ca-btn--secondary"
      >See more <svg
        width="22"
        height="17"
        viewBox="0 0 22 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="8.25"
          x2="20"
          y2="8.25"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          d="M13 1L20.5 8.5L13 16"
          stroke="currentColor"
          stroke-width="1.5"
        />
      </svg>
    </a>
  </div>
</div>
