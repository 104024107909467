<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";
  import SplitBlockItem from "../parts/SplitBlockItem.svelte";
  import { accentColors } from "$lib/constants";

  export let heading;
  export let text;
  export let media;
  export let backgroundColor;
  export let backgroundColorFit;
  export let accentColor;
  export let id;

  let currentIndex = 0;
  const updateIndex = (newIndex) => {
    currentIndex = newIndex;
  };

  let clickedStates = Array.apply(null, Array(media.length)).map(() => false);
  const updateClickedTab = (index) => {
    clickedStates = Array.apply(null, Array(media.length)).map((x, i) =>
      i === index ? !clickedStates[i] : false,
    );
  };
</script>

<section
  {id}
  class="container-fluid panel-split-block {backgroundColorFit !== 'Contain'
    ? backgroundColor
    : ''}"
>
  <div class="container-xl">
    <div
      class="bg-color-container {backgroundColorFit === 'Contain'
        ? backgroundColor
        : ''}"
    >
      <Heading {...{ ...heading, size: "3xl" }} />
      <div class="text-container ca-text-body-1 text-center mt-3">{text}</div>

      <div class="block-container">
        <div class="items-grid">
          {#each media as item, i}
            <SplitBlockItem
              {...item}
              accentColor={accentColors[accentColor]}
              {clickedStates}
              index={i}
              {currentIndex}
              {updateIndex}
              {updateClickedTab}
            />
          {/each}
        </div>
        <div class="media-container">
          <img
            height="266"
            width="522"
            src={media[currentIndex].image.url}
            alt={media[currentIndex].image.alt}
          />
        </div>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  @use "$sass/base/typography" as *;

  .panel-split-block {
    margin: 50px 0px;
    .text-container {
      width: clamp(40%, 630px, 100%);
      margin: auto;
    }
    .bg-color-container {
      padding: 100px 0px;
    }
  }

  .block-container {
    display: grid;
    grid-template-columns: minmax(586px, 54%) 1fr;
    column-gap: 60px;
    padding-top: 58px;
  }
  .items-grid {
    display: grid;
    align-content: center;
    grid-template-columns: 1fr 1fr;
    row-gap: 44px;
    column-gap: 24px;
  }
  .media-container {
    display: flex;
    justify-content: flex-end;
    img {
      aspect-ratio: 16/9;
      object-fit: contain;
    }
  }

  // mobile
  @media (max-width: 992px) {
    .panel-split-block {
      margin: 32px 0px;
      .bg-color-container {
        padding: 40px 0px;
      }
    }
    .block-container,
    .items-grid {
      grid-template-columns: 1fr;
      width: 100%;
    }
    .media-container {
      display: none;
    }
  }
</style>
