<script lang="ts">
  import StaticColsFlyout from "./variants/StaticColsFlyout.svelte";
  import TabbedDropdownFlyout from "./variants/TabbedDropdownFlyout.svelte";
  import StaticRowsFlyout from "./variants/StaticRowsFlyout.svelte";

  export let variant;
  export let isOpen;
  export let item;
  // export let featuredItem;
  // consider renaiming this ~main flyout for clarity
  $: variant = variant || "four-column";

  const variants = {
    "static-columns": StaticColsFlyout,
    "static-rows": StaticRowsFlyout,
    "tabbed-flyouts": TabbedDropdownFlyout,
  };
</script>

<svelte:component this={variants[variant]} {isOpen} {item} />
