// @ts-strict-ignore
import CardsGrid from "./CardsGrid.svelte";
import { CardConfig } from "../Card/Card.config";

export const CardsGridConfig = {
  name: "CardsGrid",
  component: CardsGrid,
  variants: {},
  mapper: (props) => {
    const result = { ...props };

    result.items = props?.cardsCollection?.items.map((card) => {
      return CardConfig.mapper(card);
    });

    delete result.cardsCollection;

    return result;
  },
  fragment: `fragment CardsGridData on CardsGrid {
        variant
        headingText
        text
        topCard {
            ...PricingCardData
          }
          bottomCard {
            ...PricingCardData
          }
        cardsCollection(limit: 20) {
        items {
            __typename
            ... on PricingCard {
                ...PricingCardData
            }
            ... on Card {
                ...CardData
            }
        }
        }
  }`,
};
