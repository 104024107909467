// @ts-strict-ignore
import Accordion from "$components/Accordion/Accordion.svelte";
import { HeadingConfig } from "$components/index.js";

export const AccordionConfig = {
  name: "Accordion",
  component: Accordion,
  variants: {},
  mapper: (props) => {
    const result = { ...props };

    const items = props?.items?.items.map((item) => {
      return {
        title: item.title,
        content: item.richTextContent?.json,
        tag: item.externalTag,
      };
    });
    result.items = items;

    if (result?.heading) {
      result.heading = HeadingConfig.mapper(props.heading);
    }

    return result;
  },
  // should change the heading so it is coming from heading config
  fragment: `fragment AccordionData on Accordion {
        variant
        text
        accentColor
        heading {
            text
            size
            level
            font
            size
            align: textAlignment
        }
        items: accordianItemsCollection(limit: 20) {
            items {
              title
              externalTag
              richTextContent: content {
                json
              }
            }
          }
      }`,
};
