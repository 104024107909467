<script lang="ts">
  import { onMount } from "svelte";
  import Panel from "../../Panel/Panel.svelte";
  import AgendaRow from "../AgendaRow.svelte";
  import { formatTabDate, formatDate, dateShort } from "../Agenda.helpers";
  import "../Agenda.css";

  export let hero;
  export let title;
  export let text;
  export let agenda;
  // may remove mainTabName option and just keep all days hardcode
  export let mainTabName;
  export let colorTheme = "light-blue";

  let activeTab = "all-days";

  let tabs = agenda?.map(({ date }) => date) || [];

  const handleTabChange = (tab) => {
    const formattedTab = formatTabDate(tab);
    activeTab = formattedTab;
    window.location.hash = formattedTab;
  };

  const handleInitialTab = () => {
    const currentHash = window.location.hash;
    if (tabs.includes(currentHash.substr(1))) {
      activeTab = currentHash.substr(1);
    } else {
      activeTab = "all-days";
      window.location.hash = activeTab;
    }
  };

  onMount(handleInitialTab);

  // console.log($$props);
</script>

<section
  style="--color:  var(--clr-{colorTheme})"
  class="ca-section pb-5 pb-lg-7"
  class:pt-5={!hero}
  class:pt-lg-7={!hero}
  class:color-theme-dark={colorTheme.includes("black")}
>
  {#if hero}
    <Panel {...hero} />
  {/if}
  <div class="container-xl">
    {#if title || text}
      <div class="row">
        {#if title}
          <div class="col-12 col-md-6 offset-md-3">
            <h2 class="ca-text-4xl ca-font-heading text-center">{title}</h2>
          </div>
        {/if}
        {#if text}
          <div class="col-12 mb-3">
            <div class="ca-section__intro ca-text-body-1 ca-flow agenda-text">
              {text}
            </div>
          </div>
        {/if}
      </div>
    {/if}

    {#if agenda}
      <div class="agenda-tabs">
        <div class="agenda-tabs-inner">
          <button
            class="agenda-tab-item {'all-days' === activeTab ? 'active' : ''}"
            on:click={() => handleTabChange("all-days")}
          >
            {mainTabName || "All days"}
          </button>
          {#each agenda as row}
            <button
              class="agenda-tab-item {formatTabDate(row.date) === activeTab
                ? 'active'
                : ''}"
              on:click={() => handleTabChange(row.date)}
            >
              {formatDate(row.date, dateShort)}
            </button>
          {/each}
        </div>
      </div>

      <div class="agenda mt-4_5">
        {#each agenda as row}
          {#if formatTabDate(row.date) === activeTab || "all-days" === activeTab}
            <AgendaRow data={row} {activeTab} />
          {/if}
        {/each}
      </div>
    {/if}
  </div>
</section>
