<script lang="ts">
  import ContentfulRichTextParserSimple from "$components/ContentfulRichTextParser/ContentfulRichTextParserSimple.svelte";
  import "$components/Accordion/Accordion.scss";
  import PlusIcon from "$components/Icons/PlusIcon.svg?raw";
  import MinusIcon from "$components/Icons/MinusIcon.svg?raw";
  import Heading from "$components/Heading/Heading.svelte";
  import { accentColors } from "$lib/constants";

  export let items;
  export let heading;
  export let text;
  export let accentColor = accentColors["Light Beige"];

  const withTitle = $$slots.title;

  $: activeClasses = "";

  $: openIndex = -1;
</script>

<div class="ca-section">
  <div class="container-xl">
    <section class:withTitle class="accordion-container">
      {#if heading?.text}
        <div class="accordion-title">
          <Heading
            text={heading.text}
            class="accordion-title__heading"
            size="base"
          />
          {#if text}
            <div class="accordion-title__sub">
              {@html text}
            </div>
          {/if}
        </div>
      {/if}

      <div class="accordion-list {activeClasses}">
        {#each items as { title, tag, content }, i}
          {@const isOpen = i === openIndex && openIndex !== -1}
          <details
            class="accordion-item"
            on:toggle={(ev) => {
              const { newState } = ev;
              if (newState === "open") {
                openIndex = i;
              }
              if (isOpen && newState === "closed") {
                openIndex = -1;
              }
            }}
            class:open={isOpen}
            open={isOpen}
          >
            <summary class="accordion-item__header" class:open={isOpen}>
              <div class="d-flex align-items-center flex-wrap gap-2">
                <span class="accordion-item__header__text" class:open={isOpen}
                  >{@html title}</span
                >
                {#if tag}
                  <span class="ca-dropdown-acc__feat ca-bg--light-blue"
                    >{tag}</span
                  >
                {/if}
              </div>
              <div
                style="color: {accentColor}; strok"
                class="accordion-item__expand-icon"
                class:expand-icon-open={openIndex === i}
              >
                {#if isOpen}
                  {@html MinusIcon}
                {:else}
                  {@html PlusIcon}
                {/if}
                <!-- <img src={isOpen ? MinusIcon : PlusIcon} alt="" /> -->
              </div>
            </summary>
            <div class="accordion-item__content" class:isOpen>
              {#if typeof content === "object"}
                <ContentfulRichTextParserSimple node={content} />
              {:else}
                {@html content}
              {/if}
            </div>
          </details>
        {/each}
      </div>
    </section>
  </div>
</div>

<style lang="scss">
  // Ref: https://www.figma.com/file/D6lmfqQUUUeNMHovZHnNtW/Carta.com-Rebrand-Library?node-id=1596%3A8117
  @use "$sass/base/typography" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/abstracts" as *;

  .accordion-container {
    display: flex;
    flex-direction: column;
    padding: 2rem 0; // 100 x 40
    gap: 48px;

    @include media-breakpoint-up(lg) {
      padding: 3.125rem 0; // 100 x 40
      flex-direction: row;
      text-align: left;
      gap: 5rem;
    }
  }
  .accordion-title {
    @include media-breakpoint-up(lg) {
      max-width: 400px;
    }
  }
  :global(.ca-text-base.ca-font-heading) {
    @include text-mobile-header-3;
    @include media-breakpoint-up(lg) {
      @include text-web-header-3;
    }
    text-align: left !important;
  }
  .accordion-title__sub {
    margin-top: 2rem;
    @include text-mobile-body-1;

    @include media-breakpoint-up(lg) {
      @include text-web-body-1;
      padding-right: 4rem;
    }
  }
  .accordion-list {
    flex: 1;
  }
  .accordion-item {
    border-bottom: 1px solid #e4ddd9;
    display: block;

    &:first-of-type {
      border-top: 1px solid #e4ddd9;
    }
  }
  .accordion-item__expand-icon {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 0.875rem;
    user-select: none;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .accordion-item__header {
    @include text-mobile-body-1;
    @include media-breakpoint-up(lg) {
      @include text-web-body-1;
    }

    cursor: pointer;
    padding: 24px 0px 24px 0;
    position: relative;
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    &.open {
      padding-bottom: 0.5rem;
      @include media-breakpoint-up(lg) {
        padding-bottom: 1.5rem;
      }
    }

    @include media-breakpoint-up(lg) {
      gap: 2rem;
      padding: 24px 0px 24px 0;
    }

    // Item title
    .accordion-item__header__text {
      &.open {
        font-weight: 500;
      }

      @include media-breakpoint-up(lg) {
        padding-right: 5rem;
      }
    }

    // remove default marker
    list-style-type: none;
    &::marker {
      display: none;
    }

    &::-webkit-details-marker {
      display: none;
    }

    &__expand-icon {
      user-select: none;
      width: 30px;
      height: 30px;
      cursor: pointer;
    }

    @include media-breakpoint-up(lg) {
      text-align: left;

      :global(h2) {
        text-align: left !important;
      }
    }
  }

  .accordion-item__content {
    display: none;
    &.isOpen {
      display: block;
      animation: fade-in 500ms;
    }
    @include text-mobile-body-2;
    margin-bottom: 1.5rem;
    padding-right: 6rem;
    @include media-breakpoint-up(lg) {
      padding-right: 13rem;
      @include text-web-body-2;
    }
  }

  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
</style>
