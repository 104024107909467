// @ts-strict-ignore
import PricingCard from "../PricingCard/PricingCard.svelte";

export const PricingCardConfig = {
  name: "PricingCard",
  component: PricingCard,
  variants: {},
  mapper: (props) => {
    const result = {
      ...props,
      variant: props?.variant?.toLowerCase(),
      buttons: props?.buttonsCollection?.items.map((n) => ({
        ...n,
        variant: n?.variant.toLowerCase(),
      })),
    };
    result.heading = result.headingText;
    delete result.headingText;
    delete result.buttonsCollection;

    return result;
  },
  fragment: `fragment PricingCardData on PricingCard {
        headingText: heading
        content
        buttonsCollection(limit: 4) {
            items {
              text
              url
              variant
              icon
            }
          }
        tag: externalTag
        tagLocation
        features
        variant
        price
        priceString
        priceSuffix
        starting
        open
        backgroundColor
      }`,
};
