<script lang="ts">
  import MarkdownIt from "markdown-it";
  import { paddingSizes } from "../../../lib/constants";
  export let id = "";
  export let text = "";
  export let containerWidth;
  export let settings;
  export let backgroundColor;

  const md = new MarkdownIt({
    breaks: true,
    html: true,
  });

  const pY = settings?.verticalPadding || "M";

  $: cw = containerWidth || "SM";
  $: containerWidthClassnames = {
    XS: "col-12 col-md-6 offset-md-3",
    SM: "col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3",
    MD: "col-12 col-md-8 offset-md-2",
    LG: "col-12 col-md-10 offset-md-1",
    XL: "col-12",
  }[cw];
</script>

<div class={`ca-section ${paddingSizes[pY]} ${backgroundColor} ${id}`}>
  <div class="container-xl">
    <div class="row">
      <div class={containerWidthClassnames}>
        {#if text}
          <div class="ca-text-body-1 ca-flow text-center">
            {@html md.render(text || "")}
          </div>
        {/if}
      </div>
    </div>
  </div>
</div>
