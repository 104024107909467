// @ts-strict-ignore
import PanelProductHero from "./PanelProductHero.svelte";
import PanelUseCaseHero from "./PanelUseCaseHero.svelte";
import PanelCenteredWithImageHero from "./PanelCenteredWithImageHero.svelte";
import PanelFeaturedStats from "./PanelFeaturedStats.svelte";
import PanelCallToAction from "./PanelCallToAction.svelte";
import PanelMediaText from "./PanelMediaText.svelte";
import PanelInnovatorStories from "./PanelInnovatorStories.svelte";
import PanelBlogHero from "./PanelBlogHero.svelte";
import PanelIconsHero from "./PanelIconsHero.svelte";
import PanelEditorialWithImageHero from "./PanelEditorialWithImageHero.svelte";
import PanelSimpleText from "./PanelSimpleText.svelte";
import PanelSideImage from "./PanelSideImage.svelte";
import PanelSimpleBanner from "./PanelSimpleBanner.svelte";
import PanelClassroomResultsHero from "./PanelClassroomResultsHero.svelte";
import PanelClassroomHomeHero from "./PanelClassroomHomeHero.svelte";
import PanelClassroomCTA from "./PanelClassroomCTA.svelte";
import PanelHeroProductSplit from "./PanelHeroProductSplit.svelte";
import PanelHeroProductSuite from "./PanelHeroProductSuite.svelte";
import PanelHeroProductFeature from "./PanelHeroProductFeature.svelte";
import PanelLeftAlignedIconsHero from "./PanelLeftAlignedIconsHero.svelte";
import PanelMediaTextFeatures from "./PanelMediaTextFeatures.svelte";
import PanelClassroomMediaText from "./PanelClassroomMediaText.svelte";
import PanelInlinePersonHero from "./PanelInlinePersonHero.svelte";
import PanelPersonHero from "./PanelPersonHero.svelte";
import PanelPersonHeroDD from "./PanelPersonHeroDD.svelte";

export const panelVariants = Object.freeze({
  "media-text-features": PanelMediaTextFeatures,
  "banner-side-image": PanelSideImage,
  "blog-hero": PanelBlogHero,
  "call-to-action": PanelCallToAction,
  "centered-with-image-hero": PanelCenteredWithImageHero,
  "Classroom Home Hero": PanelClassroomHomeHero,
  "classroom-cta": PanelClassroomCTA,
  "classroom-home-hero": PanelClassroomHomeHero,
  "classroom-media-text": PanelClassroomMediaText,
  "classroom-results-hero": PanelClassroomResultsHero,
  "editorial-with-image-hero": PanelEditorialWithImageHero,
  "featured-stats": PanelFeaturedStats,
  "icons-hero": PanelIconsHero,
  "innovator-stories": PanelInnovatorStories,
  "left-aligned-icons-hero": PanelLeftAlignedIconsHero,
  "media-text": PanelMediaText,
  "person-hero-inline": PanelInlinePersonHero,
  "person-hero": PanelPersonHero,
  "person-hero-dd": PanelPersonHeroDD,
  "product-hero-feature": PanelHeroProductFeature,
  "product-hero-split": PanelHeroProductSplit,
  "product-hero-suite": PanelHeroProductSuite,
  "product-hero": PanelProductHero,
  "simple-banner": PanelSimpleBanner,
  "simple-text": PanelSimpleText,
  "use-case-hero": PanelUseCaseHero,
  cta: PanelCallToAction,
});

export type PanelVariant = keyof typeof panelVariants;

export const variants = Object.keys(panelVariants);
