<script lang="ts">
  export let title;
  export let image;
  export let url;
</script>

<div class="ca-case-study-block">
  <a data-sveltekit-reload href={url} target="_self">
    <img src={image.src} alt={image.alt} {title} />
  </a>
</div>
