<script lang="ts">
  import CardsPagination from "./CardsPagination.svelte";
  import Card from "$components/Card/Card.svelte";

  export let items;
  export let useCarousel;

  let currentCard = 0;

  $: items = items || [];
  const incrementCard = (/** @type {'left' | 'right'} */ direction) => {
    if (direction === "left" && currentCard > 0) {
      currentCard--;
    } else if (direction === "right" && currentCard < items.length - 1) {
      currentCard++;
    }
  };
</script>

<div class="related-cards-container">
  <div class="grid-container" class:useCarousel>
    {#each items as { title, image, url, cta, eyebrow, category, date, seo, content, authors }, i}
      <div class="card" class:hide={currentCard !== i}>
        <Card
          {...{
            variant: "classroom-related",
            date,
            eyebrow: eyebrow || category,
            title,
            content: content || seo?.description,
            image,
            cta: {
              url: url || cta?.url,
              text: cta?.text || "View",
            },
            alignment: useCarousel,
            authors,
          }}
        />
      </div>
    {/each}
  </div>
  <div class="pagination-container" class:useCarousel>
    <CardsPagination {items} {currentCard} {incrementCard} />
  </div>
</div>

<style lang="scss">
  .grid-container {
    --columns: 1;

    display: grid;
    grid-template-columns: repeat(var(--columns), 1fr);
    column-gap: 2rem;
    row-gap: 64px;

    @media (min-width: 660px) {
      --columns: 2;
    }

    // temporarily commenting out due to width cause by long title in test env
    @media (min-width: 992px) {
      --columns: 3;
    }
  }

  .pagination-container {
    display: none;
  }
  // mobile
  @media (max-width: 991px) {
    .grid-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      row-gap: 48px;

      &.useCarousel {
        .card.hide {
          display: none;
        }
      }
    }
    .pagination-container.useCarousel {
      display: unset;
    }
  }
</style>
