<script lang="ts">
  export let jsonLDData;
  export let className;
  $: className = className ? " " + className : "";
</script>

<svelte:head>
  {#if jsonLDData}
    {@html `<script type="application/ld+json" class="schema ${className}">` +
      JSON.stringify(jsonLDData, null, 2) +
      `</script>`}
  {/if}
</svelte:head>
