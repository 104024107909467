<script lang="ts">
  import "./Panel.css";
  import { panelVariants, type PanelVariant } from "./variants/variantsMapping";
  import { bgColors } from "../../lib/constants";

  export let variant: PanelVariant = "product-hero";
  export let containerWidth;
  export let id;
  export let animation;
  export let eyebrow;
  export let eyebrowTag;
  export let heading;
  export let text;
  export let buttons;
  export let media;
  export let stats;
  export let backgroundColor;
  export let backgroundColorFit;
  export let settings;
  export let accentColor;
  export let titleHighlights;
  // Special props for author panel variant. Either this or forwarding all props
  export let panelPersonHeroProps = {};

  $: variant = variant || "product-hero";
  $: containerWidth = containerWidth || "";
  $: id = id || "";
  $: eyebrow = eyebrow || "";
  $: eyebrowTag = eyebrowTag || "h1";
  $: heading = heading || "";
  $: text = text || "";
  $: buttons = buttons || [];
  $: media = media || [];
  $: stats = stats || [];
  $: titleHighlights = titleHighlights || [];
  $: backgroundColor = backgroundColor || "";
  $: backgroundColorFit = backgroundColorFit || "";
  $: settings = settings || {};
  $: finalBgColor = bgColors[backgroundColor];
</script>

<svelte:component
  this={panelVariants[variant]}
  {...panelPersonHeroProps}
  {id}
  {variant}
  {buttons}
  {heading}
  {text}
  {eyebrow}
  {animation}
  {media}
  {stats}
  backgroundColor={finalBgColor}
  {backgroundColorFit}
  {containerWidth}
  {titleHighlights}
  {eyebrowTag}
  {settings}
  {accentColor}
/>
