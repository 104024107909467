<script lang="ts">
  import { dev } from "$app/environment";
  import ErrorState from "./ErrorState.svelte";
  export let error = null;
  export let onError = null;

  $: if ($error && onError) onError($error);
  $: if ($error) console.error($error);
</script>

{#if $error}
  <div class="error">
    {#if dev}
      <b>{$error.message}</b>
      <pre class="trace">{$error.stack}</pre>
    {:else}
      <ErrorState />
    {/if}
  </div>
{:else}
  <slot />
{/if}

<style>
  .trace {
    font-family: monospace;
    overflow-x: scroll;
  }
</style>
