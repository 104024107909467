<script lang="ts">
  import PricingPrimary from "./variants/PricingPrimary.svelte";
  import PricingFlexible from "./variants/PricingFlexible.svelte";

  import "./PricingGrid.scss";

  export let items;
  export let text;
  export let variant = "PricingGrid";
  export let bottomCard;
  export let topCard;

  $: items = items || [];
  $: text = text || "";

  const selectedVariantComponent = {
    PricingGrid: PricingPrimary,
    PricingGridFlexible: PricingFlexible,
    flexible: PricingFlexible,
  }[variant];
</script>

<svelte:component
  this={selectedVariantComponent}
  {text}
  {items}
  {bottomCard}
  {topCard}
/>
