<script lang="ts">
  import Breadcrumbs from "$components/Breadcrumbs/Breadcrumbs.svelte";
  import RelatedCardsGrid from "$components/RelatedCardsGrid/RelatedCardsGrid.svelte";
  import { PanelConfig, FilterMenuConfig } from "$components";
  import FinePrintText from "$components/FinePrintText/FinePrintText.svelte";
  import Article from "$layouts/Article/Article.svelte";
  import PageContainer from "./PageContainer.svelte";
  import Seo from "../parts/seo.svelte";
  import { ContentfulType } from "$lib/constants";
  import Grid from "$components/Grid/Grid.svelte";
  import Section from "$components/Section/Section.svelte";
  import FeaturedCards from "$components/FeaturedCards/FeaturedCards.svelte";
  import { page } from "$app/stores";
  import editPageInContentful from "$lib/editPageInContentful";

  export let data;
  editPageInContentful(data.sys.id);

  let contentArray;
  let h1Text;

  if (data?.content?.__typename === "Page") {
    contentArray = data.content.sectionsCollection?.items;
  } else if (data?.content?.__typename === "LayoutSections") {
    contentArray = data.sections;
  } else if (data?.content?.__typename === "BlogPost") {
    h1Text = data?.content?.title;
  }

  //   get h1 text to pass to Seo for schema for pages and layoutsections
  contentArray?.every((entry) => {
    if (entry.__typename == "Panel") {
      if (entry?.heading?.level === "H1") {
        h1Text = entry.heading.text;
        return false;
      } else if (
        entry.eyebrow &&
        (entry.eyebrowTag === "H1" || entry.eyebrowTag === null)
      ) {
        h1Text = entry.eyebrow;
        return false;
      }
    }
  });

  const pathname = $page.url.pathname || "";
  const showBreadcrumbs =
    data?.breadcrumbs?.length &&
    ((pathname.includes("/learn") && data?.slug !== "learn") ||
      pathname.includes("/blog") ||
      (pathname.includes("/data") && data?.slug !== "data"));
</script>

{#if data?.seo}
  <Seo
    {...data.seo}
    alternateLocales={data.alternateLocaleUrls}
    locale={data.locale}
    permalink={data.permalink}
    {h1Text}
  />
{/if}

{#if showBreadcrumbs}
  <div class="container-xl breadcrumbs-container">
    <Breadcrumbs
      pageType={data.content?.__typename}
      breadcrumbs={data?.seo?.breadcrumbs || data?.breadcrumbs}
    />
  </div>
{/if}

{#if data?.content?.__typename === ContentfulType.PAGE}
  <PageContainer {data} />
{:else if data?.content?.__typename === ContentfulType.BLOG_POST}
  <Article {data} />

  <!-- for query builder -->
{:else if data.sections?.length > 0}
  {#each data?.sections as section}
    {#if section.__typename === "FilterMenu"}
      <svelte:component this={FilterMenuConfig.component} {...section} />
    {:else if section.__typename === "AnchorLink"}
      <span
        class="visually-hidden"
        id={section?.slug || `AnchorLink-${section?.sys?.id}`}
      />
    {:else if section.__typename === "Panel"}
      {#if ["classroom-cta"].includes(section.variant)}
        <Section>
          <svelte:component this={PanelConfig.component} {...section} />
        </Section>
      {:else}
        <svelte:component this={PanelConfig.component} {...section} />
      {/if}
    {:else if section.__typename === "ContentTypeQuery"}
      <Section
        heading={{ text: section?.heading?.text }}
        buttons={section?.ctaCollection?.items}
      >
        {#if section.itemsLayout === "classroom-related-cards"}
          <RelatedCardsGrid
            variant="classroom"
            {...{ ...section, heading: null }}
          />
        {:else if section.itemsLayout === "headings-only-cards"}
          <Grid
            {...{
              columns: section.items.map((card) => ({
                ...card,
                __typename: "Card",
                variant: "classroom-heading-only",
              })),
              gridHero: null,
              padding: "pt-4",
              columnsLarge: 4,
              columnsMedium: 1,
              columnsSmall: 1,
            }}
          />
        {:else if section.itemsLayout === "featured-cards"}
          <FeaturedCards
            post={{ ...section?.items?.[0], variant: "classroom-featured" }}
            items={section?.items
              ?.slice(1)
              .map((item) => ({ ...item, variant: "classroom-featured-mini" }))}
            useMobileCarousel={true}
          />
        {/if}
      </Section>
    {:else if section.__typename === "Grid"}
      <Section
        heading={{ text: section?.gridHero?.heading }}
        buttons={section?.buttonCollection?.items}
      >
        <Grid {...{ ...section, gridHero: null, padding: "pt-4" }} />
      </Section>
    {:else if section.__typename === "FinePrintText"}
      <Section>
        <FinePrintText {...section} />
      </Section>
    {:else}
      <pre>{JSON.stringify(section, null, 2)}</pre>
    {/if}
  {/each}
{/if}

<style>
  .breadcrumbs-container {
    padding-left: 0;
  }
</style>
