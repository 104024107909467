<script lang="ts">
  import LocaleToggleDefault from "./variants/LocaleToggleDefault.svelte";
  import LocaleToggleBanner from "./variants/LocaleToggleBanner.svelte";
  import SortToggle from "./variants/SortToggle.svelte";

  export let theme = "dark";
  export let locale = "en-US";
  export let variant;
  export let updateSelected;
  export let geo;
  export let labels = {
    "en-US": "United States / Americas",
    "en-GB": "United Kingdom / EMEA",
    "en-SG": "Asia Pacific",
  };

  const variants = {
    default: LocaleToggleDefault,
    banner: LocaleToggleBanner,
    sort: SortToggle,
  };
</script>

<svelte:component
  this={variants[variant] || variants.default}
  {theme}
  {locale}
  {labels}
  {updateSelected}
  {geo}
/>
