<script lang="ts">
  import RelatedCardsGridPrimary from "./variants/RelatedCardsGridPrimary.svelte";
  import LatestBlogCards from "./variants/LatestBlogCards.svelte";
  import ClassroomRelatedCards from "./variants/ClassroomRelatedCards.svelte";

  export let variant;
  export let heading;
  export let items;
  export let useContainer;
  export let useCarousel;
  export let lazyloadImages;

  $: variant = variant || "primary";
  $: heading = heading || {};
  $: items = items || [];
  $: useContainer = useContainer ?? true;
  $: useCarousel = useCarousel || false;

  const variants = {
    primary: RelatedCardsGridPrimary,
    "latest-blog-cards": LatestBlogCards,
    classroom: ClassroomRelatedCards,
  };
</script>

<svelte:component
  this={variants[variant]}
  {heading}
  {items}
  {useContainer}
  {useCarousel}
  {lazyloadImages}
/>
