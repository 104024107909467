// @ts-strict-ignore
import FeaturedMediaTextList from "../FeaturedMediaTextList/FeaturedMediaTextList.svelte";

export const FeaturedMediaTextListConfig = {
  name: "FeaturedMediaTextList",
  component: FeaturedMediaTextList,
  variants: {},
  mapper: (props) => {
    return {
      ...props,
      items: props?.itemsCollection?.items.map((item) => {
        const updatedMedia = {
          ...item?.media,
          alt: item?.media?.description || item?.media?.title,
        };
        return {
          ...item,
          media: updatedMedia,
        };
      }),
    };
  },
  fragment: `fragment FeaturedMediaTextListData on FeaturedMediaTextList {
        heading {
          text
          level
          size
          classList
        }
        text
        button:cta {
          text
          variant
          icon
          url
        }
        itemsCollection(limit: 15) {
          items {
            media {
              width
              height
              title
              description
              url
              contentType
            }
            heading
            text
            button:cta {
              text
              variant
              icon
              url
            }
          }
        }
      }`,
};
