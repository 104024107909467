<script lang="ts">
  import ClassroomQuote from "./variants/ClassroomQuote.svelte";
  import DefaultQuote from "./variants/DefaultQuote.svelte";

  export let backgroundColor;
  export let align;
  export let quote;
  export let author;
  export let cta;
  export let variant;

  const Component = {
    default: DefaultQuote,
    "block-quote": DefaultQuote,
    classroom: ClassroomQuote,
    "block-quote-classroom": ClassroomQuote,
  }[variant ?? "default"];
</script>

<svelte:component
  this={Component}
  {backgroundColor}
  {align}
  {quote}
  {author}
  {cta}
  {variant}
/>
