<script lang="ts">
  import Button from "../Button/Button.svelte";
  import Heading from "../Heading/Heading.svelte";

  export let heading;
  export let text;
  export let items;
  export let id;
</script>

<section
  class="ca-section ca-section-images-with-cta py-4 py-md-5 py-lg-7"
  {id}
>
  <div class="container-xl">
    {#if heading || text}
      <div class="row">
        {#if heading}
          <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
            <Heading
              {...{ ...heading, align: "center", size: "4xl" }}
              classList="ca-section__title"
            />
          </div>
        {/if}

        {#if text}
          <div class="col-12 col-md-6 offset-md-3">
            <div class="ca-section__intro">
              {text}
            </div>
          </div>
        {/if}
      </div>
    {/if}
    {#if items}
      <div class="row mt-5 mt-lg-7">
        <div class="col-12 col-md-10 offset-md-1">
          <ul class="ca-images-with-cta">
            {#each items as { media, heading, text, button }}
              <li>
                <div class="row align-items-center">
                  {#if media}
                    <div class="col-12 col-md-6 col-lg-3">
                      <div class="ca-images-with-cta__img-wrap">
                        <!-- 3:2 ratio images -->
                        <img
                          width="1440"
                          height="1080"
                          class="ca-images-with-cta__img"
                          src={media.url}
                          alt={media.alt}
                        />
                      </div>
                    </div>
                  {/if}

                  <div
                    class="col-12"
                    class:col-md-6={media}
                    class:col-lg-9={media}
                    class:col-md-12={!media}
                    class:col-lg-12={!media}
                  >
                    <div class="row align-items-center">
                      <div class:col-lg-8={button}>
                        {#if heading}
                          <Heading
                            {...{ text: heading, font: "body", tag: "h3" }}
                            classList="ca-images-with-cta__title mt-3 mt-lg-0"
                          />
                        {/if}
                        <div class="ca-images-with-cta__tease mt-2 mt-lg-1">
                          {text}
                        </div>
                      </div>
                      {#if button}
                        <div class="col-lg-4 text-lg-end">
                          <div class="mt-4 mt-lg-0">
                            <Button {...button} />
                          </div>
                        </div>
                      {/if}
                    </div>
                  </div>
                </div>
              </li>
            {/each}
          </ul>
        </div>
      </div>
    {/if}
  </div>
</section>
