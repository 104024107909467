<script lang="ts">
  export let disclosures;
  import MarkdownIt from "markdown-it";
  const md = new MarkdownIt();
</script>

{#if disclosures}
  {#each disclosures as item}
    {@const content = md.render(item.content)}
    <div class="disclosure-block">
      {@html content}
    </div>
  {/each}
{/if}
