// @ts-strict-ignore
export const MARKS = {
  bold: (value) => `<strong>${value}</strong>`,
  underline: (value) => `<u>${value}</u>`,
  italic: (value) => `<em>${value}</em>`,
};

export const basicNodeTypeToElementMap = new Map([
  ["document", "div"],
  ["paragraph", "p"],
  ["heading-1", "h1"],
  ["heading-2", "h2"],
  ["heading-3", "h3"],
  ["heading-4", "h4"],
  ["heading-5", "h5"],
  ["heading-6", "h6"],
  ["unordered-list", "ul"],
  ["ordered-list", "ol"],
  ["list-item", "li"],
  ["blockquote", "blockquote"],
  ["hyperlink", "a"],
  ["hr", "hr"],
  ["table", "table"],
  ["table-row", "tr"],
  ["table-cell", "td"],
  ["table-header-cell", "th"],
]);

export const setMapsFromLinks = (links) => {
  const assetMap = new Map();
  const entryMap = new Map();
  const entryBlockMap = new Map();

  // this will technically always run because links is an object
  if (links) {
    for (const asset of links.assets.block) {
      if (asset?.sys.id) {
        assetMap.set(asset.sys.id, asset);
      } else {
        console.warn("There is an asset with missing id, may be unpublished");
      }
    }
    if (links?.entries?.block) {
      for (const entry of links.entries.block) {
        entryMap.set(entry.sys.id, entry);
        entryBlockMap.set(entry.sys.id, entry);
      }
    }

    if (links?.entries?.inline) {
      for (const entry of links.entries.inline) {
        entryMap.set(entry.sys.id, entry);
      }
    }
  }

  return {
    assetMap,
    entryMap,
    entryBlockMap,
  };
};
