<script lang="ts">
</script>

<div class="app-error">
  <div>
    <img src="/img/error.svg" alt="Error" />
  </div>
  Something went wrong. This content is unavailable.
</div>

<style lang="scss">
  @use "$sass/base/typography_mixins" as *;
  .app-error {
    @include text-web-body-1;

    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
    align-items: center;

    img {
      height: 120px;
    }
  }
</style>
