<script lang="ts">
  import Button from "../../Button/Button.svelte";

  export let heading;
  export let content;
  export let tag;
  export let tagLocation;
  export let features;
  export let buttons;
  export let open = false;
  export let backgroundColor;

  const MAX_LIST_ITEMS = 3;
</script>

<div class="ca-price-card" class:open>
  <div class={`ca-price-card__header ${backgroundColor}`}>
    <div class="d-flex align-items-center flex-wrap gap-2 mb-4">
      {#if tag}
        <div class={`ca-tag ca-bg--neutral-white flex-shrink-0 ${tagLocation}`}>
          {tag}
        </div>
      {/if}
      <div class="ca-price-card__heading ca-text-3xl ca-font-heading">
        {heading}
      </div>
    </div>
    <div class="ca-price-card__content ca-text-body-1 mb-5">{content}</div>
    {#if buttons?.length > 0}
      {#each buttons as button}
        <Button {...button} />
      {/each}
    {/if}
    <!-- <Button text="Request a demo" href="/" tag="a" /> -->
  </div>
  <div class="ca-price-card__body" class:open>
    <ul class="ca-price-card__list">
      {#each features as item, i}
        {#if i < MAX_LIST_ITEMS}
          <li class:highlight={item.highlight}>{item.title}</li>
        {/if}
        {#if i >= MAX_LIST_ITEMS && open}
          <li class:highlight={item.highlight}>{item.title}</li>
        {/if}
      {/each}
    </ul>
  </div>
  {#if features?.length > MAX_LIST_ITEMS}
    <div class="d-flex justify-content-center mt-auto">
      <button class="ca-price-card__toggle" on:click={() => (open = !open)}>
        {#if open}
          View less
        {:else}
          View more
        {/if}
      </button>
    </div>
  {/if}
</div>

<style lang="scss">
  @media (max-width: 768px) {
    .ca-price-card__header {
      flex-grow: 0;
      gap: 1rem;
      align-items: center;

      > * {
        margin-bottom: 0 !important;
      }

      .ca-price-card__content {
        margin-bottom: 0 !important;
      }
    }
  }
  @media (min-width: 768px) {
    .ca-price-card__header {
      flex-grow: 0;
      gap: 1rem;
    }
    .ca-price-card {
      /* adding this makes slightly offcenter on screens 995-1080px */
      min-width: 267px;
      max-width: 382px;
    }
    .ca-price-card__content {
      /* max-height: 27px; */
      height: 58px;
    }
    .ca-price-card__body {
      max-height: 172px;
    }
    .ca-price-card__body.open {
      max-height: none;
    }
  }
</style>
