<script lang="ts">
  import Button from "$components/Button/Button.svelte";

  export let heading;
  export let content;
  export let tag;
  export let tagLocation;
  export let features;
  export let buttons;
  export let open = false;
  export let backgroundColor;
  export let priceString;
  export let starting;
  export let priceSuffix;

  export let priceStringSize = "md";
  export let startingOnly = false;
</script>

<div class="ca-price-card ca-price-card-flexible" class:open>
  <div class={`ca-price-card__header ${backgroundColor}`}>
    <div class="price-card-header-inner">
      {#if heading || tag}
        <div class="d-flex align-items-center flex-wrap gap-2 mb-3">
          {#if tag}
            <div
              class={`ca-tag ca-bg--neutral-white flex-shrink-0 ${tagLocation}`}
            >
              {tag}
            </div>
          {/if}
          {#if heading}
            <div class="ca-price-card__heading ca-text-2xl ca-font-heading">
              {heading}
            </div>
          {/if}
        </div>
      {/if}

      {#if content}
        <div class="ca-price-card__content ca-text-body mb-4">{content}</div>
      {/if}

      <div class="pricing-card-flexible-bottom-wrapper">
        {#if (priceString || priceSuffix) && !startingOnly}
          <div class="pricing-card-flexible-price-wrapper">
            {#if priceString}
              <span
                class="ca-price-card__price"
                class:ca-text-2xl={priceStringSize === "md"}>{priceString}</span
              >
            {/if}
            {#if priceSuffix}
              <span class="ca-text-body-1">{priceSuffix}</span>
            {/if}
          </div>
        {/if}

        {#if starting}
          <div
            class="mt-1 ca-text-body-2 pricing-card-starting"
            class:startingOnly
          >
            {starting}
          </div>
        {/if}
      </div>
    </div>

    {#if buttons?.length > 0}
      <div class="price-card-buttons mt-auto">
        {#each buttons as button}
          <Button {...button} />
        {/each}
      </div>
    {/if}
  </div>

  {#if features.length > 0}
    <div class="ca-price-card__body" class:open>
      <ul class="ca-price-card__list">
        {#each features as item, i}
          {#if i < 3}
            <li class:highlight={item.highlight}>{item.title}</li>
          {/if}
          {#if i >= 3 && open}
            <li class:highlight={item.highlight}>{item.title}</li>
          {/if}
        {/each}
      </ul>
    </div>

    {#if features.length > 3}
      <div class="d-flex justify-content-center mt-auto">
        <button class="ca-price-card__toggle" on:click={() => (open = !open)}>
          {#if open}
            View less
          {:else}
            View more
          {/if}
        </button>
      </div>
    {/if}
  {/if}
</div>

<style lang="scss">
  .startingOnly {
    font-style: italic;

    &::before {
      content: "*";
    }
  }
  .price-card-header-inner {
    height: 100%;
    display: flex;
    flex-direction: column;
    //flex-grow: 1;
  }
  //.price-card-buttons,
  .pricing-card-flexible-bottom-wrapper {
    margin-top: auto;
  }
  .ca-price-card {
    min-height: 100%;
  }
  .ca-price-card__header {
    gap: 1.4rem;
    flex-grow: unset;
    flex-direction: column;
  }
  @media (min-width: 768px) {
    .ca-price-card {
      /* adding this makes slightly offcenter on screens 995-1080px */
      min-width: inherit;
    }
    .ca-price-card__content {
      max-height: 3rem; // fallback
      height: 2lh;
    }
    // .ca-price-card__body {
    // 	max-height: 172px;
    // }
    .ca-price-card__body.open {
      max-height: none;
    }
  }
</style>
