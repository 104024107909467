<script lang="ts">
  import { trailingslashit } from "$lib/helpers";
  import { monthYearFormat } from "$lib/dateUtils.js";
  import { page } from "$app/stores";

  export let date;
  export let eyebrow;
  export let title;
  export let content;
  export let cta;
  export let image;
  export let alignment;
  export let lazyloadImages;

  const displayDate = new Date(date).toLocaleDateString($page?.data.locale, {
    year: "numeric",
    month: "long",
  });
  const dateString = monthYearFormat(displayDate);
  const useCarousel = alignment;
</script>

<a href={trailingslashit(cta.url)} target="_self" class="cc-card">
  <div class="media-container" width="1024" height="512">
    {#if lazyloadImages}
      <img
        data-src={image?.src}
        alt={image?.alt || title}
        class="image-container lazyload"
      />
    {:else}
      <img src={image?.src} alt={image?.alt || title} class="image-container" />
    {/if}
    <div class="mobile-cta">
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17.6752 9.50497L11.2579 3.08765L10.4628 3.88275L15.5227 8.94267L1.3252 8.94266V10.0671L15.5228 10.0671L10.4628 15.1272L11.2579 15.9223L17.6752 9.50497Z"
          fill="black"
        />
      </svg>
    </div>
  </div>

  <div class="body-container">
    <div class="category-date-container" class:useCarousel>
      <div>{eyebrow || ""}</div>
      <div class="text-end">{dateString}</div>
    </div>
    <div class="title-container">
      {@html title}
    </div>
    {#if content}
      <div class="text-container">{content}</div>
    {/if}
    <div class="cta">
      <span>{cta.text}</span>
      <svg
        width="24"
        height="25"
        viewBox="0 0 24 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M22.9001 12.3755L14.3437 3.81909L13.2835 4.87923L20.0301 11.6258L1.1001 11.6258V13.125L20.0303 13.125L13.2835 19.8718L14.3437 20.932L22.9001 12.3755Z"
          fill="black"
        />
      </svg>
    </div>
  </div>
</a>

<style lang="scss">
  .cc-card {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    height: 100%;

    svg {
      transition: transform 500ms ease;
      transform: translateX(0);
    }
    @media (hover: hover) {
      &:hover {
        svg {
          transform: translateX(0.5rem);
        }
      }
    }
  }

  .media-container {
    position: relative;
    .image-container {
      aspect-ratio: 16/9;
      // object-fit: contain;
    }
  }

  .mobile-cta {
    display: none;
  }

  .body-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    .category-date-container {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.03em;
      margin-bottom: 12px;
    }

    .title-container {
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
    }

    .text-container {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      margin-top: 7px;
      margin-bottom: 10px;
    }

    .cta {
      display: flex;
      gap: 10px;
      align-items: center;
      margin-top: auto;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      text-decoration: underline;
    }
  }

  // mobile
  @media (max-width: 991px) {
    .cc-card {
      max-width: 400px;
      max-height: 500px;
      // transform: translateX(-100%);
      // transition: transform 1s, display 1s allow-discrete;

      .body-container {
        padding: 12px 0 0 0;
      }
      .title-container {
        font-size: 26px;
        line-height: 30px;
      }
      .text-container {
        font-size: 18px;
        line-height: 24px;
      }
      .cta {
        display: none;
      }
      .mobile-cta {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 20px;
        right: 20px;
        background-color: white;
        border: solid black 1px;
        height: 45px;
        width: 45px;
        border-radius: 50%;
      }
      .category-date-container {
        display: none;
        .useCarousel {
          display: flex;
        }
      }
    }
  }
</style>
