<script lang="ts">
  export let title;
  export let content;
  export let image;
  export let quote;
</script>

<div class="ca-card ca-card--fixed-info ca-card--portrait-quote">
  <img src={image.src} alt={image.alt} class="ca-card__img" />
  <div class="ca-card__info active">
    <div class="ca-card__info-header">{title}</div>
    <div class="ca-card__info-subheader">{content}</div>
  </div>
  <div class="ca-card__reveal-quote">
    <blockquote>{quote}</blockquote>
  </div>
</div>
