<script lang="ts">
  export let item;
  export let variant;

  let useLink = item.url && !item.url.includes("/null/");
</script>

<!-- change this to an a tag. Or have logic that loads as an a tag depending on if theres a link -->
<div class="primary-item-container">
  <svelte:element
    this={useLink ? "a" : "div"}
    href={useLink ? item.url : null}
    target={useLink ? "_self" : null}
    class="d-flex link-container"
  >
    <div
      class="primary-link"
      class:tabbed-flyouts={variant === "tabbed-flyouts"}
    >
      {item?.title}
    </div>
    <!-- change below so it only shows if there is a link -->
    <div class="arrow-container" class:include={variant === "tabbed-flyouts"}>
      <svg
        width="16"
        height="14"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9001 9.00663L13.3437 0.450195L12.2835 1.51033L19.0301 8.25689L0.100098 8.25689V9.75614L19.0303 9.75615L12.2835 16.5029L13.3437 17.5631L21.9001 9.00663Z"
          fill="black"
        /></svg
      >
    </div>
  </svelte:element>
  {#if item?.description}
    <div class="description">{item?.description}</div>
  {/if}
</div>

<style lang="scss">
  .arrow-container {
    background-color: var(--colorTheme);
  }

  .primary-item-container {
    border-bottom: 1px solid #d8d8d8;
    padding: 0 0 1rem 0;
    @media (min-width: 992px) {
      &:hover {
        .arrow-container.include {
          opacity: 1;
        }
      }
    }
    .primary-link {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }
    .primary-link.tabbed-flyouts {
      font-size: 18px;
    }
    .description {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-top: 10px;
    }
    .arrow-container {
      display: none;
    }
    .arrow-container.include {
      opacity: 0;
      transition: opacity 0.25s;
      justify-content: center;

      margin-top: -4px;
      margin-bottom: -4px;
      margin-left: 1rem;
      border-radius: 16px;
      height: 31px;
      width: 31px;
      @media (min-width: 992px) {
        display: flex;
      }
    }
  }

  // mobile
  @media (max-width: 991px) {
    .primary-item-container {
      border-bottom: none;
      .primary-link {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.04em;
      }
      a.link-container:active {
        text-decoration: underline;
      }
      .primary-link.tabbed-flyouts {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
</style>
