<script lang="ts">
  export let snippet;
  export let width;
  export let theme;
  export let tempDevOnlyHs;

  const widths = `full sm md lg xl`.split(` `);

  const selectedWidth = widths.includes(width) ? width : "full";

  const themes = `default event-hero`.split(` `);

  const selectedTheme = themes.includes(theme) ? theme : "default";

  const patterns = [
    "youtube.com/embed",
    "youtu.be/",
    "youtube.com/watch",
    "vimeo.com/",
    "wistia.net/embed",
  ];

  const useAspectRatio = patterns.some((pattern) => snippet.includes(pattern));
</script>

<!-- <svelte:head>
	<script type="text/javascript">
		var _hsq = _hsq || [];
		_hsq.push(['setTargetedContentMetadata', []]);

		//Targeted content metadata for forms
		var hbspt = hbspt || {};
		hbspt.targetedContentMetadata = [];
	</script>
	<script type="text/javascript" id="hs-script-loader" async="" defer="" src="//js.hs-scripts.com/2303724.js"></script>

</svelte:head> -->

{#if tempDevOnlyHs}
  {@html snippet}
{:else}
  <div
    class="cmw-embed-container"
    class:cmw-embed-container--full={selectedWidth === "full"}
    class:cmw-embed-container--sm={selectedWidth === "sm"}
    class:cmw-embed-container--md={selectedWidth === "md"}
    class:cmw-embed-container--lg={selectedWidth === "lg"}
    class:cmw-embed-container--xl={selectedWidth === "xl"}
    class:cmw-embed-theme--default={selectedTheme === "default"}
    class:cmw-embed-theme--event-hero={selectedTheme === "event-hero"}
  >
    <div class="cmw-embed" class:cmw-embed-use-ar={useAspectRatio}>
      {@html snippet}
    </div>
  </div>
{/if}

<style lang="scss">
  .cmw-embed-container {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    width: 100%;
    padding-right: calc(var(--bs-gutter-x) * 0.5);
    padding-left: calc(var(--bs-gutter-x) * 0.5);
    margin-right: auto;
    margin-left: auto;

    &--sm {
      max-width: 581px;
    }

    &--md {
      max-width: 768px;
    }

    &--lg {
      max-width: 1024px;
    }

    &--xl {
      max-width: 1320px;
    }
  }

  .cmw-embed {
    //background-color: #222;
    position: relative;
  }

  .cmw-embed-use-ar {
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%;
  }

  :global(.cmw-embed-use-ar iframe) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
</style>
