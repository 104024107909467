<script lang="ts">
  export let heading;
  export let text;
  export let href;
  export let index;
  export let updateIndex;
  export let clickedStates;
  export let updateClickedTab;
  export let image;
  export let accentColor;
  export let currentIndex;

  let innerWidth;

  $: isMobile = innerWidth <= 992;

  const handleClick = (event, index) => {
    // this condition keeps heading clickable on desktop
    if (isMobile) {
      event.preventDefault();
      updateClickedTab(index);
    }
  };
</script>

<svelte:window bind:innerWidth />

<svelte:element
  this={href ? "a" : "div"}
  {href}
  target={href ? "_self" : null}
  class="split-block-item"
  class:clicked={clickedStates[index]}
  class:active={index === currentIndex}
  on:mouseenter={() => updateIndex(index)}
  style="--accent-color: {accentColor}"
  role={href ? "link" : null}
>
  <div class="side-line"></div>
  <div
    class="heading-container"
    on:click={(event) => handleClick(event, index)}
    on:keydown={() => {}}
    role="button"
    tabindex="0"
  >
    <span>
      {heading}
    </span>
    <svg
      class="arrow"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#918177" />
      <rect width="16" height="16" transform="translate(7 7)" fill="#918177" />
      <path
        d="M22.2667 15.0043L16.5624 9.30005L15.8557 10.0068L20.3534 14.5045L7.7334 14.5045V15.504L20.3535 15.504L15.8557 20.0019L16.5624 20.7086L22.2667 15.0043Z"
        fill="white"
        stroke="white"
        stroke-width="0.5"
        stroke-linecap="square"
      />
    </svg>
    <svg
      class="chevron"
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.9419 4.32759L11.7854 12.7798C11.53 13.0155 11.1364 13.0155 10.881 12.7798L1.72445 4.32759L2.62882 3.34785L11.3332 11.3826L20.0375 3.34785L20.9419 4.32759Z"
        fill="black"
      />
    </svg>
  </div>
  <div class="mobile-media-container">
    <img height="285" width="450" src={image.url} alt={image.alt} />
  </div>
  <div class="text-container">
    <span>
      {text}
    </span>
    {#if href}
      <div class="mobile-cta">
        <svg
          class="arrow"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15" cy="15" r="15" fill="#918177" />
          <rect
            width="16"
            height="16"
            transform="translate(7 7)"
            fill="#918177"
          />
          <path
            d="M22.2667 15.0043L16.5624 9.30005L15.8557 10.0068L20.3534 14.5045L7.7334 14.5045V15.504L20.3535 15.504L15.8557 20.0019L16.5624 20.7086L22.2667 15.0043Z"
            fill="white"
            stroke="white"
            stroke-width="0.5"
            stroke-linecap="square"
          />
        </svg>
      </div>
    {/if}
  </div>
</svelte:element>

<style lang="scss">
  @use "$sass/base/typography_mixins" as *;

  .split-block-item {
    padding-left: 16px;
    position: relative;
    height: fit-content;
    .side-line {
      height: 94%;
      width: 3px;
      background-color: var(--accent-color);
      position: absolute;
      top: 5px;
      left: 0px;
      opacity: 0;
    }
    .heading-container {
      display: flex;
      padding-bottom: 8px;
      font-size: 20px;
      font-weight: 500;
      line-height: 30px;
      svg.chevron {
        display: none;
      }
      svg.arrow {
        margin-left: 5px;
        opacity: 0;
        circle,
        rect {
          fill: var(--accent-color);
        }
      }
    }
    .text-container {
      @include text-base;
    }
    .mobile-media-container {
      display: none;
    }
    .mobile-cta {
      display: none;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      svg circle,
      svg rect {
        fill: var(--accent-color);
      }
    }
  }

  // hover effects should only happen on desktop
  @media (min-width: 992px) {
    .split-block-item {
      .side-line,
      .heading-container svg {
        transition: 0.3s;
      }
    }
    .split-block-item.active {
      .side-line {
        opacity: 1;
      }
    }
    a.split-block-item.active {
      .heading-container svg.arrow {
        opacity: 1;
        transform: translateX(5px);
      }
    }
    div.split-block-item.active {
      .side-line {
        background-color: black;
      }
    }
  }

  // mobile
  @media (max-width: 992px) {
    .split-block-item {
      width: 100%;
      padding: 0;
      .side-line {
        opacity: 1;
        height: 1px;
        width: 100%;
        background-color: black;
        position: absolute;
        top: -20px;
        left: 0px;
      }
      .heading-container {
        display: flex;
        justify-content: space-between;
        padding: 0;
        svg.chevron {
          display: unset;
          transition: 0.3s;
        }
      }
      .mobile-media-container {
        margin: 24px 0px;
        img {
          margin: auto;
          aspect-ratio: 16/9;
          object-fit: contain;
        }
      }
      .text-container {
        display: none;
        @include text-body-1;
      }
      .text-container {
        padding: 0px 8px;
      }
      &.clicked {
        .text-container {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 30px;
          column-gap: 8px;
        }
        .mobile-media-container,
        .mobile-cta {
          display: block;
        }
        svg.chevron {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
