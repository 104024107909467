<script lang="ts">
  import { bgColors } from "$lib/constants";
  import Stat from "../Stat/Stat.svelte";
  export let title = "";
  export let stats = [];
  export let backgroundColor;

  const bgColor = bgColors[backgroundColor] || bgColors["Light Cream"];
</script>

<div class="ca-section">
  <div class="container-xl">
    <div class="stats-container">
      {#if title}
        <div class="stats-title ca-h3">
          <h3>{title}</h3>
        </div>
      {/if}
      <div class="stats-grid {bgColor}">
        {#each stats as statItem}
          <Stat {...statItem} variant={"stats-bar"} />
        {/each}
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "../../scss/abstracts" as *;
  @use "../../scss/abstracts/breakpoints" as *;

  .ca-section {
    padding-top: 2rem;
    padding-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      padding-top: 3.125rem;
      padding-bottom: 3.125rem;
    }
  }

  .stats-container {
    display: unset;
  }
  .stats-title {
    text-align: center;
    margin-bottom: 48px;
    margin-top: 48px;
  }
  .stats-grid {
    max-width: 100%;
    padding: 40px 50px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    flex-shrink: 1;
    flex-wrap: wrap;
    gap: 2rem;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 32px;
    }
  }
</style>
