<script lang="ts">
  import Media from "./parts/Media.svelte";
  import { page } from "$app/stores";
  import { trailingslashit } from "$lib/helpers.js";

  export let id = "";
  export let heading;
  export let text = "";
  export let media;
  // This needs a better type
  const base: string = $page.data.base;
</script>

<div class={`container-xl main-container`} {id}>
  <div class="grid-container" class:no-media={!media.image?.url}>
    {#if media.image?.url}
      <div class="media-container">
        <Media {media} />
      </div>
    {/if}
    <div class="content-container">
      <div class="heading-container">
        <span>Author: </span>
        <a
          href={trailingslashit(`${base ? base : ""}/author/${id}`)}
          target="_self"
        >
          {heading}
        </a>
      </div>

      {#if text}
        <div class="text-container">
          {@html text}
        </div>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  .main-container {
    padding-bottom: 40px;
    .grid-container {
      display: grid;
      grid-template-columns: 90px 1fr;
      column-gap: 14px;
      &.no-media {
        grid-template-columns: 1fr;
        column-gap: unset;
      }
    }
    .media-container {
      height: 90px;
      width: 90px;
      border-radius: 50%;
      overflow: hidden;
    }
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .content-container .heading-container {
      font-size: 24px;
      font-weight: 500;
      line-height: 30px;
      margin-bottom: 1rem;
    }
    .content-container .text-container {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
  }
</style>
