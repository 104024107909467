<svelte:head>
  <script lang="ts">
    (function () {
      const a = (window.mutiny = window.mutiny || {});
      if (!window.mutiny.client) {
        a.client = { _queue: {} };
        const b = ["identify", "trackConversion"];
        const c = [].concat(b, ["defaultOptOut", "optOut", "optIn"]);
        const d = function factory(c) {
          return function () {
            for (
              var d = arguments.length, e = new Array(d), f = 0;
              f < d;
              f++
            ) {
              e[f] = arguments[f];
            }
            a.client._queue[c] = a.client._queue[c] || [];
            if (b.includes(c)) {
              return new Promise(function (b, d) {
                a.client._queue[c].push({ args: e, resolve: b, reject: d });
                setTimeout(d, 500);
              });
            } else {
              a.client._queue[c].push({ args: e });
            }
          };
        };
        c.forEach(function (b) {
          a.client[b] = d(b);
        });
      }
    })();
  </script>
  <script
    data-cfasync="false"
    src="https://client-registry.mutinycdn.com/personalize/client/2c2ae57b371f1a6e.js"
    defer
  ></script>
</svelte:head>
