<script lang="ts">
  import { onMount } from "svelte";
  import Swiper, { Autoplay, Navigation, Pagination } from "swiper";

  import "swiper/css";
  import "swiper/css/pagination";
  import "swiper/css/effect-fade";
  import "../Carousel.swiper.scss";

  export let id;
  export const navigation = {
    align: "center",
  };

  let autoplayInitial = {
    delay: 3000,
    pauseOnMouseEnter: true,
  };

  let swiperRef;
  // let swiperPrevRef;
  // let swiperNextRef;
  // let swiperPaginationRef;
  let swiper;

  let playing = true;

  const togglePlaying = () => {
    swiper.autoplay[playing ? "stop" : "start"]();

    playing = playing ? false : true;
  };

  onMount(() => {
    swiper = new Swiper(swiperRef, {
      modules: [Pagination, Navigation, Autoplay],
      slidesPerView: 1.4,
      spaceBetween: 12,
      centeredSlides: true,
      loop: true,
      allowTouchMove: false,
      autoplay: autoplayInitial,
      breakpoints: {
        500: {
          slidesPerView: 1.8,
        },
        640: {
          slidesPerView: 2.2,
        },
        768: {
          slidesPerView: 3.3,
        },
        992: {
          slidesPerView: 5,
          centeredSlides: false,
          allowTouchMove: false,
        },
      },
    });
  });
</script>

<div class="container-xl">
  <div class="swiper swiper--same-height" bind:this={swiperRef} {id}>
    <div class="swiper-wrapper">
      <slot />
    </div>
    <div class="controls mt-1 d-flex [justify-content-center]">
      {#if !playing}
        <button on:click={togglePlaying}>
          <span class="visually-hidden">Play content</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="12"
              cy="12"
              r="11.6667"
              fill="white"
              fill-opacity="0.5"
              stroke="black"
              stroke-width="0.666667"
            />
            <path
              d="M16.5059 12.0045L9.50586 7.50455V16.5045L16.5059 12.0045Z"
              stroke="black"
              stroke-width="0.666667"
            />
          </svg>
        </button>
      {/if}
      {#if playing}
        <button on:click={togglePlaying}>
          <span class="visually-hidden">Pause content</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_1_7)">
              <circle
                cx="12"
                cy="12"
                r="11.6667"
                fill="white"
                fill-opacity="0.5"
                stroke="black"
                stroke-width="0.666667"
              />
              <rect
                x="8.335"
                y="7.335"
                width="2.93"
                height="10.33"
                stroke="black"
                stroke-width="0.67"
              />
              <rect
                x="13.735"
                y="7.335"
                width="2.93"
                height="10.33"
                stroke="black"
                stroke-width="0.67"
              />
            </g>
            <defs>
              <clipPath id="clip0_1_7">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </button>
      {/if}
    </div>
  </div>
</div>

<style>
  button {
    background-color: transparent;
    border: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  button:hover {
    opacity: 0.85;
  }
</style>
