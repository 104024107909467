// @ts-strict-ignore
import Stat from "./Stat.svelte";

export const StatConfig = {
  name: "Stat",
  component: Stat,
  variants: {},
  mapper: (props) => {
    let variant = props?.variant;

    const variants = {
      Large: "lg",
    };

    if (variant && variants[variant]) {
      variant = variants[variant];
    }

    const useCountUp = props?.useCountUpAnimation || false;
    const useConfetti = props?.useConfettiAnimation || false;
    const coutUp = {
      startVal: props?.startValue || 0,
    };

    return {
      ...props,
      variant,
      useCountUp,
      useConfetti,
      coutUp,
      startValue: props.startValue || 0,
    };
  },
  fragment: `fragment StatData on Stat {
        headingText
        text
        value
        prefix
        suffix
        variant
        useCountUpAnimation
        useConfettiAnimation
        startValue
      }`,
};
