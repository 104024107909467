// @ts-strict-ignore
import MarketoForm from "./MarketoForm.svelte";

export const MarketoFormConfig = {
  name: "MarketoForm",
  component: MarketoForm,
  mapper: (props) => {
    return { formId: props?.id, ...props };
  },
  fragment: `fragment MarketoFormData on MarketoForm {
        id
    }`,
};
