<script lang="ts">
  import RelatedCardsGrid from "$components/RelatedCardsGrid/RelatedCardsGrid.svelte";
  import { relatedPostsMapper } from "$layouts/Article/parts/relatedPostsMapper";
  import { page } from "$app/stores";
  import Card from "$components/Card/Card.svelte";
  import { CardConfig } from "$components/Card/Card.config";
  import Embed from "$components/Embed/Embed.svelte";

  export let columns;
  export let backgroundColor;

  const processedCols = columns.map((col) => {
    if (col.__typename === "WebPage") {
      return relatedPostsMapper(col, $page.data.locale);
    } else if (col.__typename === "Card") {
      return CardConfig.mapper(col);
    } else {
      return col;
    }
  });
</script>

<div class="ca-section">
  {#if processedCols[0].__typename === "Embed"}
    <div class="featured-card-embed">
      <Embed {...processedCols[0]} />
    </div>
  {:else}
    <Card
      {...{
        ...processedCols[0],
        variant: "classroom-related-featured",
        // temporary fix to give featured card bg color. Going forward we will remove this and enable 'classroom-related-featured' as a panel option in contentful
        backgroundColor: backgroundColor,
      }}
    />
  {/if}

  <RelatedCardsGrid
    {...{
      variant: "classroom",
      items: processedCols.slice(1),
    }}
  />
</div>

<style>
  @media (max-width: 991px) {
    .ca-section .featured-card-embed {
      max-width: 400px;
      margin: auto;
    }
  }
</style>
