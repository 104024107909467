// @ts-strict-ignore
import SubNav from "./SubNav.svelte";

export const SubNavConfig = {
  name: "SubNav",
  component: SubNav,
  variants: {},
  mapper: (props) => {
    return {
      bgColor: props.bgColor,
      items: props?.itemsCollection.items.map((item) => {
        return {
          label: item.label,
          url: item.url,
          items:
            item?.subItems?.map(({ key, value }) => {
              return {
                label: key,
                url: value,
              };
            }) || [],
        };
      }),
    };
  },
  fragment: `fragment SubNavData on SubNav {
        __typename
        bgColor:backgroundColor
        itemsCollection {
            items {
                label
                url
                subItems
            }
        }
    }`,
};
