<script lang="ts">
  import SideMenuItem from "./SideMenuItem.svelte";
  export let item;
  export let tabStates;
  export let updateActiveTab;
  export let updateClickedTab;
</script>

<div class="ca-sidemenu d-flex flex-column">
  {#each item.items as menuItemL2, i}
    <SideMenuItem
      {updateActiveTab}
      {updateClickedTab}
      item={menuItemL2}
      isActive={tabStates[i]}
      {i}
    />
  {/each}
</div>

<style lang="scss">
  .ca-sidemenu {
    font-size: 20px;
    line-height: 30px;
    width: 25%;
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 24px;
      width: 100%;
    }
  }
</style>
