<script lang="ts">
  import "./navbar.scss";
  import Button from "../Button/Button.svelte";
  import CartaHomeLogo from "./parts/CartaHomeLogo.svelte";
  import TopMenuItem from "./parts/TopMenuItem.svelte";

  export let localeHomePageURL = "";
  export let menus = {};

  let clickActive = false;
</script>

<div id="ca-navV2-wrap" class:clickActive>
  <nav class="ca-navV2" class:clickActive>
    <div class="nav-contents-container container-xl" class:clickActive>
      <div class="logo-toggle-container d-flex justify-content-between">
        <CartaHomeLogo {localeHomePageURL} />
        <div class="nav-toggle">
          <button
            class="btn-burger"
            class:active={clickActive}
            on:click={() => (clickActive = !clickActive)}
          >
            <span class="burger-menu">
              <span class="bar" />
              <span class="visually-hidden">Toggle menu</span>
            </span>
          </button>
        </div>
      </div>

      <ul class="ca-menu" class:clickActive>
        <!-- each of the menuItemL1s is the top level -- products, company, etc -->
        {#each menus.primary as menuItemL1}
          <TopMenuItem item={menuItemL1} />
        {/each}
      </ul>

      <ul class="ca-menu-secondary" class:clickActive>
        {#each menus.secondary as menuItem}
          <li class="ca-menu__item">
            {#if menuItem.type == "link"}
              <a href={menuItem.url} target="_self" class="ca-menu__btn">
                {menuItem.title}
              </a>
            {:else}
              <Button
                text={menuItem.title}
                url={menuItem.url}
                target="_self"
                icon="arrow-right"
                animation="arrow-bounce-fade"
              />
            {/if}
          </li>
        {/each}
      </ul>
    </div>
  </nav>
</div>

<style lang="scss">
  #ca-navV2-wrap {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
  }
  .ca-navV2 {
    display: flex;
    align-items: center;
    background-color: white;
    height: 88px;
  }
  .nav-contents-container {
    display: flex;
  }
  .nav-toggle {
    display: none;
  }
  .ca-menu {
    margin: 0;
    padding: 6px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 40px;
    margin-left: 40px;
  }
  .ca-menu-secondary {
    display: grid;
    grid-template-columns: 50px 195px;
    align-items: center;
    margin: 0 0 0 auto;
    gap: 20px;
  }
  @media (min-width: 992px) {
    li:first-child.ca-menu__item a {
      padding-bottom: 6px;
      border-bottom: solid transparent 2px;
      transition: 200ms ease;
      &:hover {
        border-bottom: solid black 2px;
      }
    }
  }
  //desktop small screen size
  @media (max-width: 1123px) {
    .ca-menu {
      gap: 20px;
      margin-left: 25px;
    }
    .ca-menu-secondary {
      padding-left: 25px;
    }
  }
  // mobile
  @media (max-width: 991px) {
    #ca-navV2-wrap {
      position: fixed;
      width: 100%;
      &.clickActive {
        height: 100%;
      }
    }
    .ca-navV2 {
      &.clickActive {
        height: 100%;
        overflow: scroll;
      }
    }
    .nav-contents-container {
      flex-direction: column;
      &.clickActive {
        height: 100%;
        padding-top: 23px;
      }
    }
    .nav-toggle {
      display: unset;
    }
    .ca-menu {
      display: none;
      margin: 0;
      &.clickActive {
        display: unset;
      }
    }
    .ca-menu-secondary {
      display: none;
      margin-top: auto;
      justify-content: space-between;
      width: 100%;
      padding: 3rem 0 2rem 0;
      &.clickActive {
        display: flex;
      }
    }
  }
</style>
