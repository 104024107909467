// @ts-strict-ignore
export const formatTabDate = (t) => {
  return t?.split(`T`)[0];
};

export function addDaySuffix(date) {
  const day = date.getDate();
  const suffix =
    day >= 11 && day <= 13 ? "th" : ["st", "nd", "rd"][(day % 10) - 1] || "th";
  return day + suffix;
}

export const formatDate = (d, options = {}) => {
  const date = new Date(d);
  const utcDate = new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
  );
  const formattedDate = utcDate.toLocaleDateString("en-US", options);
  const formattedDateWithSuffix = formattedDate.replace(
    /\d{1,2}/,
    addDaySuffix(utcDate),
  );

  return formattedDateWithSuffix;
};

export const dateLong = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

export const dateShort = {
  day: "numeric",
  month: "long",
};
