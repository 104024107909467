// @ts-strict-ignore
import EventGuide from "./EventGuide.svelte";
import { HeadingConfig } from "../Heading/Heading.config";

export const EventGuideConfig = {
  name: "EventGuide",
  component: EventGuide,
  mapper: (props) => {
    const items = props.sessionsCollection.items.map((item) => {
      return {
        ...item,

        speakers: item.speakersCollection.items.map((speaker) => {
          return speaker;
        }),
      };
    });

    return {
      ...props,
      heading: HeadingConfig.mapper(props?.heading),
      sessions: items,
    };
  },
  fragment: `fragment EventGuideData on EventGuide {
      sys {
        id
      }
      heading {
        ...HeadingData
      }
      backgroundColor
      sessionsCollection(limit: 25) {
        items {
          __typename
          startTime
          endTime
          title
          description
          url
          speakersCollection(limit: 15) {
            items {
              __typename
              name
              companyUrl
              companyName
            }
          }
        }
      }
    }`,
};
