<script lang="ts">
  export let title;
  export let quote;
  export let image;
  export let tag;
  export let url;
</script>

<svelte:element
  this={url ? "a" : tag ? tag : "div"}
  data-sveltekit-reload
  class="ca-company-quote d-flex flex-column bg-hover-light-blue"
  href={url ? url : null}
  target={url ? "_self" : null}
>
  <blockquote class="ca-font-heading">
    {quote}
  </blockquote>
  <div class="mt-auto pt-4">
    <div class="ca-text-body-3">{title}</div>
    <div class="ca-company-quote__logo-container mt-2">
      <img src={image.src} alt={image.alt} width="100" height="50" />
    </div>
  </div>
</svelte:element>

<style>
  .ca-company-quote {
    font-size: 0.875rem;
    line-height: 1.5;
    padding: 1rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 56%, 1);
    max-width: 350px;
    transition: 200ms ease background-color;
  }

  @media (hover: hover) {
    .ca-company-quote.bg-hover-light-blue:hover {
      background-color: var(--colorTheme, var(--clr-light-blue));
    }
  }

  .ca-company-quote__logo-container img {
    max-width: 100%;
    max-height: 50px;
  }

  blockquote {
    quotes: "“" "”" "‘" "’";
  }
  blockquote::before {
    content: open-quote;
  }
  blockquote::after {
    content: close-quote;
  }
</style>
