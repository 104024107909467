// @ts-strict-ignore
import { accentColors } from "$lib/constants";
import { slugify } from "$lib/helpers";
import Spacer from "./Spacer.svelte";

export const SpacerConfig = {
  name: "Spacer",
  component: Spacer,
  mapper: (props) => {
    const finalProps = {
      ...props,
      accentColor: accentColors[props?.accentColor],
      topPadding: slugify(props?.topPadding),
      bottomPadding: slugify(props?.bottomPadding),
    };
    return finalProps;
  },
  fragment: `fragment SpacerData on Spacer {
        __typename
		accentColor
        topPadding
        bottomPadding
        bottomPadding
        showLine
        name
    }`,
};
