<script lang="ts">
  import ContentfulRichTextParserSimple from "$components/ContentfulRichTextParser/ContentfulRichTextParserSimple.svelte";
  import Heading from "$components/Heading/Heading.svelte";

  export let items;
  export let heading;
  export let text;

  let open = false;
</script>

<section class="ca-section py-4 py-md-5 py-lg-7">
  <div class="container-xl">
    <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3">
      <Heading {...heading} />
      <div class="ca-text-body-1 text-center mt-3">
        {@html text}
      </div>
    </div>
    <div class="col-12 col-md-10 offset-md-1 accordion-container">
      <div class="ca-accordion-list">
        {#each items as { title, tag, content }}
          <details class="ca-accordion" class:open>
            <summary class="ca-accordion__title" on:click={() => !open}>
              <div class="d-flex align-items-center flex-wrap gap-2">
                <span>{@html title}</span>
                {#if tag}
                  <span class="ca-dropdown-acc__feat ca-bg--light-blue"
                    >{tag}</span
                  >
                {/if}
              </div>
            </summary>
            <div class="ca-accordion__content">
              {#if typeof content === "object"}
                <ContentfulRichTextParserSimple node={content} />
              {:else}
                {@html content}
              {/if}
            </div>
          </details>
        {/each}
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  @use "$sass/abstracts" as *;
  .accordion-container {
    margin-top: 3.625rem;
  }
</style>
