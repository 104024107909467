import { HeadingConfig } from "./Heading/Heading.config";
import { MarketoFormConfig } from "./MarketoForm/MarketoForm.config";
import { StatConfig } from "./Stat/Stat.config";
import { StatsBarConfig } from "./StatsBar/StatsBar.config";
import { PanelConfig } from "./Panel/Panel.config";
import { ButtonConfig } from "./Button/Button.config";
import { LogoBarConfig } from "./LogoBar/LogoBar.config";
import { FinePrintTextConfig } from "./FinePrintText/FinePrintText.config";
import { GridConfig } from "./Grid/Grid.config";
import { FeaturedMediaTextListConfig } from "./FeaturedMediaTextList/FeaturedMediaTextList.config";
import { TabcordionConfig } from "./Tabcordion/Tabcordion.config";
import { AccordionConfig } from "./Accordion/Accordion.config";
import { EmbedConfig } from "./Embed/Embed.config";
import { VideoBlockConfig } from "./VideoBlock/VideoBlock.config";
import { CarouselConfig } from "./Carousel/Carousel.config";
import { CardConfig } from "./Card/Card.config";
import { BlockQuoteConfig } from "./BlockQuote/BlockQuote.config";
import { PricingCardConfig } from "./PricingCard/PricingCard.config";
import { CardsGridConfig } from "./CardsGrid/CardsGrid.config";
import { PricingGridConfig } from "./PricingGrid/PricingGrid.config";
import { SectionLocationsConfig } from "./SectionLocations/SectionLocations.config";
import CarouselMediaTextPanel from "./Panel/parts/CarouselMediaTextPanel.svelte";
import { BlogCTAConfig } from "./BlogCTA/BlogCTA.config";
import { AgendaConfig } from "./Agenda/Agenda.config";
import { SubNavConfig } from "./SubNav/SubNav.config";
import { FilterMenuConfig } from "./FilterMenu/FilterMenu.config";
import { EventGuideConfig } from "./EventGuide/EventGuide.config";
import { TabsConfig } from "./Tabs/Tabs.config";
import { SpacerConfig } from "./Spacer/Spacer.config";

export {
  MarketoFormConfig,
  HeadingConfig,
  StatConfig,
  StatsBarConfig,
  SpacerConfig,
  PanelConfig,
  ButtonConfig,
  LogoBarConfig,
  VideoBlockConfig,
  FinePrintTextConfig,
  GridConfig,
  FeaturedMediaTextListConfig,
  TabcordionConfig,
  AccordionConfig,
  EmbedConfig,
  CarouselConfig,
  CardConfig,
  BlockQuoteConfig,
  PricingCardConfig,
  CardsGridConfig,
  PricingGridConfig,
  SectionLocationsConfig,
  BlogCTAConfig,
  AgendaConfig,
  SubNavConfig,
  FilterMenuConfig,
  EventGuideConfig,
  TabsConfig,
};

export const innerBlocks = {
  Panel: {
    default: PanelConfig,
    "Media Text": {
      component: CarouselMediaTextPanel,
      mapper: PanelConfig.mapper,
    },
  },
  Card: {
    default: CardConfig,
    "block-quote": BlockQuoteConfig,
  },
};
