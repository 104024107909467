<script lang="ts">
  import MarkdownIt from "markdown-it";
  import Heading from "../../Heading/Heading.svelte";
  import Icon from "../../Icon/Icon.svelte";

  export let heading;
  export let text;
  export let media;
  export let backgroundColor;

  $: heading = heading || null;
  $: heading.classList = heading.classList
    ? `${heading.classList} mb-4`
    : "mb-4";
  $: text = text || "";
  $: media = media || [];

  const md = new MarkdownIt();
</script>

<section
  class={`ca-section ca-panel-icons-hero-block py-4 py-md-5 py-lg-7 ${backgroundColor}`}
>
  <div class="container-xl">
    {#if heading?.text || text}
      <div class="row mb-4_5 text-center">
        <div class="container-xl">
          <div class="col-10 offset-1 col-lg-8 offset-lg-2">
            {#if heading}
              <Heading {...heading} />
            {/if}

            {#if text}
              <div class="ca-text-body-1 mb-5 mb-lg-6 block--text">
                {@html md.render(text)}
              </div>
            {/if}
          </div>
        </div>
      </div>
    {/if}

    <div class="row">
      <div
        class="col-12 col-sm-6 offset-sm-3 col-lg-10 offset-lg-1 text-center"
      >
        <div
          class="row row-cols-1 row-cols-md-2 gy-5 row-cols-lg-3 justify-content-center"
        >
          {#each media as icon}
            <div class="col px-lg-4">
              <div class="d-flex justify-content-center mb-4">
                {#if icon.href}
                  <a href={icon.href} target="_blank" rel="noopener">
                    <Icon
                      size={icon.size}
                      image={{
                        alt: icon?.name,
                        src: icon?.image?.url,
                      }}
                    />
                  </a>
                {:else}
                  <Icon
                    size={icon.size}
                    image={{
                      alt: icon?.name,
                      src: icon?.image?.url,
                    }}
                  />
                {/if}
              </div>

              {#if icon?.heading}
                <Heading
                  {...{
                    text: icon.heading,
                    tag: icon.headingLevel || "h3",
                    font: "text",
                    size: "xl",
                    classList: "mb-4",
                  }}
                />
              {/if}

              {#if icon?.text}
                <div class="ca-text-body-2">
                  <p>
                    {@html icon.text}

                    {#if icon?.href}
                      <br /><br />
                      <a href={icon.href} target="_self"
                        ><strong>Learn more</strong></a
                      >
                    {/if}
                  </p>
                </div>
              {/if}
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  .block--text {
    :global(a) {
      text-decoration: underline;
    }
  }
</style>
