import { format, parse, isValid } from "date-fns";

/**
 * Use unicode tokens when writing custom date formats. See:
 *
 * https://www.unicode.org/reports/tr35/tr35-dates.html#Date_Field_Symbol_Table
 * https://date-fns.org/v3.3.1/docs/format
 */

/**
 * Formats the date as `"{fullMonth} {year}"` e.g. `June 2024`
 *
 * @param {Date | string} date
 */
export function monthYearFormat(date: Date | string): string {
  let dateObj;

  if (typeof date === "string") {
    // Adjusting the dateFormat to match "February 2024" style inputs
    const dateFormat = "MMMM yyyy";
    // Parsing the date string based on the adjusted format
    dateObj = parse(date, dateFormat, new Date());

    // Check if the parsed date is valid
    if (!isValid(dateObj)) {
      console.error(`Invalid date string: ${date}`);
      return ""; // Return an empty string or some default/fallback value
    }
  } else {
    dateObj = date; // If the input is already a Date object, use it directly
  }

  // Formatting the date object to the desired output format
  try {
    return format(dateObj, "MMMM yyyy");
  } catch (error) {
    if (error instanceof Error) {
      console.error(`Error formatting date: ${error.message}`);
    }
    return ""; // Return an empty string or some default/fallback value in case of an error
  }
}