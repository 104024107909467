<script lang="ts">
  import ClassRoomCardsGrid from "../parts/ClassRoomCardsGrid.svelte";
  export let heading;
  export let items;
  export let useContainer;
  export let useCarousel;
  export let lazyloadImages;
</script>

{#if !useContainer}
  <ClassRoomCardsGrid {useCarousel} {items} {lazyloadImages} />
{:else}
  <div class="main-card-container container-xl px-0">
    {#if heading.text}
      <h2 class="ca-text-2xl heading-text-container ca-font-heading mb-4">
        {heading.text}
      </h2>
    {/if}
    <ClassRoomCardsGrid {useCarousel} {items} {lazyloadImages} />
  </div>
{/if}

<style lang="scss">
  .main-card-container {
    margin-top: 64px;
    margin-bottom: 40px;
  }
  // mobile
  @media (max-width: 991px) {
    .main-card-container {
      margin-top: 48px;
      .heading-text-container {
        font-size: 32px;
        font-weight: 400;
        line-height: 38px;
      }
    }
  }
</style>
