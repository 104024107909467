<script lang="ts">
  export let theme;
  export let updateSelected;
  export let labels = {};
  // const labels = {
  // 	alpha: 'A-Z',
  // 	date: 'Date',
  // 	feat: 'Featured'
  // };

  let innerHeight;
  let oT;
  let localeToggleEl;
  let toggleListEl;
  let selectedLabel = "alpha";

  $: show = false;

  const toggle = () => {
    show = !show;
  };

  const clickOffLang = (e) => {
    let targetEl = e.target;
    while (targetEl) {
      if (targetEl === localeToggleEl) {
        return;
      }
      targetEl = targetEl.parentNode;
    }

    show = false;
  };

  updateSelected(selectedLabel);
</script>

<svelte:window bind:innerHeight on:click={clickOffLang} />

<div
  class="locale-toggle"
  bind:this={localeToggleEl}
  class:theme-dark={theme === "dark"}
  class:theme-light={theme === "light"}
>
  <div
    class="locale-toggle-button"
    on:click={toggle}
    on:keypress
    role="button"
    tabindex="0"
  >
    <span class="locale-toggle-button-text"
      >Sort by:&nbsp;{labels[selectedLabel]}</span
    >

    <svg
      class="locale-toggle-button-icon"
      class:rotate={!show}
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      ><path
        d="M14 1 7.5 7 1 1"
        stroke="var(--fill-color)"
        stroke-linecap="square"
        stroke-linejoin="round"
        class="s-ULdW1gAf0k5X"
      /></svg
    >
  </div>

  <ul
    class="locale-toggle-list"
    bind:this={toggleListEl}
    bind:offsetHeight={oT}
    class:visible={show}
  >
    {#each Object.keys(labels) as label, i}
      <li
        class="label-container"
        class:last={i === Object.keys(labels).length - 1}
      >
        <button
          on:click={() => {
            selectedLabel = label;
            updateSelected(selectedLabel);
            toggle();
          }}
        >
          {labels[label]}
        </button>
      </li>
    {/each}
  </ul>
</div>

<!-- {/if} -->
<style>
  .theme-light {
    --color: #000;
    /* --bg-color: #fff; */
    --border-color: #000;
    --fill-color: #000;
    --logo-invert: 1;
  }

  .theme-dark {
    --color: #fff;
    --bg-color: #000;
    --border-color: #fff;
    --fill-color: #fff;
    --logo-invert: 0;
  }

  .locale-toggle {
    display: inline-block;
    padding: 0.4rem 0;
    position: relative;
    background-color: var(--bg-color);
    cursor: default;
    width: max-content;
    min-width: 155px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .locale-toggle-button {
    appearance: none;
    display: flex;
    align-items: center;
    justify-content: flex-tart;
    gap: 2rem;
    height: 40px;
    padding: 0;
  }
  /* 
	.locale-toggle-button-text {
		margin: 0 auto;
	} */

  .locale-toggle-button-icon {
    transform: rotate(180deg);
    transition: 0.5s;
  }

  .locale-toggle-button-icon.rotate {
    transform: rotate(0deg);
  }

  .locale-toggle-list {
    visibility: hidden;
    list-style: none;
    padding: unset;
    background-color: #fff;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    width: 88%;
    /* transition: 0.3s; */
  }

  .locale-toggle-list.visible {
    visibility: visible;
    /* opacity: 1; */
  }

  .locale-toggle-list li:first-child {
    margin-top: 0;
  }

  .label-container {
    padding: 0.5rem;
    cursor: default;
  }

  .label-container:first-child {
    border-radius: 7px 7px 0 0;
  }

  .label-container.last {
    border-radius: 0 0 7px 7px;
  }

  .locale-toggle-list .label-container:hover {
    background-color: aliceblue;
  }

  .locale-toggle-list .label-container button {
    width: 100%;
    padding: 0.5rem 0;
    background-color: inherit;
    color: inherit;
    text-align: left;
    border: none;
  }

  .locale-toggle-list .label-container button:hover {
    text-decoration: underline;
    -webkit-text-decoration: underline;
    cursor: pointer;
  }

  .locale-toggle-list {
    position: absolute;
    top: 100%;
    bottom: auto;
    z-index: 15;
  }
</style>
