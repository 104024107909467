<script lang="ts">
  import slugify from "slugify";

  export let gridHero;
</script>

{#if Object.values(gridHero).some((val) => val)}
  <div class="row">
    <div class="col-12 col-md-10 offset-md-1 col-lg-6 offset-lg-3 mb-6">
      {#if gridHero.eyebrow}
        <div class="ca-text-eyebrow text-center mb-4">{gridHero.eyebrow}</div>
      {/if}
      {#if gridHero.heading}
        <h2
          class="text-center ca-text-3xl ca-font-heading"
          id={slugify(gridHero.heading)}
        >
          {gridHero.heading}
        </h2>
      {/if}
      {#if gridHero.text}
        <div class="ca-section__intro ca-text-body-1 ca-flow">
          <p>{gridHero.text}</p>
        </div>
      {/if}
    </div>
  </div>
{/if}
