// @ts-strict-ignore
import SectionLocations from "./SectionLocations.svelte";
import { CardConfig } from "../Card/Card.config";

export const SectionLocationsConfig = {
  name: "SectionLocations",
  component: SectionLocations,
  variants: {},
  mapper: (props) => {
    const result = { ...props };
    result.items = props?.cardsCollection?.items.map((card) => {
      return CardConfig.mapper(card);
    });

    delete result.cardsCollection;

    return result;
  },
  // no fragement necessary because data is pulled in through CardsGrid
};
