<script lang="ts">
  import "./button.scss";
  import { createEventDispatcher } from "svelte";
  import RightRrowFilledIcon from "$components/Icons/RightArrowFilled.svg";
  import RightArrow from "$components/Icons/RightArrow.svg?raw";

  export let url = "";
  export let text = "";
  export let isParentHovered = false;

  /**
   * @type {import('./Button.types').ButtonVariant} Which button type to use?
   */
  export let variant = "primary";
  /** @type { 'arrow-right' }*/
  export let icon = "";
  export let active = false;
  export let tag = "a";
  export let eventName = "default-button-click";
  export let target = "_self";
  export let animation = undefined;

  let isTextCircleDownArrow = variant === "text-circle-down-arrow";
  let isLinkRightArrowFilled = variant === "link-right-arrow-filled";
  const isTextBounce = variant === "text-bounce";
  let isLinkVariant =
    variant === "link" || isLinkRightArrowFilled || isTextBounce;
  let isClassroomCirclRightArrow = variant === "classroom-circle-right-arrow";

  const caBtnExcludes = [
    isClassroomCirclRightArrow,
    isTextCircleDownArrow,
    isLinkVariant,
  ];

  let useCaBtnClass = caBtnExcludes.every((v) => !v);

  const dispatch = createEventDispatcher();
  const clickHandler = () => dispatch(eventName);
</script>

<svelte:element
  this={tag}
  href={url?.replace("https://carta.com", "")}
  role="button"
  tabindex="0"
  class:arrow-bounce-fade={animation === "arrow-bounce-fade" &&
    icon === "arrow-right"}
  class:ca-link={isLinkVariant}
  class:ca-btn={!isLinkVariant && useCaBtnClass}
  class:text-circle-down-arrow={isTextCircleDownArrow}
  class:classroom-heading-only-arrow={isClassroomCirclRightArrow}
  class:ca-btn--secondary={variant.toLowerCase() === "secondary"}
  class:ca-btn--inverted={variant.toLowerCase() === "inverted"}
  class:ca-btn--ghost={variant.toLowerCase() === "ghost"}
  class:ca-btn--text={variant.toLowerCase() === "text"}
  class:ca-btn--text-bounce={isTextBounce}
  class:ca-btn--link-right-arrow-filled={variant === "link-right-arrow-filled"}
  class:ca-btn--muted={variant.toLowerCase() === "muted"}
  class:active={active === true}
  class={$$props["class"]}
  class:isParentHovered
  {target}
  on:keypress={clickHandler}
  on:click={clickHandler}
>
  {#if text}
    <span class:text-circle-down-arrow-text={isTextCircleDownArrow}>{text}</span
    >
  {/if}

  {#if isClassroomCirclRightArrow}
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="30" cy="30" r="29.5" fill="currentColor" stroke="black" />
      <path
        d="M40.9001 30.0066L32.3437 21.4502L31.2835 22.5103L38.0301 29.2569L19.1001 29.2569V30.7561L38.0303 30.7561L31.2835 37.5029L32.3437 38.5631L40.9001 30.0066Z"
        fill="black"
      />
    </svg>
  {/if}

  {#if isTextCircleDownArrow}
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.9867 45.8002L41.0996 28.6873L38.9793 26.5671L25.4862 40.0602L25.4862 2.2002L22.4877 2.2002L22.4877 40.0606L8.99415 26.5671L6.87388 28.6873L23.9867 45.8002Z"
        fill="black"
      />
    </svg>
  {/if}

  {#if icon === "arrow-right" && !isTextBounce}
    <svg
      class="arrow-svg d-inline-block ms-2"
      width="20"
      height="17"
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.9918 8.60518L12.1484 0.76178L11.1766 1.73357L17.3609 7.91792L0.00842285 7.91791V9.29223L17.3611 9.29224L11.1766 15.4768L12.1484 16.4486L19.9918 8.60518Z"
        fill="currentColor"
      />
    </svg>
  {/if}

  {#if isLinkRightArrowFilled}
    <img src={RightRrowFilledIcon} alt="" />
  {/if}

  {#if isTextBounce}
    {@html RightArrow}
  {/if}

  <slot />
</svelte:element>

<style lang="scss">
  @use "$sass/abstracts/breakpoints" as *;
  .classroom-heading-only-arrow {
    width: 60px;
    height: 60px;
    display: block;
    margin-top: 1rem;
    color: white;
    transition: 500ms;

    @media (hover: hover) {
      &:hover {
        color: var(--clr-light-cream);
      }
    }

    &.isParentHovered {
      transform: translateX(1rem);
      color: var(--clr-light-cream);
    }
  }

  .ca-btn {
    min-width: var(--button-min-width, initial);
  }

  .ca-btn--muted {
    border-color: #777;
    color: #777;
    background-color: #eee;
    box-shadow: none;

    @media (hover: hover) {
      &:hover {
        box-shadow: none;
        border-color: #555 !important;
        color: #555;
        background-color: #e0e0e0;
      }
    }
  }
  .text-circle-down-arrow {
    min-width: 80px;
    min-height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 20px;
    position: relative;
    z-index: 1;

    &::before {
      width: 80px;
      height: 80px;
      border-radius: 100px;
      background-color: #f6c099;
      content: "";
      display: block;
      position: absolute;
      top: 0.3rem;
      z-index: -1;
      transform: none;
      transition: 500ms;
    }

    &-text {
      transform: none;
      transition: 500ms;
      transition-delay: 120ms;
      font-weight: 500;
    }

    svg {
      position: absolute;
      top: 60px;
      transform: none;
      transition: 500ms;
      transition-delay: 100ms;
    }

    @media (hover: hover) {
      &:hover {
        &::before {
          transform: translateY(-0.3rem);
        }

        svg {
          transform: translateY(0.3rem);
        }

        .text-circle-down-arrow-text {
          transform: translateY(0.2rem);
        }
      }
    }
  }

  .arrow-bounce-fade {
    .arrow-svg {
      transition: transform 1s
        linear(
          0 0%,
          0.22 2.1%,
          0.86 6.5%,
          1.11 8.6%,
          1.3 10.7%,
          1.35 11.8%,
          1.37 12.9%,
          1.37 13.7%,
          1.36 14.5%,
          1.32 16.2%,
          1.03 21.8%,
          0.94 24%,
          0.89 25.9%,
          0.88 26.85%,
          0.87 27.8%,
          0.87 29.25%,
          0.88 30.7%,
          0.91 32.4%,
          0.98 36.4%,
          1.01 38.3%,
          1.04 40.5%,
          1.05 42.7%,
          1.05 44.1%,
          1.04 45.7%,
          1 53.3%,
          0.99 55.4%,
          0.98 57.5%,
          0.99 60.7%,
          1 68.1%,
          1.01 72.2%,
          1 86.7%,
          1 100%
        );
    }
    @media (hover: hover) {
      &:hover {
        background-color: #000000;
        color: white;
        opacity: 0.8;
        .arrow-svg {
          transform: translateX(25%);
        }
      }
    }
  }

  // mobile
  @media (max-width: 991px) {
    .classroom-heading-only-arrow {
      width: 45px;
      height: 45px;
    }
  }

  .ca-btn--text-bounce {
    gap: 13px;
    display: inline-flex;
    align-items: center;

    @include media-breakpoint-up(md) {
      flex-shrink: 0;
      gap: 13px;
    }

    :global(svg) {
      font-size: 22px;
      transition: 500ms;
      width: 22px;
      height: 22px;
    }

    @media (hover: hover) {
      &:hover {
        :global(svg) {
          transform: translateX(0.5rem);
        }
      }
    }
  }
</style>
