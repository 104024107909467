<script lang="ts">
  export let environment = "test";
</script>

<svelte:head>
  {#if environment === "test"}
    <script
      defer
      src="https://www.googletagmanager.com/gtag/js?id=G-GE775CJC69"
    ></script>
    <script lang="ts">
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("js", new Date());

      gtag("config", "G-GE775CJC69");
    </script>

    <script data-ot-ignore>
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);

        // DO NOT Auto-Block GTM
        j.setAttributeNode(d.createAttribute("data-ot-ignore"));
      })(window, document, "script", "dataLayer", "GTM-KBM5Q8GB");
    </script>
  {:else}
    <script data-cookieconsent="ignore">
      (function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        // below line is causing two invalid request errors"
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "GTM-TBVBP67");
    </script>
    <script
      defer
      data-cookieconsent="ignore"
      src="https://www.googletagmanager.com/gtag/js?id=G-HB6KGNG78T"
    ></script>
    <script lang="ts">
      function getParam(p) {
        var match = RegExp("[?&]" + p + "=([^&]*)").exec(
          window.location.search,
        );
        return match && decodeURIComponent(match[1].replace(/\+/g, " "));
      }
      function getExpiryRecord(value) {
        var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds

        var expiryDate = new Date().getTime() + expiryPeriod;
        return {
          value: value,
          expiryDate: expiryDate,
        };
      }

      function addGclid() {
        const gclidParam = getParam("gclid");
        let gclidRecord = null;
        let currGclidFormField;

        const gclsrcParam = getParam("gclsrc");
        const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf("aw") !== -1;

        if (gclidParam && isGclsrcValid) {
          gclidRecord = getExpiryRecord(gclidParam);
          localStorage.setItem("gclid", JSON.stringify(gclidRecord));
        }

        const gclid = gclidRecord || JSON.parse(localStorage.getItem("gclid"));
        const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate;

        if (isGclidValid) {
          const gclid_c = document.querySelector('[name="gclid__c"]');
          if (gclid_c) {
            gclid_c.setAttribute("value", gclid.value);
          }
        }
      }
      window.addEventListener("load", addGclid);
    </script>

    <script lang="ts">
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag("set", "linker", { domains: ["carta.com"] });
      gtag("js", new Date());
      gtag("config", "G-HB6KGNG78T");
      gtag("config", "AW-971080215");
      gtag("config", "AW-768372799");
      gtag("set", "developer_id.dZTNiMT", true);
      gtag("config", "UA-33931903-1", { anonymize_ip: true });
      gtag("config", "G-GGJVST6FH9");
    </script>
  {/if}
</svelte:head>

{#if environment === "test"}
  <noscript
    ><iframe
      src="https://www.googletagmanager.com/ns.html?id=GTM-KBM5Q8GB"
      height="0"
      width="0"
      style="display:none;visibility:hidden"
      title="gtag-iframe--test"
    /></noscript
  >
{:else}
  <noscript id="gtm-no-js">
    <iframe
      src="https://www.googletagmanager.com/ns.html?id=GTM-TBVBP67"
      height="0"
      width="0"
      style="display:none;visibility:hidden"
      title="gtag-iframe"
    />
  </noscript>
{/if}
