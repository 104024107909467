// @ts-strict-ignore
import PricingGrid from "./PricingGrid.svelte";
import { PricingCardConfig } from "../PricingCard/PricingCard.config";

export const PricingGridConfig = {
  name: "PricingGrid",
  component: PricingGrid,
  variants: {},
  mapper: (props) => {
    const result = { ...props };

    result.topCard = PricingCardConfig.mapper(props?.topCard);
    result.bottomCard = PricingCardConfig.mapper(props?.bottomCard);

    result.items = props?.cardsCollection?.items.map((card) => {
      return PricingCardConfig.mapper(card);
    });

    delete result.cardsCollection;

    return result;
  },
  // no fragment needed because CardsGrid pulls the data
};
