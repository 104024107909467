<script lang="ts">
  import Button from "$components/Button/Button.svelte";
  import Heading from "$components/Heading/Heading.svelte";
  import slugify from "slugify";
  import Eyebrow from "./parts/Eyebrow.svelte";
  import { highlightText } from "$lib/text.helpers";
  import { accentColors, bgColors } from "$lib/constants";
  import EmbedLottie from "$components/Embed/EmbedLottie.svelte";

  export let heading = { text: "" };
  export let text = "";
  export let media = [];
  export let animation;
  export let eyebrow = "";
  export let buttons = [];
  export let titleHighlights = [];
  export let backgroundColor = bgColors["Light Beige"];
  export let accentColor;

  const _accentColor = accentColors[accentColor] || accentColors["Brown"];

  $: firstMedia = media?.[0];

  $: img = firstMedia?.image;

  $: img = firstMedia?.image;
  $: lottieAnimation = animation;
  $: hasAnimation = !!lottieAnimation?.file?.url;

  $: button1 = buttons[0];
  $: button2 = buttons[1];
  $: anyActions = button1 || button2;

  $: isVideoEmbed = firstMedia?.type === "Embed";

  $: finalText = highlightText(heading?.text, titleHighlights);

  let leftSideWidth;
  let screenSize;
  $: contentWidth = screenSize < 992 ? "100%" : `${leftSideWidth}px`;
  $: sideImage = isVideoEmbed ? "none" : `url('${img.url}')`;
</script>

<svelte:window bind:innerWidth={screenSize} />
<div class="newWrapper">
  <div class="container-xl width-placeholder">
    <div class="beacon" bind:clientWidth={leftSideWidth} />
  </div>
  <div class="maxWidthWrapper {backgroundColor}">
    <div class="leftSide">
      <div
        class="hero-content"
        style="--highlights-color: {_accentColor};
          width: {contentWidth};
          opacity: {leftSideWidth ? 1 : 0};
          transition: opacity ease-in 100ms;
       "
      >
        {#if eyebrow}
          <Eyebrow tag="h1" classList="hero-content-eyebrow">{eyebrow}</Eyebrow>
        {/if}
        <Heading
          {...heading}
          size={heading?.size || "5xl"}
          text={finalText}
          classList="heading-text"
        />

        <div class="hero-content-text">
          <p>{text}</p>
        </div>

        {#if anyActions}
          <div class="hero-content-actions">
            {#if button1}
              <Button {...button1} variant={slugify(button1.variant)} />
            {/if}
            {#if button2}
              <Button {...button2} variant={"text-bounce"} />
            {/if}
          </div>
        {/if}
      </div>
    </div>
    <div
      class="rightSide"
      style="background-image: {sideImage};"
      class:hasAnimation
    >
      {#if isVideoEmbed}
        <div class="ca-video-block__stage" data-video-block-stage>
          {@html firstMedia?.embed}
        </div>
      {:else if hasAnimation && animation}
        <EmbedLottie
          autoplay={animation.autoplay}
          loop={animation.loop}
          src={animation.file?.url}
        />
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography_mixins" as *;

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 3rem;

    @include media-breakpoint-up(lg) {
      justify-content: center;
      align-items: flex-start;
      padding-top: 4.375rem;
      padding-bottom: 4.375rem;
      padding-left: 0;
      padding-right: 0;
    }

    :global(.heading-text) {
      @include text-mobile-header-1;
      margin-bottom: 2rem;
      margin-top: 0;
      text-align: center;

      @include media-breakpoint-up(lg) {
        font-size: 3.625rem;
        padding-right: 2rem;
        line-height: 1.15;
        margin-bottom: 2rem;
        text-align: left;
      }
    }

    :global(.hero-content-eyebrow) {
      margin-bottom: 1.25rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 1.25rem;
      }
    }

    :global(mark) {
      color: var(--highlights-color);
      background: none;
    }
  }

  .hero-content-text {
    text-align: center;

    @include media-breakpoint-up(lg) {
      @include text-web-body-1;
      text-align: left;
      padding-right: 2.125rem;
      letter-spacing: -0.15px;
    }
  }

  .hero-content-actions {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    :global(.ca-link) {
      display: flex;
      @include text-link;
      text-decoration: none;
      align-items: center;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 2rem;
      gap: 1.875rem;
    }
  }

  .newWrapper {
    width: 100%;
    position: relative;
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 3.125rem;
    }
  }

  .container-xl.width-placeholder {
    .beacon {
      width: 50%;
    }
  }

  .maxWidthWrapper {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      min-height: 400px;
      display: grid;
      grid-row-gap: 0px;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: 1fr;
      grid-column-gap: 0px;
    }

    .leftSide {
      .hero-content {
        @include media-breakpoint-up(lg) {
          margin-left: auto;
        }
      }
    }

    .rightSide {
      background-position: center right;
      background-repeat: no-repeat;
      background-size: cover;
      display: none;

      @include media-breakpoint-up(lg) {
        display: block;

        &.hasAnimation {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      :global(.lottie-wrapper) {
        display: flex;
        align-items: center;
        justify-content: center;

        max-width: 80%;

        @include media-breakpoint-up(lg) {
          min-height: 400px;
        }
      }
    }
  }
</style>
