<script lang="ts">
  export let currentCard;
  export let items;
  export let incrementCard;
</script>

<div class="pagination-container">
  <div class="pagniation-bar">
    <div
      class="inner-pagination-bar"
      style={`--bar-width: ${((currentCard + 1) * 100) / items.length}%`}
    />
  </div>
  <div class="arrows-container">
    <button
      class="left-arrow"
      on:click={() => incrementCard("left")}
      class:inactive={currentCard === 0}
      disabled={currentCard === 0}
    >
      <div class="arrow-container">
        <svg
          width="12"
          height="21"
          viewBox="0 0 12 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M4.8858 10.2148L12 2.21135L9.75775 0.218262L0.871933 10.2148L9.75775 20.2114L12 18.2183L4.8858 10.2148Z"
            fill="black"
          />
        </svg>
      </div>
    </button>
    <button
      class="right-arrow"
      on:click={() => incrementCard("right")}
      class:inactive={currentCard === items.length - 1}
      disabled={currentCard === items.length - 1}
    >
      <div class="arrow-container">
        <svg
          width="12"
          height="21"
          viewBox="0 0 12 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M7.99307 10.2148L0.878885 2.21135L3.12111 0.218262L12.0069 10.2148L3.12111 20.2114L0.878885 18.2183L7.99307 10.2148Z"
            fill="black"
          />
        </svg>
      </div>
    </button>
  </div>
</div>

<style lang="scss">
  .pagination-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .pagniation-bar {
      height: 4px;
      width: 100%;
      max-width: 433px;
      background-color: lightgrey;
      .inner-pagination-bar {
        height: 4px;
        transition: width 0.5s;
        width: var(--bar-width);
        background-color: black;
      }
    }
    .arrows-container {
      display: flex;
      justify-content: center;
      margin-top: 24px;
    }
    .right-arrow,
    .left-arrow {
      background: unset;
      border: unset;
      .arrow-container {
        display: flex;
        justify-content: center;
        height: 48px;
        width: 48px;
        border: solid black 1px;
        border-radius: 50%;
      }
      &:disabled {
        opacity: 40%;
      }
    }
  }
</style>
