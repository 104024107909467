<script lang="ts">
  import VideoBlockPrimary from "./variants/VideoBlockPrimary.svelte";
  import VideoBlockRebrand from "./variants/VideoBlockRebrand.svelte";
  import { bgColors } from "$lib/constants";

  export let variant;
  export let items;
  export let heading;
  export let eyebrow;
  export let text;
  export let bgColor;
  export let cta;

  $: variant = variant || "default";
  $: items = items || [];
  $: heading = heading || {};
  $: eyebrow = eyebrow || "";
  $: text = text || "";
  $: bgColor = bgColors[bgColor] || "Light Cream";

  const variants = {
    default: VideoBlockPrimary,
    "product-page": VideoBlockRebrand,
  };
</script>

<svelte:component
  this={variants[variant]}
  {variant}
  {items}
  {eyebrow}
  {text}
  {heading}
  {bgColor}
  {cta}
/>
