<script lang="ts">
  export let title;
  export let image;
  export let cta;
  export let categories;
</script>

<div class="ca-infocard" data-category={categories[0]}>
  <div class="ca-infocard__tags">
    {#each categories as category}
      <span class="ca-tag ca-bg--light-blue">{category}</span>
    {/each}
  </div>
  <div class="ca-infocard__headline">
    <img src={image.src} alt={image.alt} class="ca-infocard__logo" />
    <div class="ca-infocard__title">{title}</div>
  </div>
  {#if cta.url}
    <a
      data-sveltekit-reload
      href={cta.url}
      target="_self"
      class="ca-btn ca-btn--secondary ca-infocard__btn">{cta.text}</a
    >
  {/if}
</div>
