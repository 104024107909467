<script lang="ts">
  export let quote = "";
  export let cta;
</script>

<div class="container-xl">
  <div class="classroom-quote">
    <div class="classroom-quote-open">
      <svg
        width="60"
        height="67"
        viewBox="0 0 60 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="30" cy="30.3691" r="30" fill="#FADFCC" />
        <path
          d="M21.944 40.5211C17.84 40.5211 14.24 37.2091 14.24 32.0251C14.24 27.1291 18.848 21.4411 26.264 17.8411L27.2 19.8571C22.232 22.6651 20.216 26.4811 20.216 28.9291C20.216 32.5291 22.376 34.3291 26.768 35.6971C26.768 38.7211 24.176 40.5211 21.944 40.5211ZM36.344 40.5211C32.24 40.5211 28.64 37.2091 28.64 32.0251C28.64 27.1291 33.248 21.4411 40.664 17.8411L41.6 19.8571C36.632 22.6651 34.616 26.4811 34.616 28.9291C34.616 32.5291 36.776 34.3291 41.168 35.6971C41.168 38.7211 38.576 40.5211 36.344 40.5211Z"
          fill="black"
        />
      </svg>
    </div>
    <div class="classroom-quote-text">{quote}</div>
    <div class="classroom-quote-actions">
      {#if cta}
        <a href={cta.url} target="_blank" class="classroom-quote-cta">
          <span class="classroom-quote-cta-text">{cta.text}</span>

          <span class="classroom-quote-cta-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.9001 12.0066L14.3437 3.4502L13.2835 4.51033L20.0301 11.2569L1.1001 11.2569V12.7561L20.0303 12.7561L13.2835 19.5029L14.3437 20.5631L22.9001 12.0066Z"
                fill="black"
              />
            </svg>
          </span>
        </a>
      {/if}
    </div>
  </div>
</div>

<style lang="scss">
  @use "$sass/abstracts/breakpoints" as *;

  .classroom-quote-open {
    width: 60px;
  }
  .classroom-quote-text {
    font-size: clamp(2rem, 1.64rem + 1.8vw, 3.125rem);
    line-height: 1.2;
  }

  .classroom-quote {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    @media (min-width: 768px) {
      grid-template-columns: 60px 1fr max-content;
      gap: 4rem;
    }
  }
  :global(.block-layout-article) {
    .classroom-quote {
      gap: 0.5rem;
      @include media-breakpoint-up(md) {
        gap: 1rem;
      }
    }

    .container-xl:has(.classroom-quote) {
      padding: 0;
    }
  }

  .classroom-quote-actions {
    display: flex;

    @media (max-width: 767px) {
      justify-content: flex-end;
    }

    @media (min-width: 768px) {
      align-items: center;
    }
  }

  .classroom-quote-cta {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    text-decoration: underline;
    font-weight: 500;

    &:hover {
      cursor: pointer;
    }
  }

  .classroom-quote-cta-icon {
    width: 60px;
    height: 60px;
    background: #fadfcc;
    border-radius: 100px;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    transition: 500ms;

    svg {
      margin-left: 0.2rem;
      transition: 500ms;

      .classroom-quote-cta:hover & {
        transform: translateX(0.75rem);
      }
    }

    .classroom-quote-cta:hover & {
      transform: translateX(0.5rem);
    }
  }
</style>
