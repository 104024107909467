import CardFeatured from "./variants/CardFeatured.svelte";
import CardFeaturedMini from "./variants/CardFeaturedMini.svelte";
import CardPortrait from "./variants/CardPortrait.svelte";
import CardPortraitQuote from "./variants/CardPortraitQuote.svelte";
import Cardlet from "./variants/Cardlet.svelte";
import CardContent from "./variants/CardContent.svelte";
import CardLocation from "./variants/CardLocation.svelte";
import CardCaseStudy from "./variants/CardCaseStudy.svelte";
import CardTimeline from "./variants/CardTimeline.svelte";
import CardColoredHeading from "./variants/CardColoredHeading.svelte";
import CardLogoBox from "./variants/CardLogoBox.svelte";
import CardOutline from "./variants/CardOutline.svelte";
import CardCustomerQuote from "./variants/CardCustomerQuote.svelte";
import CardBlog from "./variants/CardBlog.svelte";
import CardRoundedMini from "./variants/CardRoundedMini.svelte";
import CardNavFeat from "./variants/CardNavFeat.svelte";
import CardClassroomFeatured from "./variants/CardClassroomFeatured.svelte";
import CardClassroomFeaturedMini from "./variants/CardClassroomFeaturedMini.svelte";
import CardClassroomImageBottom from "./variants/CardClassroomImageBottom.svelte";
import CardClassroomHeadingOnly from "./variants/CardClassroomHeadingOnly.svelte";
import CardResourcesTextOnly from "./variants/CardResourcesTextOnly.svelte";
import CardPortraitCustomerQuote from "./variants/CardPortraitCustomerQuote.svelte";
import CardClassroomVideo from "./variants/CardClassroomVideo.svelte";
import CardClassroomVideoMini from "./variants/CardClassroomVideoMini.svelte";
import CardClassroomWithIcon from "./variants/CardClassroomWithIcon.svelte";
import CardFeaturesWithImage from "./variants/CardFeaturesWithImage.svelte";
import CardClassroomRelated from "./variants/CardClassroomRelated.svelte";
import CardClassroomFeaturedRelated from "./variants/CardClassroomRelatedFeatured.svelte";
import CardLogin from "./variants/CardLogin.svelte";

export const cardVariants = {
  "card-blog": CardBlog,
  "card-case-study": CardCaseStudy,
  "card-content": CardContent,
  "card-location": CardLocation,
  "card-timeline": CardTimeline,
  "card-login": CardLogin,
  "classroom-featured-mini": CardClassroomFeaturedMini,
  "classroom-featured": CardClassroomFeatured,
  "classroom-heading-only": CardClassroomHeadingOnly,
  "classroom-image-bottom": CardClassroomImageBottom,
  "classroom-video-mini": CardClassroomVideoMini,
  "classroom-video": CardClassroomVideo,
  "classroom-with-icon": CardClassroomWithIcon,
  "classroom-related": CardClassroomRelated,
  "classroom-related-featured": CardClassroomFeaturedRelated,
  "colored-heading": CardColoredHeading,
  "customer-quote": CardCustomerQuote,
  "featured-mini": CardFeaturedMini,
  "features-with-image": CardFeaturesWithImage,
  "logo-box": CardLogoBox,
  "nav-feat": CardNavFeat,
  "portrait-customer-quote": CardPortraitCustomerQuote,
  "portrait-quote": CardPortraitQuote,
  "resources-text-only": CardResourcesTextOnly,
  "rounded-mini": CardRoundedMini,
  cardlet: Cardlet,
  featured: CardFeatured,
  outline: CardOutline,
  portrait: CardPortrait,
};
