<script lang="ts">
  import MarkdownIt from "markdown-it";
  import Heading from "../../Heading/Heading.svelte";
  import PanelButtonsGroup from "../variants/parts/PanelButtonsGroup.svelte";
  import Eyebrow from "../variants/parts/Eyebrow.svelte";

  export let eyebrow = "";
  export let eyebrowTag;
  export let heading = {
    text: "",
    id: "",
  };

  export let text = "";
  export let media = [];
  export let buttons = [];

  let img = media ? media[0] : [];

  const md = new MarkdownIt({
    breaks: true,
    html: true,
  });

  // set default heading if none set via CMS
  if (!heading.font) {
    heading.font = "text";
  }
</script>

<div class="row">
  <div class="col-12 col-lg-10 offset-lg-1">
    <div
      class="row align-items-md-top"
      class:py-4_5={!buttons[0]}
      class:py-md-5={!buttons[0]}
      class:py-lg-6={!buttons[0]}
    >
      <div
        class="col-12 col-md-6"
        class:order-lg-1={img?.align.toLowerCase() === "right"}
      >
        {#if img.type === "Embed"}
          {@html img.embed}
        {:else}
          <img
            src={img?.image?.url}
            alt={img?.image?.alt}
            class="mx-auto mx-md-0"
            loading="lazy"
          />
        {/if}
      </div>
      <div
        class="col-12 col-md-6"
        class:ps-xl-7={img?.align === "Left"}
        class:pe-xl-7={img?.align === "Right"}
      >
        <!-- if I put classList first here, it would be overwritten by 'undefined' in heading -->
        {#if eyebrow}
          <Eyebrow tag={eyebrowTag}>{eyebrow}</Eyebrow>
        {/if}
        <Heading
          {...{
            ...heading,
            classList:
              (heading.classList || "mb-3") +
              " text-center text-md-start mt-4 mt-md-0",
          }}
        />

        <div class="ca-split__intro text-center text-md-start">
          {@html md.render(text || "")}
        </div>

        <PanelButtonsGroup {buttons} />
      </div>
    </div>
  </div>
</div>
