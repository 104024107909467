<script lang="ts">
  import { page } from "$app/stores";
  import { slugify } from "$lib/helpers"; // Using the helper function rather than `slugify` package because the package doesn't strip quotation marks and causes console errors with the current implementation
  import RichTextParser from "./RichTextParser.svelte";
  import SocialMediaBar from "$components/SocialMediaBar/SocialMediaBar.svelte";
  import { onMount } from "svelte";
  import Panel from "$components/Panel/Panel.svelte";
  import { setMapsFromLinks } from "./RichText.common";
  import "$components/RichText/RichText.scss";
  import Button from "$components/Button/Button.svelte";
  import Disclosures from "./Disclosures.svelte";
  import { Boundary } from "$components/ErrorBoundary";
  import { isAllowedCardVariant, isCardBlock } from "./RichText.utils";

  export let body;
  export let data;
  export let toc;
  export let hasSubMenu = false;
  export let ogUri;
  export let authors;
  export let disclosure;
  export let disclosures;
  export let hero;
  export let hasBgColor;
  export let articleSideBarButton: {
    text: string;
    variant: string;
    url: string;
  };

  /** If `true` class `lazyload` gets added to images */
  export let lazyloadImages = false;

  const blogHeroHeading = {
    type: "heading-1",
    text: hero.heading.text,
    href: `#${slugify(hero.heading.text)}`,
  };

  let richTextRef: HTMLDivElement;
  let tocDrawer;
  let innerHeight;

  const links = body?.links;

  const { entryBlockMap } = setMapsFromLinks(links);

  const TOC_HEADINGS = ["heading-1", "heading-2", "heading-3"].filter(
    () => toc === true,
  );

  const HEADING_CLASSNAMES = {
    "heading-1": "ps-0",
    "heading-2": "ps-2",
    "heading-3": "ps-4",
  };

  const transformContent = (content) => {
    return content
      .map((node) => {
        if (node.nodeType === "embedded-entry-block") {
          const block = entryBlockMap.get(node.data.target.sys.id);

          if (block?.__typename === "AnchorLink") {
            return {
              ...node,
              cmwHeadingData: {
                type: "AnchorLink",
                text: block.text,
                href: `#${block.slug}`,
              },
            };
          } else if (isCardBlock(block)) {
            if (isAllowedCardVariant(node, block)) return node;
            else return null; // we don't want to render unsupported cards inside articles
          }
        }

        if (TOC_HEADINGS.includes(node.nodeType)) {
          const plainText = removeEmptyTagsWithRegex(
            getPlainTextFromHeader(node.content),
          );
          let hrefText = plainText;

          return {
            ...node,
            cmwHeadingData: {
              type: node.nodeType,
              text: plainText,
              href: `#${slugify(hrefText)}`,
            },
          };
        }

        return node;
      })
      .filter(Boolean);
  };

  const bodyJson = {
    ...body.json,
    content: transformContent(body.json.content),
  };

  function removeEmptyTagsWithRegex(htmlString) {
    let prevString;

    // Keep removing empty tags until there are none left.
    // This is necessary because after removing some tags, others might become empty.
    do {
      prevString = htmlString;
      htmlString = htmlString.replace(/<[^/>][^>]*><\/[^>]+>/g, "");
    } while (htmlString !== prevString);

    return htmlString;
  }

  function getPlainTextFromHeader(contentNode) {
    return contentNode.reduce((acc, current) => {
      return acc + current.value;
    }, "");
  }

  function getHeadersFromRichText(input) {
    const headers = (node) =>
      TOC_HEADINGS.includes(node.nodeType) ||
      node?.cmwHeadingData?.type === "AnchorLink";

    return input.filter(headers).map((heading) => {
      return heading.cmwHeadingData;
    });
  }

  const headings = [blogHeroHeading].concat(
    getHeadersFromRichText(bodyJson.content),
  );

  onMount(() => {
    // add if headings conditional
    function scrollSpy() {
      const verticalMargin =
        (window.innerHeight < 500 ? 500 : window.innerHeight - 500) * -1;
      let options = {
        // Add some top margin to rootMargin, to account for navigation height.
        rootMargin: `-150px 0px ${verticalMargin}px 0px`,
        threshold: [0, 1],
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          const id =
            entry.target.getAttribute("id") ||
            slugify(entry.target.textContent);
          const selector = `.ca-ces-toc__list li a[data-spyid="#${id}"]`;
          const intersectionElement = richTextRef?.querySelector(selector);

          if (intersectionElement && entry.intersectionRatio === 1) {
            richTextRef
              .querySelectorAll(".ca-ces-toc__list li a.current")
              ?.forEach((elem) => {
                elem.classList.remove("current");
              });

            intersectionElement.classList.add("current");
            mobileTocCurrent = intersectionElement.textContent;
          }
        });
      }, options);

      const sectionsToObserve = document.querySelectorAll(
        ".ca-ces-report-col [id], .blog-hero-container h1",
      );
      sectionsToObserve.forEach((section) => {
        observer.observe(section);
      });
    }

    scrollSpy();
  });

  const hasHeadings = headings.length > 1;
  const downloadableAsset = data?.content?.downloadableAsset || false;
  const urlNoQueryParams = $page.url.href.replace($page.url.search, "");

  let showTocMobile = false;
  let mobileTocCurrent = "Overview";
</script>

<svelte:window bind:innerHeight />
<div
  class="social-bar-container"
  class:no-headings-social-bar={!hasHeadings}
  class:d-lg-none={hasHeadings}
>
  <SocialMediaBar
    useBlueIcons
    {downloadableAsset}
    url={urlNoQueryParams}
    direction="horizontal"
    articleTitle={hero.heading.text}
  />
</div>

<div class="rich-text-container" class:triple-dotted-border-top={!hasBgColor}>
  <div
    class="rich-text pt-lg-2"
    class:rich-text--full={!hasHeadings}
    bind:this={richTextRef}
  >
    {#if hasHeadings}
      <div
        class="rich-text-secondary mt-4_5"
        class:showTocMobile
        class:hasSubMenu
      >
        <div class="ca-ces-toc-wrap" class:hasSubMenu>
          <div
            class="ca-ces-toc"
            class:triple-dotted-border-top={hasBgColor}
            id="ca-ces-toc-main"
            bind:this={tocDrawer}
          >
            <button
              class="ca-ces-toc__toggle"
              on:click={() => {
                showTocMobile = !showTocMobile;
              }}
            >
              <div class="ca-ces-toc__toggle-title">
                <span class="fw-bold">Contents: </span>{mobileTocCurrent}
              </div>
              <svg
                class="chevron"
                width="15"
                height="8"
                viewBox="0 0 15 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1 7.5 7 1 1"
                  stroke="#000"
                  stroke-linecap="square"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
            <div class="toc-list-body">
              <div class="ca-ces-toc__heading ca-font-heading">Contents</div>
              <ul class="ca-ces-toc__list triple-dotted-border-bottom">
                {#each headings as { text, href, type }}
                  <li class={HEADING_CLASSNAMES[type]}>
                    <div class="tracker-arrow">
                      <svg
                        width="16"
                        height="12"
                        viewBox="0 0 16 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.2667 6.00434L9.56244 0.300049L8.85569 1.00681L13.3534 5.50451L0.733398 5.50451V6.50401L13.3535 6.50402L8.85568 11.0019L9.56244 11.7086L15.2667 6.00434Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    <a
                      href={$page.url.pathname + href}
                      data-spyid={href}
                      on:click={() => {
                        showTocMobile = false;
                      }}>{text}</a
                    >
                  </li>
                {/each}
              </ul>
            </div>
          </div>

          <div class="d-none d-lg-flex justify-content-between">
            <SocialMediaBar
              {downloadableAsset}
              url={urlNoQueryParams}
              direction="horizontal"
              articleTitle={hero.heading.text}
              useBlueIcons
            />
            <Button {...articleSideBarButton} />
          </div>
        </div>
      </div>
    {/if}

    <div
      class="rich-text-primary ca-article-body ca-text-body-1 ca-flow ca-ces-report-col"
    >
      <!-- 
			Add a helper element (marker) to avoid a loop on ToC re-positioning
			based on it being off screen.
		  -->
      <div class="ca-ces-toc-inline-marker" />

      <Boundary>
        <RichTextParser
          {ogUri}
          {links}
          node={bodyJson}
          locale={data?.locale || "en-US"}
          {lazyloadImages}
        />
      </Boundary>

      <div class="mobile-social-bar">
        <SocialMediaBar
          {downloadableAsset}
          url={urlNoQueryParams}
          useBlueIcons
          direction="horizontal"
          articleTitle={hero.heading.text}
        />
      </div>
      {#if authors.length > 0}
        <div
          class="author-block triple-dotted-border-top triple-dotted-border-bottom"
        >
          {#each authors as author}
            <Panel {...author} />
          {/each}
        </div>
      {/if}

      {#if disclosures && disclosures?.length > 0}
        <Disclosures {disclosures} />
      {:else if disclosure}
        <div class="disclosure-block">
          {disclosure}
        </div>
      {/if}
    </div>
  </div>
</div>
