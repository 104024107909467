<script lang="ts">
  import { onMount } from "svelte";
  import Swiper, { Navigation, Pagination } from "swiper";
  import Heading from "../../Heading/Heading.svelte";
  import MarkdownIt from "markdown-it";

  import "swiper/css";
  import "swiper/css/pagination";
  import "../Carousel.swiper.scss";

  export let navigation = {
    align: "center",
  };
  export let heading;
  export let text;

  let swiperRef;
  let swiperPrevRef;
  let swiperNextRef;
  let swiperPaginationRef;

  onMount(() => {
    new Swiper(swiperRef, {
      modules: [Navigation, Pagination],
      pagination: {
        el: swiperPaginationRef,
        type: "progressbar",
      },
      navigation: {
        nextEl: swiperNextRef,
        prevEl: swiperPrevRef,
      },
    });
  });

  const md = new MarkdownIt();
</script>

<div class="row mt-4_5 carousel--variant-default">
  <div class="col-12">
    {#if heading?.text}
      <div class="mb-6">
        <Heading {...heading} />
      </div>
    {/if}
    {#if text}
      <div class="ca-section__intro">
        {@html md.render(text)}
      </div>
    {/if}
    <div class="swiper swiper--has-pagination" bind:this={swiperRef}>
      <div class="swiper-wrapper">
        <slot />
      </div>
    </div>
  </div>
</div>

{#if navigation.align === "center"}
  <div
    class="ca-swiper-controls mt-4_5 mt-lg-5 d-flex justify-content-center align-items-center mx-auto"
  >
    <div class="ca-swiper-pagination" bind:this={swiperPaginationRef} />
    <div class="ca-swiper-navigation">
      <div class="swiper-button-prev" bind:this={swiperPrevRef} />
      <div class="swiper-button-next" bind:this={swiperNextRef} />
    </div>
  </div>
{:else if navigation.align === "left"}
  <div class="row offset-lg-1">
    <div class="col-12 col-lg-5">
      <div class="ca-swiper-controls">
        <div class="ca-swiper-pagination" bind:this={swiperPaginationRef} />
        <div class="ca-swiper-navigation">
          <div class="swiper-button-prev" bind:this={swiperPrevRef} />
          <div class="swiper-button-next" bind:this={swiperNextRef} />
        </div>
      </div>
    </div>
  </div>
{/if}
