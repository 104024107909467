<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";
  import Button from "$components/Button/Button.svelte";

  export let title;
  export let url;
  let isHovered = false;
</script>

<div
  class="classroom-heading-only"
  on:mouseenter={() => (isHovered = true)}
  on:mouseleave={() => (isHovered = false)}
  role="button"
  tabindex="0"
>
  <a data-sveltekit-reload href={url}>
    <Heading
      {...{
        text: title,
        level: 2,
        font: "text",
        size: "2xl",
        classList: "align-left",
      }}
    />

    {#if url}
      <Button
        variant="classroom-circle-right-arrow"
        isParentHovered={isHovered}
        tag="span"
      />
    {/if}
  </a>
</div>

<style lang="scss">
  @use "$sass/abstracts/breakpoints" as *;

  .classroom-heading-only {
    border-top: 3px solid #000;
    padding-top: 1rem;
    height: 100%;

    a {
      @include media-breakpoint-up(md) {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
      }
    }
  }
  // mobile
  @media (max-width: 767px) {
    .classroom-heading-only {
      display: grid;
      grid-template-columns: 1fr 65px;
      align-items: start;
      gap: 1rem;
      align-items: center;
    }
  }
</style>
