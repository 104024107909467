<script lang="ts">
  import SideMenu from "../parts/SideMenu.svelte";
  import CenterMenu from "../parts/CenterMenu.svelte";

  export let item;

  let tabStates = Array.apply(null, Array(item.items.length)).map((x, i) =>
    i === 0 ? true : false,
  );
  let clickTabStates = Array.apply(null, Array(item.items.length)).map(
    () => false,
  );

  const updateActiveTab = (index) => {
    tabStates = Array.apply(null, Array(item.items.length)).map((x, i) =>
      i === index ? true : false,
    );
  };

  const updateClickedTab = (index) => {
    clickTabStates = Array.apply(null, Array(item.items.length)).map((x, i) =>
      i === index ? true : false,
    );
  };
</script>

<div class="tabbed-fo-container container-xl d-flex flex-column mx-lg-4">
  <div class="row">
    <SideMenu
      {item}
      {tabStates}
      {updateActiveTab}
      {clickTabStates}
      {updateClickedTab}
    />
    <CenterMenu {item} {tabStates} {clickTabStates} {updateClickedTab} />
  </div>
  <div class="item-sideline" />
</div>

<style lang="scss">
  @media (max-width: 991px) {
    .tabbed-fo-container {
      padding: 0 0 0 0.75rem;
      position: relative;
    }
    .item-sideline {
      background: #d8d8d8;
      position: absolute;
      top: 8px;
      left: 0;
      height: 85%;
      width: 1px;
    }
  }
</style>
