<script lang="ts">
  import { slugify } from "$lib/helpers";
  export let id;
  export let heading;
  export let text;
  export let media;
  export let backgroundColorFit;
  export let backgroundColor;
  export let buttons;

  $: id = id || (heading?.text && slugify(heading.text)) || null;

  const cover = backgroundColorFit === "Cover";
  media = media[0];
</script>

<section class={cover ? backgroundColor : ""} {id}>
  <div class="main-container container-xl" class:no-media={!media?.image?.url}>
    <div class="grid-container {!cover ? backgroundColor : ''}">
      {#if media?.image?.url}
        <div class="media-container">
          <img
            width="1440"
            height="1080"
            class="mx-auto mx-lg-0"
            src={media?.image.url}
            alt={media?.image.alt}
          />
        </div>
      {/if}
      <div class="content-container py-4">
        {#if heading.text}
          <div class="heading-container text-decoration-none">
            {heading.text}
          </div>
        {/if}

        {#if text}
          <div class="text-container">
            {@html text}
          </div>
        {/if}
        <a
          class="cta"
          data-sveltekit-reload
          href={`${buttons[0]?.url}`}
          target="_self"
        >
          <span>
            {buttons[0]?.text}
          </span>
          <svg
            width="22"
            height="18"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.9001 9.00663L13.3437 0.450195L12.2835 1.51033L19.0301 8.25689L0.100098 8.25689V9.75614L19.0303 9.75615L12.2835 16.5029L13.3437 17.5631L21.9001 9.00663Z"
              fill="currentcolor"
            />
          </svg>
        </a>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  .main-container {
    margin: auto;
    padding: 0px;
    &.no-media .grid-container {
      display: block;
    }
    .grid-container {
      display: grid;
      grid-template-columns: 32% 1fr;
    }
    .media-container {
      height: 260px;
      // width: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-left: 34px;
      padding-right: 34px;
    }
    .content-container .heading-container {
      font-family: SangBleu Versailles;
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 12px;
    }
    .content-container .text-container {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }
    .content-container .cta {
      margin-top: 16px;
      display: flex;
      text-decoration: underline;
      gap: 8px;
      svg {
        transition: transform 500ms;
      }
      @media (hover: hover) {
        &:hover {
          svg {
            transform: translateX(0.5rem);
          }
        }
      }
    }
    .content-container .social-media-container {
      margin: 30px 0 0 -12px;
    }
  }
  // mobile
  @media (max-width: 992px) {
    .main-container {
      max-width: 400px;
      &.no-media {
        max-width: unset;
      }

      .grid-container {
        grid-template-columns: 1fr;
      }
      .content-container {
        padding: 0px 20px;
      }
    }
  }
</style>
