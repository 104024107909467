<script lang="ts">
  import { onMount } from "svelte";
  import Swiper from "swiper";

  import "swiper/css";
  import "swiper/css/pagination";
  import "../Carousel.swiper.scss";

  let swiperRef;

  onMount(() => {
    new Swiper(swiperRef, {
      loop: true,
      /* 
			There is a known bug, where inifinite scrolling only works properly if there are double the number of items as 'slidesPerView'.
			The 'loopedSlides' property is a workaround, but scrolling skips slightly (See Panel - Innovator Stories variant).
			Another possible workaround is having at least 6 innovator profiles (2x 2.4), by having duplicates (Currently in place for Carousl - Innovator Stories).
			*/
      slidesPerView: 2.4,
      loopedSlides: 2,
      spaceBetween: 16,
      slidesOffsetBefore: 32,
      autoplay: {
        delay: 3000,
        pauseOnMouseEnter: true,
      },
      breakpoints: {
        768: {
          slidesPerView: 2.5,
          spaceBetween: 16,
          slidesOffsetBefore: 76,
        },
        autoplay: {
          delay: 3000,
          pauseOnMouseEnter: true,
        },
      },
    });
  });
</script>

<div class="swiper-hero-home-wrapper">
  <div
    class="swiper d-lg-none swiper-hero-home swiper--same-height"
    id="swiper-hero-home"
    bind:this={swiperRef}
  >
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</div>
