<script lang="ts">
  // import Seo from '../parts/seo.svelte';

  import ContentfulBlockParser from "../components/ContentfulBlockParser/ContentfulBlockParser.svelte";

  export let data;

  const blocks = data?.content?.sectionsCollection?.items;
</script>

{#if blocks}
  {#each blocks as block}
    <ContentfulBlockParser {block} />
  {/each}
{/if}
