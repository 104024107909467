// @ts-strict-ignore
import slugify from "slugify";
import Button from "./Button.svelte";

export const ButtonConfig = {
  name: "Button",
  component: Button,
  variants: {
    primary: "primary",
    secondary: "secondary",
    inverted: "inverted",
  },
  mapper: (props) => {
    return {
      ...props,
      variant: slugify(props.variant).toLowerCase(),
    };
  },
  fragment: `fragment ButtonData on Button {
		text
		url
		variant
		icon
	  }`,
};
