<script lang="ts">
  import Button from "../Button/Button.svelte";

  export let id;
  export let heading;
  export let items;

  let activeCategory = "";

  const updateSelectedCategory = ({ event, category }) => {
    event.preventDefault();
    activeCategory = category;
  };

  const categories =
    items?.reduce((found, item) => {
      if (!found.includes(item.category)) {
        return [...found, item.category];
      }

      return found;
    }, []) || [];
</script>

<section class="ca-section py-4 py-md-4_5 py-lg-7 ca-bg--light-blue" {id}>
  <div class="container-xl">
    <div class="row">
      <div class="col-12 col-lg-10 offset-lg-1">
        <div class="ca-grid-filter__bar">
          <span class="d-none d-md-block">{heading}</span>
          <div class="d-flex align-items-center justify-content-end gap-2">
            <button
              on:click={(event) =>
                updateSelectedCategory({ event, category: "" })}
              class="ca-btn js-grid-filter-btn"
              class:active={"" === activeCategory}
            >
              All
            </button>
            {#each categories as category}
              <Button
                class="cjs-grid-filter-btn"
                active={category === activeCategory}
                text={category}
                tag="button"
                eventName="eventUpdateSelectedCategory"
                on:eventUpdateSelectedCategory={(event) =>
                  updateSelectedCategory({ event, category })}
              />
            {/each}
          </div>
        </div>

        {#if items?.length > 0}
          <div class="ca-grid-default ca-grid-case-studies mt-4_5 mt-lg-7">
            {#each items as item}
              <div
                class="ca-infocard"
                class:hidden={activeCategory !== "" &&
                  activeCategory !== item.category}
                data-category="startup"
              >
                <div class="ca-infocard__tags">
                  <span class="ca-tag ca-bg--light-blue">{item.category}</span>
                  {#if item?.length > 0}
                    {#each item.tags as tag}
                      <span class="ca-tag ca-bg--light-cream">{tag}</span>
                    {/each}
                  {/if}
                </div>
                <div class="ca-infocard__headline">
                  <img
                    src={item?.logo?.src}
                    alt={item?.logo?.alt}
                    class="ca-infocard__logo"
                  />
                  <div class="ca-infocard__title">
                    {item.text}
                  </div>
                </div>
                <a
                  href={item.url}
                  target="_self"
                  class="ca-btn ca-btn--secondary ca-infocard__btn"
                >
                  Learn more
                </a>
              </div>
            {/each}
          </div>
        {/if}
      </div>
    </div>
  </div>
</section>
