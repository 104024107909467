<script lang="ts">
  import { onMount } from "svelte";
  let mount = false;
  const controlsLayout = ["playpause", "stop", "spacer"];

  export let src = "";
  export let autoplay = false;
  export let loop = false;
  export let playerProps = {
    autoplay,
    loop,
    style: "display: flex; align-items: center;",
  };

  let LottiePlayer;

  onMount(async () => {
    const module = await import("@lottiefiles/svelte-lottie-player");
    LottiePlayer = module.LottiePlayer;
    setTimeout(() => {
      mount = true;
    }, 50);
  });
</script>

<div class="lottie-wrapper">
  {#if src && !mount}
    <!-- placeholder to prevent layout shifts -->
    <div style="height: 200px"></div>
  {/if}
  {#if src && mount && LottiePlayer}
    <svelte:component
      this={LottiePlayer}
      {src}
      {autoplay}
      {loop}
      controls={!autoplay}
      renderer="svg"
      background="transparent"
      height={"100%"}
      width={"100%"}
      {controlsLayout}
      {...playerProps}
    />
  {/if}
</div>

<style lang="scss">
  .lottie-wrapper {
    :global(.lottie-player-controls) {
      background: rgba(0, 0, 0, 0.6);
      :global(.btn) {
        background-color: rgba(237, 145, 74, 0.7);
        padding: 4px;
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        :global(svg) {
          fill: white;
          opacity: 0.6;
        }
      }
    }
  }
</style>
