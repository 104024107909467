<script lang="ts">
  export let eyebrow;
  export let title;
  export let content;
  export let image;
  export let url;
  export let tag = "div";
</script>

<svelte:element this={tag} data-sveltekit-reload href={url} class="ca-card">
  <img src={image.src} alt={image.alt} class="ca-card__img" />
  <div class="ca-card__info">
    <div class="ca-text-eyebrow mb-3">{eyebrow}</div>
    <div class="ca-card__info-header">{title}</div>
    <div class="ca-text-body-2 mt-2">
      {@html content}
    </div>
  </div>
</svelte:element>
