<script lang="ts">
  import Button from "$components/Button/Button.svelte";
  import Heading from "$components/Heading/Heading.svelte";
  import Eyebrow from "./parts/Eyebrow.svelte";
  import { highlightText } from "$lib/text.helpers";
  import { accentColors, bgColors } from "$lib/constants";

  export let heading = { text: "" };
  export let text = "";
  export let eyebrow = "";
  export let buttons = [];
  export let titleHighlights = [];
  export let accentColor;
  export let backgroundColor = bgColors["Light Beige"];

  const _accentColor = accentColors[accentColor] || accentColors["Brown"];

  $: button1 = buttons[0];
  $: anyActions = button1;

  $: finalText = highlightText(heading?.text, titleHighlights);
</script>

<div class="wrapper {backgroundColor}">
  <div class="container-xl">
    <div class="panel-product-hero-feature">
      <div class="hero-content" style="--highlights-color: {_accentColor};">
        <div class="hero-content-heading">
          {#if eyebrow}
            <Eyebrow tag="h1" classList="hero-content-eyebrow">
              {eyebrow}
            </Eyebrow>
          {/if}
          <Heading
            {...heading}
            size={heading?.size || "5xl"}
            text={finalText}
            classList="heading-text"
          />
        </div>
        <div class="hero-content-extra">
          <div class="hero-content-text">
            <p>{text}</p>
          </div>
          {#if anyActions}
            <div class="hero-content-actions">
              {#if button1}
                <Button {...button1} />
              {/if}
            </div>
          {/if}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography_mixins" as *;

  .wrapper {
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 3.125rem;
    }

    @include media-breakpoint-down(sm) {
      .container-xl {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .panel-product-hero-feature {
    display: flex;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  .hero-content {
    display: block;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem 2.25rem;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      padding-top: 5.625rem;
      padding-bottom: 5.625rem;
      padding-left: 0;
      padding-right: 0;
      align-items: center;
      gap: 4.5rem;
    }

    .hero-content-heading {
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
        flex-grow: 1;
        flex-basis: 722px;
        max-width: 722px;
      }
    }

    .hero-content-extra {
      display: flex;
      align-items: center;
      flex-direction: column;

      @include media-breakpoint-up(lg) {
        gap: 4.6875rem;
        flex-direction: row;
        flex-wrap: nowrap;
      }
    }

    :global(.heading-text) {
      @include text-mobile-header-1;
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
        font-size: 3.625rem;
      }
    }

    :global(mark) {
      color: var(--highlights-color);
      background: none;
    }

    :global(.hero-content-eyebrow) {
      margin-bottom: 1.25rem;
    }
  }

  .hero-content-text {
    text-align: center;
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      @include text-web-body-1;
      text-align: left;
      flex-shrink: 1;
      max-width: 322px;
    }
  }

  .hero-content-actions {
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
      flex-grow: 1;
      flex-shrink: 0;
    }
  }
</style>
