<script lang="ts">
  /**
   * @typedef {import('./Spacer.types').PaddingOptions} PaddingOptions
   */
  import { accentColors } from "$lib/constants";

  export let accentColor = accentColors["Light Beige"];
  /** @type {PaddingOptions} */
  export let topPadding = "medium";
  /** @type {PaddingOptions} */
  export let bottomPadding = "medium";
  /** @type {boolean} */
  export let showLine = true;

  /** @type {Record<PaddingOptions, string>} */
  const paddingSizes = {
    "negative-extra-small": "-25px",
    "negative-small": "-50px",
    none: "0px",
    "extra-small": "25px",
    small: "50px",
    medium: "75px",
    large: "100px",
  };
  const negativeSettings = new Set(["negative-extra-small", "negative-small"]);

  $: bottomPaddingSize = paddingSizes[bottomPadding];
  $: topPaddingSize = paddingSizes[topPadding];

  const isNegative =
    negativeSettings.has(bottomPadding) || negativeSettings.has(topPadding);
</script>

<div
  class="container-xl spacer-block"
  style="--divider-accent-color: {accentColor};"
  class:isNegative
>
  <hr
    class="spacer"
    class:showLine
    style="margin-top: {topPaddingSize}; margin-bottom: {bottomPaddingSize};"
  />
</div>

<style lang="scss">
  @use "$sass/abstracts/breakpoints" as *;

  .spacer-block {
    display: flex;
    &.isNegative {
      display: block;
    }
    @media (max-width: 992px) {
      display: none;
    }
  }

  .spacer {
    border: none;
    display: flex;
    flex: 1;
    width: 100%;

    &.showLine {
      display: block;
      height: 1px;
      background-color: var(--divider-accent-color);
    }
  }
</style>
