<script lang="ts">
  export let title;
  export let content;
  export let image;
</script>

<div class="ca-location-card">
  <div class="ca-location-card__content">
    <span class="ca-location-card__title">{title}</span>
  </div>
  <img
    loading="lazy"
    class="ca-location-card__img"
    src={image.src}
    alt={image.alt}
  />
  <div class="ca-location-card__back">
    <div class="ca-text-2xl">{title}</div>
    <div class="ca-location-card__back-data">{@html content}</div>
  </div>
</div>
