<script lang="ts">
  import ContentfulImage from "$components/ContentfulImage/ContentfulImage.svelte";

  export let title;
  export let content;
  export let image;
  export let url;
</script>

<a
  data-sveltekit-reload
  href={url}
  target="_self"
  class="ca-card ca-card--fixed-info ca-card--blog-featured"
>
  <div class="row">
    {#if image}
      <div class="col col-12">
        <ContentfulImage
          fetchpriority="high"
          preferWebp
          width="1200"
          height="600"
          src={image.src || image.uri}
          alt={image.alt || ""}
          class="ca-card__img"
        />
      </div>
    {/if}

    <div class="col col-12">
      <div class="ca-card__info">
        <div class="ca-card__info-header">
          {title}
        </div>
        <div class="ca-card__info-subheader">
          {@html content}
        </div>
      </div>
    </div>
  </div>
</a>
