<script lang="ts">
  import { HeadingConfig } from "$components/Heading/Heading.config.js";
  import { MarketoFormConfig } from "$components/MarketoForm/MarketoForm.config.js";
  import { StatConfig } from "$components/Stat/Stat.config";
  import { PanelConfig } from "$components/Panel/Panel.config";
  import { ButtonConfig } from "$components/Button/Button.config";
  import { LogoBarConfig } from "$components/LogoBar/LogoBar.config";
  import { FinePrintTextConfig } from "$components/FinePrintText/FinePrintText.config";
  import { GridConfig } from "$components/Grid/Grid.config";
  import { CardConfig } from "$components/Card/Card.config";
  import { bgColors } from "../../lib/constants";

  import GridDefault from "./variants/GridDefault.svelte";
  import GridWrapSimple from "./variants/GridWrapSimple.svelte";
  import GridCardFeaturesWithImage from "./variants/GridCardFeaturesWithImage.svelte";
  import GridFeaturedCards from "./variants/GridFeaturedCards.svelte";
  import GridCurriculumCards from "./variants/GridCurriculumCards.svelte";
  import GridHighlightBlock from "./variants/GridHighlightBlock.svelte";
  import GridResourcesTextOnly from "./variants/GridResourcesTextOnly.svelte";

  export const config = [
    MarketoFormConfig,
    HeadingConfig,
    StatConfig,
    PanelConfig,
    ButtonConfig,
    LogoBarConfig,
    FinePrintTextConfig,
    GridConfig,
    CardConfig,
  ];

  export const BLOCKS = config.reduce((acc, { name, component }) => {
    acc[name] = component;

    return acc;
  }, {});

  export const VARIANTS = config.reduce((acc, { name, variants }) => {
    if (acc[name]) {
      acc[name] = variants;
    }

    return acc;
  }, {});

  export const MAPPERS = config.reduce((acc, { name, mapper }) => {
    acc[name] = mapper;

    return acc;
  }, {});

  export let variant;
  export let columns;
  export let columnsLarge;
  export let columnsMedium;
  export let columnsSmall;
  export let buttons;
  export let gridWidth;
  export let backgroundColor;
  export let accentColor;
  export let gridHero;
  export let seeMoreButton;
  export let initialItemsAmount;
  export let padding;

  $: variant = variant || "default";
  $: columns = columns || [];
  $: columnsLarge = columnsLarge || 4;
  $: columnsMedium = columnsMedium || 2;
  $: columnsSmall = columnsSmall || 1;
  $: gridWidth = gridWidth || "XL";
  $: backgroundColor = backgroundColor || "None";
  $: gridHero = gridHero || {};
  $: seeMoreButton = seeMoreButton || false;
  $: initialItemsAmount = initialItemsAmount || 15;

  const gridWidthOptions = {
    "XL - max width 1140px": "col 12",
    XL: "col-12",
    L: "col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2",
    M: "col-6 offset-3 | col-lg-8 offset-lg-2",
  };

  const variants = {
    default: GridDefault,
    "wrap-simple": GridWrapSimple,
    "features-with-image": GridCardFeaturesWithImage,
    "curriculum-cards": GridCurriculumCards,
    "featured-cards": GridFeaturedCards,
    "highlight-block": GridHighlightBlock,
    "resources-text-only": GridResourcesTextOnly,
  };

  if (columns.some((col) => col.__typename === "WebPage")) {
    variant = "highlight-block";
    console.error(
      "The variant was automatically switched to highlight-block because the columns include webpages",
    );
  }
</script>

<svelte:component
  this={variants[variant]}
  {BLOCKS}
  {VARIANTS}
  {MAPPERS}
  {variant}
  {columns}
  {columnsLarge}
  {columnsMedium}
  {columnsSmall}
  {gridWidth}
  {gridWidthOptions}
  {backgroundColor}
  {accentColor}
  {bgColors}
  {gridHero}
  {seeMoreButton}
  {initialItemsAmount}
  {padding}
  {buttons}
/>
