<script lang="ts">
  export let item;
  export let i;
  export let isActive;
  export let updateActiveTab;
  export let updateClickedTab;
</script>

<div
  class="ca-sidemenu-item"
  class:active={isActive}
  class:ibby={isActive}
  on:mouseenter={() => updateActiveTab(i)}
  on:focusin={() => updateActiveTab(i)}
  on:click={() => updateClickedTab(i)}
  on:keydown
  role="button"
  tabindex="0"
>
  <div class="text-container">
    {item.title}
  </div>
  <svg
    class="arrow-svg"
    width="22"
    height="18"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.9001 9.00663L13.3437 0.450195L12.2835 1.51033L19.0301 8.25689L0.100098 8.25689V9.75614L19.0303 9.75615L12.2835 16.5029L13.3437 17.5631L21.9001 9.00663Z"
      fill="black"
    /></svg
  >
</div>

<style lang="scss">
  .ca-sidemenu-item {
    display: grid;
    grid-template-columns: 1fr 22px;
    padding: 0.8rem 1rem;
    @media (min-width: 992px) {
      .arrow-svg {
        display: none;
      }
      &:hover,
      &.active {
        background-color: var(--colorTheme);
        .arrow-svg {
          display: unset;
        }
      }
    }
    @media (max-width: 991px) {
      padding-right: 0;
    }
  }
</style>
