/**
 * Returns true if the link is a wordpress asset, i.e. something like:
 * `wp-content/uploads/:year/:month/some-name.pdf
 * @param {string} link
 */
export function isWordpressPDFAsset(link) {
  const isWordpressAsset = /[/]wp-content[/]uploads[/].*.(pdf)$/.test(link);

  return isWordpressAsset;
}
