<script lang="ts">
  import { page } from "$app/stores";
  import { onMount } from "svelte";
  import Swiper, { Navigation, Pagination, Manipulation } from "swiper";
  import Heading from "../../Heading/Heading.svelte";
  import ContentfulRichTextParserSimple from "../../ContentfulRichTextParser/ContentfulRichTextParserSimple.svelte";

  import "swiper/css";
  import "../../Carousel/Carousel.swiper.scss";
  import EmbedLottie from "$components/Embed/EmbedLottie.svelte";
  import Button from "$components/Button/Button.svelte";

  export let items;
  export let heading;
  export let eyebrow;
  export let text;
  export let bgColor;
  export let cta;

  $: embed = items[0];
  $: isLottie = embed?.isLottie;

  let swiperRef;
  let swiperPrevRef;
  let swiperNextRef;
  let swiperPaginationRef;
  let activeIndex = 0;

  onMount(() => {
    const searchParams = $page?.url?.searchParams;
    const searchParamsActiveIndex = Number(searchParams?.get("slide"));

    new Swiper(swiperRef, {
      modules: [Navigation, Pagination, Manipulation],
      pagination: {
        el: swiperPaginationRef,
        type: "progressbar",
      },
      navigation: {
        nextEl: swiperNextRef,
        prevEl: swiperPrevRef,
      },
      loop: false,
      slidesPerView: 1,
      spaceBetween: 12,
      breakpoints: {
        375: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      },
      on: {
        init: function (swiper) {
          if (!isNaN(searchParamsActiveIndex)) {
            const _activeIndex = searchParamsActiveIndex - 1;

            if (_activeIndex >= 0 && _activeIndex <= swiper.slides.length) {
              swiper.activeIndex = _activeIndex;
              activeIndex = _activeIndex;
            }
          } else {
            activeIndex = swiper.activeIndex;
          }

          swiper.updateSlidesClasses();
        },
        resize: function (swiper) {
          // dont change active slide on resize
          if (swiper.activeIndex !== activeIndex) {
            swiper.activeIndex = activeIndex;
            swiper.updateSlidesClasses();
          }
        },
        click: function (swiper) {
          swiper.activeIndex = swiper.clickedIndex;
          activeIndex = swiper.activeIndex;
          swiper.updateSlidesClasses();
        },
        slideChangeTransitionEnd: function (swiper) {
          activeIndex = swiper.activeIndex;
          swiper.updateSlidesClasses();
        },
      },
    });
  });
</script>

<section
  class="block-video-block-product-page ca-section {bgColor}"
  data-video-block=""
  id=""
>
  <div class="container-xl">
    <div class="block-content">
      <div class="heading-container">
        {#if eyebrow}
          <div class="ca-text-eyebrow">
            {eyebrow}
          </div>
        {/if}
        {#if heading?.text}
          <Heading {...heading} classList="heading-text" />
        {/if}
        {#if text}
          <div class="text-container">
            {#if typeof text === "object"}
              <ContentfulRichTextParserSimple node={text} />
            {:else}
              <p>
                {@html text}
              </p>
            {/if}
          </div>
        {/if}
        {#if cta}
          <div class="actions-container">
            <Button {...cta} icon="" variant="text-bounce" />
          </div>
        {/if}
      </div>
      <div class="media-container">
        {#if !isLottie}
          <div class="ca-video-block__stage" data-video-block-stage>
            {@html items[activeIndex]?.embed}
          </div>
        {:else}
          <EmbedLottie src={embed?.src} />
        {/if}
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography_mixins" as *;

  .block-video-block-product-page {
    margin: 0;
    @include media-breakpoint-up(lg) {
      margin: 3.125rem 0px;
    }
  }

  .block-content {
    display: flex;
    padding-top: 2rem;
    padding-bottom: 2rem;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      padding-top: 3.125rem;
      padding-bottom: 3.125rem;
      flex-direction: row;
    }
  }

  .actions-container {
    margin-top: 1rem;
    display: flex;
    justify-content: center;

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      margin-top: 2rem;
    }

    :global(.ca-link) {
      display: flex;
      align-items: center;
    }
  }

  .heading-container {
    display: flex;
    flex-shrink: 0;
    padding-top: 0;
    padding-bottom: 2rem;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-basis: 43%;
      padding-bottom: 2.5rem;
      padding-right: 5.75rem;
    }

    @include media-breakpoint-up(lg) {
      padding-right: 5.75rem;
    }

    :global(.ca-text-eyebrow) {
      margin-bottom: 1rem;
      text-align: center;

      @include media-breakpoint-up(lg) {
        text-align: left;
      }
    }

    :global(.heading-text) {
      @include text-mobile-header-3;
      margin-bottom: 2rem;
      text-align: center;

      @include media-breakpoint-up(lg) {
        @include text-web-header-3;
        margin-bottom: 1rem;
        text-align: left;
      }
    }
  }
  .text-container {
    @include text-mobile-body-1;
    text-align: center;

    @include media-breakpoint-up(lg) {
      text-align: left;
      @include text-web-body-1;
      padding-right: 2rem;
    }

    @include media-breakpoint-up(xl) {
      padding-right: 5rem;
    }
    @include media-breakpoint-up(xxl) {
      padding-right: 5.5rem;
    }
  }
  .media-container {
    flex-shrink: 1;
    display: flex;

    @include media-breakpoint-up(lg) {
      flex-basis: 55%;
      display: flex;
      flex: 1;

      .ca-video-block__stage {
        width: 100%;
      }
    }
  }
</style>
