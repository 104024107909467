<script lang="ts">
  export let menuColumn;
  export let locale;

  import { locales } from "../../lib/constants";
  import { onMount } from "svelte";

  let button;
  let buttonText =
    "Do not share or sell my personal information (cookie preferences)";

  /**
   * Cookie pro will auto change the text of this button to "Manage preferences"
   * We are wanting custom text for this so this observer listens for when that
   * change happens then updates the text to our preferred text.
   */
  onMount(() => {
    if (button) {
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (
            mutation.type === "characterData" ||
            mutation.type === "childList"
          ) {
            if (buttonText !== button.textContent) {
              button.textContent = buttonText;
            }
          }
        }
      });

      observer.observe(button, {
        characterData: true,
        childList: true,
        subtree: true,
      });

      return () => {
        observer.disconnect();
      };
    }
  });
</script>

<div class="ca-footer-group">
  <div class="ca-footer-group__title">{menuColumn.title}</div>
  <ul class="ca-footer__list">
    {#each menuColumn.items as { title, url }}
      <li class="asd">
        <a class="cmo-link cmo-link--footer" href={url} target="_self"
          >{title}</a
        >
      </li>
    {/each}
  </ul>
  {#if menuColumn.title.toLowerCase() === "featured blogs"}
    <a
      target="_self"
      href={`${locales[locale]}/blog/`}
      class="ca-footer__list-link mt-4"
    >
      <span class="me-2">Read more</span>
      <svg
        width="22"
        height="17"
        viewBox="0 0 22 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="8.25" x2="20" y2="8.25" stroke="white" stroke-width="1.5" />
        <path d="M13 1L20.5 8.5L13 16" stroke="white" stroke-width="1.5" />
      </svg>
    </a>
  {/if}

  {#if menuColumn.title.toLowerCase() === "legal"}
    <button
      bind:this={button}
      id="ot-sdk-btn"
      class="ot-sdk-show-settings ca-footer__list-link mt-4 cmw-cookie-center-toggle"
      >{buttonText}</button
    >
  {/if}
</div>

<style lang="scss">
  .cmw-cookie-center-toggle {
    background: none;
    text-align: left;
    border: 0;
    padding: 0;
    cursor: pointer;
  }
  .cmw-cookie-center-toggle:hover {
    text-decoration: underline;
  }

  // TODO: not sure why it's not picking up that this exists
  #ot-sdk-btn.ot-sdk-show-settings {
    background-color: inherit;
    color: inherit;
    padding: 0;
    line-height: initial;
    font-size: inherit;
    border: none;

    &:hover {
      background-color: transparent;
    }
  }
</style>
