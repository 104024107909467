<script lang="ts">
  import PricingCard from "../../PricingCard/PricingCard.svelte";

  export let items;
  export let text;

  export let bottomCard = null;
  export let topCard = null;

  $: items = items || [];
  $: text = text || "";
</script>

<div class="ca-section mb-4_5">
  <div class="container-xl">
    {#if topCard?.heading}
      <div
        class="top-card pricing-flexible-wrapper"
        class:pricing-flexible-wrapper--col1={items.length === 1}
        class:pricing-flexible-wrapper--col2={items.length === 2}
        class:pricing-flexible-wrapper--col3={items.length === 3}
        class:pricing-flexible-wrapper--col4={items.length === 4}
      >
        <PricingCard {...topCard} />
      </div>
    {/if}

    <div
      class="pricing-flexible-grid pricing-flexible-wrapper align-items-start"
      class:pricing-flexible-wrapper--col1={items.length === 1}
      class:pricing-flexible-wrapper--col2={items.length === 2}
      class:pricing-flexible-wrapper--col3={items.length === 3}
      class:pricing-flexible-wrapper--col4={items.length === 4}
      class:pricing-flexible-grid--col1={items.length === 1}
      class:pricing-flexible-grid--col2={items.length === 2}
      class:pricing-flexible-grid--col3={items.length === 3}
      class:pricing-flexible-grid--col4={items.length === 4}
    >
      {#each items as item, i (item.content + i)}
        <PricingCard {...item} />
      {/each}
    </div>

    {#if bottomCard?.heading}
      <div
        class="bottom-card pricing-flexible-wrapper"
        class:pricing-flexible-wrapper--col1={items.length === 1}
        class:pricing-flexible-wrapper--col2={items.length === 2}
        class:pricing-flexible-wrapper--col3={items.length === 3}
        class:pricing-flexible-wrapper--col4={items.length === 4}
      >
        <PricingCard {...bottomCard} />
      </div>
    {/if}

    {#if text}
      <div class="ca-text-body-1 text-center mt-4_5">
        <p>{@html text}</p>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .bottom-card {
    margin: 1rem auto 0;
  }

  .pricing-flexible-grid {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    justify-content: center;

    &--col2 {
      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }
    }

    &--col3 {
      @media (min-width: 1024px) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    &--col4 {
      @media (min-width: 768px) {
        grid-template-columns: 1fr 1fr;
      }

      @media (min-width: 1196px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }
    }
  }

  .pricing-flexible-wrapper {
    margin-right: auto;
    margin-left: auto;

    &--col1 {
      max-width: 480px;
    }

    &--col2 {
      max-width: 780px;
    }

    &--col3 {
      max-width: 980px;
    }
  }
</style>
