<script lang="ts">
  import { formatDate, dateLong } from "./Agenda.helpers";

  export let data;
  export let activeTab;

  let headingRef;

  let open = false;

  const handleOpen = () => {
    open = !open;

    window.scrollTo({
      // adding 200 to clear nav and secondary nav
      top: headingRef.offsetTop - 200,
      left: 0,
      behavior: "smooth",
    });
  };
</script>

<h3 bind:this={headingRef} class="ca-text-2xl ca-font-heading">
  {formatDate(data.date, dateLong)}
</h3>

{#if data?.items}
  <ul class="agenda-items mt-4" class:mb-4_5={"all-days" === activeTab}>
    {#each data.items as item, index}
      {#if "all-days" !== activeTab || index < 2 || (open && index > 1)}
        <li
          class:alt-sesh={data.items[index]?.time ===
            data.items[index - 1]?.time}
        >
          <div class="session-time ca-text-eyebrow mb-1">{item.time}</div>
          <div class="session-title ca-font-text">{item.title}</div>
          {#if item?.description}
            <div class="mb-2 ca-font-text">{item.description}</div>
          {/if}
          {#if item?.speakers?.length > 0}
            <div class="speakers">
              {#each item.speakers as speaker}
                <div class="speaker-item">
                  <svelte:element
                    this={speaker.url ? "a" : "span"}
                    target={speaker.url ? "_self" : null}
                    href={speaker.url}
                  >
                    {[speaker.name, speaker.company].filter(Boolean).join(", ")}
                  </svelte:element>
                </div>
              {/each}
            </div>
          {/if}
        </li>
      {/if}
    {/each}
  </ul>
{/if}

{#if "all-days" === activeTab}
  <div class="mb-4_5">
    <button class:active={open} on:click={handleOpen} class="agenda-view-more"
      >View {open ? "less" : "more"}</button
    >
  </div>
{/if}
