<script lang="ts">
  import TopBannerDefault from "./variants/TopBannerDefault.svelte";
  import TopBannerGeoSwitcher from "./variants/TopBannerGeoSwitcher.svelte";
  export let text;
  export let variant;
  export let pageLocale;
  export let bannerID = `banner${getHash(text)}`;

  $: text = text || "";

  function getHash(input) {
    let hash = 0;
    for (let i = 0; i < input.length; i++) {
      hash = (hash << 5) - hash + input.charCodeAt(i);
      hash |= 0; // to 32bit integer
    }
    return hash;
  }

  const variants = {
    default: TopBannerDefault,
    "geo-switcher": TopBannerGeoSwitcher,
  };
</script>

<svelte:component
  this={variants[variant] || variants.default}
  {text}
  {pageLocale}
  {bannerID}
>
  <slot />
</svelte:component>
