// @ts-strict-ignore
import VideoBlock from "./VideoBlock.svelte";
import { HeadingConfig } from "../Heading/Heading.config";

export const VideoBlockConfig = {
  name: "VideoBlock",
  component: VideoBlock,
  variants: {},
  mapper: (props) => {
    const result = { ...props };

    const items = props?.videoItemsCollection?.items.map((item) => {
      return {
        embed: item?.embed,
        thumbnail: {
          src: item?.image?.url,
          alt: item?.image?.description || item?.image?.title,
        },
        heading: item?.heading,
        accordionTitle: item?.accordion?.title,
        accordionText: item?.accordion?.richTextContent?.json,
      };
    });
    result.items = items;
    result.heading = HeadingConfig.mapper(result?.heading);
    result.text = result.introText?.json;
    result.cta = props?.ctaCollection?.items?.[0];

    return result;
  },
  // should change the heading so it is coming from heading config
  fragment: `
    fragment VideoBlockData on VideoBlock {    
      eyebrow
      heading {
        text
        size
        level
        font
        size
        align: textAlignment
        classList
      }
      ctaCollection (limit: 1) {
          items {
            text
            url
            variant
            icon
          }
      }
      introText: text {
        json
      }
      variant
      videoItemsCollection (limit: 50) {
        items {
          type 
          image {
            url
            title
            description
          }
          embed
          heading
          accordion {
            title
              richTextContent: content {
                json
              }
          }
        }
      }
      bgColor
    }
    `,
};
