<script lang="ts">
  import { onMount } from "svelte";
  import { page } from "$app/stores";
  import {
    getUserLocale,
    getUserSelectedRegion,
    setUserSelectedRegion,
    getContinueUrl,
  } from "$lib/helpers";
  import { localizedHomePages } from "$lib/constants";
  import type { LocaleKeys, Regions } from "$types/utils";
  import DropdownToggle from "../../DropdownToggle/DropdownToggle.svelte";

  export let text: string;
  export let pageLocale: LocaleKeys;
  let bannerRef: HTMLDivElement;
  let continueUrl: string;
  let userLocale: LocaleKeys | undefined;
  let loadBanner = false;
  const defaultLocale = "en-US" as LocaleKeys;

  const remove = () => {
    userLocale = pageLocale;
    setStorageBannerSeenAndNFCountryCookie();
    bannerRef.remove();
  };

  const updateSelected = (selectedValue: LocaleKeys) => {
    const alternateTag = document.querySelector(
      `link[rel='alternate'][hreflang='${selectedValue}']`,
    ) as HTMLLinkElement;
    continueUrl = getContinueUrl(
      alternateTag?.href || localizedHomePages[selectedValue],
    );
    userLocale = selectedValue;
  };

  const setStorageBannerSeenAndNFCountryCookie = () => {
    if (userLocale) {
      const userRegion = userLocale.slice(-2);
      setUserSelectedRegion(userRegion as Regions);
      document.cookie = `nf_country=${userRegion}; Path=/;`;
    }
  };

  onMount(async () => {
    const defaultUserRegionInSearchParams =
      $page.url.searchParams.get("defaultRegion");
    if (defaultUserRegionInSearchParams) {
      setUserSelectedRegion(defaultUserRegionInSearchParams as Regions);
      document.cookie = `nf_country=${defaultUserRegionInSearchParams}; Path=/;`;
    }
    const userSelectedRegion = getUserSelectedRegion();
    userLocale = (await getUserLocale()) || defaultLocale;
    const localesAreMismatched = userLocale !== pageLocale;
    // we want to load the banner if:
    // a) the user has not already picked a locale
    loadBanner = !userSelectedRegion && localesAreMismatched;
    const alternateTag = document.querySelector(
      `link[rel='alternate'][hreflang='${userLocale}']`,
    ) as HTMLLinkElement;
    continueUrl = getContinueUrl(
      alternateTag?.href || localizedHomePages[userLocale],
    );
  });
</script>

{#if loadBanner}
  <div
    class="ca-announcement-bar-carta"
    class:load-banner={loadBanner}
    bind:this={bannerRef}
    data-test="top-banner-geo-switcher"
  >
    <div class="container-xl ca-announcement-bar-carta__container">
      <div class="ca-announcement-bar-carta__main">
        {text}
      </div>

      <div class="toggle-container">
        <DropdownToggle
          theme="light"
          variant="banner"
          {updateSelected}
          geo={userLocale || defaultLocale}
        />
        <a
          class="continue-button"
          href={continueUrl}
          target="_self"
          on:click={setStorageBannerSeenAndNFCountryCookie}>Continue</a
        >
      </div>

      <button class="ca-announcement-bar-carta__close" on:click={remove}>
        <svg
          fill="#000000"
          height="200px"
          width="200px"
          version="1.1"
          id="Capa_1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 460.775 460.775"
          xml:space="preserve"
          ><g id="SVGRepo_bgCarrier" stroke-width="0" /><g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          /><g id="SVGRepo_iconCarrier">
            <path
              d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55 c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55 c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505 c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55 l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719 c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z"
            />
          </g></svg
        >
      </button>
    </div>
  </div>
{/if}

<style>
  .ca-announcement-bar-carta.load-banner:has(
      + .ca-announcement-bar-carta.load-banner
    ) {
    margin-bottom: 10px;
  }
  .ca-announcement-bar-carta {
    background-color: #e3eef3;
    padding: 18px 0;
    color: var(--clr-global-body);
    font-size: 0.875rem;
    line-height: 1.45;
    display: none;
  }
  .ca-announcement-bar-carta.load-banner {
    display: block;
  }
  .ca-announcement-bar-carta__container {
    display: grid;
    grid-template-columns: 2fr 1fr 28px;
    gap: 1rem;
    align-items: center;
  }
  .ca-announcement-bar-carta__main {
    width: 100%;
  }

  .ca-announcement-bar-carta__close {
    appearance: none;
    border: 0;
    width: 28px;
    height: 28px;
    background: transparent;
    cursor: pointer;
  }
  .ca-announcement-bar-carta__close svg {
    width: 100%;
  }
  .continue-button {
    display: flex;
    align-items: center;
    background-color: inherit;
    border: inherit;
    /* text-decoration: underline solid black;
		-webkit-text-decoration: underline;
		text-underline-offset: 5px;
		text-decoration-thickness: 1px; */
    cursor: pointer;
  }
  .continue-button:hover {
    /* text-decoration: underline solid black; */
    /* font-weight: bold; */
    text-decoration: underline solid black;
    -webkit-text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 1px;
  }
  .toggle-container {
    display: flex;
  }
  @media (max-width: 656px) {
    .ca-announcement-bar-carta__main {
      grid-area: a;
    }
    .toggle-container {
      grid-area: b;
    }
    .ca-announcement-bar-carta__close {
      grid-area: c;
      margin-top: -25px;
    }
    .ca-announcement-bar-carta__container {
      display: grid;
      justify-items: center;
      gap: 11px;
      grid-template-areas:
        "a a a a a c"
        "b b b b b b";
    }
  }
</style>
