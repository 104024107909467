<script lang="ts">
  import { trailingslashit } from "$lib/helpers.js";
  export let heading;
  export let items;

  $: heading = heading || {};
  $: items = items || [];
</script>

<section class="ca-section ca-section-portrait-grid py-4_5 py-md-5 py-lg-7">
  <div class="container-xl">
    <div class="col-12 col-lg-10 offset-lg-1">
      {#if Object.keys(heading)?.length > 0}
        <h2 class="ca-text-4xl ca-font-heading text-center mb-4_5">
          {heading.text}
        </h2>
      {/if}
      <div class="row g-4">
        {#each items as { title, image, url }}
          <div class="col-12 col-md-4">
            <a
              href={trailingslashit(url)}
              target="_self"
              class="ca-card ca-card--fixed-info ca-card--blog-related"
            >
              <div class="row g-0">
                <div class="col-12">
                  <img
                    width="1024"
                    height="512"
                    src={image.src}
                    class="ca-card__img h-100"
                    alt={image.alt}
                  />
                </div>
                <div class="col-12">
                  <div class="ca-card__info">
                    <div class="ca-card__info-header">
                      {@html title}
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        {/each}
      </div>
    </div>
  </div>
</section>
