<script lang="ts">
  import { trailingslashit } from "$lib/helpers";
  import Schema from "./Schema.svelte";
  import { page } from "$app/stores";
  import { getJsonLDBlogData } from "$layouts/Article/parts/schemaData";
  import type { SeoFieldsFragment } from "$types/contentful-queries";
  import { locales, localesMap } from "$lib/constants";
  import type { AlternateLocaleUrls } from "$types/utils";
  import { stripLocaleFromPath } from "$lib/helpers";

  export let locale;
  export let title: SeoFieldsFragment["title"] = "";
  export let description: SeoFieldsFragment["description"] = "";
  export let image: SeoFieldsFragment["image"] = null;
  export let permalink = $page.url.toString();
  export let openGraphTitle: SeoFieldsFragment["openGraphTitle"] = "";
  export let openGraphDescription: SeoFieldsFragment["openGraphDescription"] =
    "";
  export let openGraphImage: SeoFieldsFragment["openGraphImage"] = null;
  export let twitterCardTitle: SeoFieldsFragment["twitterCardTitle"] = "";
  export let twitterCardDescription: SeoFieldsFragment["twitterCardDescription"] =
    "";
  export let twitterCardImage: SeoFieldsFragment["twitterCardImage"] = null;
  export let index: SeoFieldsFragment["index"] = true;
  export let follow: SeoFieldsFragment["follow"] = true;
  export let h1Text: string = "";
  h1Text ??= "";
  export let canonicalLink: SeoFieldsFragment["canonicalLink"] = null;
  export let schema: SeoFieldsFragment["schema"] = null;
  export let alternateLocales: AlternateLocaleUrls = {
    "en-US": stripLocaleFromPath($page.url.pathname),
    "en-GB": stripLocaleFromPath($page.url.pathname),
    "en-SG": stripLocaleFromPath($page.url.pathname),
  };

  // This is a bit complex due to existing Contentful field usage
  const url = canonicalLink
    ? new URL(`${localesMap[locale]}${canonicalLink}`, `${$page.url.origin}`)
    : new URL($page.url);
  url.search = ""; // Remove query parameters
  canonicalLink = url.toString();

  const imageUrl = image?.url;

  const inLanguage = locale;
  const webPageSchema = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "/#organization",
        name: "Carta",
        url: "https://carta.com/",
        sameAs: [
          "https://en.wikipedia.org/wiki/Carta_(software_company)",
          "https://www.linkedin.com/company/carta--/",
          "https://www.facebook.com/cartahq/",
          "https://x.com/cartainc",
          "https://www.youtube.com/@CartaInc",
          "https://www.instagram.com/cartainc/",
        ],
        logo: {
          "@type": "ImageObject",
          "@id": "/#logo",
          url: "https://carta.com/Logo.svg",
          contentUrl: "https://carta.com/Logo.svg",
          caption: "Carta",
          inLanguage,
          width: "99",
          height: "43",
        },
      },
      {
        "@type": "WebSite",
        "@id": "/#website",
        url: "https://carta.com/",
        name: "Carta",
        inLanguage,
        publisher: {
          "@id": "/#organization",
        },
      },
      {
        "@type": "WebPage",
        "@id": `${trailingslashit(permalink)}#webpage`,
        url: trailingslashit(permalink),
        name: h1Text || title,
        datePublished: $page?.data?.sys?.firstPublishedAt,
        dateModified: $page?.data?.sys?.publishedAt,
        description,
        primaryImageOfPage: image
          ? {
              "@type": "ImageObject",
              url: imageUrl,
              height: image?.height,
              width: image?.width,
              caption: image?.description,
            }
          : null,
        inLanguage,
        isPartOf: {
          "@id": "/#website",
        },
      },
    ],
  };

  let articleSchema;
  if ($page.data?.content?.__typename === "BlogPost") {
    articleSchema = getJsonLDBlogData($page.data);
  }

  $: ogTitle = openGraphTitle || title;
  $: ogDescription = openGraphDescription || description;
  $: ogImage = openGraphImage?.url || imageUrl;

  $: robotsContent = Object.entries({
    follow: follow,
    nofollow: !follow,

    index: index,
    noindex: !index,

    "max-snippet:-1": true,
    "max-video-preview:-1": true,
    "max-image-preview:large": true,
  })
    .filter(([k, v]) => v)
    .map(([k, v]) => k)
    .join(", ");
</script>

<svelte:head>
  <title>{title}</title>
  <meta name="description" content={description} />

  <meta name="robots" content={robotsContent} />

  <link rel="canonical" href={trailingslashit(canonicalLink || permalink)} />
  {#each Object.keys(alternateLocales) as locale}
    {#if alternateLocales[locale]}
      <link
        rel="alternate"
        href={trailingslashit(
          `${$page.url.origin}${locales[locale]}${alternateLocales[locale]}`,
        )}
        hreflang={locale}
      />
      {#if locale === "en-US"}
        <link
          rel="alternate"
          href={trailingslashit(
            `${$page.url.origin}${alternateLocales[locale]}`,
          )}
          hreflang="x-default"
        />
      {/if}
    {/if}
  {/each}

  <meta property="og:locale" content={locale} />
  <meta property="og:type" content="website" />
  <meta property="og:title" content={ogTitle} />
  <meta property="og:description" content={ogDescription} />
  <meta property="og:url" content={trailingslashit(permalink)} />
  <meta property="og:site_name" content="Carta" />
  <meta property="og:image" content={ogImage} />
  <meta property="og:image:secure_url" content={ogImage} />
  <meta property="og:image:alt" content={ogTitle} />

  <meta name="twitter:card" content="summary_large_image" />
  <meta name="twitter:title" content={twitterCardTitle || title} />
  <meta
    name="twitter:description"
    content={twitterCardDescription || description}
  />
  <meta name="twitter:site" content="@cartainc" />
  <meta name="twitter:creator" content="@cartainc" />
  <meta name="twitter:image" content={twitterCardImage?.url || imageUrl} />
  <!-- <meta name="twitter:label1" content="Time to read" />
	<meta name="twitter:data1" content="Less than a minute" /> -->
</svelte:head>

<Schema jsonLDData={webPageSchema} className="webpage" />
{#if articleSchema}
  <Schema jsonLDData={articleSchema} className="article" />
{/if}
<!-- for if a schema is manually added through cms -->
{#if schema}
  <Schema jsonLDData={schema} />
{/if}
