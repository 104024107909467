// @ts-strict-ignore
import LogoBar from "../LogoBar/LogoBar.svelte";
import { CardConfig } from "../Card/Card.config";

export const LogoBarConfig = {
  name: "LogoBar",
  component: LogoBar,
  mapper: (props) => {
    let result = { ...props };
    let images = [];
    for (const item of props.imagesCollection.items) {
      images.push({
        src: item.url,
        alt: item.description || item.title,
      });
    }
    result.images = images;
    result.cards = props?.cardsCollection?.items?.map((card) =>
      CardConfig.mapper(card),
    );
    result.cardsBgColor = props.cardsBgColor;

    return result;
  },
  fragment: `
        fragment LogoBarData on LogoBar {
            title
            headingText: heading
            headingLevel
            headingSize
            imagesCollection(limit: 15) {
                items {
                    url
                    title
                    width
                    height
                    description
                }
            }
            cardsCollection {
                items {
                  ...CardData
                }
            }
            cardsBgColor
        }
    `,
};
