import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31')
];

export const server_loads = [2,4,5];

export const dictionary = {
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(staticPages)": [~23,[2],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/author/[author]": [~7,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/blog": [~8,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/blog/all": [~10,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/blog/all/page/[...pageNumber]": [~11,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/blog/category/[category]": [~12,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/blog/category/[category]/page/[...pageNumber]": [~13,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/blog/[...page]": [~9,[2,4],[3]],
		"/caching-test": [~27],
		"/cmw/navigation": [~28],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/c/the-new-standard": [~14,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/data": [~15,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/data/about": [~17,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/data/podcast": [~18,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/data/s": [~19,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/data/[...page]": [~16,[2,4],[3]],
		"/friend": [29,[5]],
		"/friend/ty": [30,[5]],
		"/(app)/marketing-site-beta": [26,[2],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/requestdemo-fund-forecasting-planning": [20,[2,4],[3]],
		"/sentry-example": [31],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/s": [~21,[2,4],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/s/page/[...page]": [~22,[2,4],[3]],
		"/(app)/[country=ukCountryOnly]/[lang=validLang]/product-release-notes": [24,[2],[3]],
		"/(app)/[country=ukCountryOnly]/[lang=validLang]/support": [25,[2],[3]],
		"/(app)/[[country=validCountry]]/[[lang=validLang]]/(dynamicPages)/[...page]": [~6,[2,4],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';