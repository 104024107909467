// @ts-strict-ignore
import StatsBar from "./StatsBar.svelte";

export const StatsBarConfig = {
  name: "StatsBar",
  component: StatsBar,
  variants: {},
  mapper: (props) => {
    return {
      ...props,
      stats: props?.statsCollection?.items || [],
      title: props.title,
    };
  },
  fragment: `fragment StatsBarData on StatsBar {
		  __typename
		  statsBarName
		  title
		  backgroundColor
		  statsCollection(limit: 4) {
			items{
			headingText
			text
			value
			prefix
			suffix
			variant
			useCountUpAnimation
			useConfettiAnimation
			startValue
			}
		}
      }`,
};
