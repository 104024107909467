<script lang="ts">
  export let item;
</script>

<div class="ca-custom-dropdown">
  <button class="ca-btn ca-btn--secondary ca-btn--secondary">
    {item.label}
  </button>
  <ul class="ca-custom-dropdown__body" role="menu">
    {#each item.items as subItem}
      <li class="ca-custom-dropdown__item" role="menuitem">
        <a target="_self" href={subItem.value || subItem.url}
          >{subItem.key || subItem.label}</a
        >
      </li>
    {/each}
  </ul>
</div>
