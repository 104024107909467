<script lang="ts">
  import { onMount } from "svelte";
  import { page } from "$app/stores";

  import Heading from "../Heading/Heading.svelte";
  import Button from "../Button/Button.svelte";
  import { formatDateTime, getSessionStatus } from "./EventGuide.functions";

  export let heading;
  export let backgroundColor;
  export let sessions = [];

  let currentSessionId = null;

  const updateSessionStatus = () => {
    const now = new Date();
    let updated = false;
    currentSessionId = null; // Reset the currentSessionId

    const updatedSessions = sessions.map((session) => {
      const start = new Date(session.startTime);
      const end = new Date(session.endTime);

      session.datetime = formatDateTime(start, end);

      const status = getSessionStatus(now, start, end);

      if (status === "active") {
        currentSessionId = session.id;
      }

      if (session.status !== status) {
        updated = true;
        return { ...session, status };
      }

      return session;
    });

    if (updated) {
      sessions = updatedSessions;
    }
  };

  const formatSpeakersList = (speakers) => {
    return speakers
      .map(({ name }) => `<span>${name?.trim()}</span>`)
      .join(", ");
  };

  onMount(() => {
    updateSessionStatus();

    const interval = setInterval(() => {
      updateSessionStatus();
    }, 1000);

    return () => clearInterval(interval);
  });

  sessions = sessions.map((session) => ({
    ...session,
    showDescription: false,
  }));

  function toggleDescription(sessionTitle) {
    sessions = sessions.map((session) => {
      if (session.title === sessionTitle) {
        return { ...session, showDescription: !session.showDescription };
      }
      return session;
    });
  }
</script>

<section
  class="py-4_5 py-lg-7"
  class:ca-bg--neutral-black={backgroundColor === "neutral-black"}
  class:ca-bg--neutral-100={backgroundColor === "neutral-100"}
  class:ca-bg--neutral-200={backgroundColor === "neutral-200"}
  class:ca-bg--neutral-300={backgroundColor === "neutral-300"}
  class:ca-bg--neutral-400={backgroundColor === "neutral-400"}
  class:ca-bg--neutral-white={backgroundColor === "neutral-white"}
  class:ca-bg--highlight-blue={backgroundColor === "highlight-blue"}
  class:ca-bg--highlight-green={backgroundColor === "highlight-green"}
  class:ca-bg--highlight-yellow={backgroundColor === "highlight-yellow"}
  class:ca-bg--midtone-blue={backgroundColor === "midtone-blue"}
  class:ca-bg--midtone-green={backgroundColor === "midtone-green"}
  class:ca-bg--midtone-orange={backgroundColor === "midtone-orange"}
  class:ca-bg--light-blue={backgroundColor === "light-blue"}
  class:ca-bg--light-green={backgroundColor === "light-green"}
  class:ca-bg--light-orange={backgroundColor === "light-orange"}
  class:ca-bg--light-beige={backgroundColor === "light-beige"}
  class:ca-bg--light-cream={backgroundColor === "light-cream"}
>
  <div class="container-xl">
    <div class="row">
      <div class="col-12 col-md-10 offset-md-1">
        {#if heading}
          <div class="mb-7">
            <Heading
              {...{
                font: "heading",
                align: "center",
                size: "3xl",
                tag: "h2",
                ...heading,
              }}
            />
          </div>
        {/if}

        <ul class="ca-ces-event-list" role="list">
          {#each sessions as session}
            <li class="ces-event-wrapper">
              <div
                class={`ca-ces-event ${session.status === "active" ? "ca-ces-event--active" : session.status}`}
              >
                <div class="ca-ces-event__time-wrap">
                  <span
                    class={`ca-ces-event__tag ${
                      session.status === "active"
                        ? ""
                        : session.status === "past"
                          ? "ca-ces-event__tag--muted"
                          : "ca-ces-event__tag--upcoming"
                    }`}
                  >
                    {session.status === "active"
                      ? "LIVE"
                      : session.status === "past"
                        ? "DONE"
                        : "UPCOMING"}
                  </span>
                  <div class="ca-ces-event__time">
                    {session.status === "active"
                      ? "NOW " + session.datetime
                      : session.datetime}
                  </div>
                </div>
                <div class="ca-ces-event__body">
                  <div class="ca-ces-event__title">{session.title}</div>
                  <div class="ca-ces-event__speakers">
                    {@html formatSpeakersList(session.speakers)}
                  </div>
                  {#if session.description}
                    <button
                      class="ca-ces-event__toggle-description"
                      class:open={session.showDescription}
                      on:click={() => toggleDescription(session.title)}
                    >
                      {session.showDescription ? "View less" : "View more"}
                    </button>
                  {/if}
                </div>
                <div class="ca-ces-event__cta">
                  {#if session.status === "past"}
                    Complete - recording coming soon
                  {:else}
                    <Button
                      variant={session.status === "upcoming"
                        ? "muted"
                        : "secondary"}
                      url={session.url + $page?.url?.search}
                      tag="a"
                      text={`Watch ${session.status === "upcoming" ? " soon" : ""}`}
                    />
                  {/if}

                  {#if session.description}
                    <button
                      class="ca-ces-event__toggle-description toggle-desc-small"
                      class:open={session.showDescription}
                      on:click={() => toggleDescription(session.title)}
                    >
                      {session.showDescription ? "View less" : "View more"}
                    </button>
                  {/if}
                </div>
              </div>

              {#if session.showDescription}
                <div class="ca-ces-event__intro">
                  <p><strong>Session description</strong></p>
                  {session.description}
                </div>
              {/if}
            </li>
          {/each}
        </ul>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  .ca-ces-event__toggle-description {
    display: inline-flex;
    padding: 0 30px 0 0;
    min-height: 20px;

    align-items: center;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M19.2065 9.02826L12.3391 15.3674C12.1476 15.5442 11.8524 15.5442 11.6609 15.3674L4.79346 9.02826L5.47174 8.29346L12 14.3195L18.5283 8.29346L19.2065 9.02826Z' fill='black'/%3E%3C/svg%3E%0A");
    background-position: center right;
    background-repeat: no-repeat;
    border: none;
    background-color: transparent;
    border-radius: 8px;
    cursor: pointer;

    font-weight: 500;
    color: var(--clr-neutral-black);

    &.open {
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M4.79346 14.9717L11.6609 8.6326C11.8524 8.4558 12.1476 8.4558 12.3391 8.6326L19.2065 14.9717L18.5283 15.7065L12 9.68045L5.47174 15.7065L4.79346 14.9717Z' fill='black'/%3E%3C/svg%3E%0A");
    }

    @media (width < 62rem) {
      &:not(.toggle-desc-small) {
        display: none;
      }
    }

    &.toggle-desc-small {
      display: flex;
      margin-top: 1rem;
      @media (width > 62rem) {
        display: none;
      }
    }
  }
</style>
