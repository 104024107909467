<script lang="ts">
  import Panel from "$components/Panel/Panel.svelte";
  import RichText from "$components/RichText/RichText.svelte";
  import RelatedCardsGrid from "../../components/RelatedCardsGrid/RelatedCardsGrid.svelte";
  import { page } from "$app/stores";
  import LoadMoreButton from "../../parts/LoadMoreButton.svelte";
  import { relatedPostsMapper } from "./parts/relatedPostsMapper";
  import { onMount } from "svelte";
  import { getWordCount } from "$lib/helpers";

  export let data;
  export let hasSubMenu = false;

  let relatedPosts = data?.related?.items?.map((post) =>
    relatedPostsMapper(post, data?.locale),
  );
  let latestArticles = data?.latestArticles?.items?.map((post) =>
    relatedPostsMapper(post, data?.locale),
  );

  const authors = data?.authors?.items.map((author) => ({
    variant: "person-hero-inline",
    heading: author.name,
    media: {
      type: "Image",
      image: {
        url: author.avatar?.url,
        lazyload: true,
        alt: author.name,
      },
    },
    text: author.bio,
    id: author.seo.uri,
  }));

  const publishedDate = new Date(
    data?.displayDate || data?.sys?.firstPublishedAt,
  ).toLocaleDateString(data.locale, {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  const readTime = Math.ceil(
    getWordCount(data?.content?.body.json.content) / 275,
  );

  const hero = {
    variant: "blog-hero",
    backgroundColor: data.content.themeColor || "Light Cream",
    eyebrow: data.content.eyebrow,
    heading: {
      text: data.content.title,
      authors,
      readTime: `${readTime} ${readTime === 1 ? "minute" : "minutes"}`,
      publishedDate,
    },
    // text: data.content.text,
    text: data?.seo?.description,
    media: {
      image: {
        url: data?.seo?.image?.url,
        alt: data?.content?.title,
        width: data?.seo?.image?.width,
        height: data?.seo?.image?.height,
      },
      type: "Image",
    },
  };

  const disclosure = data.content.disclosure;
  const disclosures = data.content.disclosuresCollection.items;

  let totalPosts = data?.related?.total;
  let remainingPosts;
  let getPostsLoaded = true;
  let pageLoaded = false;

  $: {
    totalPosts = data?.related?.total;
    remainingPosts = totalPosts - relatedPosts?.length;
  }

  const getPosts = async () => {
    getPostsLoaded = false;
    const skip = relatedPosts.length;
    const excludeId = data.content?.sys?.id;
    const tagIds = data?.contentfulMetadata?.tags?.map((tag) => tag.id);
    const locale = $page.data.locale;
    const preview = data.path?.includes("edit/webpage");
    const response = await fetch(
      `/types/related-posts?skip=${skip}&tagIds=${tagIds}&excludeId=${excludeId}&locale=${locale}&preview=${preview}`,
    );
    const postData = await response.json();

    relatedPosts = relatedPosts.concat(postData);

    getPostsLoaded = true;
  };

  onMount(() => {
    pageLoaded = true;
  });
</script>

<article class="block-layout-article">
  <Panel {...hero} />

  {#if data?.content?.body}
    <div class="container-xl">
      <div
        class="ca-article-body ca-text-body-1 pb-4_5 d-flex flex-column align-items-center"
      >
        <RichText
          body={data?.content?.body}
          toc={true}
          {hasSubMenu}
          {data}
          {authors}
          {disclosure}
          {disclosures}
          {hero}
          lazyloadImages={true}
          hasBgColor={hero.backgroundColor}
          articleSideBarButton={data.articleSideBarButton}
        />
        {#if relatedPosts?.length > 0}
          <RelatedCardsGrid
            lazyloadImages={true}
            items={relatedPosts}
            heading={{ text: "Related Content" }}
            variant="classroom"
            useCarousel={true}
          />
        {:else}
          <RelatedCardsGrid
            lazyloadImages={true}
            items={latestArticles}
            heading={{ text: "Latest Articles" }}
            variant="classroom"
            useCarousel={true}
          />
        {/if}
        {#if remainingPosts > 0}
          <div class="load-more-button">
            <LoadMoreButton {getPostsLoaded} {pageLoaded} {getPosts} />
          </div>
        {/if}
      </div>
    </div>
  {/if}
</article>

<style lang="scss">
  /* mobile */
  @media (max-width: 991px) {
    .load-more-button {
      display: none;
    }
  }
</style>
