<script lang="ts">
  export let heading;
  export let content;
  export let tag;
  export let tagLocation;
  export let features;
  export let price;
  export let starting;
  export let open = false;
  export let backgroundColor;
</script>

<div class="ca-price-card" class:open>
  <div class={`ca-price-card__header ${backgroundColor}`}>
    <div class="d-flex align-items-center flex-wrap gap-2 mb-4">
      <div class="ca-price-card__heading ca-text-3xl ca-font-heading">
        {heading}
      </div>
      {#if tag}
        <div class={`ca-tag ca-bg--neutral-white flex-shrink-0 ${tagLocation}`}>
          {tag}
        </div>
      {/if}
    </div>
    <div class="ca-price-card__content ca-text-body-1 mb-5">
      {content}
    </div>
    <div>
      <span class="ca-price-card__price">${price}</span>
      <span class="ca-text-body-1">/ stakeholder</span>
    </div>
    <div class="mt-1 mb-0 mb-lg-5 ca-text-body-2">{starting}</div>
  </div>
  <div class="ca-price-card__body">
    <ul class="ca-price-card__list">
      {#each features as item, i}
        {#if i < 3}
          <li class:highlight={item.highlight}>{item.title}</li>
        {/if}
        {#if i >= 3 && open}
          <li class:highlight={item.highlight}>{item.title}</li>
        {/if}
      {/each}
    </ul>
  </div>
  <div class="d-flex justify-content-center mt-auto">
    <button class="ca-price-card__toggle" on:click={() => (open = !open)}>
      {#if open}
        View less
      {:else}
        View more
      {/if}
    </button>
  </div>
</div>
