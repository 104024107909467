<script lang="ts">
  import { bgColors } from "$lib/constants";
  export let title;
  export let quote;
  export let image;
  export let tag;
  export let url;
  export let content;
  export let backgroundColor;
  export let author;

  const quoteBgColor = "#918177";

  title = title || author.careerTitle;
  content = content || author.name;
  image = image.src ? image : author.avatar;
</script>

<svelte:element
  this={url ? "a" : tag ? tag : "div"}
  data-sveltekit-reload
  class="portrait-customer-quote {bgColors[backgroundColor]}"
  href={url ? url : null}
  target={url ? "_self" : null}
>
  <div class="media-container">
    <!-- image turns to quotes if not available -->
    <img src={image.src} alt="customer portrait" />
    <svg
      class="quote-marks"
      width="40"
      height="45"
      viewBox="0 0 40 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="20" cy="20.7501" r="20" fill={quoteBgColor} />
      <path
        d="M14.6295 26.8514C11.8935 26.8514 9.4935 24.6434 9.4935 21.1874C9.4935 17.9234 12.5655 14.1314 17.5095 11.7314L18.1335 13.0754C14.8215 14.9474 13.4775 17.4914 13.4775 19.1234C13.4775 21.5234 14.9175 22.7234 17.8455 23.6354C17.8455 25.6514 16.1175 26.8514 14.6295 26.8514ZM24.2295 26.8514C21.4935 26.8514 19.0935 24.6434 19.0935 21.1874C19.0935 17.9234 22.1655 14.1314 27.1095 11.7314L27.7335 13.0754C24.4215 14.9474 23.0775 17.4914 23.0775 19.1234C23.0775 21.5234 24.5175 22.7234 27.4455 23.6354C27.4455 25.6514 25.7175 26.8514 24.2295 26.8514Z"
        fill="white"
      />
    </svg>
  </div>
  <div class="quote-container">
    {quote}
  </div>
  <div class="info-container">
    <div class="name">{content}</div>
    <div class="title">{title}</div>
  </div>
</svelte:element>

<!-- <img src={image.src} alt={image.alt} width="100" height="50" /> -->
<style lang="scss">
  @use "$sass/base/typography" as *;

  // multiple cards + default
  .portrait-customer-quote {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 30px 42px 30px;
  }
  .quote-container {
    margin-bottom: 16px;
    margin-right: 0px;
    font-size: 24px;
    font-weight: 400;
    line-height: 33px;
  }
  .media-container {
    position: relative;
    margin-right: 60px;
    margin-bottom: 23px;
    height: 140px;
    width: 140px;
    img {
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
    }
    .quote-marks {
      position: absolute;
      right: 0px;
      bottom: 4px;
    }
  }
  .info-container {
    @include text-body-2;
    div:first-child {
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
    }
    .name {
      padding-bottom: 4px;
    }
  }

  // single card
  @media (min-width: 992px) {
    .portrait-customer-quote:only-child {
      display: grid;
      align-items: center;
      grid-template-columns: 200px 2fr 1fr;
      padding: 32px 30px 32px 30px;
      .media-container {
        margin-bottom: 0px;
      }
      .quote-container {
        margin-right: 90px;
        margin-bottom: 0px;
      }
    }
  }

  // mobile
  @media (max-width: 992px) {
    .portrait-customer-quote {
      padding: 20px 20px 34px 20px;
      .quote-container {
        font-size: 22px;
      }
    }
    .portrait-customer-quote:nth-child(n + 2) {
      display: none;
    }
  }
</style>
