<script lang="ts">
  /* global MktoForms2, ChiliPiper, LDBookItV2 */
  import { loadScript } from "../../lib/helpers";
  import { onMount } from "svelte";
  import {
    chiliOrgTypes,
    europeanCountryCodes,
    marketoFormsIds,
    marketoFormsToBookItRouters,
  } from "./constants";

  export let formId;

  const baseUrl = "https://info.carta.com/";
  const munchkinId = "214-BTD-103";

  onMount(async () => {
    await loadScript("https://info.carta.com/js/forms2/js/forms2.min.js");
    function loadChiliPiper() {
      const el = document.createElement("script");
      el.setAttribute("src", "https://js.chilipiper.com/marketing.js");
      document.body.appendChild(el);
    }

    loadChiliPiper();

    MktoForms2.loadForm(baseUrl, munchkinId, formId);

    MktoForms2.whenReady(async function (form) {
      const params = new URLSearchParams(window.location.search);
      const pid = params.get("PID");
      // pre-pop partner id field
      if (pid) {
        form.vals({ Most_Recent_Partner_ID__c: pid });
      }

      if (form?.getFormElem("Country")) {
        try {
          const { data: countryCodes } = await import(
            "./MarketoFormCountryCodesMap.json"
          );
          const response = await fetch(
            "https://cmw-utilities.netlify.app/geolocation",
          );
          const data = await response.json();

          const countryCode = data?.geo?.country?.code;
          const Country = countryCodes[countryCode] || "";

          form.setValues({ Country });
        } catch (error) {
          console.error("Error fetching geo location:", error);
        }
      }

      form.setValues({
        landing_page_url: document.location.href,
        referring_page_url: document.referrer,
        landingpageurl: document.location.href,
        referringpageurl: document.referrer,
      });

      // load bookit
      let orgID = "00Df4000000nrKSEAY";
      let triggerNode = marketoFormsToBookItRouters[formId];
      let hiddenFieldName = "LD_BookIt_Log_ID__c";

      if (triggerNode) {
        // only load bookit if the form is in the list
        // this event listener will redirect user to thank you if modal is closed before booking meeting
        window.addEventListener("message", (event) => {
          if (event.data.message === "LD_POPUP_CLOSED") {
            window.location.href = "/thanks/requestdemo";
          }
        });

        var _ld_scriptEl = document.createElement("script");
        _ld_scriptEl.src = "https://cdn.leandata.com/js-snippet/ld-book-v2.js";
        _ld_scriptEl.addEventListener("load", function () {
          let options = {
            // eslint-disable-next-line no-unused-vars -- this code is provided by BookIt engineer, dont want to alter.
            formDataCollectorTrigger: (formTarget) => {},
            calendarTimeoutLength: 600,
          };

          LDBookItV2.initialize(orgID, triggerNode, hiddenFieldName, options);
          LDBookItV2.setFormProvider("custom");
          LDBookItV2.setFormTarget(
            document.querySelector(`#mktoForm_${formId}`),
          );
        });
        document.body.appendChild(_ld_scriptEl);
      }

      form.onSuccess(function (values) {
        const opType = document.getElementById(
          "organizationTypeDemoShortForm__c",
        );
        const portCo = document.getElementById(
          "Majority_owned_by_single_investor__c",
        );
        const country = document.getElementById("Country");
        const isChilipiperOrgType =
          opType &&
          chiliOrgTypes.includes(opType.value) &&
          portCo &&
          portCo.value !== "Yes - Majority by Private Equity" &&
          marketoFormsIds.includes(formId);

        if (
          Object.keys(europeanCountryCodes).includes(country.value) &&
          isChilipiperOrgType
        ) {
          ChiliPiper.submit("capdesk", "carta-leads_router", {
            titleStyle: "Roboto 22px #EA5938",
            debug: true,
          });

          // return false prevents marketo from redirecting the page, redirect is handled via ChiliPiper
          return false;
        } else if (triggerNode) {
          // everything else will go to bookit
          LDBookItV2.submit({ formData: values });

          // return false prevents marketo from redirecting the page, redirect is handled via BookIt
          return false;
        }
      });
    });
  });
</script>

<div class="ca-marketo-style-override mb-4 px-4 px-lg-0">
  <form id="mktoForm_{formId}" />
</div>
