<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";
  import Button from "$components/Button/Button.svelte";
  import Eyebrow from "$components/Panel/variants/parts/Eyebrow.svelte";

  export let title;
  export let image;
  export let url;
  export let backgroundColor;
  export let eyebrow;
  let isHovered = false;
</script>

<a
  href={url}
  target="_self"
  class="card-classroom-featured-mini"
  class:has-background-color={backgroundColor !== ""}
  class:ca-bg--highlight-green={backgroundColor === "highlight-green"}
  class:ca-bg--highlight-yellow={backgroundColor === "highlight-yellow"}
  class:ca-bg--midtone-green={backgroundColor === "midtone-green"}
  class:ca-bg--midtone-orange={backgroundColor === "midtone-orange"}
  class:ca-bg--light-green={backgroundColor === "light-green"}
  class:ca-bg--light-orange={backgroundColor === "light-orange"}
  class:ca-bg--light-beige={backgroundColor === "light-beige"}
  class:ca-bg--light-cream={backgroundColor === "light-cream"}
  class:ca-bg--light-blue={backgroundColor === "light-blue"}
  class:ca-bg--black={backgroundColor === "black" ||
    backgroundColor === "neutral-black"}
  class:ca-bg--neutral-white={backgroundColor === "neutral-white"}
  on:mouseenter={() => (isHovered = true)}
  on:mouseleave={() => (isHovered = false)}
>
  {#if image}
    <div class="card-classroom-featured-mini-media">
      <img
        width="1200"
        height="600"
        src={image.src || image.uri}
        alt={image.alt}
        class="ca-card__img"
      />
    </div>
  {/if}

  <div class="card-classroom-featured-mini-body">
    <div class="card-classroom-featured-mini-body-title-wrapper">
      {#if eyebrow}
        <Eyebrow classList="mb-2">{eyebrow}</Eyebrow>
      {/if}
      <Heading
        text={title}
        id=""
        tag="h2"
        font="text"
        size="2xl"
        align=""
        classList="align-left card-classroom-featured-mini-body-title clamp-title"
        contain={false}
      />
    </div>

    <div class="card-classroom-featured-mini-body-icon">
      <Button
        variant="classroom-circle-right-arrow"
        isParentHovered={isHovered}
        tag="span"
      />
    </div>
  </div>
</a>

<style lang="scss">
  .card-classroom-featured-mini-media {
    overflow: hidden;
    display: flex;
    .ca-card__img {
      object-fit: fill;
      object-position: center;
    }
  }
  .card-classroom-featured-mini-media img {
    aspect-ratio: 16 / 9;
    height: 100%;
  }
  .card-classroom-featured-mini {
    max-width: 700px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }

  .card-classroom-featured-mini :global(.clamp-title) {
    line-clamp: 2;
    -webkit-line-clamp: 2;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    flex-direction: column;
    text-overflow: ellipsis;
    text-wrap: pretty;
  }

  // mobile
  @media (max-width: 991px) {
    .card-classroom-featured-mini {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      margin-bottom: 2rem;
    }
    .card-classroom-featured-mini-body {
      position: relative;
    }

    .card-classroom-featured-mini-body-icon {
      position: absolute;
      left: 84%;
      top: -6.5rem;
    }
  }
</style>
