<script lang="ts">
  import Button from "../../Button/Button.svelte";

  export let heading;
  export let content;
  // export let tag;
  // export let features;
  export let buttons;
  // export let open = false;
  export let backgroundColor;
</script>

<div class="ca-price-card ca-price-card--wide ca-price-card--bottom">
  <div class={`ca-price-card__header ${backgroundColor}`}>
    <div class="d-flex align-items-center flex-wrap gap-2 mb-4">
      <div class="ca-price-card__heading ca-text-3xl ca-font-heading">
        {heading}
      </div>
    </div>

    <div class="ca-price-card__content">
      <div class="">
        <p>
          {@html content}
        </p>
      </div>

      <div class="flex-shrink-0">
        {#if buttons?.length > 0}
          {#each buttons as button}
            <Button {...button} />
          {/each}
        {/if}
      </div>
    </div>
  </div>
</div>
