<script lang="ts">
  import MarkdownIt from "markdown-it";
  import Heading from "../../Heading/Heading.svelte";
  import Button from "../../Button/Button.svelte";
  import { bgColors } from "$lib/constants";

  export let id = "";
  export let heading = {
    text: "",
    id: "",
  };

  export let text = "";
  export let media = [];
  export let buttons = [];

  let img = media ? media[0] : [];

  export let backgroundColor = bgColors["Light Beige"];

  const md = new MarkdownIt({
    breaks: true,
    html: true,
  });

  // set default heading if none set via CMS
  if (!heading.font) {
    heading.font = "heading";
  }

  const mediaToRight = img?.align?.toLowerCase() === "right";

  let screenSize;
</script>

<svelte:window bind:innerWidth={screenSize} />

<div class="ca-section">
  <div class="container-xl">
    <section
      class={`panel-container ${backgroundColor}`}
      class:panel-container-imageRight={mediaToRight}
      {id}
    >
      <div
        class="banner-image"
        style="background-image: url({img?.image?.url});"
      />
      <div class="header-content" class:imageRight={mediaToRight}>
        <div>
          <Heading text={heading.text} size="base" />
          <div
            class="banner-text text-md-start"
            class:imageRight={mediaToRight}
          >
            {@html md.render(text || "")}
          </div>
        </div>

        <div class="panelButtons">
          {#if buttons[0]}
            <Button {...buttons[0]} variant={"primary"} />
          {/if}
          {#if buttons[1]}
            <Button {...buttons[1]} icon="" variant={"text-bounce"} />
          {/if}
        </div>
      </div>
    </section>
  </div>
</div>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography" as *;
  @use "$sass/base/panels" as *;

  .ca-section {
    display: flex;
  }

  .container-xl {
    @extend .ca-panel-margins;
  }

  .panel-container {
    display: flex;
    flex-direction: column;
    gap: 0px;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      &.panel-container-imageRight {
        flex-direction: row-reverse;
      }
    }
  }
  .banner-image {
    flex-grow: 0;
    background-color: gray;
    flex-shrink: 1;
    aspect-ratio: 16/9;
    height: auto;
    width: 100%;

    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    @include media-breakpoint-up(lg) {
      flex-shrink: 0;
      width: 437px;
      height: auto;
    }
  }
  .header-content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 1.5rem;

    :global(.ca-text-base.ca-font-heading) {
      @include text-mobile-header-3;
      margin-bottom: 1rem;
      text-align: left !important;

      @include media-breakpoint-up(lg) {
        @include text-web-header-3;

        margin-bottom: 0.5rem;
        padding-right: 2rem;
      }
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      justify-content: center;
      padding-top: 1.875rem;
      padding-bottom: 1.875rem;
      padding-left: 3.875rem;
      padding-right: 0;

      &.imageRight {
        padding-left: 2.5rem;
      }

      :global(.ca-text-base.ca-font-heading) {
        padding-right: 2rem;
      }
    }
  }

  // Text below title
  .banner-text {
    @include text-mobile-body-1;

    @include media-breakpoint-up(lg) {
      @include text-web-body-1;
      padding-right: 9rem;

      &.imageRight {
        padding-right: 15rem;
      }
    }
  }

  .panelButtons {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 1rem;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      align-items: center;
      padding-right: 2rem;
      padding: 0;
      gap: 1.5rem;
      margin-top: 1.625rem;
    }

    :global(a.ca-link) {
      text-decoration: none;
      @include text-link;
      font-weight: 500;
      padding-top: 1.75rem;
      padding-bottom: 0.75rem;
      display: flex;
      align-items: center;

      @include media-breakpoint-up(lg) {
        padding: 0;
      }
    }
  }
</style>
