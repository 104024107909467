<script lang="ts">
  import Heading from "../../Heading/Heading.svelte";
  import PanelButtonsGroup from "./parts/PanelButtonsGroup.svelte";
  import Eyebrow from "./parts/Eyebrow.svelte";
  import Media from "./parts/Media.svelte";

  export let id = "";
  export let eyebrow = "";
  export let eyebrowTag;
  export let heading = {
    text: "",
    id: "",
    tag: "h2",
  };

  export let text = "";
  export let media = [];
  export let buttons = [];
  export let backgroundColor;

  let _media = media ? media[0] : [];

  let screenSize;
</script>

<svelte:window bind:innerWidth={screenSize} />

<section class="ca-section py-4 py-md-4_5 py-lg-6 {backgroundColor}" {id}>
  <div class="container-xl">
    <div class="panel-grid">
      {#if eyebrow}
        <div class="eyebrow">
          <Eyebrow tag={eyebrowTag} classList="ca-text-eyebrow mb-4">
            {eyebrow}
          </Eyebrow>
        </div>
      {:else}
        <!-- If removed content gets pushed below -->
        <div class="eyebrow" />
      {/if}
      <div class="heading mb-3">
        <Heading
          {...{
            size: "4xl",
            font: "heading",
            ...heading,
            align: "center",
          }}
          classList="text-center text-lg-start"
        />
      </div>
      {#if text}
        <div
          class="ca-split__intro text text-center text-lg-start mt-4 mt-lg-0 col-12 col-md-8 col-lg-12"
        >
          {text}
        </div>
      {/if}
      <div class="media">
        <Media media={_media} />
      </div>
      <div class="buttons mt-4">
        <PanelButtonsGroup {buttons} ucHero={true} />
      </div>
    </div>
  </div>
</section>

<style>
  .panel-grid {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-template-rows: 1fr repeat(4, min-content) 1fr;
    align-items: center;
    column-gap: 4%;
  }
  .eyebrow {
    grid-row: 2 / 3;
    grid-column: 1 / 2;
  }
  .heading {
    grid-row: 3 / 4;
    grid-column: 1 / 2;
  }
  .text {
    grid-row: 4 / 5;
    grid-column: 1 / 2;
  }
  .buttons {
    grid-row: 5 / 6;
    grid-column: 1 / 2;
  }
  .media {
    grid-row: 1 / 7;
    grid-column: 2 / 3;
    height: 100%;
    width: 100%;
  }

  /* mobile */
  @media (max-width: 991px) {
    .panel-grid {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      justify-items: center;
    }
    .eyebrow {
      grid-row: 1 / 2;
      grid-column: 1 / 2;
    }
    .heading {
      grid-row: 2 / 3;
      grid-column: 1 / 2;
    }
    .media {
      grid-row: 3 / 4;
      grid-column: 1 / 2;
    }
    .text {
      grid-row: 4 / 5;
      grid-column: 1 / 2;
    }
    .buttons {
      grid-row: 5 / 6;
      grid-column: 1 / 2;
    }
  }
</style>
