<script lang="ts">
  export let media;
  $: lazyload = !!media?.image?.lazyload;
</script>

{#if media?.type === "Embed"}
  {@html media.embed}
{/if}

{#if media?.type === "Image"}
  {#if lazyload}
    <img
      class:lazyload
      class="mx-auto mx-lg-0"
      data-src={media.image.url}
      alt={media.image.alt}
    />
  {:else}
    <img
      width="1440"
      height="1080"
      class="mx-auto mx-lg-0"
      src={media.image.url}
      alt={media.image.alt}
    />
  {/if}
{/if}
