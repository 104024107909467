<script lang="ts">
  import StatDefaultVariant from "./variants/StatDefaultVariant.svelte";
  import StatsBarVariant from "./variants/StatsBarVariant.svelte";

  export let prefix;
  export let value;
  export let suffix;
  export let text;
  /** @type {'lg' | 'stats-bar'} Variant */
  export let variant;
  export let decimalPlaces;
  export let separator;
  export let useCountUp;
  export let useConfetti;
  export let startValue;
  export let countUp;
  export let headingText;

  const variants = {
    default: StatDefaultVariant,
    lg: StatDefaultVariant,
    "stats-bar": StatsBarVariant,
  };

  const component = variants[variant] || variants["default"];
</script>

<svelte:component
  this={component}
  {prefix}
  {value}
  {suffix}
  {text}
  {variant}
  {decimalPlaces}
  {separator}
  {useConfetti}
  {startValue}
  {countUp}
  {useCountUp}
  {headingText}
/>
