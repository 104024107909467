<script lang="ts">
  import CookiePro from "./tags/CookiePro.svelte";
  import Google from "./tags/Google.svelte";
  import Munchkin from "./tags/Munchkin.svelte";
  import Mutiny from "./tags/Mutiny.svelte";
  import Bizible from "./tags/Bizible.svelte";
  import { PUBLIC_CARTA_SITE_ENVIRONMENT } from "$env/static/public";

  export let data;

  const { isUsingSequel } = data;
  const environment = PUBLIC_CARTA_SITE_ENVIRONMENT || "production";
</script>

<CookiePro />
<Google {environment} />
<Munchkin />
<Mutiny />
<Bizible {isUsingSequel} />
