<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";
  import Button from "$components/Button/Button.svelte";
  import Eyebrow from "$components/Panel/variants/parts/Eyebrow.svelte";

  export let title;
  export let content;
  export let image;
  export let url;
  export let backgroundColor;
  export let icon;
  export let eyebrow;

  let isHovered = false;
</script>

<a
  href={url}
  target="_self"
  class="card-classroom-featured"
  class:has-background-color={backgroundColor !== ""}
  class:ca-bg--highlight-green={backgroundColor === "highlight-green"}
  class:ca-bg--highlight-yellow={backgroundColor === "highlight-yellow"}
  class:ca-bg--midtone-green={backgroundColor === "midtone-green"}
  class:ca-bg--midtone-orange={backgroundColor === "midtone-orange"}
  class:ca-bg--light-green={backgroundColor === "light-green"}
  class:ca-bg--light-orange={backgroundColor === "light-orange"}
  class:ca-bg--light-beige={backgroundColor === "light-beige"}
  class:ca-bg--light-cream={backgroundColor === "light-cream"}
  class:ca-bg--light-blue={backgroundColor === "light-blue"}
  class:ca-bg--black={backgroundColor === "black" ||
    backgroundColor === "neutral-black"}
  class:ca-bg--neutral-white={backgroundColor === "neutral-white"}
  on:mouseenter={() => (isHovered = true)}
  on:mouseleave={() => (isHovered = false)}
>
  {#if image}
    <div class="card-classroom-featured-media">
      <img
        width="1200"
        height="600"
        src={image.src || image.uri}
        alt={image.alt}
        class="ca-card__img"
      />
    </div>
  {/if}

  <div class="card-classroom-featured-body">
    <div class="card-classroom-featured-body-title-wrapper">
      {#if eyebrow}
        <Eyebrow classList="mb-2">{eyebrow}</Eyebrow>
      {/if}
      <Heading
        {...{
          text: title,
          level: 2,
          font: "text",
          size: "2xl",
          classList: "align-left",
        }}
      />
    </div>
    <div class="card-classroom-featured-body-text">
      {@html content}
    </div>

    <div class="card-classroom-featured-body-icon">
      {#if icon === "play"}
        <svg
          width="60"
          height="60"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_b_341_2577)">
            <circle cx="30" cy="30" r="29.5" stroke="black" />
          </g>
          <path
            d="M41.2646 30.0114L23.7646 18.7614V41.2614L41.2646 30.0114Z"
            stroke="black"
            stroke-width="1.5"
          />
          <defs>
            <filter
              id="filter0_b_341_2577"
              x="-4"
              y="-4"
              width="68"
              height="68"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
              <feComposite
                in2="SourceAlpha"
                operator="in"
                result="effect1_backgroundBlur_341_2577"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_backgroundBlur_341_2577"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      {/if}

      <Button
        variant="classroom-circle-right-arrow"
        isParentHovered={isHovered}
        tag="span"
      />
    </div>
  </div>
</a>

<style lang="scss">
  .card-classroom-featured {
    max-width: 700px;
    display: flex;
    flex-direction: column;
  }

  .card-classroom-featured-body-title-wrapper {
    margin-top: 1.6rem;
  }

  .card-classroom-featured-body-text,
  .card-classroom-featured-body-icon {
    margin-top: 1rem;
  }
  .card-classroom-featured-body-text {
    font-size: clamp(1rem, 0.92rem + 0.4vw, 1.25rem);
    line-height: 1.5;
  }
  .has-background-color .card-classroom-featured-body {
    padding-right: 2rem;
    padding-bottom: 2rem;
    padding-left: 2rem;
  }
  // mobile
  @media (max-width: 991px) {
    .card-classroom-featured {
      max-width: 400px;
      margin-bottom: 2rem;
    }
    .card-classroom-featured-body {
      position: relative;
    }
    .card-classroom-featured-body-icon {
      position: absolute;
      top: -6rem;
      left: 84%;
    }
  }
</style>
