<script lang="ts">
  import { getFinalImageUrl } from "$lib/images/getContenfulImageUrl";

  export let src = "";
  export let alt = "";
  export let preferWebp = false;
  /**
   * Optinally change the image type to be returned. See `ImageFormat`.
   *
   * Contentful Images API:
   * @see https://www.contentful.com/developers/docs/references/images-api/
   * @type {import("./ContentfulImage.types").ImageFormat}
   **/
  export let imageType = "";
  /**
     * If `true` it renders the image inside a `<picture>` like:
	 * @example
    <picture>
        <source srcset={'some-image.png?fm=webp'} type="image/webp" />
        <img src={'some-image.png'} />
    </picture>
     */
  export let useProgressiveJPG = false;

  $: imgSrc = getFinalImageUrl({ imageType, useProgressiveJPG, src });
  $: imgWebpSrc = getFinalImageUrl({ imageType: "webp", src });
</script>

{#if preferWebp}
  <picture>
    <source srcset={imgWebpSrc} type="image/webp" />
    <img src={imgSrc} alt={alt || ""} {...$$restProps} />
  </picture>
{:else}
  <img src={imgSrc} alt={alt || ""} {...$$restProps} />
{/if}
