<script lang="ts">
  import Card from "../Card/Card.svelte";

  export let items;
  export let headingText;
  export let text;

  $: items = items || [];
  $: headingText = headingText || "";
  $: text = text || "";
</script>

<section class="ca-section ca-section-locations py-4 py-md-5 py-lg-7" id="">
  <div class="container-xl">
    {#if headingText || text}
      <div class="row">
        {#if headingText}
          <div class="col-12 col-md-10 offset-md-1">
            <h2 class="ca-section__title">{@html headingText}</h2>
          </div>
        {/if}
        {#if text}
          <div class="col-12 col-md-6 offset-md-3">
            <div class="ca-section__intro">
              <p>
                {@html text}
              </p>
            </div>
          </div>
        {/if}
      </div>
    {/if}
    <div class="row mt-4_5 mt-md-5 mt-lg-6">
      <div class="col-12 col-lg-10 offset-lg-1">
        <div class="ca-locations-grid">
          {#each items as item (item.title)}
            <Card {...item} />
          {/each}
        </div>
      </div>
    </div>
  </div>
</section>
