<script lang="ts">
  import Button from "$components/Button/Button.svelte";
  import Carousel from "$components/Carousel/Carousel.svelte";
  import CarouselItem from "$components/Carousel/CarouselItem.svelte";
  import { accentColors } from "$lib/constants";
  import { slugify } from "$lib/helpers";

  export let columns;
  const columnsLarge = 3;
  const columnsMedium = 3;
  const columnsSmall = 1;
  export let gridWidth;
  export let backgroundColor;
  export let bgColors;
  export let gridHero;
  export let gridWidthOptions;
  export let accentColor = accentColors["Light Beige"];
  export let BLOCKS;
  export let MAPPERS;
  export let buttons;

  $: cta = buttons?.[0];

  const headingId = slugify(gridHero?.heading || "");
</script>

<div
  class={`ca-section ${bgColors[backgroundColor]}`}
  style="--grid-accent-color: {accentColors[accentColor]};"
>
  <div
    class="container-xl"
    class:cmw-container-md={gridWidth === "XL - max width 1140px"}
  >
    <div class="hero-container">
      <h2 id={headingId}>{gridHero?.heading}</h2>
      <p class="hero-text">{gridHero?.text}</p>

      {#if cta}
        <div class="hero-cta-container">
          <Button {...cta} />
        </div>
      {/if}
    </div>
    <div class="cards-mobile">
      <Carousel variant="default">
        {#each columns as column}
          <CarouselItem>
            <svelte:component
              this={BLOCKS[column.__typename]}
              {...MAPPERS[column.__typename](column)}
            />
          </CarouselItem>
        {/each}
      </Carousel>
    </div>
    <div class="row cards-web">
      <div class={gridWidthOptions[gridWidth]}>
        <div class="row g-4">
          {#each columns as column}
            <div
              class="col"
              class:col-12={columnsSmall === 1}
              class:col-6={columnsSmall === 2}
              class:col-4={columnsSmall === 3}
              class:col-3={columnsSmall === 4}
              class:col-md-12={columnsMedium === 1}
              class:col-md-6={columnsMedium === 2}
              class:col-md-4={columnsMedium === 3}
              class:col-md-3={columnsMedium === 4}
              class:col-lg-12={columnsLarge === 1}
              class:col-lg-6={columnsLarge === 2}
              class:col-lg-4={columnsLarge === 3}
              class:col-lg-3={columnsLarge === 4}
            >
              <svelte:component
                this={BLOCKS[column.__typename]}
                {...MAPPERS[column.__typename](column)}
              />
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography_mixins" as *;

  .cards-web {
    display: none;
  }
  .ca-section {
    padding: 2rem 0;
  }
  .hero-container {
    text-align: center;
    margin-bottom: 2rem;

    h2 {
      @include text-mobile-header-3;
      margin-bottom: 2rem;
    }
    p.hero-text {
      @include text-mobile-body-1;
    }
  }
  :global(.row.carousel--variant-default) {
    margin-top: 2rem !important;
  }

  .hero-cta-container {
    margin-top: 2rem;
  }

  @include media-breakpoint-up(md) {
    .ca-section {
      padding: 3.125rem 0;
    }
    .cards-web {
      display: block;
    }
    .cards-mobile {
      display: none;
    }

    .hero-container {
      text-align: center;
      margin-bottom: 3.375rem;

      h2 {
        @include text-web-header-3;
        margin-bottom: 1rem;
      }
      p.hero-text {
        @include text-web-body-1;
        max-width: 700px;
        margin: 0 auto;
      }
    }
  }
</style>
