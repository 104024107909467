import { onMount } from "svelte";

export default function editPageInContentful(pageId: string) {
  return onMount(() => {
    const currentParams = new URLSearchParams(window.location.search);
    if (currentParams.has("edit")) {
      window.location.href = `https://app.contentful.com/spaces/y88td1zx1ufe/entries/${pageId}`;
    }
  });
}
