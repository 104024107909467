<script lang="ts">
  import Heading from "../../Heading/Heading.svelte";
  import Button from "../../Button/Button.svelte";
  import Eyebrow from "./parts/Eyebrow.svelte";

  export let eyebrow = "";
  export let eyebrowTag = "div";
  export let heading = {
    text: "",
    id: "",
  };

  export let text = "";

  export let buttons = [];

  heading.size = heading?.size?.toLowerCase() || "4xl";
</script>

<section class="section-classroom-hero">
  <div class="section-classroom-hero-content">
    {#if eyebrow}
      <Eyebrow tag={eyebrowTag}>{eyebrow}</Eyebrow>
    {/if}
    <Heading
      {...{
        font: "heading",
        align: "center",
        size: "4xl",
        tag: "h1",
        ...heading,
      }}
    />

    {#if text}
      <div class="ca-section__intro ca-text-body-1 ca-flow">
        {@html text}
      </div>
    {/if}

    {#if buttons.length}
      <div
        class="d-flex justify-content-center mt-4_5 mt-lg-6 g-4 gap-3 flex-wrap"
      >
        {#each buttons as button}
          <Button {...button} variant="text-circle-down-arrow" />
        {/each}
      </div>
    {/if}
  </div>
</section>

<style>
  .section-classroom-hero-content {
    max-width: 700px;
  }
  .section-classroom-hero {
    padding: 5rem 2rem 6rem;
    background-color: #fff3ec;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
