<script lang="ts">
  import MenuSubItem from "../../MenuSubItem.svelte";
  import SubMenuItemTitle from "../../SubMenuItemTitle.svelte";
  import Card from "../../../../Card/Card.svelte";
  export let item;

  // item.featuredItem = null;
</script>

<div class="static-rows-container container-xl">
  <div class={`col-${item?.featuredItem?.title ? 9 : 12} menus-container`}>
    {#each item.items as row}
      <div class="menu-group mb-3">
        <div class="title-container col-4">
          <SubMenuItemTitle
            item={{ ...row, title: row?.title?.toUpperCase() }}
          />
        </div>
        <ul class="sub-item-container">
          {#each row.items as col}
            <MenuSubItem item={col} variant="static-rows" />
          {/each}
          <div class="item-sideline" />
        </ul>
      </div>
    {/each}
  </div>
  {#if item?.featuredItem?.title}
    <div class="featitem-container col-3">
      <Card
        variant="nav-feat"
        {...item.featuredItem}
        backgroundColor={item.featuredItem.colorTheme}
      />
    </div>
  {/if}
</div>

<style lang="scss">
  .static-rows-container {
    display: flex;
    .menus-container {
      padding-right: 25px;
    }
    .menu-group {
      display: flex;
      flex-direction: column;
      .title-container {
        padding-right: 2%;
      }
      .sub-item-container {
        /* border: solid green 1px; */
        margin-top: 26px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        column-gap: 40px;

        @media (min-width: 992px) {
          // reset ul,ol
          padding: 0;
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
  }
  /* mobile */
  @media (max-width: 991px) {
    .static-rows-container {
      flex-direction: column;
      padding: 0;
      .menus-container {
        width: 100%;
      }
      .menu-group {
        .title-container {
          width: 100%;
        }
        .sub-item-container {
          display: flex;
          flex-direction: column;
          position: relative;
        }
        .item-sideline {
          position: absolute;
          background-color: #d8d8d8;
          top: 27px;
          top: 0px;
          left: 0;
          height: 82%;
          width: 1px;
        }
      }
      .featitem-container {
        width: 100%;
      }
    }
  }
</style>
