/**
 *  Wraps phrases inside `text` with `<mark>{phrase}</mark>` using case-sensitive match.
 *
 * @param {string} text Original text
 * @param {string[]} list List of phrases that want to be highlighted. They are case-sensitive.
 * @param {string} className Class to attach to the <mark> tag
 * @returns {string}
 */
export function highlightText(text, list, className = "") {
  if (!text || !list || list.length === 0) {
    return text;
  }

  /** @type {string} */
  let finalText = text;
  const classAttr = className ? `class="${className}"` : "";
  for (let hl of list) {
    finalText = finalText.replaceAll(hl, `<mark ${classAttr}>${hl}</mark>`);
  }

  return finalText;
}
