<script lang="ts">
  import Button from "$components/Button/Button.svelte";
  import Heading from "$components/Heading/Heading.svelte";
  import slugify from "slugify";
  import Eyebrow from "./parts/Eyebrow.svelte";
  import { highlightText } from "$lib/text.helpers";
  import { accentColors, bgColors } from "$lib/constants";

  export let heading = { text: "", size: "5xl" };
  export let text = "";
  export let media = [];
  export let eyebrow = "";
  export let buttons = [];
  export let titleHighlights = [];
  export let backgroundColor = bgColors["Light Beige"];
  export let accentColor;

  const _accentColor = accentColors[accentColor] || accentColors["Brown"];

  $: img = media[0]?.image;

  $: img = media[0]?.image;

  $: button1 = buttons[0];
  $: button2 = buttons[1];
  $: anyActions = button1 || button2;

  $: finalText = highlightText(heading?.text, titleHighlights);
</script>

<div class="wrapper {backgroundColor}">
  <div class="container-xl">
    <div class="panel-product-hero-split">
      <div class="hero-content" style="--highlights-color: {_accentColor};">
        {#if eyebrow}
          <Eyebrow tag="h1" classList="hero-content-eyebrow">{eyebrow}</Eyebrow>
        {/if}
        <Heading
          {...heading}
          size={heading?.size || "5xl"}
          text={finalText}
          classList="heading-text"
        />

        <div class="hero-content-text">
          <p>{text}</p>
        </div>

        {#if anyActions}
          <div class="hero-content-actions">
            {#if button1}
              <Button {...button1} variant={slugify(button1.variant)} />
            {/if}
            {#if button2}
              <Button {...button2} variant={"text-bounce"} />
            {/if}
          </div>
        {/if}
      </div>
      <div class="hero-media">
        {#if img?.url}
          <img width="100px" height="212px" src={img?.url} alt="" />
        {/if}
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography_mixins" as *;

  .wrapper {
    margin-bottom: 2rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 3.125rem;
    }

    @include media-breakpoint-down(sm) {
      .container-xl {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .panel-product-hero-split {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }
  }

  .hero-content {
    @include media-breakpoint-up(lg) {
      width: 55%;
      flex-basis: 55%;
      flex-shrink: 0;
      flex-grow: 1;
    }
  }
  .hero-media {
    display: flex;
    @include media-breakpoint-up(lg) {
      flex-basis: 40%;
      width: 40%;
      flex-shrink: 1;
      flex-grow: 0;
    }
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 3rem 1.5rem;

    @include media-breakpoint-up(lg) {
      justify-content: center;
      align-items: flex-start;
      padding-left: 0;

      padding-top: 3.75rem;
      padding-bottom: 3.75rem;
      padding-right: 0;
    }

    :global(.heading-text) {
      @include text-mobile-header-1;
      margin-bottom: 2rem;
      text-align: center;

      @include media-breakpoint-up(lg) {
        font-size: 3.625rem;
        margin-bottom: 2.625rem; // margin between title and text
        text-align: left;
      }
    }

    :global(.hero-content-eyebrow) {
      margin-bottom: 1.25rem;
      @include media-breakpoint-up(lg) {
        margin-bottom: 1.25rem;
      }
    }

    :global(mark) {
      color: var(--highlights-color);
      background: none;
    }
  }

  .hero-content-text {
    text-align: center;

    @include media-breakpoint-up(lg) {
      @include text-web-body-1;
      text-align: left;
      padding-right: 7.375rem;
    }
  }

  .hero-content-actions {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;

    :global(.ca-link) {
      display: flex;
      @include text-link;
      text-decoration: none;
      align-items: center;
    }

    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      flex-direction: row;
      margin-top: 2.625rem;
      gap: 1.875rem;
    }
  }

  .hero-media {
    img {
      width: 100%;
    }
    @include media-breakpoint-down(md) {
      padding: 2.5rem;
      padding-top: 0;
    }
    @include media-breakpoint-up(md) {
      display: flex;
      justify-content: center;
    }

    @include media-breakpoint-up(lg) {
      padding-left: 2rem;
      img {
        width: 100%;
        height: 100%;

        object-fit: contain;
        max-width: 522px;
      }
    }
  }
</style>
