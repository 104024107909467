// @ts-strict-ignore

import type { ContentfulTag } from "$types/graphql-schema";

/**
 * Parses ContentfulTag into group and name. Default divider is `:`.
 * If no group is present it returns the original tag name.
 *
 * @example
    parseTagGroup({ id: 'topicRegulation', name: 'Topic: Regulation' })
    // returns
    {
        group: 'Topic', // group
        cleanName: 'Regulation', // parsed name
        id: 'topicRegulation', name: 'Topic: Regulation' // original values
    }
 *
 */
export function parseTagGroup(
  contentfulTag: ContentfulTag,
  options = { divider: ":" },
) {
  const [group, ...rest] = (contentfulTag.name || "").split(options.divider);

  if (!contentfulTag.name?.includes(options.divider)) {
    return {
      ...contentfulTag,
      cleanName: contentfulTag.name,
      group: "",
    };
  }
  const cleanName = rest?.join("").trimStart();

  return {
    id: contentfulTag.id,
    name: contentfulTag.name,

    /** Parsed info */
    group,
    cleanName,
  };
}
