<script lang="ts">
  type CardLoginImage = {
    src: string;
    alt: string;
  };

  type CardLoginCta = {
    url: string;
    text: string;
  };

  export let image: CardLoginImage;
  export let cta: CardLoginCta;
</script>

<div class="login-card">
  <div class="img-container">
    <img src={image.src} alt={image.alt} />
  </div>
  <div class="login-button">
    <a href={cta.url} target="_self"
      ><span>{cta.text}</span>
      <svg
        width="20"
        height="17"
        viewBox="0 0 20 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M19.9918 8.60518L12.1484 0.76178L11.1766 1.73357L17.3609 7.91792L0.00842285 7.91791V9.29223L17.3611 9.29224L11.1766 15.4768L12.1484 16.4486L19.9918 8.60518Z"
          fill="currentColor"
        />
      </svg>
    </a>
  </div>
</div>

<style>
  .login-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: solid black 2px;
    border-radius: 5px;
    padding: 96px 32px 48px 32px;
  }
  .login-card .img-container {
    height: 72%;
    display: flex;
  }
  .login-card .img-container img {
    width: 200px;
  }
  .login-card .login-button {
    font-family: Plus Jakarta Sans;
    margin: 64px auto 0px auto;
    color: #fff;
    background-color: #000;
    border: none;
    border-radius: 100px;
    padding: 14px 20px;
    cursor: pointer;
    box-shadow: inset 0 0 0 1px #000;
    transition: 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: fit-content;
  }
  .login-card .login-button a {
    text-decoration: none;
    color: white;
    font-size: 0.9375rem;
    line-height: 1.2;
    display: flex;
    justify-content: center;
    gap: 18px;
    width: max-content;
  }
  .login-card .login-button a svg {
    stroke: currentColor;
  }
  .login-card .login-button:hover {
    border: solid blacka 1px;
    background-color: white;
  }
  .login-card .login-button:hover a {
    color: black;
  }
  .login-card .login-button:hover a svg {
    stroke: currentColor;
  }
</style>
