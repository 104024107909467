// @ts-strict-ignore
import Tabcordion from "../Tabcordion/Tabcordion.svelte";
import { HeadingConfig } from "$components/Heading/Heading.config";

export const TabcordionConfig = {
  name: "Tabcordion",
  component: Tabcordion,
  variants: {},
  mapper: (props) => {
    const heading = HeadingConfig.mapper(props.heading);
    const colorUpdates = {
      "light-blue": "Light Blue",
      "light-cream": "Light Cream",
    };
    const bgColor = colorUpdates[props?.bgColor] || props.bgColor;
    const items = props?.tabsCollection?.items.map((item) => {
      const heading = HeadingConfig.mapper(item.heading);
      const media = {
        ...item.media,
        alt: item?.media?.description || item?.media?.title,
      };
      return {
        ...item,
        heading,
        media,
        buttons: item?.buttonsCollection?.items || [],
      };
    });

    return {
      ...props,
      heading,
      items,
      bgColor,
    };
  },
  fragment: `
    fragment TabcordionData on Tabcordion {
        mediaAlignment
        bgColor:backgroundColor
        heading {
          ...HeadingData
        }
        text
        blockSettings {
          verticalPadding
          classnames
        }
        tabsCollection(limit: 6) {
          items {
            label
            slug
            heading {
                ...HeadingData
            }
            text
            buttonsCollection(limit: 3) {
              items {
                url
                text
                icon
                variant
              }
            }
            media {
              url
              width
              height
              title
              description
              contentType
            }
            embed {
              snippet
            }
          }
        }
    }`,
};
