<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";

  export let columns;
  export let columnsLarge;
  export let columnsMedium;
  export let columnsSmall;
  export let gridWidth;
  export let backgroundColor;
  export let accentColor;
  export let bgColors;
  export let gridHero;
  export let gridWidthOptions;
  export let BLOCKS;
  export let MAPPERS;
</script>

<div class={`resources-grid ca-section ${bgColors[backgroundColor]}`}>
  <div
    class="container-xl"
    class:cmw-container-md={gridWidth === "XL - max width 1140px"}
  >
    <Heading
      {...{
        text: gridHero.heading,
        size: "3xl",
        classList: "align-text-left mb-4_5",
      }}
    />
    <div class="row">
      <div class={gridWidthOptions[gridWidth]}>
        <div class="row g-md-4 g-lg-5">
          {#each columns as column}
            <div
              class="col"
              class:col-12={columnsSmall === 1}
              class:col-6={columnsSmall === 2}
              class:col-4={columnsSmall === 3}
              class:col-3={columnsSmall === 4}
              class:col-md-12={columnsMedium === 1}
              class:col-md-6={columnsMedium === 2}
              class:col-md-4={columnsMedium === 3}
              class:col-md-3={columnsMedium === 4}
              class:col-lg-12={columnsLarge === 1}
              class:col-lg-6={columnsLarge === 2}
              class:col-lg-4={columnsLarge === 3}
              class:col-lg-3={columnsLarge === 4}
            >
              <svelte:component
                this={BLOCKS[column.__typename]}
                {...MAPPERS[column.__typename]({
                  ...column,
                  backgroundColor: accentColor,
                })}
              />
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .resources-grid {
    padding: 50px 0px;
  }
  //   mobile
  @media (max-width: 992px) {
    .resources-grid {
      padding: 32px 0px;
    }
  }
</style>
