<script lang="ts">
  import slugify from "slugify";
  import "./heading.css";

  import headingContain from "./parts/headingContain.svelte";
  import headingDefault from "./parts/headingDefault.svelte";

  // These types be stricter, just trying to reduce errors right now
  export let text: string | null = null;
  export let id: string | null = null;
  export let tag: string | null = null;
  export let font: string | null = null;
  export let size: string | null = null;
  export let align: string | null = null;
  // This should be cleaned up, but we've got a broad net to ensure existing use continues to work
  export let classList: (string | null)[] | string | null | undefined = null;

  export let contain: string | boolean | undefined | null = false;

  $: text = text || "";
  $: id = id || slugify(text?.toLowerCase());
  $: tag = tag || "h2";
  $: font = font || "heading";
  $: size = size ? size.toLowerCase() : "2xl";
  $: align = align || "";
  $: classList = classList || "text-center";
  // Cleaning up some messy classList values to ensure pre-typescript usage doesn't break
  if (Array.isArray(classList)) {
    classList = classList.filter(Boolean).join(" ");
  }
  $: contain = Boolean(contain);
  $: component = contain ? headingContain : headingDefault;
</script>

{#if text}
  <svelte:component this={component}>
    <svelte:element
      this={tag}
      class:ca-text-sm={size === "sm"}
      class:ca-text-base={size === "base"}
      class:ca-text-xl={size === "xl"}
      class:ca-text-2xl={size === "2xl"}
      class:ca-text-3xl={size === "3xl"}
      class:ca-text-4xl={size === "4xl"}
      class:ca-text-5xl={size === "5xl"}
      class:ca-font-heading={font === "heading"}
      class:ca-font-text={font === "text"}
      class={classList}
      {id}
    >
      {@html text}
    </svelte:element>
  </svelte:component>
{/if}
