// @ts-strict-ignore
export function generateTestSessions(testCase = "start") {
  const totalSessions = Math.floor(Math.random() * (10 - 5 + 1)) + 5; // Simplified for example
  let startTime;
  const now = new Date();

  switch (testCase) {
    case "start":
      startTime = new Date(now.getTime() + 60 * 60000); // All sessions in the future
      break;
    case "mid":
      // Start time adjusted so half of the sessions are in the past and two are currently active
      startTime = new Date(now.getTime() - (totalSessions / 4) * 30 * 60000);
      break;
    case "end":
      startTime = new Date(now.getTime() - totalSessions * 30 * 60000); // All sessions in the past
      break;
    default:
      startTime = new Date(now.getTime() + 60 * 60000);
  }

  const sessions = [];
  let isConcurrentSessionAdded = false;

  for (let i = 0; i < totalSessions; i++) {
    let start = new Date(startTime.getTime() + i * 30 * 60000);
    let end = new Date(start.getTime() + 30 * 60000);

    // For 'mid' case, insert two concurrent active sessions at the current time
    if (
      testCase === "mid" &&
      !isConcurrentSessionAdded &&
      now >= start &&
      now < end
    ) {
      // Insert two concurrent sessions with the same start and end time as now
      const activeStart = new Date(now.getTime());
      const activeEnd = new Date(now.getTime() + 30 * 60000);

      sessions.push(createSession(i + 1, activeStart, activeEnd));
      sessions.push(createSession(i + 1.5, activeStart, activeEnd)); // Mark as additional session

      isConcurrentSessionAdded = true;
      continue;
    }

    sessions.push(createSession(i + 1, start, end));
  }

  return sessions;
}

function createSession(index, start, end) {
  const speakers = generateSpeakers();
  return {
    __typename: "AgendaSession",
    startTime: start.toISOString(),
    endTime: end.toISOString(),
    title: `Session ${index}`,
    description: null,
    url: `https://example.com/session/${index}`,
    speakersCollection: {
      items: speakers,
    },
    speakers: speakers,
  };
}

function generateSpeakers() {
  const speakers = [];
  const totalSpeakers = Math.floor(Math.random() * 4) + 1; // Generates 1 to 4 speakers per session
  for (let i = 0; i < totalSpeakers; i++) {
    speakers.push({
      __typename: "Person",
      name: `Speaker ${i + 1}`,
      companyUrl: `/innovators-summit/speaker-bios/#Speaker-${i + 1}`,
      companyName: null,
    });
  }
  return speakers;
}

export function formatDateTime(start, end) {
  const options = { hour: "2-digit", minute: "2-digit", timeZoneName: "short" };
  return `${start.toLocaleTimeString("en-US", options)} - ${end.toLocaleTimeString("en-US", options)}`;
}

export function getSessionStatus(now, start, end) {
  if (now >= start && now < end) return "active";
  if (now >= end) return "past";
  return "upcoming";
}
