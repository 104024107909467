<script lang="ts">
  import Carousel from "./Carousel.svelte";
  import CarouselItem from "./CarouselItem.svelte";

  import { innerBlocks } from "../index";

  export let headingText;
  export let headingLevel;
  export let headingSize;
  export let text;
  export let variant;
  export let navigation;
  export let slides;
  export let colorTheme;

  let heading;

  $: heading = {
    text: headingText,
    tag: headingLevel?.toLowerCase(),
    classList: "text-center",
    size: headingSize || "2xl",
  };

  let useBgPy = !colorTheme?.includes("null");

  let usePy = variant !== "customer-quotes";
</script>

<section
  style="--colorTheme: var(--clr-{colorTheme}, --clr-neutral-white);"
  class="ca-section cmw-carousel"
  class:py-4={usePy}
  class:py-lg-4_5={usePy}
>
  <div class="container-xl">
    <div
      class:cmw-carousel-bg={variant === "default"}
      class:pt-4={useBgPy}
      class:pb-6={useBgPy}
      class:px-4={useBgPy}
    >
      {#if slides}
        <Carousel {variant} {navigation} {heading} {text}>
          {#each slides as item}
            {@const { mapper, component } =
              innerBlocks[item.__typename]?.[item.variant] ||
              innerBlocks[item.__typename]?.default ||
              {}}
            <CarouselItem>
              <svelte:component this={component} {...mapper(item)} />
            </CarouselItem>
          {/each}
        </Carousel>
      {/if}
    </div>
  </div>
</section>

<style>
  .cmw-carousel-bg {
    background-color: var(--colorTheme);
  }
</style>
