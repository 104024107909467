<script lang="ts">
  import { addLocaleToUrl } from "$lib/helpers";
  import { monthYearFormat } from "$lib/dateUtils.js";
  import { bgColors } from "$lib/constants";
  import { page } from "$app/stores";
  import Button from "$components/Button/Button.svelte";

  export let date;
  export let title;
  export let content;
  export let cta;
  export let image;
  export let backgroundColor;
  export let authors;
  let isHovered = false;

  const displayDate = new Date(date).toLocaleDateString($page?.data.locale, {
    year: "numeric",
    month: "long",
  });
  const dateString = monthYearFormat(displayDate);
</script>

<div class={`text-decoration-none main-container ${bgColors[backgroundColor]}`}>
  <a
    class={`container-xl p-0`}
    href={addLocaleToUrl(cta.url, $page?.data.locale)}
    data-sveltekit-reload
    target="_self"
    on:mouseenter={() => (isHovered = true)}
    on:mouseleave={() => (isHovered = false)}
  >
    <div class="grid-container">
      <div class="media-container" class:no-media={image?.src}>
        {#if image?.src}
          <img
            width="1440"
            height="1080"
            class="mx-auto mx-lg-0"
            src={image.src}
            alt={image.alt}
          />
        {/if}
      </div>
      <div class="content-container py-4">
        {#if authors[0]}
          {@const mainAuthor = authors[0]}
          {@const secondaryAuthor = authors[1]}
          <div class="author-container">
            {#if mainAuthor?.avatar?.url && authors.length === 1}
              <div class="author-image">
                <img
                  src={mainAuthor?.avatar?.url}
                  alt={mainAuthor?.avatar?.description || mainAuthor?.name}
                />
              </div>
            {/if}
            <div class="author-info">
              <div>
                <span>{authors?.length > 1 ? "Authors" : "Author"}:</span>
                {authors[0].name}
                {#if secondaryAuthor}
                  &amp; {secondaryAuthor.name}
                {/if}
              </div>
              <div>
                <span>Date:</span>
                {dateString}
              </div>
            </div>
          </div>
        {/if}
        <div class="ca-text-eyebrow mb-2">featured article</div>

        <div class="heading-container">
          {title}
        </div>

        {#if content}
          <div class="text-container line-clamp">
            {@html content}
          </div>
        {/if}

        <Button
          variant="classroom-circle-right-arrow"
          isParentHovered={isHovered}
          tag="span"
        />
      </div>
    </div>
  </a>
</div>

<style lang="scss">
  @use "$sass/base/typography_mixins" as *;
  .main-container {
    margin: auto;
    .grid-container {
      display: grid;
      justify-items: end;
      grid-template-columns: 48% 1fr;
      column-gap: 40px;
    }
    .media-container {
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 1rem;
    }
    .content-container .author-container {
      display: flex;
      align-items: center;
      gap: 14px;
      margin-bottom: 40px;
      .author-image {
        height: 90px;
        width: 90px;
        border-radius: 50%;
        overflow: hidden;
      }
      .author-info {
        @include text-body-2;
        display: flex;
        flex-direction: column;
        span {
          font-weight: 500;
        }
      }
    }
    .content-container .heading-container {
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 1rem;
    }
    .content-container .text-container {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }
    .content-container .social-media-container {
      margin: 30px 0 0 -12px;
    }
  }
  // mobile
  @media (max-width: 991px) {
    .main-container {
      display: block;
      max-width: 400px;
      .grid-container {
        display: unset;
      }
      .content-container {
        padding: 0px 20px;
        .heading-container {
          font-size: 22px;
          line-height: 28px;
        }
        .text-container {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }
</style>
