<script lang="ts">
  import { onMount } from "svelte";
  import Swiper, { Navigation, Pagination } from "swiper";
  import Heading from "../../Heading/Heading.svelte";

  import "swiper/css";
  import "swiper/css/pagination";
  import "../Carousel.swiper.scss";

  export let id;
  export let navigation = {
    align: "center",
  };
  export let heading;

  let swiperRef;
  let swiperPrevRef;
  let swiperNextRef;
  let swiperPaginationRef;

  onMount(() => {
    new Swiper(swiperRef, {
      modules: [Navigation, Pagination],
      pagination: {
        el: swiperPaginationRef,
        type: "progressbar",
      },
      navigation: {
        nextEl: swiperNextRef,
        prevEl: swiperPrevRef,
      },
      loop: false,
      slidesPerView: 1,
      spaceBetween: 32,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
    });
  });
</script>

<section class="ca-section py-4 swiper-home-carousel-2 py-lg-4_5" {id}>
  <div class="container-xl">
    <div class="row">
      <div class="col-12">
        {#if heading}
          <Heading {...heading} />
        {/if}
        <div
          class="swiper swiper--has-pagination"
          class:mt-4_5={heading}
          class:mt-md-5={heading}
          class:mt-lg-7={heading}
          bind:this={swiperRef}
        >
          <div class="swiper-wrapper">
            <slot />
          </div>
        </div>
      </div>
    </div>

    {#if navigation.align === "center"}
      <div
        class="ca-swiper-controls mt-4_5 mt-lg-5 d-flex justify-content-center align-items-center mx-auto"
      >
        <div class="ca-swiper-pagination" bind:this={swiperPaginationRef} />
        <div class="ca-swiper-navigation">
          <div class="swiper-button-prev" bind:this={swiperPrevRef} />
          <div class="swiper-button-next" bind:this={swiperNextRef} />
        </div>
      </div>
    {:else if navigation.align === "left"}
      <div class="row">
        <div class="col-12 col-lg-5">
          <div class="ca-swiper-controls">
            <div class="ca-swiper-pagination" bind:this={swiperPaginationRef} />
            <div class="ca-swiper-navigation">
              <div class="swiper-button-prev" bind:this={swiperPrevRef} />
              <div class="swiper-button-next" bind:this={swiperNextRef} />
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
</section>
