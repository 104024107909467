<script lang="ts">
  import "../BlockQuote.scss";

  import Button from "$components/Button/Button.svelte";

  export let backgroundColor;
  export let align;
  export let quote;
  export let author;
  export let cta;

  $: backgroundColor = backgroundColor || "light-blue";
  $: align = align || "center";
  $: quote = quote || "";
  $: author = author || {};
  $: cta = cta || {};

  const colors = {
    "light-green": "ca-bg--light-green",
    "light-orange": "ca-bg--light-orange",
    "light-beige": "ca-bg--light-beige",
    "light-cream": "ca-bg--light-cream",
    "light-blue": "ca-bg--light-blue",
    black: "ca-bg--black",
  };
</script>

<section class="ca-section py-4 py-lg-4_5">
  <div class="container-xl">
    <div class="row">
      <div
        class={`col-12 col-lg-8 offset-lg-2 py-4_5 px-4_5 px-md-5 px-lg-7 ${colors[backgroundColor]}`}
        class:text-center={align === "center"}
      >
        <blockquote class="block-quote">
          {quote}
        </blockquote>

        {#if author?.image?.src}
          <div class="ca-quote-avatar mx-auto mt-4_5 mt-md-5">
            <img
              alt="Author avatar"
              width="800"
              height="800"
              src={author?.image?.src}
            />
          </div>
        {/if}

        {#if author?.name}
          <div class="mt-3 mt-lg-4 ca-text-body-1">
            {author?.name}
          </div>
        {/if}

        {#if author?.title}
          <div class="ca-quote__position mt-2">
            {author?.title}
          </div>
        {/if}

        {#if cta.text && cta.url}
          <div class="ca-quote__cta mt-3">
            <Button type="link" text={cta.text} url={cta.url} />
          </div>
        {/if}
      </div>
    </div>
  </div>
</section>
