<script lang="ts">
  export let image;
  export let content;
  export let title;
</script>

<div class="card-wrapper card-features-with-image">
  <div class="image-container">
    <img src={image?.src} alt={image?.alt || ""} />
  </div>
  <div class="heading">
    {title}
  </div>
  <div class="content">
    {content}
  </div>
</div>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography_mixins" as *;

  .card-features-with-image {
    .heading {
      color: var(--grid-accent-color);
      @include text-mobile-body-1;
      font-weight: 500;
    }
    .content {
      @include text-mobile-body-1;
    }
    .image-container {
      aspect-ratio: 1/1;
      object-fit: contain;
      width: auto;
      margin-bottom: 2rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: bottom;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .card-features-with-image {
      display: flex;
      flex-direction: column;

      .heading {
        @include text-web-body-1;
        font-weight: 500;
      }
      .content {
        @include text-web-body-1;
      }
      .image-container {
        aspect-ratio: 1/1;
        object-fit: contain;
        width: auto;
        margin-bottom: 1.5rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: bottom;
        }
      }
    }
  }
</style>
