// @ts-strict-ignore
import Tabs from "$components/Tabs/Tabs.svelte";

export const TabsConfig = {
  name: "Tabs",
  component: Tabs,
  variants: {},
  mapper: (props) => {
    const result = {
      bgColor: props?.colors?.backgroundColor,
      accentColor: props?.colors?.accentColor,
      tabs: props?.tabsCollection?.items?.map((tab) => ({
        title: tab.tabTitle,
        content: tab.text,
        image: {
          src: tab.image?.image?.url,
          alt: tab.image?.image?.description || tab.image?.image?.title,
        },
        list: tab?.listItemsCollection?.items?.map((listItem) => ({
          title: listItem.heading,
          text: listItem.text,
          icon: listItem.icon,
        })),
      })),
    };
    return result;
  },
  fragment: `fragment TabsData on Tabs {
        colors {
            backgroundColor
            accentColor
        }
        tabsCollection(limit: 20) {
          items {
            tabTitle
            text
            image {
              image {
                url
                description
                title
              }
            }
            listItemsCollection(limit: 8) {
              items {
                __typename
                ... on MediaWrapper {
                  heading
                  text
                  icon
                }
              }
            }
          }
        }
    }`,
};
