<script lang="ts">
  import { BLOCKS, MAPPERS } from "./ContenfulBlockParser";

  export let block;

  // will refactor this later to be cleaner
  if (block?.__typename === "Card") {
    if (
      block?.variant === "block-quote" ||
      block?.variant === "block-quote-classroom"
    ) {
      block.__typename = "BlockQuote";
    }
  } else if (block?.__typename === "EventAgenda") {
    block.__typename = "Agenda";
  } else if (block?.__typename === "CardsGrid") {
    if (
      block?.variant === "PricingGrid" ||
      block?.variant === "PricingGridFlexible"
    ) {
      block.__typename = "PricingGrid";
    } else if (block?.variant === "SectionLocations") {
      block.__typename = "SectionLocations";
    }
  }
</script>

{#if block?.__typename === "Group"}
  {#if block?.headScripts}
    <div style="display: none;">
      {@html block?.headScripts || ""}
    </div>
  {/if}

  <div
    class="cmw-group {block?.classAttribute || ''}"
    id={block?.idAttribute || block?.sys?.id}
  >
    {#each block?.innerBlocksCollection?.items as item}
      <svelte:component this={BLOCKS["Panel"]} {...MAPPERS["Panel"](item)} />
    {/each}
  </div>
{/if}

{#if block?.__typename === "AnchorLink"}
  <div id={block.slug} />
{/if}

{#if BLOCKS[block?.__typename]}
  <svelte:component
    this={BLOCKS[block.__typename]}
    {...MAPPERS[block.__typename](block)}
  />
{/if}
