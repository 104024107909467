import { isWordpressPDFAsset } from "$lib/links/wordpress";
import { locales, type SupportedLocale } from "$lib/constants";
import type { NodeTypeHyperlink } from "./RichText.types";

/**
 * Gets the modified href link for 'hyperlink' nodes
 */
export function getNodeHref(
  node?: NodeTypeHyperlink,
  { locale, ogUri }: { locale?: SupportedLocale; ogUri?: string } = {},
) {
  let href;
  if (!node?.data?.uri) return;

  const localePrefix = locale ? locales[locale] : "";
  const isAbsoluteLink = node.data.uri.includes("http");
  const isAnchorLink = node.data.uri[0] === "#";
  const isWordpressAsset = isWordpressPDFAsset(node.data.uri);
  const isMailtoLink = /^mailto[:]/.test(node.data.uri);

  if (isAbsoluteLink || isMailtoLink || isWordpressAsset) {
    href = node.data.uri;
    // anchor tags were only auto adding URI for US, this seems to fix it
  } else if (isAnchorLink) {
    // this is an href to a section in the current page
    if (ogUri && localePrefix) {
      href = `${localePrefix}/blog/${ogUri}/${node.data.uri}`;
    } else {
      href = `${node.data.uri}`;
    }
  } else {
    href = `${localePrefix}${node.data.uri}`;
  }

  return href;
}

export function getHrefTarget(href: string | null): "_self" | "_blank" | null {
  if (!href) return null;

  try {
    const isAbsolute =
      href.startsWith("https://") || href.startsWith("http://");
    const isCartaDomain = href.includes("carta.com");

    return isAbsolute && !isCartaDomain ? "_blank" : "_self";
  } catch {
    return null;
  }
}

export function isCardBlock(block?: { __typename: string }) {
  const entryType = block?.__typename;
  const isCardType = entryType === "Card";
  /*
   * __typename can get re-assigned during runtime from Card to respective Card variant e.g. BlockQuote.
   * See ContentfulBlockParser.svelte
   */
  return isCardType || entryType === "BlockQuote";
}

function isBlockQuoteCard(
  node: { nodeType: string },
  block?: { __typename: string; variant: string },
) {
  if (node.nodeType !== "embedded-entry-block") return false;

  // Allow BlockQuote card variant
  const entryType = block?.__typename;
  const isCardType = entryType === "Card";
  const isBlockQuoteType = isCardType || entryType === "BlockQuote";

  return (
    (isCardType || isBlockQuoteType) &&
    block?.variant === "block-quote-classroom"
  );
}

export function isAllowedCardVariant(node: any, block: any) {
  // Here we can add more card variants as they get requested
  return isBlockQuoteCard(node, block);
}
