// @ts-strict-ignore
import {
  MarketoFormConfig,
  HeadingConfig,
  StatConfig,
  StatsBarConfig,
  SpacerConfig,
  PanelConfig,
  ButtonConfig,
  LogoBarConfig,
  VideoBlockConfig,
  FinePrintTextConfig,
  GridConfig,
  FeaturedMediaTextListConfig,
  TabcordionConfig,
  AccordionConfig,
  EmbedConfig,
  CarouselConfig,
  BlockQuoteConfig,
  PricingCardConfig,
  CardsGridConfig,
  PricingGridConfig,
  CardConfig,
  SectionLocationsConfig,
  BlogCTAConfig,
  AgendaConfig,
  SubNavConfig,
  EventGuideConfig,
  TabsConfig,
} from "../index.js";

export const config = [
  MarketoFormConfig,
  HeadingConfig,
  StatConfig,
  StatsBarConfig,
  SpacerConfig,
  PanelConfig,
  ButtonConfig,
  LogoBarConfig,
  VideoBlockConfig,
  FinePrintTextConfig,
  GridConfig,
  FeaturedMediaTextListConfig,
  TabcordionConfig,
  AccordionConfig,
  EmbedConfig,
  CarouselConfig,
  BlockQuoteConfig,
  PricingCardConfig,
  CardsGridConfig,
  PricingGridConfig,
  CardConfig,
  SectionLocationsConfig,
  BlogCTAConfig,
  AgendaConfig,
  SubNavConfig,
  EventGuideConfig,
  TabsConfig,
];

export const BLOCKS = config.reduce((acc, { name, component }) => {
  acc[name] = component;

  return acc;
}, {});

export const VARIANTS = config.reduce((acc, { name, variants }) => {
  if (acc[name]) {
    acc[name] = variants;
  }

  return acc;
}, {});

export const MAPPERS = config.reduce((acc, { name, mapper }) => {
  acc[name] = mapper;

  return acc;
}, {});

// testing to fix tabbing issue
