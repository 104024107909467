<script lang="ts">
  import Panel from "../../Panel/Panel.svelte";
  import AgendaRow from "../AgendaRow.svelte";
  import "../Agenda.css";

  export let hero;
  export let title;
  export let text;
  export let agenda;
  export let mainTabName;
  export let colorTheme = "light-blue";
</script>

<section
  style="--color:  var(--clr-{colorTheme})"
  class="ca-section pb-5 pb-lg-7"
  class:pt-5={!hero}
  class:pt-lg-7={!hero}
  class:color-theme-dark={colorTheme.includes("black")}
>
  {#if hero}
    <Panel {...hero} />
  {/if}
  <div class="container-xl">
    {#if title || text}
      <div class="row">
        {#if title}
          <div class="col-12 col-md-6 offset-md-3">
            <h2 class="ca-text-4xl ca-font-heading text-center">{title}</h2>
          </div>
        {/if}
        {#if text}
          <div class="col-12 mb-3">
            <div class="ca-section__intro ca-text-body-1 ca-flow agenda-text">
              {text}
            </div>
          </div>
        {/if}
      </div>
    {/if}

    {#if agenda}
      <div class="agenda-tabs">
        <div class="agenda-tabs-inner">
          <button class="agenda-tab-item">
            {mainTabName || "All days"}
          </button>
        </div>
      </div>

      <div class="agenda mt-4_5">
        {#each agenda as row}
          <AgendaRow data={row} activeTab="all-days" />
        {/each}
      </div>
    {/if}
  </div>
</section>
