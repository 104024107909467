<script lang="ts">
  import Heading from "../../Heading/Heading.svelte";

  import Stat from "../../Stat/Stat.svelte";

  export let id = "";
  export let heading = {
    text: "",
    id: "",
  };

  export let stats = [];

  let [s1, ...ss] = stats;
</script>

<section
  class="ca-section ca-section-stats py-4 py-md-5 py-lg-7 text-center"
  {id}
>
  <div class="container-xl">
    <div class="row">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <Heading
          {...{
            ...{
              font: "heading",
              size: "4xl",
            },
            ...heading,
          }}
          classList="ca-section__title"
        />
        {#if ss.length}
          <div class="ca-section__intro" />
          <div class="mt-5">
            <Stat {...s1} />
          </div>
        {/if}
      </div>
    </div>
    {#if ss.length}
      <div class="row mt-5">
        <div class="col-6 offset-3 | col-lg-8 offset-lg-2">
          <div class="row">
            {#each ss as s}
              <div class="col-12 col-lg-4">
                <Stat {...s} />
              </div>
            {/each}
          </div>
        </div>
      </div>
    {/if}
  </div>
</section>
