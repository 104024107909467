<script lang="ts">
  export let title;
  export let content;
  export let image;
  export let url;
</script>

<svelte:element
  this={url ? "a" : "div"}
  data-sveltekit-reload
  class="ca-ces-speaker"
  href={url ? url : null}
  target={url ? "_self" : null}
>
  <div class="ca-ces-speakers-grid__avatar">
    <img
      loading="lazy"
      src={image.src}
      alt={image.alt}
      width="200"
      height="200"
      title="Home"
    />
  </div>
  <div class="ca-ces-speakers-grid__header mt-4">{title}</div>
  <div class="ca-ces-speakers-grid__subheader mt-2">{content}</div>
</svelte:element>
