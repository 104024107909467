<script lang="ts">
  import Media from "./parts/Media.svelte";
  import take from "lodash/take";

  export let id = "";
  export let eyebrow;
  export let heading;
  export let text = "";
  export let backgroundColor = "ca-bg--light-cream";
  export let media;

  // Social links
  export let twitterUrl;
  export let linkedInUrl;
  export let email;
  export let websiteUrl;
  export let alternateWebsiteUrl;

  /* At most show 3 social buttons */
  const MAX_SOCIAL_LINKS = 3;
  /* Cutoff point for mobile */
  const MAX_TEXT_LENGTH = 120;

  const socialLinks = take(
    [
      email && {
        link: `mailto:${email}`,
        icon: "ca-social-icon--email",
        alt: "Email",
      },
      twitterUrl && {
        link: twitterUrl,
        icon: "ca-social-icon--tw",
        alt: "Twitter Profile",
      },
      linkedInUrl && {
        link: linkedInUrl,
        icon: "ca-social-icon--ln",
        alt: "LinkedIn Profile",
      },
      websiteUrl && {
        link: websiteUrl,
        icon: "ca-social-icon--web",
        alt: "Website",
      },
      alternateWebsiteUrl && {
        link: alternateWebsiteUrl,
        icon: "ca-social-icon--web-alt",
        alt: "Website",
      },
    ].filter(Boolean),
    MAX_SOCIAL_LINKS,
  );

  let shortText =
    text.length > MAX_TEXT_LENGTH
      ? `${text.slice(0, MAX_TEXT_LENGTH).split(" ").slice(0, -1).join(" ")}...`
      : text;
  let buttonText = "Show more";
</script>

<!-- need to fix href to include locale. might be able to use svelte page data -->
<div class="main-container text-decoration-none {backgroundColor} {id}">
  <div class="container-xl">
    <div class="grid-container">
      <div class="media-container" class:no-media={!media.image?.url}>
        {#if media.image?.url}
          <Media {media} />
        {:else}
          <div class="initials">
            {heading
              .split(" ")
              .map((word) => word[0])
              .join("")
              .slice(0, 2)}
          </div>
        {/if}
      </div>
      <div class="content-container">
        {#if eyebrow}
          <div class="ca-text-eyebrow mb-3">{eyebrow}</div>
        {/if}
        <div class="heading-container">
          {heading}
        </div>

        <div class="text-container">
          {@html text}
        </div>

        <div class="text-container-mobile">
          {#if buttonText === "Show less"}
            {@html text}
          {:else}
            {@html shortText}
          {/if}
          <button
            class:open={buttonText === "Show less"}
            class:hide={shortText === text}
            class="read-more-button"
            on:click={() => {
              buttonText =
                buttonText === "Show more" ? "Show less" : "Show more";
            }}
          >
            <span>{buttonText}</span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.2065 9.02826L12.3391 15.3674C12.1476 15.5442 11.8524 15.5442 11.6609 15.3674L4.79346 9.02826L5.47174 8.29346L12 14.3195L18.5283 8.29346L19.2065 9.02826Z"
                fill="black"
              />
            </svg>
          </button>
        </div>

        <div class="social-media-container">
          <div class="container-xl">
            <div
              class="ca-social-widget justify-content-center justify-content-lg-start"
            >
              {#each socialLinks as item}
                <a
                  href={item.link}
                  class="ca-social-icon {item.icon}"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span class="visually-hidden">{item.alt}</span>
                </a>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  .main-container {
    padding: 77px 0 40px 0;
    .grid-container {
      display: grid;
      justify-items: end;
      grid-template-columns: 30% 1fr;
      column-gap: 70px;
    }
    .media-container {
      height: 200px;
      width: 200px;
      border-radius: 50%;
      overflow: hidden;
      &.no-media {
        background-color: #fadfcc;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .media-container .initials {
      font-family: SangBleu Versailles;
      font-size: 72px;
      font-weight: 400;
      line-height: 106px;
      letter-spacing: 0.1em;
      text-align: center;
    }
    .content-container {
      width: 100%;
      max-width: 630px;
      margin-right: auto;
    }
    .content-container .heading-container {
      font-family: SangBleu Versailles;
      font-size: 50px;
      font-weight: 400;
      line-height: 58px;
      margin-bottom: 1rem;
    }
    .content-container .text-container {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }
    .content-container .social-media-container {
      margin: 30px 0 0 -12px;
      display: flex;
      gap: 1rem;
    }
    .content-container .text-container-mobile {
      display: none;
    }
  }
  // mobile
  @media (max-width: 991px) {
    .main-container {
      padding-top: 40px;
      .grid-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .content-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        margin-right: unset;
        .heading-container {
          margin: 15px 0 2rem 0;
        }
        .text-container {
          display: none;
        }
        .text-container-mobile {
          display: unset;
          text-align: center;
          font-size: 20px;
          font-weight: 400;
          line-height: 30px;
          .read-more-button {
            display: flex;
            gap: 6px;
            margin: 8px auto 0 auto;
            background: unset;
            border: unset;
            font-size: 15px;
            font-weight: 500;
            line-height: 24px;
            svg {
              transition: rotate 0.3s ease;
            }
            &.open {
              svg {
                rotate: (180deg);
              }
            }
            &.hide {
              display: none;
            }
          }
        }
      }
    }
  }
</style>
