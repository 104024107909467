<script lang="ts">
  import GridHero from "../parts/GridHero.svelte";

  export let columns;
  export let columnsLarge;
  export let columnsMedium;
  export let columnsSmall;
  export let gridWidth;
  export let backgroundColor;
  export let bgColors;
  export let gridHero;
  export let gridWidthOptions;
  export let BLOCKS;
  export let MAPPERS;
  export let padding = "py-4_5 py-md-5 py-lg-6";
</script>

<div class={`ca-section ${padding} ${bgColors[backgroundColor]}`}>
  <div
    class="container-xl"
    class:cmw-container-md={gridWidth === "XL - max width 1140px"}
  >
    <GridHero {gridHero} />
    <div class="row">
      <div class={gridWidthOptions[gridWidth]}>
        <div class="row g-4">
          {#each columns as column}
            <div
              class="col"
              class:col-12={columnsSmall === 1}
              class:col-6={columnsSmall === 2}
              class:col-4={columnsSmall === 3}
              class:col-3={columnsSmall === 4}
              class:col-md-12={columnsMedium === 1}
              class:col-md-6={columnsMedium === 2}
              class:col-md-4={columnsMedium === 3}
              class:col-md-3={columnsMedium === 4}
              class:col-lg-12={columnsLarge === 1}
              class:col-lg-6={columnsLarge === 2}
              class:col-lg-4={columnsLarge === 3}
              class:col-lg-3={columnsLarge === 4}
            >
              <svelte:component
                this={BLOCKS[column.__typename]}
                {...MAPPERS[column.__typename](column)}
              />
            </div>
          {/each}
        </div>
      </div>
    </div>
  </div>
</div>
