<script lang="ts">
  export let tag = "div";
  export let classList = "text-center mb-4";

  $: tag = tag || "div";
  $: classList = classList || "text-center mb-4";
</script>

<svelte:element this={tag} class={`ca-text-eyebrow ${classList}`}>
  <slot />
</svelte:element>
