<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";
  import { bgColors } from "$lib/constants";

  export let title;
  export let content;
  export let image;
  export let cta;
  export let backgroundColor;
  export let eyebrow;

  const buttonText: string = cta.text || "View"; // Prop for button text
  backgroundColor = backgroundColor || "none";

  let textWidth = 0;
  let measuringElement;
  const padding = 90; // svg arrow width + ~1.5rem

  $: if (measuringElement) {
    const width = measuringElement.offsetWidth + padding;
    textWidth = width;
  }
</script>

<svelte:element
  this={cta?.url ? "a" : "div"}
  data-sveltekit-reload
  href={cta?.url}
  class="card-classroom-image-bottom {bgColors[backgroundColor]}"
>
  <div class="card-classroom-image-bottom-body">
    <div class="card-text-container">
      {#if eyebrow}
        <div class="eyebrow">
          {eyebrow}
        </div>
      {/if}
      {#if title}
        <Heading
          {...{
            text: title,
            font: "text",
            classList: "card-classroom-image-bottom-body-title",
          }}
        />
      {/if}

      {#if content}
        <div class="card-classroom-image-bottom-body-content">{content}</div>
      {/if}
    </div>

    {#if cta?.url}
      <div
        class="card-classroom-image-bottom-body-arrow"
        style="--button-hover-width: {textWidth}px;"
      >
        <span class="text-measurer" bind:this={measuringElement}>
          {buttonText}
        </span>

        <span class="card-classroom-image-bottom-body-arrow-text"
          >{buttonText}</span
        >
        <svg
          width="64"
          height="60"
          viewBox="0 0 64 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="60" height="60" rx="30" fill="black" />
          <path
            d="M40.9001 30.0066L32.3437 21.4502L31.2835 22.5103L38.0301 29.2569L19.1001 29.2569V30.7561L38.0303 30.7561L31.2835 37.5029L32.3437 38.5631L40.9001 30.0066Z"
            fill="white"
          />
        </svg>
      </div>
    {/if}
  </div>

  {#if image}
    <div class="card-classroom-image-bottom-media">
      <img
        width="1200"
        height="600"
        src={image.src || image.uri}
        alt={image.alt}
        class="ca-card__img"
      />
    </div>
  {/if}
</svelte:element>

<style lang="scss">
  .text-measurer {
    visibility: hidden;
    position: absolute;
    height: 0;
    overflow: hidden;
    white-space: nowrap;
    font-size: 16px; /* Same as button */
  }
  .card-text-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .card-text-container .eyebrow {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.03em;
    margin-bottom: 16px;
  }
  .card-classroom-image-bottom-body-arrow {
    margin-top: 2rem;
    position: relative;
    align-self: flex-end;
    color: #fff;
    background-color: #000;
    border-radius: 100px;
    display: flex;
    align-items: center;
    padding-left: 1.5rem;
    transition: 500ms;
    height: 60px;
    width: 60px;

    svg {
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      position: absolute;
    }

    //transition: width 0.5s, opacity 0.5s ease-in-out;

    @media (hover: hover) {
      .card-classroom-image-bottom:hover & {
        width: var(--button-hover-width);
      }
    }

    &-text {
      opacity: 0;
      transition: 250ms;
      transition-delay: unset;
      text-wrap: nowrap;

      @media (hover: hover) {
        .card-classroom-image-bottom:hover & {
          opacity: 1;
          transition: 500ms;
          transition-delay: 100ms;
        }
      }
    }
  }
  .card-classroom-image-bottom {
    max-width: 768px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
    height: 100%;
  }
  .card-classroom-image-bottom-body-content {
    font-size: 20px;
    line-height: 30px;
    margin-top: 1rem;
  }
  .card-classroom-image-bottom-body {
    padding: 2.6rem 2.4rem 2.8rem;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
  }

  //mobile
  @media (max-width: 767px) {
    .card-classroom-image-bottom-body-arrow {
      position: absolute;
      bottom: 2rem;
      right: 2rem;

      height: 45px;
      width: 45px;
      svg {
        width: 45px;
        height: 45px;
      }
    }
    .card-classroom-image-bottom-body-content {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
    }
  }
</style>
