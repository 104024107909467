<script lang="ts">
  import Heading from "../Heading/Heading.svelte";
  import Card from "../Card/Card.svelte";

  export let variant;
  export let images;
  export let headingText;
  export let headingLevel;
  export let cards = [];
  export let cardsBgColor;
  let heading;

  $: variant = variant || "";
  $: images = images || [];
  $: heading = headingText
    ? {
        text: headingText,
        tag: headingLevel || "H2",
        size: "3xl",
        font: "heading",
      }
    : null;
</script>

<section class="ca-section ca-section-logos">
  <div class="container-xl">
    {#if headingText}
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 mb-1">
          <Heading {...heading} />
        </div>
      </div>
    {/if}
    {#if images.length > 0}
      <div class="row">
        <div class="col-12 col-md-12">
          <div
            class="ca-logos (gap-3 / gap-4 / gap-5)"
            class:ca-logos--max-5={variant === "max-5"}
          >
            {#each images as { src, alt }}
              <img width="300" height="300" {src} {alt} title="Home" />
            {/each}
          </div>
        </div>
      </div>
    {/if}
    {#if cards.length > 0}
      <div
        class="cards-container"
        style="--cols: {cards?.length}"
        class:no-top-margin={!images.length > 0 && !headingText}
      >
        {#each cards as card}
          <Card
            {...{
              ...card,
              variant: "portrait-customer-quote",
              backgroundColor: cardsBgColor,
            }}
          />
        {/each}
      </div>
    {/if}
  </div>
</section>

<style lang="scss">
  .ca-section-logos {
    padding: 50px 0px;
  }
  .ca-logos {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    column-gap: 5px;
    row-gap: 10px;

    > * {
      max-width: 145px;
      max-height: 145px;
      min-width: 64px;
      height: auto;
    }

    &.ca-logos--max-5 {
      > * {
        max-width: 16%;
      }
    }
  }
  .cards-container {
    display: grid;
    grid-template-columns: repeat(var(--cols), 1fr);
    column-gap: 24px;
    margin-top: 25px;
    &.no-top-margin {
      margin-top: 0px;
    }
  }

  // mobile
  @media (max-width: 992px) {
    .ca-section-logos {
      padding-top: 32px;
      padding-bottom: 32px;
    }
    .cards-container {
      grid-template-columns: 1fr;
    }
  }
</style>
