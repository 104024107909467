<script lang="ts">
  import { page } from "$app/stores";
  import { writable, get } from "svelte/store";
  import { parseTagGroup } from "$lib/tags/tagUtils";
  import { getFilters } from "./utils";
  import Button from "$components/Button/Button.svelte";

  export let resultsPageUrl: string;
  export let items = []; // Accept 'items' as a prop
  export let accordionData = {}; // Accept 'accordionData' as a prop
  export let buttons = [];

  resultsPageUrl = resultsPageUrl || "/s/";
  // localize results page url
  resultsPageUrl = `${$page.data.base ? "/" + $page.data.base : ""}${resultsPageUrl}`;

  const accordionDataMap = Object.entries(accordionData).reduce(
    (acc, [name, { items }]) => {
      items.forEach(({ id }) => (acc[id] = name));

      return acc;
    },
    {},
  );

  const isModalOpen = writable(false);
  const accordion = writable(accordionData);
  const filters = writable({});

  function toggleModal() {
    isModalOpen.update((n) => !n);

    if (isModalOpen) {
      const firstKey = Object.keys(accordionData)?.[0];
      const paramFilters =
        $page?.url?.searchParams?.get("filters")?.split(",") || [];

      paramFilters?.forEach((filter) => {
        if (accordionDataMap[filter]) {
          updateSelectedCount(accordionDataMap[filter], filter, true);
        }
      });

      if (firstKey) {
        toggleAccordion("Role");
      }
    }
  }

  function toggleAccordion(category) {
    accordion.update((current) => ({
      ...current,
      [category]: { ...current[category], open: !current[category].open },
    }));
  }

  function updateSelectedCount(category, item, checked) {
    const key = `${item}`;
    filters.update((current) => ({ ...current, [key]: checked }));
    const currentFilters = get(filters);
    accordion.update((current) => {
      const selectedCount = current[category]?.items?.filter(
        (item) => currentFilters[`${item}`],
      ).length;
      return {
        ...current,
        [category]: { ...current[category], selectedCount },
      };
    });
  }

  function applyFilters() {
    const newFilters = getFilters($filters);
    const urlWithParams = `${resultsPageUrl}?filters=${newFilters}`;

    // For empty filters we want to go to search homepage
    if (newFilters === "") {
      window.location.assign(resultsPageUrl);
    } else if (resultsPageUrl) {
      window.location.assign(urlWithParams);
    }
  }

  function clearFilters() {
    filters.set({});
    accordion.update((current) => {
      const updatedCategories = Object.fromEntries(
        Object.entries(current).map(([category, details]) => [
          category,
          { ...details, selectedCount: 0 },
        ]),
      );
      return updatedCategories;
    });

    if (history.pushState) {
      var newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname;
      window.history.pushState({ path: newurl }, "", newurl);
    }
  }
</script>

<nav class="filter-menu ca-bg--neutral-white">
  <div class="container">
    <div class="filter-menu-inner">
      <div class="menu-links">
        {#each items as { url, text }}
          <!-- TODO: Remove target self once our routing is cleaned up -->
          <a href={url} target="_self">{text}</a>
        {/each}
      </div>
      <div class="menu-buttons">
        <Button
          on:open-filter-modal={toggleModal}
          text="Filters"
          variant="secondary"
          tag="button"
          eventName="open-filter-modal"
          url={null}
          icon=""
          isParentHovered={false}
          active={false}
          target="_self"
          animation={undefined}
          --button-min-width="max-content"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.35352 5.75H1V4.25H9.35352C9.67998 3.09575 10.7412 2.25 12 2.25C13.2588 2.25 14.32 3.09575 14.6465 4.25H23V5.75H14.6465C14.32 6.90425 13.2588 7.75 12 7.75C10.7412 7.75 9.67998 6.90425 9.35352 5.75ZM10.75 5C10.75 4.30964 11.3096 3.75 12 3.75C12.6904 3.75 13.25 4.30964 13.25 5C13.25 5.69036 12.6904 6.25 12 6.25C11.3096 6.25 10.75 5.69036 10.75 5Z"
              fill="currentColor"
              stroke="none"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.35352 11.25H1V12.75H4.35352C4.67998 13.9043 5.74122 14.75 7 14.75C8.25878 14.75 9.32002 13.9043 9.64648 12.75H23V11.25H9.64648C9.32002 10.0957 8.25878 9.25 7 9.25C5.74122 9.25 4.67998 10.0957 4.35352 11.25ZM5.75 12C5.75 11.3096 6.30964 10.75 7 10.75C7.69036 10.75 8.25 11.3096 8.25 12C8.25 12.6904 7.69036 13.25 7 13.25C6.30964 13.25 5.75 12.6904 5.75 12Z"
              fill="currentColor"
              stroke="none"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M14.3535 18.25H1V19.75H14.3535C14.68 20.9043 15.7412 21.75 17 21.75C18.2588 21.75 19.32 20.9043 19.6465 19.75H23V18.25H19.6465C19.32 17.0957 18.2588 16.25 17 16.25C15.7412 16.25 14.68 17.0957 14.3535 18.25ZM15.75 19C15.75 18.3096 16.3096 17.75 17 17.75C17.6904 17.75 18.25 18.3096 18.25 19C18.25 19.6904 17.6904 20.25 17 20.25C16.3096 20.25 15.75 19.6904 15.75 19Z"
              fill="currentColor"
              stroke="none"
            />
          </svg>
        </Button>
        {#each buttons as { text, url, variant, icon, eventName, tag }}
          <Button
            on:open-filter-modal={toggleModal}
            {text}
            {url}
            {variant}
            {icon}
            {eventName}
            {tag}
            isParentHovered={false}
            active={false}
            target="_self"
            animation={undefined}
          />
        {/each}
      </div>
    </div>
  </div>
</nav>

{#if $isModalOpen}
  <div class="modal">
    <div class="modal-content">
      <div class="modal-header">
        <h2>
          <span
            ><svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M9.35352 5.75H1V4.25H9.35352C9.67998 3.09575 10.7412 2.25 12 2.25C13.2588 2.25 14.32 3.09575 14.6465 4.25H23V5.75H14.6465C14.32 6.90425 13.2588 7.75 12 7.75C10.7412 7.75 9.67998 6.90425 9.35352 5.75ZM10.75 5C10.75 4.30964 11.3096 3.75 12 3.75C12.6904 3.75 13.25 4.30964 13.25 5C13.25 5.69036 12.6904 6.25 12 6.25C11.3096 6.25 10.75 5.69036 10.75 5Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M4.35352 11.25H1V12.75H4.35352C4.67998 13.9043 5.74122 14.75 7 14.75C8.25878 14.75 9.32002 13.9043 9.64648 12.75H23V11.25H9.64648C9.32002 10.0957 8.25878 9.25 7 9.25C5.74122 9.25 4.67998 10.0957 4.35352 11.25ZM5.75 12C5.75 11.3096 6.30964 10.75 7 10.75C7.69036 10.75 8.25 11.3096 8.25 12C8.25 12.6904 7.69036 13.25 7 13.25C6.30964 13.25 5.75 12.6904 5.75 12Z"
                fill="black"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M14.3535 18.25H1V19.75H14.3535C14.68 20.9043 15.7412 21.75 17 21.75C18.2588 21.75 19.32 20.9043 19.6465 19.75H23V18.25H19.6465C19.32 17.0957 18.2588 16.25 17 16.25C15.7412 16.25 14.68 17.0957 14.3535 18.25ZM15.75 19C15.75 18.3096 16.3096 17.75 17 17.75C17.6904 17.75 18.25 18.3096 18.25 19C18.25 19.6904 17.6904 20.25 17 20.25C16.3096 20.25 15.75 19.6904 15.75 19Z"
                fill="black"
              />
            </svg></span
          >
          Filters
        </h2>
        <button on:click={toggleModal} class="close-btn"
          ><span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.9417 12.0018L0.830078 1.89012L1.95689 0.900024L12.0002 10.9433L22.0435 0.900024L23.1703 1.89012L13.0586 12.0018L23.1701 22.1132L22.0433 23.1033L12.0002 13.0602L1.95706 23.1033L0.830251 22.1132L10.9417 12.0018Z"
                fill="black"
              />
            </svg></span
          >
          <div class="close-btn-text">Close</div></button
        >
      </div>

      <div class="modal-body">
        {#each Object.entries($accordion) as [category, details]}
          <div class="accordion">
            <button
              class="accordion-button"
              on:click={() => toggleAccordion(category)}
            >
              <span class="accordion-button-text"
                >{category}
                {@html details.selectedCount > 0
                  ? `<span class="accordion-button-text-count">${details.selectedCount} selected</span>`
                  : ""}
              </span>
              {#if details.open}
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.05792 11.9623L10.2145 3.51009C10.4698 3.27436 10.8635 3.27436 11.1188 3.51009L20.2754 11.9623L19.371 12.942L10.6666 4.90723L1.9623 12.942L1.05792 11.9623Z"
                    fill="black"
                  />
                </svg>
              {:else}
                <svg
                  width="22"
                  height="16"
                  viewBox="0 0 22 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M20.2754 4.03766L11.1189 12.4898C10.8635 12.7256 10.4699 12.7256 10.2145 12.4898L1.05794 4.03766L1.96231 3.05792L10.6667 11.0927L19.371 3.05792L20.2754 4.03766Z"
                    fill="black"
                  />
                </svg>
              {/if}
            </button>
            {#if details.open}
              <div class="accordion-content">
                {#each details.items as { id, name }, index (index)}
                  <label class="checkbox-label">
                    <input
                      type="checkbox"
                      bind:checked={$filters[`${id}`]}
                      on:change={(event) =>
                        updateSelectedCount(category, id, event.target.checked)}
                    />
                    <div class="custom-checkbox">
                      <svg
                        width="16"
                        height="11"
                        viewBox="0 0 16 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        class="checkmark-icon"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M15.1478 0.89779L5.75004 10.2955L0.852295 5.39779L1.64779 4.6023L5.75004 8.70455L14.3523 0.102295L15.1478 0.89779Z"
                          fill="black"
                        />
                      </svg>
                    </div>
                    {parseTagGroup({ id, name }).cleanName}
                  </label>
                {/each}
              </div>
            {/if}
          </div>
        {/each}

        <div class="buttons buttons-footer">
          <button class="clear-btn" on:click={clearFilters}
            >Clear Filters</button
          >
          <button class="apply-btn" on:click={applyFilters}
            >Apply Filters</button
          >
        </div>
      </div>
    </div>
  </div>
{/if}

<style lang="scss">
  .modal-body {
    padding: 1rem;
    background-color: #fff;
  }
  .buttons-footer {
    display: flex;
    justify-content: flex-end;
    gap: 2rem;

    button {
      &:hover {
        cursor: pointer;
      }
    }
  }
  .clear-btn {
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: right;
    text-decoration: underline;
    appearance: none;
    background-color: transparent;
    border: none;
  }
  .apply-btn {
    font-family: Plus Jakarta Sans;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    border: 1px solid #000;
    appearance: none;
    background-color: #000;
    color: #fff;
    border-radius: 50px;
    height: 48px;
    padding: 0 1.2rem;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.8rem;
  }
  .filter-menu {
    height: 88px;
    overflow: hidden;

    position: sticky;
    z-index: 9;
    top: 88px; // adjusted for global header navigation
  }
  .filter-menu-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 4rem;
    white-space: nowrap;
    overflow-x: scroll;

    // hide scrollbar behind overflow
    height: 100px;
    padding-bottom: 12px;
  }
  .menu-links {
    display: flex;
    gap: 2rem;
  }
  .menu-links a {
    font-family: Plus Jakarta Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
  .menu-buttons {
    display: flex;
    gap: 16px;
  }
  .modal {
    position: fixed;
    z-index: 9879;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#114b73, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    background: #e3eef3;
    height: 88px;
    padding: 1rem;

    h2 {
      display: flex;
      align-items: center;
      gap: 0.8rem;
      font-family: SangBleu Versailles;
      font-size: 24px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0px;
      text-align: left;

      span {
        background-color: #fff;
        border-radius: 100px;
        width: 35px;
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .modal-content {
    background-color: white;
    border-radius: 5px;
    text-align: center;
    width: 100%;
    max-width: 668px;
  }
  .close-btn {
    float: right;
    background: none;
    border: none;
    font-size: 1.2rem;
    cursor: pointer;

    span {
      width: 24px;
      height: 24px;
      display: flex;
      //border: 1px solid red;

      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .close-btn-text {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .accordion {
    border-bottom: 1px solid #000;
  }

  :global(.accordion-button-text-count) {
    background-color: var(--clr-light-blue);
    padding: 4px 10px;
    border-radius: 17px;
    width: -moz-fit-content;
    width: fit-content;
    height: 28px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .accordion-button {
    width: 100%;
    text-align: left;
    border: 0;

    padding: 1rem 0;
    cursor: pointer;
    appearance: none;
    background-color: transparent;

    display: flex;
    justify-content: space-between;

    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;

    span {
      display: flex;
      gap: 0.8rem;
    }
  }
  .accordion-content {
    display: grid;
    display: grid;
    grid-template-columns: repeat(
      auto-fill,
      minmax(150px, 1fr)
    ); /* Adjust minmax values as needed */
    gap: 1rem;
    padding: 0 0 1rem;
  }

  .buttons {
    margin-top: 20px;
  }

  .checkbox-label {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-align: left;
  }

  .checkbox-label input[type="checkbox"] {
    opacity: 0;
    position: absolute;
  }

  .checkbox-label .custom-checkbox {
    display: inline-flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid black;
    margin-right: 10px;
    position: relative;
  }

  .checkbox-label:hover .custom-checkbox,
  .checkbox-label input[type="checkbox"]:checked + .custom-checkbox {
    background-color: #e3eef3;
    border-color: #e3eef3;
  }

  .checkmark-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    visibility: hidden;
  }

  .checkbox-label
    input[type="checkbox"]:checked
    + .custom-checkbox
    .checkmark-icon {
    visibility: visible;
  }
</style>
