// @ts-strict-ignore
import CarouselView from "$components/Carousel/Carousel.contenful.svelte";

export const CarouselConfig = {
  name: "Carousel",
  component: CarouselView,
  mapper: (props) => {
    // console.log(props)

    const o = {
      ...props,

      navigation: {
        align: props?.arrowsAlignment || "left",
      },
      heading: {
        text: props.headingText,
        level: props.headingLevel,
      },
      slides: props.slides.items,
    };

    return o;
  },
  fragment: `
    fragment CarouselData on Carousel {
        headingText
        headingLevel
        headingSize
        text
        variant
        colorTheme
        arrowsAlignment
        slides:itemsCollection(limit: 20){
            items {
                __typename
                ... on Panel {
                    ...PanelData
                }
                ... on Card {
                    ...CardData
                }
            }
        }
    }`,
};
