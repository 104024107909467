<script lang="ts">
  import MarkdownIt from "markdown-it";

  export let text;
  text = text || "";

  const md = new MarkdownIt();

  let content = md.render(text);

  content = content.replace(
    'href="https://carta.com',
    'target="_self" href="https://carta.com',
  );
  content = content.replace('href="/', 'target="_self" href="/');
</script>

<section class="fine-print-text">
  <div class="container-xl pb-4_5 pt-4_5">
    <div class="row">
      <div class="col-12 col-md-10 col-lg-10 offset-md-1 offset-lg-1">
        <div class="fine-print-text-body">
          {@html content}
        </div>
      </div>
    </div>
  </div>
</section>

<style>
  .fine-print-text-body {
    font-size: 0.75rem;
    line-height: 1.5;
    color: var(--clr-neutral-100);
  }

  .fine-print-text-body :global(p:not(:first-child)) {
    margin-top: 1rem;
  }

  .fine-print-text-body :global(a) {
    text-decoration: underline;
  }

  @media (min-width: 48rem) {
    .fine-print-text-body {
      font-size: 0.875rem;
      line-height: 1.42;
    }
  }
</style>
