<script lang="ts">
  import "../../scss/app.scss";
  import NavBar from "$components/NavBarV2/NavBar.svelte";
  import Footer from "$components/Footer/Footer.svelte";
  import TopBanner from "../../components/TopBanner/TopBanner.svelte";
  import ThirdPartyTools from "$components/ThirdPartyTools/ThirdPartyTools.svelte";
  // This is for the images in the navbar
  import "lazysizes";
  import { addLocaleToUrl } from "$lib/helpers";

  export let data;

  let navBarMenuSecondary = [
    {
      title: "Sign In",
      url:
        data.locale === "en-GB"
          ? `https://www.app.europe.carta.com/select_product/`
          : "https://login.app.carta.com/credentials/login/",
      type: "link",
    },
    {
      title: "Request a demo",
      url: addLocaleToUrl(
        data.locale === "en-SG"
          ? "/requestdemo-apac/?ir=nav"
          : "/requestdemo/?ir=nav",
        data.locale,
      ),
      type: "button",
    },
  ];

  let navBarMenus = {
    primary: data.menus,
    secondary: navBarMenuSecondary,
  };

  let navBarData = {
    localeHomePageURL: data?.localeHomePageURL,
    menus: navBarMenus,
  };
</script>

<!-- <EventTracker /> -->

<svelte:head>
  <link rel="preconnect" crossorigin href="https://www.googletagmanager.com" />
  <link
    rel="preconnect"
    crossorigin
    href="https://client-registry.mutinycdn.com"
  />
  <link rel="preconnect" crossorigin href="https://fast.wistia.net" />

  <link
    rel="dns-prefetch"
    crossorigin
    href="https://www.googletagmanager.com"
  />
  <link
    rel="dns-prefetch"
    crossorigin
    href="https://client-registry.mutinycdn.com"
  />
  <link rel="dns-prefetch" crossorigin href="https://fast.wistia.net" />
</svelte:head>
<ThirdPartyTools {data} />

<TopBanner
  text="Let us know which country/region you are in to see content and products specific to your location."
  variant="geo-switcher"
  pageLocale={data.locale}
/>

<NavBar {...navBarData} />
<slot />
<Footer
  data={{
    url: data.baseURL,
    footerMenus: data.footerMenus,
    locale: data.locale,
    footerTextLeft: "",
    footerTextRight: "",
  }}
/>

<style>
  :global(.banner-grid) {
    display: flex;
    justify-content: space-between;
  }

  :global(.banner-grid a) {
    font-weight: bold;
  }

  :global(.banner-grid a:hover) {
    text-decoration: underline;
  }

  @media (max-width: 992px) {
    :global(.banner-grid) {
      flex-direction: column;
    }
  }
</style>
