<!-- in rich text parser simple, can render marks and assets, no blocks -->
<script lang="ts">
  import { locales } from "../../lib/constants";
  import { slugify } from "$lib/helpers.js";

  export let node;
  export let links;
  export let locale = "en-US";
  export let ogUri;

  let href = null;

  if (node?.data?.uri) {
    if (node.data.uri.includes("http")) {
      href = node.data.uri;
      // anchor tags were only auto adding URI for US, this seems to fix it
    } else if (node.data.uri[0] === "#") {
      href = `${locales[locale]}/blog/${ogUri}/${node.data.uri}`;
    } else {
      href = `${locales[locale]}${node.data.uri}`;
    }
  }
  const MARKS = {
    bold: (value) => `<strong>${value}</strong>`,
    underline: (value) => `<u>${value}</u>`,
    italic: (value) => `<em>${value}</em>`,
  };

  const basicNodeTypeToElementMap = new Map([
    ["document", "div"],
    ["paragraph", "p"],
    ["heading-1", "h1"],
    ["heading-2", "h2"],
    ["heading-3", "h3"],
    ["heading-4", "h4"],
    ["heading-5", "h5"],
    ["heading-6", "h6"],
    ["unordered-list", "ul"],
    ["ordered-list", "ol"],
    ["list-item", "li"],
    ["blockquote", "blockquote"],
    ["hyperlink", "a"],
    ["hr", "hr"],
    ["table", "table"],
    ["table-row", "tr"],
    ["table-cell", "td"],
    ["table-header-cell", "th"],
  ]);

  // commented out here because the css for tables in app.css is the styling we need. Styling here is for other tables in regular text parser
  const styles = {
    // table: 'border: 3px solid black',
    // 'table-row': 'border-bottom: 1px solid black; background: none;'
  };

  const assetMap = new Map();
  const entryMap = new Map();

  // this will technically always run because links is an object
  if (links) {
    for (const asset of links.assets.block) {
      if (asset?.sys.id) {
        assetMap.set(asset.sys.id, asset);
      } else {
        console.warn("There is an asset with missing id, may be unpublished");
      }
    }
    if (links.entries.block) {
      for (const entry of links.entries.block) {
        entryMap.set(entry.sys.id, entry);
      }
    }
    for (const entry of links.entries.inline) {
      entryMap.set(entry.sys.id, entry);
    }
  }

  function getID(node) {
    if (
      node?.nodeType.includes("heading-") &&
      node.content[0]?.nodeType === "text"
    ) {
      return slugify(node.content[0].value);
    }

    return null;
  }
</script>

{#if node?.nodeType === "text"}
  {#if node.value === "✓"}
    {@html `<i class="ca-pricing-check" />`}
  {:else}
    {@html (() => {
      let result = node.value;
      for (const mark of node.marks) {
        result = MARKS[mark.type] ? MARKS[mark.type](result) : result;
      }
      result = result.replace(/\n/g, `</br>`);
      return result;
    })()}
  {/if}
{:else if node?.nodeType === "embedded-asset-block" && assetMap.get(node.data.target.sys.id)}
  <img
    alt={assetMap.get(node.data.target.sys.id).title}
    src={assetMap.get(node.data.target.sys.id).url}
    width={assetMap.get(node.data.target.sys.id).width}
    height={assetMap.get(node.data.target.sys.id).height}
  />
{:else if node?.nodeType === "embedded-entry-block"}
  <!-- nothing, to render blocks use regular version -->
{:else}
  <!-- added this if statement so we dont get this warning https://github.com/sveltejs/svelte/issues/7566 -->
  {#if ["br", "hr"].includes(node?.nodeType)}
    <svelte:element this={basicNodeTypeToElementMap.get(node?.nodeType)} />
  {:else}
    <svelte:element
      this={basicNodeTypeToElementMap.get(node?.nodeType)}
      {href}
      target={href ? "_self" : null}
      class:ca-flow={node?.nodeType === "document"}
      id={getID(node)}
      style={styles[node?.nodeType] ? styles[node?.nodeType] : null}
    >
      {#each node.content as innerNode}
        <svelte:self node={innerNode} {links} {locale} {ogUri} />
      {/each}
    </svelte:element>
  {/if}
{/if}
