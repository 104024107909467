// @ts-strict-ignore
import BlockQuote from "./BlockQuote.svelte";

export const BlockQuoteConfig = {
  name: "BlockQuote",
  component: BlockQuote,
  variants: {},
  mapper: (props) => {
    return {
      ...props,
      author: props?.author
        ? {
            name: props?.author?.name,
            title: props?.author?.careerTitle,
            image: {
              src: props?.author?.avatar?.url,
              alt:
                props?.author?.avatar?.description ||
                props?.author?.avatar?.title,
            },
          }
        : null,
      quote: props?.quote,
    };
  },
  // no fragment because block-quote is included as Card type in contenful
};
