<script lang="ts">
  import ContentfulBlockParser from "$components/ContentfulBlockParser/ContentfulBlockParser.svelte";
  import { slugify } from "$lib/helpers.js";
  import { getHrefTarget, getNodeHref } from "./RichText.utils";
  import {
    MARKS,
    basicNodeTypeToElementMap,
    setMapsFromLinks,
  } from "./RichText.common";

  /** @type {import('./RichText.types').NodeType} */
  export let node;
  export let links;
  export let bqStyle = "";
  export let locale;
  export let ogUri;
  /** If `true` class `lazyload` gets added to images */
  export let lazyloadImages = false;

  const href = getNodeHref(node, { locale, ogUri });

  const blockQuoteTypes = {
    "orange-indented": "orange-indented",
  };

  // doing like this for table because we already have CSS in app.scss for tables
  const styles = {
    table: "border: 3px solid black",
    "table-row": "border-bottom: 1px solid black; background: none;",
  };

  const { assetMap, entryBlockMap } = setMapsFromLinks(links);

  function getID(node) {
    if (node?.cmwHeadingData?.href) {
      return node?.cmwHeadingData?.href?.replace("#", "");
    }

    if (
      node?.nodeType.includes("heading-") &&
      node.content[0]?.nodeType === "text"
    ) {
      return slugify(node.content[0].value);
    }

    return null;
  }
</script>

{#if node.nodeType === "text"}
  {@html (() => {
    let result = node.value;
    for (const mark of node.marks) {
      result = MARKS[mark.type] ? MARKS[mark.type](result) : result;
    }
    result = result.replace(/\n/g, `</br>`);
    return result;
  })()}
{:else if node.nodeType === "embedded-asset-block" && assetMap.get(node.data.target.sys.id)}
  {#if lazyloadImages}
    <img
      class="lazyload"
      alt={assetMap.get(node.data.target.sys.id).title}
      data-src={assetMap.get(node.data.target.sys.id).url}
      width={assetMap.get(node.data.target.sys.id).width}
      height={assetMap.get(node.data.target.sys.id).height}
    />
  {:else}
    <img
      alt={assetMap.get(node.data.target.sys.id).title}
      src={assetMap.get(node.data.target.sys.id).url}
      width={assetMap.get(node.data.target.sys.id).width}
      height={assetMap.get(node.data.target.sys.id).height}
    />
  {/if}
{:else if node.nodeType === "embedded-entry-block"}
  <ContentfulBlockParser block={entryBlockMap.get(node.data.target.sys.id)} />
{:else}
  <!-- added this if statement so we dont get this warning https://github.com/sveltejs/svelte/issues/7566 -->
  {#if ["br", "hr"].includes(node.nodeType)}
    <svelte:element this={basicNodeTypeToElementMap.get(node.nodeType)} />
  {:else if node.nodeType === "table"}
    <div class="table-scroll">
      <svelte:element this={basicNodeTypeToElementMap.get(node.nodeType)}>
        {#each node.content as innerNode}
          <svelte:self node={innerNode} {links} {bqStyle} {locale} {ogUri} />
        {/each}
      </svelte:element>
    </div>
  {:else}
    <svelte:element
      this={basicNodeTypeToElementMap.get(node.nodeType)}
      {href}
      target={getHrefTarget(href)}
      class:ca-flow={node.nodeType === "document"}
      class={node.nodeType === "blockquote" && bqStyle
        ? blockQuoteTypes[bqStyle]
        : null}
      id={getID(node)}
      style={styles[node.nodeType] ? styles[node.nodeType] : null}
    >
      {#each node.content as innerNode}
        <svelte:self
          node={innerNode}
          {links}
          {bqStyle}
          {locale}
          {ogUri}
          {lazyloadImages}
        />
      {/each}
    </svelte:element>
  {/if}
{/if}

<style lang="scss">
  .table-scroll {
    width: 100%;
    overflow-x: scroll;
  }

  blockquote.orange-indented {
    display: flex;
    border: 1px #ff6f43;
    border-left: 4px solid #ff6f43;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    font-size: 18px;
    /* font-style: italic; */
    line-height: 22px;
  }
</style>
