<script lang="ts">
  import "../Stat.scss";

  import inView from "../../../lib/inView";

  import * as CountUpModule from "countup.js";
  import party from "party-js";

  export let prefix;
  export let value;
  export let suffix;
  export let text;
  export let variant;
  export let decimalPlaces;
  export let separator;
  export let useCountUp;
  export let useConfetti;
  export let startValue;
  export let countUp;
  export let headingText;

  let valueRef;

  let didCountUp = false;
  let didConfetti = false;

  $: prefix = prefix || "";
  $: value = value || 0;
  $: suffix = suffix || "";
  $: text = text || "";
  $: variant = variant || "";
  $: decimalPlaces = decimalPlaces || 0;
  $: separator = separator || ",";
  $: useCountUp = useCountUp || false;
  $: useConfetti = useConfetti || false;
  $: startValue = startValue || 0;
  $: countUp = countUp || {
    startVal: startValue,
    duration: 2,
    decimalPlaces,
    separator,
  };

  const runConfetti = () => {
    const colors = [
      new party.Color(136, 234, 235, 1),
      new party.Color(196, 231, 77, 1),
      new party.Color(248, 214, 72, 1),
      new party.Color(84, 137, 173, 1),
      new party.Color(101, 191, 177, 1),
      new party.Color(101, 191, 177, 1),
      new party.Color(237, 145, 74, 1),
      new party.Color(227, 238, 243, 1),
      new party.Color(217, 243, 239, 1),
      new party.Color(255, 243, 236, 1),
      new party.Color(239, 234, 232, 1),
      new party.Color(252, 249, 244, 1),
    ];
    party.confetti(valueRef, {
      count: party.variation.range(100, 150),
      color: colors,
    });
  };

  const startCounter = () => {
    if (useCountUp && !didCountUp) {
      const CountUp = CountUpModule.CountUp;
      const v = Number(value.toString().replace(/[^0-9]/gi, ""));
      const counter = new CountUp(valueRef, v, countUp);
      counter.start(() => {
        didCountUp = true;
        if (useConfetti && didConfetti === false) {
          runConfetti();
          didConfetti = true;
        }
      });
    }
  };
</script>

{#if headingText}
  <section
    class="ca-section ca-section-stats pt-4 pt-md-5 pt-lg-7 text-center"
    id=""
  >
    <div class="container-xl">
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
          <h2 class="ca-section__title">
            {@html headingText}
          </h2>
          <div class="ca-section__intro" />
          <div class="stat" class:stat--lg={variant === "lg"}>
            <div
              class="stat__num"
              use:inView={{ bottom: 100 }}
              on:enter={startCounter}
            >
              {#if prefix}
                <span class="stat__prefix">{prefix}</span>
              {/if}

              {#if value}
                <span class="stat__value" bind:this={valueRef}>
                  {value}
                </span>
              {/if}

              {#if suffix}
                <span class="stat__suffix">{suffix}</span>
              {/if}
            </div>

            {#if text}
              <div class="stat__text">{text}</div>
            {/if}
          </div>
        </div>
      </div>
    </div>
  </section>
{:else}
  <div class="stat" class:stat--lg={variant === "lg"}>
    <div class="stat__num">
      {#if prefix}
        <span class="stat__prefix">{prefix}</span>
      {/if}

      {#if value}
        <span class="stat__value" bind:this={valueRef}>
          {value}
        </span>
      {/if}

      {#if suffix}
        <span class="stat__suffix">{suffix}</span>
      {/if}
    </div>

    {#if text}
      <div class="stat__text">{text}</div>
    {/if}
  </div>
{/if}
