<script lang="ts">
  import { page } from "$app/stores";
  import { onMount } from "svelte";
  import Swiper, { Navigation, Pagination, Manipulation } from "swiper";
  import Heading from "../../Heading/Heading.svelte";
  import Accordion from "../../Accordion/Accordion.svelte";
  import ContentfulRichTextParserSimple from "../../ContentfulRichTextParser/ContentfulRichTextParserSimple.svelte";

  import "swiper/css";
  import "../../Carousel/Carousel.swiper.scss";

  export let items;
  export let heading;
  export let eyebrow;
  export let text;
  export let bgColor;

  let swiperRef;
  let swiperPrevRef;
  let swiperNextRef;
  let swiperPaginationRef;
  let activeIndex = 0;

  onMount(() => {
    const searchParams = $page?.url?.searchParams;
    const searchParamsActiveIndex = Number(searchParams?.get("slide"));

    new Swiper(swiperRef, {
      modules: [Navigation, Pagination, Manipulation],
      pagination: {
        el: swiperPaginationRef,
        type: "progressbar",
      },
      navigation: {
        nextEl: swiperNextRef,
        prevEl: swiperPrevRef,
      },
      loop: false,
      slidesPerView: 1,
      spaceBetween: 12,
      breakpoints: {
        375: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 24,
        },
        992: {
          slidesPerView: 3,
          spaceBetween: 24,
        },
      },
      on: {
        init: function (swiper) {
          if (!isNaN(searchParamsActiveIndex)) {
            const _activeIndex = searchParamsActiveIndex - 1;

            if (_activeIndex >= 0 && _activeIndex <= swiper.slides.length) {
              swiper.activeIndex = _activeIndex;
              activeIndex = _activeIndex;
            }
          } else {
            activeIndex = swiper.activeIndex;
          }

          swiper.updateSlidesClasses();
        },
        resize: function (swiper) {
          // dont change active slide on resize
          if (swiper.activeIndex !== activeIndex) {
            swiper.activeIndex = activeIndex;
            swiper.updateSlidesClasses();
          }
        },
        click: function (swiper) {
          swiper.activeIndex = swiper.clickedIndex;
          activeIndex = swiper.activeIndex;
          swiper.updateSlidesClasses();
        },
        slideChangeTransitionEnd: function (swiper) {
          activeIndex = swiper.activeIndex;
          swiper.updateSlidesClasses();
        },
      },
    });
  });
</script>

<section class="ca-section py-4 py-lg-4_5" data-video-block="" id="">
  <div class="container-xl">
    <div class="{bgColor} py-4_5 py-lg-7 px-4 px-md-0">
      {#if heading?.text || text || eyebrow}
        <div class="row mb-4_5">
          <div class="col-12 col-md-10 col-lg-8 offset-md-1 offset-lg-2">
            {#if eyebrow}
              <div class="ca-text-eyebrow text-center mb-4">
                {eyebrow}
              </div>
            {/if}
            {#if heading?.text}
              <Heading {...heading} />
            {/if}
            {#if text}
              <div class="ca-section__intro">
                {#if typeof text === "object"}
                  <ContentfulRichTextParserSimple node={text} />
                {:else}
                  <p>
                    {@html text}
                  </p>
                {/if}
              </div>
            {/if}
          </div>
        </div>
      {/if}
      <div class="row">
        <div class="col-12 col-md-10 offset-md-1">
          <!-- Video Embed Placeholder -->
          <div class="ca-video-block__stage" data-video-block-stage>
            {@html items[activeIndex]?.embed}
          </div>
          <!-- Video Embed Placeholder -->
        </div>
      </div>
      {#if items.length > 1}
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1">
            <div class="swiper-video-block">
              <div
                class="swiper swiper--has-pagination mt-4 mt-md-5 mt-lg-7 swiper--same-height"
                bind:this={swiperRef}
              >
                <!-- Additional required wrapper -->
                <div class="swiper-wrapper">
                  {#each items as item}
                    <div
                      class="swiper-slide"
                      style="width: 352px; margin-right: 24px;"
                    >
                      <div
                        data-video-id=""
                        class="ca-card ca-card--fixed-info ca-card--hover-dark ca-video-block__btn ca-video-block__btn--video"
                      >
                        <img
                          src={item?.thumbnail?.src}
                          alt={item?.thumbnail?.alt}
                          class="ca-card__img"
                        />
                        <div class="ca-card__info">
                          <div class="ca-card__info-header">
                            {item.heading || ""}
                          </div>
                          <div class="ca-text-body-2 mt-2" />
                        </div>
                      </div>
                    </div>
                  {/each}
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="ca-swiper-controls d-flex my-4 mx-auto">
                      <div
                        class="ca-swiper-pagination"
                        bind:this={swiperPaginationRef}
                      />
                      <div
                        class="ca-swiper-navigation d-flex justify-content-center gap-2"
                      >
                        <div
                          class="swiper-button-prev"
                          bind:this={swiperPrevRef}
                        />
                        <div
                          class="swiper-button-next"
                          bind:this={swiperNextRef}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      {/if}
      <!-- this is if there is a transript -->
      {#if items[activeIndex]?.accordionTitle && items[activeIndex]?.accordionText}
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1">
            <Accordion
              items={[
                {
                  title: items[activeIndex].accordionTitle,
                  content: items[activeIndex].accordionText,
                },
              ]}
            />
          </div>
        </div>
      {/if}
    </div>
  </div>
</section>
