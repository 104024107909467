// @ts-strict-ignore
import BlogCTA from "./BlogCTA.svelte";
import { HeadingConfig } from "../Heading/Heading.config";
import { slugify } from "$lib/helpers";

export const BlogCTAConfig = {
  name: "BlogCtas",
  variants: {},
  component: BlogCTA,
  mapper: (props) => {
    const result = {
      ...props,
      buttons: props.buttonsCollection?.items.map((n) => ({
        ...n,
        variant: n?.variant?.toLowerCase(),
      })),
      variant: props.variant ? slugify(props.variant).toLowerCase() : "",
      media: props?.media,
      heading: HeadingConfig.mapper(props?.heading),
    };

    delete result.buttonsCollection;

    return result;
  },
  fragment: `fragment BlogCtasData on BlogCtas {
        eyebrow
        backgroundColor
        heading {
          text
          size
          level
          font
          size
          align: textAlignment
          classList
        }
        text
        buttonsCollection(limit: 4) {
          items {
            text
            url
            variant
            icon
          }
        }
        media {
              image {
                url
                title
                width
                height
                description
              }
        }
      }`,
};
