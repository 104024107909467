<script lang="ts">
  export let title;
  export let content;
  export let image;
  export let cta = {
    url: "#",
    text: "",
  };
</script>

<div class="ca-card ca-card--portrait">
  <img src={image.src} alt={image.alt} class="ca-card__img" />
  <div class="ca-card__info">
    <div class="ca-card__info-header">{title}</div>
    <div class="ca-card__info-subheader">{@html content}</div>
    {#if cta}
      <a
        data-sveltekit-reload
        href={cta.url}
        target="_self"
        class="ca-card__info-link ca-link mt-2">{cta.text}</a
      >
    {/if}
  </div>
</div>
