<script lang="ts">
  export let id = "";
  export let eyebrow;
  export let heading;
  export let text = "";
  export let backgroundColor = "ca-bg--light-cream";
  export let media;
  export let buttons;
</script>

<!-- need to fix href to include locale. might be able to use svelte page data -->
<div class="text-decoration-none main-container {backgroundColor}">
  <a class={`container-xl p-0`} {id} href={buttons[0]?.url} target="_self">
    <div class="grid-container">
      <div class="media-container" class:no-media={!media.image?.url}>
        {#if media.image?.url}
          <img
            width="1440"
            height="1080"
            class="mx-auto mx-lg-0"
            src={media.image.url}
            alt={media.image.alt}
          />
        {/if}
      </div>
      <div class="content-container py-4">
        {#if eyebrow}
          <div class="ca-text-eyebrow mb-2">{eyebrow}</div>
        {/if}
        <div class="heading-container">
          {heading}
        </div>

        {#if text}
          <div class="text-container">
            {@html text}
          </div>
        {/if}

        <div class="cta">
          <svg
            width="24"
            height="24"
            viewBox="0 0 22 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.9001 9.00663L13.3437 0.450195L12.2835 1.51033L19.0301 8.25689L0.100098 8.25689V9.75614L19.0303 9.75615L12.2835 16.5029L13.3437 17.5631L21.9001 9.00663Z"
              fill="black"
            /></svg
          >
        </div>
      </div>
    </div>
  </a>
</div>

<style lang="scss">
  .main-container {
    margin: auto;
    .grid-container {
      display: grid;
      justify-items: end;
      grid-template-columns: 48% 1fr;
      column-gap: 40px;
    }
    .media-container {
      height: 100%;
      width: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .content-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding-right: 1rem;
    }
    .content-container .heading-container {
      font-size: 28px;
      font-weight: 400;
      line-height: 36px;
      margin-bottom: 1rem;
    }
    .content-container .text-container {
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
    }
    .content-container .cta {
      margin-top: 24px;
      display: flex;
      justify-content: center;
      width: 60px;
      height: 60px;
      border: solid black 1px;
      border-radius: 50%;
    }
    .content-container .social-media-container {
      margin: 30px 0 0 -12px;
    }
  }
  // mobile
  @media (max-width: 991px) {
    .main-container {
      display: block;
      max-width: 400px;
      .grid-container {
        display: unset;
      }
      .content-container {
        padding: 0px 20px;
        .heading-container {
          font-size: 22px;
          line-height: 28px;
        }
        .text-container {
          font-size: 15px;
          line-height: 22px;
        }
      }
    }
  }
</style>
