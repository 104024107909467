// @ts-strict-ignore
import Card from "../Card/Card.svelte";

export const CardConfig = {
  name: "Card",
  component: Card,
  variants: {},
  mapper: (props) => {
    return {
      ...props,
      image: props.image
        ? {
            src: props.image.image?.url,
            alt: props.image.image?.description || props.image.image?.title,
          }
        : null,
      icon: props?.icon?.icon,
      text: props.content,
      date: props.date || props.sys.publishedAt,
      author: {
        ...props?.author,
        avatar: {
          src: props?.author?.avatar?.url,
          alt:
            props?.author?.avatar?.description || props?.author?.avatar?.title,
        },
      },
    };
  },
};
