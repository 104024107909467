<script lang="ts">
  import Heading from "../../Heading/Heading.svelte";
  import { accentColors, icons } from "../../../lib/constants";
  import { bgColors } from "../../../lib/constants";

  export let heading;
  export let media = [];
  export let accentColor = "black";
  export let backgroundColor;

  media = media.map((item) => {
    let processedIcon;
    if (typeof icons[item.icon] === "function") {
      processedIcon = icons[item.icon](accentColors[accentColor]);
    } else if (icons[item.icon]) {
      processedIcon = icons[item.icon];
    }

    const iconSizes = {
      sm: "36px",
      md: "48px",
      lg: "60px",
      xl: "72px",
    };

    return {
      ...item,
      icon: processedIcon,
      size: iconSizes[item.size] || iconSizes.sm,
    };
  });
</script>

<section class={`container-fluid ${bgColors[backgroundColor]}`}>
  <div class="container-xl icons-hero-container">
    {#if heading}
      <Heading {...{ ...heading, size: "3xl" }} />
    {/if}

    <div class="icons-grid">
      {#each media as { heading, text, icon, size }}
        <div class="block" style="--icon-size: {size};">
          <div class="icon">{@html icon}</div>
          <div class="text">
            <div
              class="heading"
              style="--accent-color: {accentColors[accentColor]}"
            >
              {heading}
            </div>
            <div class="body">{text}</div>
          </div>
        </div>
      {/each}
    </div>
  </div>
</section>

<style lang="scss">
  @use "$sass/base/typography_mixins" as *;

  .icons-hero-container {
    padding: var(--left-aligned-icons-hero-padding, 50px 24px);
  }
  .block-heading {
    @include text-3xl;
    @include font-heading;
  }
  .icons-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 25px;
    margin-top: 48px;
    .icon {
      :global(svg) {
        width: 100%;
      }
    }
  }
  .block {
    display: grid;
    width: 100%;
    grid-template-columns: var(--icon-size) 1fr;
    column-gap: 16px;
    .text {
      @include text-body-1;
      .heading {
        // color: #918177;
        color: var(--accent-color);
      }
    }
  }

  // mobile
  @media (max-width: 992px) {
    .icons-hero-container {
      padding-top: var(--left-aligned-icons-hero-mobile-padding, 32px);
      padding-bottom: var(--left-aligned-icons-hero-mobile-padding, 32px);
    }
    .icons-grid {
      margin-top: 32px;
      grid-template-columns: 1fr;
      justify-items: center;
      row-gap: 32px;
    }
  }
</style>
