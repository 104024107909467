<script lang="ts">
  import Button from "../../Button/Button.svelte";
  import GridHero from "../parts/GridHero.svelte";

  export let BLOCKS;
  export let MAPPERS;

  export let columns;
  export let gridWidth;
  export let backgroundColor;
  export let bgColors;
  export let gridHero;
  export let gridWidthOptions;
  export let seeMoreButton;
  export let initialItemsAmount;

  let seeMore = false;
  $: amountToShow = seeMore ? columns.length : initialItemsAmount;

  const showAllItems = () => {
    seeMore = true;
  };
</script>

<section class={`${bgColors[backgroundColor]} py-4_5 py-md-5 py-lg-6`}>
  <div class="container-xl">
    <div class="container-fluid">
      <GridHero {gridHero} />
      <div class="row">
        <div class={gridWidthOptions[gridWidth]}>
          <div class="grid-simple-minis">
            {#each columns as column, i}
              {#if i < amountToShow}
                <svelte:component
                  this={BLOCKS[column?.__typename]}
                  {...MAPPERS[column?.__typename](column)}
                />
              {/if}
            {/each}
          </div>

          <div
            class="see-more-button d-flex justify-content-center mt-4_5"
            class:visible={seeMoreButton && !seeMore}
          >
            <Button
              text="See more"
              variant="secondary"
              tag="button"
              eventName="showAll"
              on:showAll={showAllItems}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<!-- this styling is copied directly from ces.scss file, class .ca-ces-speakers-grid. Thought it made sense to include directly in component -->
<style>
  .grid-simple-minis {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    column-gap: 16px;
    row-gap: 48px;
  }
  @media (min-width: 48rem) {
    .grid-simple-minis {
      column-gap: 24px;
      row-gap: 68px;
    }
  }
  .see-more-button {
    visibility: hidden;
  }
  .see-more-button.visible {
    visibility: visible;
  }
</style>
