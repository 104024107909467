<script lang="ts">
  export let size;
  export let image;

  $: size = size || "lg";
  $: image = image || { alt: "", src: "" };

  const scaleMap = {
    sm: 36,
    md: 54,
    lg: 81,
    xl: 140,
  };
</script>

<img
  class="ca-icon--{size}"
  alt={image.alt}
  src={image.src}
  width={scaleMap[size]}
  height={scaleMap[size]}
/>
