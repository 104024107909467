// @ts-strict-ignore
import FinePrintText from "./FinePrintText.svelte";

export const FinePrintTextConfig = {
  name: "FinePrintText",
  component: FinePrintText,
  mapper: (props) => {
    return {
      ...props,
      tag: props?.level,
      size: props?.size?.toLowerCase(),
      classList: props?.classList?.join(" "),
    };
  },
  fragment: `fragment FinePrintTextData on FinePrintText {
        __typename
        text
    }`,
};
