<script lang="ts">
  export let url;
  /** @type {"horizontal" | "vertical"} */
  export let direction;
  export let downloadableAsset;
  export let articleTitle = "Carta";
  /**  Use the blue version of the social icons. Defaults to `false` i.e. black icons */
  export let useBlueIcons = false;

  $: url = url || "";
  $: {
    if (url?.[url.length - 1] !== "/") {
      url = `${url}/`;
    }
  }

  $: iconClasses = {
    twitter: useBlueIcons ? "ca-social-icon--tw-blue" : "ca-social-icon--tw",
    linkedIn: useBlueIcons ? "ca-social-icon--ln-blue" : "ca-social-icon--ln",
    email: useBlueIcons
      ? "ca-social-icon--email-blue"
      : "ca-social-icon--email",
    download: useBlueIcons
      ? "ca-social-icon--download-blue"
      : "ca-social-icon--download",
  };

  $: downloadableAssetIconHTML = downloadableAsset?.url
    ? `<a download="" href="${downloadableAsset.url}" target="_BLANK" rel="nofollow noopener" class="ca-social-icon ${iconClasses.download}"><span class="visually-hidden">Download report</span></a>`
    : ``;

  function getURL(baseUrl, queryparams) {
    try {
      const params = new URLSearchParams(queryparams);
      return `${baseUrl}?${params.toString()}`;
    } catch {
      return baseUrl;
    }
  }

  $: direction = direction || "horizontal";
  $: socialIcons = `
			<a
				href="https://twitter.com/intent/tweet?source=${url}&amp;text=${articleTitle}: ${url}"
				class="ca-social-icon ${iconClasses.twitter}"
				target="_blank"
				rel="noopener noreferrer"
			>
				<span class="visually-hidden">Share on Twitter</span>
			</a>
			<a
				href="https://www.linkedin.com/sharing/share-offsite/?url=${url}"
				class="ca-social-icon ${iconClasses.linkedIn}"
				target="_blank"
				rel="noopener noreferrer"
			>
				<span class="visually-hidden">Share on Linkedin</span>
			</a>
			<a
				href="${getURL("mailto:", { subject: articleTitle, body: url })}"
				class="ca-social-icon ${iconClasses.email}"
				target="_blank"
				rel="noopener noreferrer"
			>
				<span class="visually-hidden">Share by Email</span>
			</a>
			${downloadableAssetIconHTML}
    `;
</script>

{#if direction === "horizontal"}
  <!-- horizontal -->
  <section>
    <div class="container-xl">
      <div
        class="ca-social-widget justify-content-center justify-content-lg-start"
      >
        {@html socialIcons}
      </div>
    </div>
  </section>
{:else}
  <!-- vertical -->
  <div class="row">
    <div class="col-6 offset-3 position-relative">
      <div class="ca-article-social-sharing-vertical">
        <div class="ca-social-widget flex-column">
          {@html socialIcons}
        </div>
      </div>
    </div>
  </div>
{/if}
