<script lang="ts">
  import Heading from "../../Heading/Heading.svelte";
  import Button from "../../Button/Button.svelte";
  import Eyebrow from "./parts/Eyebrow.svelte";

  export let eyebrow = "";
  export let eyebrowTag = "div";
  export let heading = {
    text: "",
    id: "",
  };

  export let text = "";
  export let buttons = [];
  export let backgroundColor = "ca-bg--light-cream";
</script>

<section class="section-classroom-hero {backgroundColor}">
  <div class="section-classroom-hero-content">
    {#if eyebrow}
      <Eyebrow tag={eyebrowTag}>{eyebrow}</Eyebrow>
    {/if}
    <Heading
      {...{
        font: "heading",
        align: "center",
        size: "4xl",
        tag: "h1",
        ...heading,
      }}
    />

    {#if text}
      <div class="ca-section__intro ca-text-base ca-flow">
        {@html text}
      </div>
    {/if}

    {#if buttons.length}
      <div
        class="d-flex justify-content-center mt-4_5 mt-lg-6 g-4 gap-3 flex-wrap"
      >
        {#each buttons as button}
          <Button {...button} />
        {/each}
      </div>
    {/if}
  </div>
</section>

<style>
  .section-classroom-hero-content {
    max-width: 700px;
  }
  .section-classroom-hero {
    padding: 4.5rem 2rem 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
