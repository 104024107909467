<script lang="ts">
  import AgendaDefault from "./variants/AgendaDefault.svelte";
  import AgendaAgg from "./variants/AgendaAgg.svelte";

  export let variant;
  export let hero;
  export let title;
  export let text;
  export let agenda;
  export let mainTabName;
  export let colorTheme = "light-blue";

  $: variant = variant || "default";
  $: colorTheme = colorTheme || "light-blue";

  const variants = {
    default: AgendaDefault,
    "aggregate-only": AgendaAgg,
  };
</script>

<svelte:component
  this={variants[variant]}
  {hero}
  {title}
  {text}
  {agenda}
  {mainTabName}
  {colorTheme}
/>
