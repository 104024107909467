// @ts-strict-ignore
import Agenda from "./Agenda.svelte";
import { PanelConfig } from "../Panel/Panel.config";

export const AgendaConfig = {
  name: "Agenda",
  component: Agenda,
  variants: {},
  mapper: (props) => {
    props.hero = PanelConfig.mapper(props.hero);

    props.agenda = props.itemsCollection.items.map((item) => {
      return {
        date: item.date,
        items: item.sessionsCollection?.items.map((session) => {
          return {
            time: session.time,
            title: session.title,
            description: session.description,
            speakers: session.speakersCollection.items.map((speaker) => {
              return {
                name: speaker.name,
                url: speaker.companyUrl,
                company: speaker.companyName,
              };
            }),
          };
        }),
      };
    });

    return props;
  },
  // may remove mainTabName depending on Alexs decision
  fragment: `
    fragment AgendaData on EventAgenda {
        __typename
        variant
        title
        text
        colorTheme
        mainTabName
        hero {
          ...PanelData
        }
        itemsCollection(limit: 15) {
          items {
            __typename
            sys {
              id
            }
          }
        }
	  }`,
};
