<script lang="ts">
  export let eyebrow;
  export let title;
  export let url;
  export let date;
  export let tag = url ? "a" : "div";
</script>

<svelte:element
  this={tag}
  data-sveltekit-reload
  href={url}
  class="cmw-reskin-news-card"
>
  <div class="cmw-reskin-news-card__tag ca-text-eyebrow mb-1">{eyebrow}</div>
  <div class="cmw-reskin-news-card__title ca-text-body-1 ca-flow">
    {title}
  </div>
  <div class="cmw-reskin-news-card__date ca-text-body-2 mt-2">{date}</div>
</svelte:element>
