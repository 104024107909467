<script lang="ts">
  import Button from "../components/Button/Button.svelte";

  export let getPostsLoaded = true;
  export let pageLoaded = false;
  export let getPosts;
</script>

<div class="load-more-button-container">
  {#if getPostsLoaded && pageLoaded}
    <Button
      on:load-more={getPosts}
      variant="secondary"
      text="Load more"
      eventName="load-more"
      tag="div"
    />
  {:else}
    <div class="loader" />
  {/if}
</div>

<style lang="scss">
  .load-more-button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 4rem;
    .loader {
      width: 25px;
      height: 25px;
      border: solid rgb(178, 216, 232) 5px;
      border-top: solid hsl(204, 44%, 41%) 5px;
      border-radius: 50%;
      animation: loading 0.75s ease infinite;
    }
  }
  @keyframes loading {
    0% {
      transform: rotate(0turn);
    }
    100% {
      transform: rotate(1turn);
    }
  }
</style>
