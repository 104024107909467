<script lang="ts">
  import MarkdownIt from "markdown-it";
  import Heading from "../../Heading/Heading.svelte";
  import PanelButtonsGroup from "./parts/PanelButtonsGroup.svelte";
  import Eyebrow from "./parts/Eyebrow.svelte";

  export let eyebrow = "";
  export let eyebrowTag;
  export let id = "";
  export let heading = {
    text: "",
    id: "",
  };

  export let text = "";
  export let media = [];
  export let buttons = [];

  let img = media ? media[0] : [];

  export let backgroundColor = "";
  export let backgroundColorFit = "";
  const isCover = backgroundColorFit === "Cover";
  const isContain = backgroundColorFit === "Contain";

  const md = new MarkdownIt({
    breaks: true,
    html: true,
  });

  $: useExternalPadding = isContain || !backgroundColorFit;

  // set default heading if none set via CMS
  if (!heading.font) {
    heading.font = "text";
  }

  $: outerBgClass = isCover ? backgroundColor : "";

  let screenSize;
</script>

<svelte:window bind:innerWidth={screenSize} />

<section
  {id}
  class="ca-section block-panel-media-text {outerBgClass}"
  class:panel-topbottom-paddings={useExternalPadding}
>
  <div class="container-xl">
    <div class="panel-topbottom-paddings {backgroundColor}">
      <div class:container-fluid={backgroundColor !== null}>
        <div class="row">
          <div class="col-12 col-lg-10 offset-lg-1">
            <div class="row align-items-lg-center">
              <div
                class="col-12 col-lg-6"
                class:order-lg-1={img?.align?.toLowerCase() === "right"}
              >
                {#if img?.type === "Embed"}
                  {@html img.embed}
                {:else}
                  <img
                    src={img?.image?.url}
                    alt={img?.image?.alt}
                    class="mx-auto mx-lg-0"
                    loading="lazy"
                  />
                {/if}
                <!-- on screens below large (phones/tablets), this heading will show -->
                {#if screenSize < 992}
                  <Heading
                    {...{
                      text: heading.text,
                      font: "heading",
                      size: "3xl",
                      classList: "text-center heading-text",
                    }}
                  />
                {/if}
              </div>
              <div
                class="col-12 col-lg-6 ps-xl-7"
                class:ps-xl-7={img?.align === "Left"}
                class:pe-xl-7={img?.align === "Right"}
              >
                {#if eyebrow}
                  <Eyebrow tag={eyebrowTag}>{eyebrow}</Eyebrow>
                {/if}
                <!-- on screens large and above, this heading will show -->
                {#if screenSize >= 992}
                  <Heading
                    {...{
                      text: heading.text,
                      font: "text",
                      size: "2xl",
                      classList: "mb-3 heading-text",
                    }}
                  />
                {/if}
                <div class="ca-split__intro text-center text-lg-start">
                  {@html md.render(text || "")}
                </div>

                <PanelButtonsGroup {buttons} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography_mixins" as *;
  @use "$sass/base/panels" as *;

  .panel-topbottom-paddings {
    @extend .ca-panel-paddings;
  }

  :global(.heading-text.ca-font-heading) {
    @include media-breakpoint-down(lg) {
      margin-top: 2rem;
      @include text-mobile-subhead-1;
    }
  }
</style>
