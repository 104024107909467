<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";
  import { accentColors } from "$lib/constants";

  export let title;
  export let url;
  export let content;
  // using backgroundColor prop for accent color
  export let backgroundColor;
</script>

<div class="resource-text-only">
  <a data-sveltekit-reload href={url}>
    <div>
      <Heading
        {...{
          text: title,
          level: 2,
          font: "text",
          size: "xl",
          classList: "align-left",
        }}
      />

      <div class="text-container">
        {content}
      </div>
    </div>

    {#if url}
      <svg
        width="41"
        height="41"
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style="--accent-color: {accentColors[backgroundColor]};"
      >
        <circle cx="20.5" cy="20.3691" r="20" fill="#EFEAE8" />
        <rect
          width="16"
          height="16"
          transform="translate(12.5 12.3691)"
          fill="#EFEAE8"
        />
        <path
          d="M27.7667 20.3742L22.0624 14.6699L21.3557 15.3767L25.8534 19.8744L13.2334 19.8744V20.8739L25.8535 20.8739L21.3557 25.3717L22.0624 26.0785L27.7667 20.3742Z"
          fill="black"
        />
      </svg>
    {/if}
  </a>
</div>

<style lang="scss">
  @use "$sass/base/typography_mixins" as *;

  .resource-text-only {
    border-top: 3px solid hsla(17, 18%, 92%, 1);
    padding-top: 1rem;
    height: 100%;

    a {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }

    .text-container {
      @include text-body-2;
      padding: 4px 0px 16px 0px;
    }

    svg {
      margin-right: auto;
      circle,
      rect {
        fill: var(--accent-color);
      }
    }
  }

  // only want hover effects on desktop
  @media (min-width: 768px) {
    .resource-text-only {
      svg {
        transition: transform 1s
          linear(
            0 0%,
            0.22 2.1%,
            0.86 6.5%,
            1.11 8.6%,
            1.3 10.7%,
            1.35 11.8%,
            1.37 12.9%,
            1.37 13.7%,
            1.36 14.5%,
            1.32 16.2%,
            1.03 21.8%,
            0.94 24%,
            0.89 25.9%,
            0.88 26.85%,
            0.87 27.8%,
            0.87 29.25%,
            0.88 30.7%,
            0.91 32.4%,
            0.98 36.4%,
            1.01 38.3%,
            1.04 40.5%,
            1.05 42.7%,
            1.05 44.1%,
            1.04 45.7%,
            1 53.3%,
            0.99 55.4%,
            0.98 57.5%,
            0.99 60.7%,
            1 68.1%,
            1.01 72.2%,
            1 86.7%,
            1 100%
          );
        circle,
        rect {
          transition: all 0.5s;
        }
      }

      &:hover {
        svg {
          transform: translateX(5px);
          circle,
          rect {
            filter: brightness(90%);
          }
        }
      }
    }
  }

  // small mobile, when grid drops to one column
  @media (max-width: 767px) {
    .resource-text-only {
      padding-top: 24px;
      .text-container {
        padding-bottom: 40px;
      }
      a {
        display: grid;
        grid-template-columns: 1fr 40px;
        column-gap: 24px;
      }
    }
  }
</style>
