<script lang="ts">
  import Schema from "../../parts/Schema.svelte";
  import { page } from "$app/stores";
  import {
    getBlogArticleBreadcrumbs,
    getJsonLDCrumbData,
  } from "$lib/breadcrumbs/breadcrumbs";
  import type { UIBreadcrumbItem } from "$lib/breadcrumbs/types";

  export let breadcrumbs: UIBreadcrumbItem[] = [];

  $: crumbs = breadcrumbs || getBlogArticleBreadcrumbs({ page: $page }) || [];
  $: filteredBreadcrumbs = crumbs.filter((crumb) => crumb.name);
  $: jsonLDData = getJsonLDCrumbData({
    breadcrumbs: filteredBreadcrumbs,
    baseURL: $page.url?.origin,
  });
</script>

<Schema {jsonLDData} className="breadcrumbs" />
<div class="container-xl outer-container">
  <div class="breadcrumbs-container">
    <div class="breadcrumbs-menu">
      {#each filteredBreadcrumbs as breadcrumb, i}
        <a class="breadcrumbs-item" href={breadcrumb.url} data-sveltekit-reload
          >{breadcrumb.name}</a
        >
        {#if i !== filteredBreadcrumbs.length - 1}
          <span>/</span>
        {/if}
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  .outer-container {
    // position: relative;
    position: absolute;
    margin: 1rem 0;
  }
  // .breadcrumbs-container {
  // 	position: absolute;
  // 	top: 14px;
  // 	padding-bottom: 1rem;
  // }

  .breadcrumbs-menu {
    display: flex;
    gap: 6px;
  }

  .breadcrumbs-item {
    min-width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0.03em;

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }

  // mobile
  @media (max-width: 991px) {
    .outer-container {
      display: none;
    }
  }
</style>
