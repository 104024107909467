<script lang="ts">
  import AccordionPrimary from "$components/Accordion/variants/AccordionPrimary.svelte";
  import AccordionTitle from "$components/Accordion/variants/AccordionTitle.svelte";
  import AccordionFAQ from "$components/Accordion/variants/AccordionFAQ.svelte";
  import { accentColors } from "$lib/constants";

  /** @type {'primary' | 'title' | 'faq'} */
  export let variant;
  export let items;
  export let heading;
  export let text;
  export let accentColor;

  $: variant = variant || "primary";
  $: items = items || [];
  $: heading = heading || {};
  $: text = text || "";

  const variants = {
    primary: AccordionPrimary,
    title: AccordionTitle,
    faq: AccordionFAQ,
  };
</script>

<svelte:component
  this={variants[variant]}
  {items}
  {heading}
  {text}
  accentColor={accentColors[accentColor]}
/>
