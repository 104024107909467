<script lang="ts">
  export let image;
  export let title;
  export let content;
  export let cta;
  export let backgroundColor;
  export let alignment;
  export let tag;
  // add width and height here as well, being pulling in from cms
  // console.log($$props);

  const isSelectedColor = (color) => color === backgroundColor;
</script>

<div
  class="card-nav-feat-container"
  class:ca-bg--light-blue={isSelectedColor("light-blue") || !backgroundColor}
  class:ca-bg--light-orange={isSelectedColor("light-orange")}
  class:ca-bg--light-beige={isSelectedColor("light-beige")}
  class:ca-bg--light-cream={isSelectedColor("light-cream")}
  class:stretch={alignment === "stretch"}
>
  <div class="inner-container">
    <a data-sveltekit-reload href={cta.url} target="_self">
      <div class="image-section">
        <img class="lazyload" data-src={image.src} alt={image.alt} />
      </div>
      <div class="text-section">
        <div class="header-text">
          {@html title}
          {#if tag}
            <div class="tag-container">{tag}</div>
          {/if}
        </div>
        <div class="main-text">
          {content}
        </div>
        <div class="link-text">
          {cta.text}
          <div class="arrow-container">
            <svg
              width="16"
              height="14"
              viewBox="0 0 22 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.9001 9.00663L13.3437 0.450195L12.2835 1.51033L19.0301 8.25689L0.100098 8.25689V9.75614L19.0303 9.75615L12.2835 16.5029L13.3437 17.5631L21.9001 9.00663Z"
                fill="black"
              /></svg
            >
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<style lang="scss">
  .card-nav-feat-container {
    display: flex;
    transition: 0.25s ease-in-out;
    &.stretch {
      height: 96%;
      align-items: center;
    }
    .image-section {
      display: flex;
      justify-content: center;
    }
    .text-section {
      padding: 26px;
    }
    .header-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 0.5rem;
      width: max-content;
      .tag-container {
        display: inline;
        background-color: white;
        padding: 4px 10px 4px 10px;
        margin-left: 10px;
        border-radius: 17px;
        width: fit-content;
        height: 28px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
      }
    }
    .main-text {
      font-size: 15px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 1rem;
    }
    .link-text {
      display: flex;
      font-size: 16px;
      font-weight: 600;
      line-height: 30px;
      .arrow-container {
        display: none;
        opacity: 0;
        transition: opacity 0.25s;
        background-color: white;
        justify-content: center;
        // margin-top: -2px;
        margin-left: 1rem;
        border-radius: 16px;
        height: 31px;
        width: 31px;
      }
    }
    @media (min-width: 992px) {
      .link-text .arrow-container {
        display: flex;
      }
      &:hover {
        transform: scale(1.01);
        .arrow-container {
          opacity: 1;
        }
      }
    }
  }
  // mobile
  @media (max-width: 991px) {
    .card-nav-feat-container {
      .image-section {
        display: none;
      }
      .text-section {
        padding: 26px;
      }
    }
  }
</style>
