<script lang="ts">
  import Flyout from "./Flyout.svelte/Flyout.svelte";

  export let item = {};

  let hoverActive = false;
  let clickActive = false;

  let colorTheme = item?.colorTheme;

  // const handleBlur = (event) => {
  // 	const currentTarget = event.currentTarget;

  // 	requestAnimationFrame(() => {
  // 		if (!currentTarget.contains(document.hoverActiveElement)) {
  // 			hoverActive = false;
  // 		}
  // 	});
  // };
  // on:focusout={handleBlur}

  // item.title.slice(0, 3) === 'Pro'
</script>

<li
  class="ca-menu__item"
  on:mouseenter={() => (hoverActive = true)}
  on:mouseleave={() => (hoverActive = false)}
  on:focusin={() => (hoverActive = true)}
  style={`--colorTheme: var(--clr-${colorTheme}, var(--clr-light-blue))`}
>
  <!--will show drop down option if sub items  -->
  {#if item?.items?.length > 0}
    <button
      class="ca-menu__btn"
      class:hoverActive
      class:clickActive
      type="button"
      id="btn-item-{item.id}"
      aria-controls="dropdown-menu-{item.id}"
      aria-expanded={hoverActive}
      data-toggle="ca-dropdown-menu-{item.id}"
      on:click={() => (clickActive = !clickActive)}
    >
      <span>{item.title}</span>
      <svg
        class="ca-menu__arr"
        width="15"
        height="8"
        viewBox="0 0 15 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 1 7.5 7 1 1"
          stroke="#000"
          stroke-linecap="square"
          stroke-linejoin="round"
        />
      </svg>
    </button>

    <div
      class="ca-dropdown-menu"
      class:hoverActive
      class:clickActive
      id="ca-dropdown-menu-{item.id}"
    >
      <Flyout variant={item.layout} {item} />
    </div>
    <div
      class="item-underline"
      class:show={!(clickActive && item.layout === "static-columns")}
    />
  {:else}
    <a
      target="_self"
      href={item.url}
      class="ca-menu__btn no-drop"
      class:hoverActive
      id="btn-item-{item.id}"
    >
      <span>{item.title}</span>
    </a>
    <div
      class="item-underline"
      class:show={!(clickActive && item.layout === "static-columns")}
    />
  {/if}
</li>

<style lang="scss">
  .ca-menu__item {
    position: initial;

    & > .ca-menu__btn > span {
      position: relative;
    }
    span::after {
      opacity: 0;
      display: block;
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: black;
      top: calc(100% + 8px);
      content: " ";
      transition: 200ms ease;
    }
  }
  .ca-menu__btn {
    color: var(--clr-global-body);
    padding: 10px 0;
    // display: flex;
    display: grid;
    grid-template-columns: 1fr 15px;
    align-items: center;
    gap: 8px;
    font-size: 15px;
    font-weight: 500;
    background-color: transparent;
    border: none;
    text-decoration: none;
    text-align: left;
    cursor: pointer;
    &.no-drop {
      grid-template-columns: 1fr;
    }
    @media (min-width: 992px) {
      &.hoverActive {
        span {
          &::after {
            opacity: 1;
          }
        }
      }
      &.hoverActive {
        .ca-menu__arr {
          transform: rotate(180deg);
        }
      }
    }
  }
  .ca-dropdown-menu {
    display: none;
    width: 100%;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: calc(100% - 22px);
    left: 0;
    padding: 40px 0;
    background-color: white;
    font-size: 18px;
    min-height: 500px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    // padding: 62px 0 40px;
    @media (min-width: 992px) {
      &.hoverActive {
        display: flex;
      }
    }
  }
  .item-underline {
    display: none;
  }

  // mobile
  @media (max-width: 991px) {
    .ca-menu__item {
      position: relative;
    }
    .ca-menu__btn {
      height: 58px;
      width: 100%;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      &.clickActive {
        .ca-menu__arr {
          transform: rotate(180deg);
        }
      }
    }
    .ca-dropdown-menu {
      &.clickActive {
        display: flex;
        position: relative;
      }
      min-height: unset;
      padding: 15px 0;
      box-shadow: unset;
      margin-bottom: 1rem;
    }
    .item-underline.show {
      display: unset;
      background: #d8d8d8;
      height: 1px;
      width: 100vw;
      position: absolute;
      left: -25px;
    }
  }
</style>
