<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";
  import Button from "$components/Button/Button.svelte";
  import { icons } from "$lib/constants";

  export let title;
  export let url;
  export let backgroundColor;
  export let icon;
  export let cta;
  let isHovered = false;
</script>

<a
  href={url}
  target="_self"
  data-sveltekit-reload
  class="classroom-with-icon"
  on:mouseenter={() => (isHovered = true)}
  on:mouseleave={() => (isHovered = false)}
>
  <div
    class="classroom-with-icon-media"
    class:has-background-color={backgroundColor !== ""}
    class:ca-bg--highlight-green={backgroundColor === "highlight-green"}
    class:ca-bg--highlight-yellow={backgroundColor === "highlight-yellow"}
    class:ca-bg--midtone-green={backgroundColor === "midtone-green"}
    class:ca-bg--midtone-orange={backgroundColor === "midtone-orange"}
    class:ca-bg--light-green={backgroundColor === "light-green"}
    class:ca-bg--light-orange={backgroundColor === "light-orange"}
    class:ca-bg--light-beige={backgroundColor === "light-beige"}
    class:ca-bg--light-cream={backgroundColor === "light-cream"}
    class:ca-bg--light-blue={backgroundColor === "light-blue"}
    class:ca-bg--black={backgroundColor === "black" ||
      backgroundColor === "neutral-black"}
    class:ca-bg--neutral-white={backgroundColor === "neutral-white"}
  >
    {#if icons[icon]?.()}
      {@html icons[icon]()}
    {/if}
  </div>
  <Heading
    {...{
      text: title,
      level: 2,
      font: "text",
      size: "2xl",
      classList: "align-left mt-3",
    }}
  />

  {#if cta?.url}
    <div class="cta">
      <span>{cta.text}</span><svg
        width="24"
        height="24"
        viewBox="0 0 22 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.9001 9.00663L13.3437 0.450195L12.2835 1.51033L19.0301 8.25689L0.100098 8.25689V9.75614L19.0303 9.75615L12.2835 16.5029L13.3437 17.5631L21.9001 9.00663Z"
          fill="black"
        /></svg
      >
    </div>
    <div class="cta-small">
      <Button
        variant="classroom-circle-right-arrow"
        isParentHovered={isHovered}
        tag="span"
      />
    </div>
  {/if}
</a>

<style lang="scss">
  .cta {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    margin-top: 1rem;

    font-weight: 500;

    text-decoration: underline;
    svg {
      transition: 500ms;
    }

    @media (hover: hover) {
      &:hover {
        svg {
          transform: translateX(0.5rem);
        }
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .cta-small {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      justify-content: end;
    }
  }
  .classroom-with-icon-media {
    width: 120px;
    height: 120px;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .classroom-with-icon {
    @media (max-width: 768px) {
      border-top: 3px solid #000;
      padding-top: 0.5rem;
    }
    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 1fr 65px;
      align-items: start;
      gap: 1rem;
      align-items: center;
    }
  }
</style>
