import type { ImageFormat } from '$types/cfQueries';
import type { ImageFit } from '$types/cfQueries';

/**
 * Generates the final `src` attribute for the `<img />` tag
 *
 */
export function getFinalImageUrl(params: {
  /** Wether to use PJPG */
  useProgressiveJPG?: boolean;
  imageType: ImageFormat;
  /** The original image src */
  src: string;
  /** Width transformation */
  w?: number | string;
  /** Fit transformation, e.g. `fill` */
  fit?: ImageFit;
}) {
  const { imageType, useProgressiveJPG, src, fit, w } = params;
  const queryParams = new URLSearchParams();

  if (imageType) {
    queryParams.set('fm', imageType);
  }

  if (useProgressiveJPG) {
    queryParams.set('fm', 'jpg');
    queryParams.set('fl', 'progressive');
  }

  if (fit) {
    queryParams.set('fit', fit);
  }

  if (w) {
    queryParams.set('w', String(w));
  }

  try {
    const finalImage = new URL(src);
    finalImage.search = queryParams.toString();
    return finalImage.toString();
  } catch (err) {
    return src;
  }
}
