// @ts-strict-ignore
import Grid from "./Grid.svelte";

export const GridConfig = {
  name: "Grid",
  component: Grid,
  mapper: (props) => {
    return {
      ...props,
      buttons: props?.buttonCollection?.items || [],
      columns: props?.columnsCollection?.items || [],
    };
  },
  fragment: `fragment GridData on Grid {
        __typename
        variant
        columnsLarge
        columnsMedium
        columnsSmall
        gridWidth
        backgroundColor
        accentColor
        gridHero: hero 
        seeMoreButton
        initialItemsAmount
        buttonCollection (limit: 2) {
          items {
            url
            text
            variant
            icon
          }
        }
        columnsCollection(limit: 35) {
          items {
            __typename
            ... on WebPage {
              sys {
                id
              }
            }
            ... on Embed {
              snippet
              width
            }
            ... on Stat {
              text
              variant
              startValue
              useCountUpAnimation
              value
              prefix
              suffix
            }
            ... on Card {
                variant
                title
                content
                quote
                eyebrow
                image {
                  name
                  type
                  embed
                  align
                  image {
                    title
                    description
                    contentType
                    fileName
                    size
                    url
                    width
                    height
                  }
                }
                icon {
                  __typename
                  ... on Icon {
                    icon
                  }
                }
                url
                cta
                quote
                backgroundColor
                tag
                externalCategories
                date
                alignment
                author {
                  name
                  careerTitle
                  avatar {
                    title
                    description
                    contentType
                    fileName
                    size
                    url
                    width
                    height
                  }
                }
              }
          }
        }
    }`,
};
