<script lang="ts">
  import { onMount, tick } from "svelte";
  import { icons, bgColors, accentColors } from "$lib/constants";
  import { fade } from "svelte/transition";
  export let tabs = [];
  export let bgColor = "None";
  export let accentColor = "";

  let section;
  let navContainer;
  let selectedTabIndex = 0;
  let autoplayTimer;
  let inView = false;
  let buttons = [];

  const nextTab = () => {
    selectedTabIndex = (selectedTabIndex + 1) % tabs.length;
  };

  const previousTab = () => {
    selectedTabIndex = (selectedTabIndex - 1 + tabs.length) % tabs.length;
  };

  const startAutoplay = () => {
    if (!autoplayTimer && inView) {
      // Check if the timer isn't already running and the section is in view
      autoplayTimer = setInterval(nextTab, 3000);
    }
  };

  const stopAutoplay = () => {
    clearInterval(autoplayTimer);
    autoplayTimer = null;
  };

  // Intersection Observer callback
  function handleIntersection(entries) {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (!inView) {
          inView = true;
          startAutoplay();
        }
      } else {
        if (inView) {
          inView = false;
          stopAutoplay();
        }
      }
    });
  }

  onMount(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      rootMargin: "0px",
      threshold: 0,
    });

    observer.observe(section);

    return () => {
      observer.disconnect();
      stopAutoplay();
    };
  });

  async function scrollToActiveTab() {
    await tick();
    const activeButton = buttons[selectedTabIndex];
    if (activeButton && inView) {
      const scrollX = activeButton.offsetLeft - navContainer.offsetLeft - 40;
      navContainer.scrollLeft = scrollX;
    }
  }

  $: selectedTabIndex, scrollToActiveTab();

  $: _bgColor = bgColors[bgColor || "None"];
</script>

<div class={`tabs ${_bgColor}`} bind:this={section}>
  <div
    class="tabs-container container-xl"
    on:mouseover={stopAutoplay}
    on:focus={stopAutoplay}
    role="tablist"
    tabindex="0"
  >
    <nav class="tabs-nav" class:noArrows={tabs.length < 4}>
      <div class="tabs-nav-slider" bind:this={navContainer}>
        <div class="tabs-nav-inner">
          {#each tabs as tab, index}
            <button
              class="tabs-nav-button"
              class:tabs-nav-button--is-selected={selectedTabIndex === index}
              bind:this={buttons[index]}
              on:click={() => {
                selectedTabIndex = index;
                stopAutoplay();
              }}
            >
              {tab.title}
            </button>
          {/each}
        </div>
      </div>

      <div class="tabs-nav-arrows">
        <button
          class={`tabs-nav-arrows-button ${_bgColor}`}
          on:click={previousTab}
          aria-label="previous tab"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ><g id="SVGRepo_bgCarrier" stroke-width="0" /><g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            /><g id="SVGRepo_iconCarrier">
              <path
                d="M14.5 17L9.5 12L14.5 7"
                stroke={bgColor?.includes("black") ? `#fff` : `#000000`}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g></svg
          >
        </button>

        <button
          class={`tabs-nav-arrows-button ${_bgColor}`}
          on:click={nextTab}
          aria-label="next tab"
        >
          <svg
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            ><g id="SVGRepo_bgCarrier" stroke-width="0" /><g
              id="SVGRepo_tracerCarrier"
              stroke-linecap="round"
              stroke-linejoin="round"
            /><g id="SVGRepo_iconCarrier">
              <path
                d="M9.5 7L14.5 12L9.5 17"
                stroke={bgColor?.includes("black") ? `#fff` : `#000000`}
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g></svg
          >
        </button>
      </div>
    </nav>

    <div class="tabs-items">
      {#each tabs as tab, index (tab.title)}
        <div
          class="tab-body"
          class:activeTab={selectedTabIndex === index}
          transition:fade|global={{ duration: 250 }}
        >
          <div class="tab-body-content">
            <div class="tab-body-content-copy">
              {tab.content}
            </div>

            {#if tab.list}
              <ul class="tab-body-content-list">
                {#each tab.list as item}
                  <li class:list-item-no-text={!item.text}>
                    <div
                      class="list-item"
                      style={`--color: ${accentColors[accentColor] || "black"};`}
                    >
                      <div class="list-item-icon">
                        {@html icons[item.icon]?.(
                          accentColors[accentColor] || "black",
                        )}
                      </div>

                      <div>
                        <div class="list-item-heading">{item.title}</div>

                        {#if item.text}
                          <div class="list-item-text">
                            {item.text}
                          </div>
                        {/if}
                      </div>
                    </div>
                  </li>
                {/each}
              </ul>
            {/if}
          </div>

          <div class="image-container">
            {#if tab.image}
              <img src={tab.image.src} alt={tab.image.alt} />
            {/if}
          </div>
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  @use "$sass/base/typography_mixins" as *;

  .tabs-container {
    max-width: 1100px;
  }
  .list-item {
    display: grid;
    grid-template-columns: 50px 1fr;

    &-heading {
      color: var(--color);
      font-weight: 500;
    }

    &-icon {
      :global(svg) {
        width: 36px;
      }
    }
  }
  .tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .tabs-nav {
    width: 100%;
    padding-bottom: 3px;
    position: relative;
    margin-bottom: 32px;
    overflow: hidden;
  }

  .tabs-nav-slider {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .tabs-nav-inner {
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;
    min-width: max-content;
    width: 100%;
    padding-bottom: 12px;
    margin-left: 19px;
    margin-right: 19px;

    border-bottom: 1px solid rgba(0, 0, 0, 0.2);

    .ca-bg--black & {
      border-color: rgba(#fff, 0.2);
    }
  }
  .tabs-nav-button {
    appearance: none;
    border: none;
    background-color: transparent;
    padding: 0;
    margin: 0;
    cursor: pointer;
    display: none;

    font-size: 18px;
    line-height: 24px;
    color: inherit;

    &::after {
      content: "";
      position: absolute;
      height: 3px;
      width: 70%;
      bottom: -14px;
      left: 0;
      transition: 250ms;
      transform: scaleX(0);
      transform-origin: center left;
      background-color: #000;

      .ca-bg--black & {
        background-color: #fff;
      }
    }

    &--is-selected {
      display: unset;
      font-weight: 600;
      position: relative;

      &::after {
        transform: scaleX(1);
      }
    }
  }

  .tabs-items {
    display: grid;
  }
  .tabs-items > * {
    grid-area: 1 / 1;
  }

  .tab-body {
    display: none;
    grid-template-columns: 1fr;
    height: min-content;
    gap: 32px;

    @media (width >= 960px) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .tab-body.activeTab {
    display: grid;
    // animation: fade-in 0.5s forwards;
  }
  .tab-body-content {
    @include text-web-body-1;

    ul {
      margin-top: 32px;
      padding-left: 0;
      list-style-type: none;

      @media (width >= 960px) {
        margin-top: 54px;
      }

      li {
        margin-top: 32px;

        @media (width >= 960px) {
          &:not(.list-item-no-text) {
            margin-top: 24px;
          }
        }
      }
    }
  }

  .tabs-nav-arrows-button {
    appearance: none;
    border: none;
    border-bottom: solid transparent 13px;
    background-color: white;
    padding: 0;
    cursor: pointer;

    position: absolute;
    top: 0;

    &:first-child {
      left: -11px;
    }

    &:last-child {
      right: -11px;
    }

    svg {
      width: 30px;
      height: 26px;
    }
  }

  // desktop only
  @media (min-width: 992px) {
    .tabs {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .tabs-nav-slider {
      overflow-x: auto;
      scroll-behavior: smooth;
    }
    .tabs-nav-button {
      display: unset;
    }
    .tab-body {
      gap: 25px;
    }
    .tab-body-content {
      padding-top: 4rem;
    }
    .tab-body-content-list {
      max-width: 500px;
    }
    .noArrows {
      .tabs-nav-arrows-button {
        display: none;
      }
      .tabs-nav-inner {
        margin-left: 0px;
        margin-right: 0px;
      }
    }

    .tabs-nav {
      margin-bottom: 48px;
    }
  }
</style>
