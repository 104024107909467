<script lang="ts">
  import Button from "$components/Button/Button.svelte";

  export let buttons = [];
  export let ucHero;
</script>

{#if buttons.length > 0}
  <div
    class="gap-4 d-flex justify-content-center justify-content-lg-start"
    class:mt-4_5={!ucHero}
    class:mt-md-4={!ucHero}
  >
    {#each buttons as button}
      <Button {...button} />
    {/each}
  </div>
{/if}
