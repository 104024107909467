// @ts-strict-ignore
import { localesMap, regionToCountryObject } from "./constants";
import { addDays, differenceInDays } from "date-fns";
import type { LocaleKeys, Regions } from "$types/utils";
import type { GetWebPageBySlugParentTreeFragment } from "$types/contentful-queries";

export function loadScript(src) {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = src;

    document.body.appendChild(script);

    script.addEventListener("load", () => resolve(script));
    script.addEventListener("error", () => reject(script));
  });
}

export function serializeSchema(data) {
  return `<script type="application/ld+json">${JSON.stringify(data, null, 2)}</script>`;
}

export function trailingslashit(str: string) {
  if (typeof str !== "string") {
    console.warn("Trailingslashit input must be a string.");
    return "/";
  }

  if (str === "") {
    return "/";
  }

  // if string uses # anchor link, dont add trailing slash
  if (str.match(/#(?:[^/]+)$/)) {
    return str;
  }

  // if string has query params, dont add trailing slash
  if (str.match(/\?.+=.+/)) {
    return str;
  }

  if (str.endsWith("/")) {
    return str;
  }

  return str + "/";
}

export function removeLeadingSlash(str: string): string | undefined {
  if (typeof str !== "string") {
    console.warn("removeLeadingSlash input must be a string.");
    return;
  }

  if (str.startsWith("/")) {
    return str.slice(1);
  } else {
    return str;
  }
}

// TODO: we have a third party library and this function. Do we need both?
export function slugify(str: string): string {
  return str
    .toLowerCase() // Convert the string to lowercase
    .replace(/[^a-zA-Z0-9-]/g, "-") // Replace non-alphanumeric characters with dashes
    .replace(/-+/g, "-") // Replace multiple dashes with a single dash
    .replace(/^-|-$/g, ""); // Remove dashes from the beginning or end
}

/**
 *
 * @param {string} path URI like /blog/my-article
 * @param {"en-US" | "en-GB" | "en-SG"} [locale] If provided, breadcrumb urls localized
 * @returns {import('./breadcrumbs/types').UIBreadcrumbItem[]} List of crumbs. Name title-cased
 *
 *  ```
 *  [{ name: 'Blog', url: '/blog' }, { name: 'My Article', url: '/blog/article-1' }]
 *  ```
 */
export function generateBreadcrumbs(path, locale = "en-US") {
  const segments = path.replace(/^\/|\/$/g, "").split("/");
  const breadcrumbs = segments.map((segment, index) => {
    const name = segment
      .replace(/-/g, " ")
      .replace(/\b\w/g, (l) => l.toUpperCase());
    const url = "/" + segments.slice(0, index + 1).join("/") + "/";
    const localePrefix = localesMap[locale];
    const localizedUrl = localePrefix ? `/${localePrefix}${url}` : url;

    return { name, url: localizedUrl };
  });

  return breadcrumbs;
}

type GetWordCountArgs = {
  nodeType: string;
  value?: string;
  content?: GetWordCountArgs[];
};
export function getWordCount(content: GetWordCountArgs[]): number {
  // should add some time for embedded assest blocks as well?
  let count = 0;

  for (const node of content) {
    if (node.nodeType === "text") {
      const wC = node.value?.split(" ").length;
      if (wC) {
        count += wC;
      }
    } else if (node.content) {
      count += getWordCount(node.content);
    }
  }

  return count;
}

/**
 * Constructs a path string from a nested object representing a hierarchical structure,
 * based on the `slug` properties at each level.
 *
 * This function recursively traverses the hierarchical structure, starting from the given object,
 * prepending each object's `slug` property to an accumulator array. Once the topmost parent
 * (i.e., an object without a `parent` property) is reached, the accumulated slugs are joined
 * into a path string, separated by slashes. This approach effectively builds a path from
 * the deepest nested child back to the root.
 *
 * @param {Object} obj - The object from which to start building the path. It must contain a `slug` property.
 *                       Optionally, it may contain a `parent` property pointing to another object
 *                       of the same structure.
 * @returns {string} The constructed path string, with each level's slug separated by a forward slash.
 *
 * @example
 * // Define a nested object structure
 * const obj = {
 *   slug: 'child',
 *   parent: {
 *     slug: 'parent',
 *     parent: {
 *       slug: 'grandparent'
 *     }
 *   }
 * };
 *
 * // Build the path
 * const path = buildSlugPathFromNestedObject(obj);
 * console.log(path); // Output: "grandparent/parent/child"
 */

export const buildSlugPathFromNestedObject = ({
  slug,
  parent,
}: GetWebPageBySlugParentTreeFragment): string => {
  const recursiveBuildSlugPathFromNestedObject = (
    { slug, parent }: GetWebPageBySlugParentTreeFragment,
    acc: string[] = [],
  ): string => {
    if (slug) {
      acc.unshift(slug);
    }
    return parent
      ? recursiveBuildSlugPathFromNestedObject(parent, acc)
      : acc.join("/");
  };
  return recursiveBuildSlugPathFromNestedObject({ slug, parent });
};

export function capitalize(str) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isCurrentDateInRange(
  startDate: string,
  endDate: string,
): boolean {
  const currentDate = new Date();
  const start = new Date(startDate);
  const end = new Date(endDate);

  return currentDate >= start && currentDate <= end;
}

type BannerMessageDateRange = {
  start: string;
  end: string;
  message: string;
};

export function getBannerMessageForCurrentDateTime(
  dateRanges: BannerMessageDateRange[],
): string | null {
  const currentUserTime = new Date();
  const ptToUtcOffset = 0; // PT offset in minutes (UTC-8, not accounting for daylight saving time)

  for (const range of dateRanges) {
    // Convert PT times to UTC
    let startDateTime = new Date(range.start);
    let endDateTime = new Date(range.end);
    startDateTime = new Date(startDateTime.getTime() + ptToUtcOffset * 60000);
    endDateTime = new Date(endDateTime.getTime() + ptToUtcOffset * 60000);

    if (currentUserTime >= startDateTime && currentUserTime <= endDateTime) {
      return range.message;
    }
  }

  return null;
}

export function addLocaleToUrl(url, locale = "en-US") {
  let result = [localesMap[locale] && `/${localesMap[locale]}`, url]
    .filter(Boolean)
    .join("");
  result = trailingslashit(result);
  return result;
}

export type CMWGeolocationAPI = {
  geo: {
    city: string;
    country: {
      code: string;
      name: string;
    };
    subdivision: {
      code: string;
      name: string;
    };
    timezone: string;
    latitude: number;
    longitude: number;
    postalCode: string;
  };
};

const maybeGetGeolocationResponseFromSS = (): CMWGeolocationAPI | undefined => {
  const sessionStorageAvailable = storageAvailable("sessionStorage");
  if (sessionStorageAvailable) {
    const geolocationInSessionStorage = sessionStorage.getItem("userRegion");
    if (geolocationInSessionStorage) {
      return JSON.parse(geolocationInSessionStorage);
    }
  }
  return undefined;
};

export async function getUserLocale(): Promise<LocaleKeys | undefined> {
  let userRegion: LocaleKeys = "en-US";
  let geolocationResponse = maybeGetGeolocationResponseFromSS();

  if (!geolocationResponse) {
    try {
      const response = await fetch(
        "https://cmw-utilities.netlify.app/geolocation",
      );
      geolocationResponse = (await response.json()) as CMWGeolocationAPI;
      if (storageAvailable("sessionStorage")) {
        sessionStorage.setItem(
          "userRegion",
          JSON.stringify(geolocationResponse),
        );
      }
    } catch (error) {
      console.error("issue in getCountry\n", error);
    }
  }

  if (geolocationResponse) {
    const { geo } = geolocationResponse;
    const countryCode = geo?.country?.code;

    for (const region in regionToCountryObject) {
      const regionArray = regionToCountryObject[region];
      if (regionArray.includes(countryCode)) {
        userRegion = region as LocaleKeys;
        break;
      }
    }

    return userRegion;
  }
}

type TempURLFixArgs = {
  featuredLink?: string;
  url?: { uri?: string } | string;
  uri?: string;
};

export function tempURLFix(obj: TempURLFixArgs, url: string) {
  let link: string | null =
    obj?.featuredLink ||
    (typeof obj.url === "object" ? obj?.url?.uri : null) ||
    obj?.uri ||
    (typeof obj.url === "string" ? obj?.url : null);

  if (link?.startsWith("http")) {
    return trailingslashit(link);
  }

  return trailingslashit(url + "/" + link);
}

// From https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#testing_for_availability
export function storageAvailable(type: "localStorage" | "sessionStorage") {
  let storage: Storage | undefined;

  try {
    storage = window[type];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return (
      e instanceof DOMException &&
      e.name === "QuotaExceededError" &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}

type UserSelectedRegion = {
  region: Regions;
  expiration: string;
};

export const getUserSelectedRegion = (): Regions | null => {
  if (storageAvailable("localStorage")) {
    const userSelectedRegionInLS = localStorage.getItem("userSelectedRegion");

    const parsedUserSelectedRegion: UserSelectedRegion | null =
      userSelectedRegionInLS ? JSON.parse(userSelectedRegionInLS) : null;

    if (!parsedUserSelectedRegion) return null;

    const expirationDate = parsedUserSelectedRegion.expiration;
    const dayDiff = differenceInDays(expirationDate, new Date());

    if (dayDiff < 0) return null;

    return parsedUserSelectedRegion.region;
  }
  return null;
};

export const setUserSelectedRegion = (region: Regions) => {
  if (storageAvailable("localStorage")) {
    const expirationDate = addDays(new Date(), 7);
    const userSelectedRegion = {
      region,
      expiration: expirationDate.toISOString(),
    };
    localStorage.setItem(
      "userSelectedRegion",
      JSON.stringify(userSelectedRegion),
    );
    return true;
  }
  return false;
};

export const getContinueUrl = (url: string) => {
  const regexForAbsoluteURL = /^https?:\/\//i;

  if (regexForAbsoluteURL.test(url)) {
    const urlToReturn = new URL(url);
    return trailingslashit(urlToReturn.pathname);
  }

  return url;
};

export const stripLocaleFromPath = (path: string): string => {
  if (typeof path !== "string") {
    console.warn("stripLocaleFromPath input must be a string.");
    return "/";
  }

  // Remove leading `/uk/en` or `/sg/en`
  path = path.replace(/^\/(uk|sg)\/en/, "");

  // Ensure the path starts and ends with a slash
  if (!path.startsWith("/")) {
    path = "/" + path;
  }
  if (!path.endsWith("/")) {
    path = path + "/";
  }

  return path;
};
