<script lang="ts">
  import MarkdownIt from "markdown-it";
  import { bgColors } from "../../../lib/constants";
  import Button from "$components/Button/Button.svelte";
  export let id = "";
  export let text = "";
  export let backgroundColor = bgColors["Light Beige"];
  export let buttons;

  const MAX_CHARS = 80;

  const md = new MarkdownIt({
    breaks: true,
    html: true,
  });

  $: mainButton = buttons[0];

  function getDisplayText(text) {
    if (text?.length <= MAX_CHARS) {
      return text;
    } else {
      return String(text).substring(0, MAX_CHARS) + "...";
    }
  }

  $: mainText = getDisplayText(text);
</script>

<div class="ca-section">
  <div class="container-xl">
    <div class={`${backgroundColor} ${id} panel-container`}>
      <div class="bannerRow">
        <div class="ca-flow primary-action">
          {@html md.render(mainText)}
        </div>
        <Button
          variant="text-bounce"
          url={mainButton?.url}
          target="_self"
          text={mainButton?.text}
        />
      </div>
    </div>
  </div>
</div>

<style lang="scss">
  @use "$sass/abstracts" as *;
  @use "$sass/abstracts/breakpoints" as *;
  @use "$sass/base/typography_mixins" as *;
  @use "$sass/base/panels" as *;

  .ca-section {
    display: flex;
  }

  .container-xl {
    @extend .ca-panel-margins;
  }

  .panel-container {
    padding: 1.5rem;
    @include media-breakpoint-up(md) {
      padding: 1.8125rem 2.625rem;
    }
  }

  .trim {
    max-width: 80ch;
    text-overflow: ellipsis;
    overflow: hdiden;
  }
  .bannerRow {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: space-between;
    align-items: start;
    .primary-action {
      @include text-mobile-body-1;
      @include media-breakpoint-up(md) {
        @include text-web-body-1;
      }
    }

    @include media-breakpoint-up(md) {
      gap: 4rem;
      flex-direction: row;
      align-items: center;
    }

    :global(.ca-btn--text-bounce) {
      @include text-mobile-body-1;
      @include media-breakpoint-up(md) {
        @include text-web-body-1;
        font-weight: 500;
      }
    }
  }
</style>
