<script lang="ts">
  import { trailingslashit } from "../../lib/helpers";
  import { cardVariants } from "./cardsMapping";

  /**
   * Type of card to use. See Storybook Components>Card
   * Defaults to `featured`.
   *
   * @type {import('./card.types').CardVariants}
   **/
  export let variant;
  export let title;
  export let content;
  export let eyebrow;
  export let image;
  export let url;
  export let cta;
  export let quote;
  export let backgroundColor;
  export let tag;
  export let categories;
  export let date;
  export let alignment;
  export let icon;
  export let author;
  export let authors;
  export let lazyloadImages;

  const variants = cardVariants;

  $: variant = variant || "featured";
  $: title = title || "";
  $: content = content || "";
  $: eyebrow = eyebrow || "";
  $: image = image || {};
  $: url = url
    ? url.slice(0, 6) === "mailto"
      ? url
      : trailingslashit(url)
    : "";
  $: cta = cta || {};
  $: quote = quote || "";
  $: backgroundColor = backgroundColor || "";
  $: tag = tag || "";
  $: categories = categories || [];
  $: date = date || "";
  $: alignment = alignment || "";
  $: icon = icon || "";
  $: authors = authors || [];
</script>

<svelte:component
  this={variants[variant]}
  {title}
  {content}
  {image}
  {url}
  {cta}
  {quote}
  {backgroundColor}
  {eyebrow}
  {tag}
  {categories}
  {date}
  {alignment}
  {icon}
  {author}
  {authors}
  {lazyloadImages}
/>
