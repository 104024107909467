<script lang="ts">
  import Heading from "$components/Heading/Heading.svelte";
  import Button from "$components/Button/Button.svelte";

  export let heading = {
    text: "",
    id: "",
    font: "text",
    size: "4xl",
    tag: "h2",
    classList: "align-left",
  };

  export let text;

  export let buttons;
</script>

<section class="section">
  <div class="container-xl section-inner">
    {#if heading}
      <Heading
        {...{
          ...heading,
          //font: '',
          size: "3xl",
          tag: "h2",
          classList: "align-left mb-4_5 section-title",
        }}
      />
    {/if}

    {#if text}
      <div class="section-text">{@html text}</div>
    {/if}

    <slot />

    {#if buttons?.length > 0}
      <div class="section-buttons">
        {#each buttons as button}
          <Button {...button} />
        {/each}
      </div>
    {/if}
  </div>
</section>

<style lang="scss">
  .section-text {
    margin-top: -1.5rem;
    margin-bottom: 2.5rem;
    max-width: 555px;
    line-height: 1.5;

    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  .section-buttons {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-top: 4rem;
  }
  .section {
    padding-top: clamp(1rem, 0.68rem + 1.6vw, 2rem);
    padding-bottom: clamp(1rem, 0.68rem + 1.6vw, 2rem);
  }

  .section-inner {
    // This avoids having the scroll to go behind the filters submenu
    :global(.section-title),
    :global(section:not([id=""])) {
      scroll-margin-top: 200px;
    }

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 20px;

      background: url("/img/border-dotted.svg");
      background-color: #ffffff;
      background-size: 12px;
      background-repeat: repeat-x;
      margin-top: 64px;
    }
  }
  // mobile
  @media (max-width: 992px) {
    .section-text {
      font-size: 15px;
      font-weight: 400;
      line-height: 22px;
    }
  }
</style>
